

export default function AwsSDKLB({ values, onChangeOfConfiguration, isViewer }) {
    try {

        return (

            <>
                <div
                    class="accordion-body p-0 estimation-container">
                    <div className="row py-4 mx-4 px-5" hidden={(values.isPriceAvailable == "No") ? false : true}>
                        <div className="col-md-6 px-3"><span className="font-semibold font-14 text-danger">{values.isPriceAvailable == "No" ? "The Price is not available for the region / the configuration that you have selected might be not available in the region" : null}</span></div>
                    </div>
                    <div className="row py-4 mx-4 px-5">
                        <div className="col-md-6 px-3">
                            <label htmlFor="type" className="form-label font-14 font-medium black-v2 mb-3">
                                Load balancer type
                            </label>
                            <select
                                className="form-select custom-form font-semibold font-14 form-select-sm mb-3"
                                id="type"
                                value={values.type}
                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                name={"type"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="Application">Application Load Balancer</option>
                                <option value="Network">Network Load balancer</option>
                            </select>
                        </div>

                        <div className="col-md-6 px-3">
                            <label htmlFor="region" className="form-label font-14 font-medium black-v2 mb-3">
                                Region
                            </label>
                            <select
                                className="form-select custom-form font-semibold font-14 form-select-sm"
                                id="region"
                                value={values.region}
                                style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                name={"region"}
                                onChange={(e) => {
                                    onChangeOfConfiguration(e, values.instanceName);
                                }} disabled={isViewer}
                            >
                                <option value="ap-south-2">ap-south-2</option><option value="ap-southeast-3">ap-southeast-3</option><option value="ap-southeast-1">ap-southeast-1</option><option value="us-gov-east-1">us-gov-east-1</option><option value="eu-west-2">eu-west-2</option><option value="af-south-1">af-south-1</option><option value="ap-northeast-1">ap-northeast-1</option><option value="ap-southeast-2">ap-southeast-2</option><option value="us-east-2">us-east-2</option><option value="eu-central-1">eu-central-1</option><option value="ca-central-1">ca-central-1</option><option value="ap-south-1">ap-south-1</option><option value="ap-northeast-3">ap-northeast-3</option><option value="sa-east-1">sa-east-1</option><option value="ap-east-1">ap-east-1</option><option value="eu-west-1">eu-west-1</option><option value="eu-south-1">eu-south-1</option><option value="ap-southeast-4">ap-southeast-4</option><option value="eu-north-1">eu-north-1</option><option value="eu-south-2">eu-south-2</option><option value="us-west-1">us-west-1</option><option value="us-west-2">us-west-2</option><option value="me-south-1">me-south-1</option><option value="eu-central-2">eu-central-2</option><option value="us-gov-west-1">us-gov-west-1</option><option value="ap-northeast-2">ap-northeast-2</option><option value="me-central-1">me-central-1</option><option value="us-east-1">us-east-1</option><option value="eu-west-3">eu-west-3</option>
                            </select>
                        </div>



                        <> {values.type === "Application" && <>

                            <div>
                                <label htmlFor="loadBalancer" className="form-label font-14 font-medium black-v2 mb-3">
                                    Number of Application Load Balancers
                                </label>
                                <input
                                    type="text"
                                    style={{paddingTop:"9px"}}
                                    className="form-control custom-form text-field font-14 font-medium mb-3"
                                    id="loadBalancer"
                                    value={values.loadBalancer}
                                    name={"loadBalancer"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}

                                />
                            </div>

                            <div >
                                <h6 htmlFor="loadBalancer" className="form-label label-color  font-semibold" style={{ "margin-top": "1%", "margin-bottom": "4%" }}>
                                    Load Balancer Capacity Units (LCUs)
                                </h6>
                            </div>

                            <div className="col-md-6 px-3">
                                <label htmlFor="bytesLambda" className="form-label font-14 font-medium black-v2 mb-3">
                                    Processed bytes (Lambda functions as targets)-Value
                                </label>
                                <input
                                    type="text"
                                    style={{paddingTop:"9px"}}
                                    className="form-control custom-form text-field font-14 font-medium mb-3"
                                    id="bytesLambda"
                                    value={values.bytesLambda}
                                    name={"bytesLambda"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}

                                />
                            </div>

                            <div className="col-md-6 px-3">
                                <label htmlFor="unitBL" className="form-label font-14 font-medium black-v2 mb-3">
                                    Unit
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    id="unitBL"
                                    value={values.unitBL}
                                    style={{ marginTop: "16px", paddingTop: "8px", paddingBottom: "8px" }}
                                    name={"unitBL"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="GB per hour">GB per hour</option>
                                    <option value="GB per month">GB per month</option>
                                    <option value="TB per hour">TB per hour</option>
                                    <option value="TB per month">TB per month</option>
                                </select>
                            </div>

                            <div className="col-md-6 px-3">
                                <label htmlFor="bytesEC2" className="form-label font-14 font-medium black-v2 mb-3">
                                    Processed bytes (EC2 Instances and IP addresses as targets)- Value
                                </label>
                                <input
                                    type="text"
                                    style={{paddingTop:"9px"}}
                                    className="form-control custom-form text-field font-14 font-medium mb-3"
                                    id="bytesEC2"
                                    value={values.bytesEC2}
                                    name={"bytesEC2"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}

                                />
                            </div>

                            <div className="col-md-6 px-3">
                                <label htmlFor="unitBE" className="form-label font-14 font-medium black-v2 mb-3">
                                    Unit
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    id="unitBE"
                                    value={values.unitBE}
                                    name={"unitBE"}
                                    style={{ marginTop: "16px", paddingTop: "8px", paddingBottom: "8px" }}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="GB per hour">GB per hour</option>
                                    <option value="GB per month">GB per month</option>
                                    <option value="TB per hour">TB per hour</option>
                                    <option value="TB per month">TB per month</option>
                                </select>
                            </div>

                            <div className="col-md-6 px-3">
                                <label htmlFor="newConnections" className="form-label font-14 font-medium black-v2 mb-3">
                                    Average number of new connections per ALB
                                </label>
                                <input
                                    type="text"
                                    style={{paddingTop:"9px"}}
                                    className="form-control custom-form text-field font-14 font-medium mb-3"
                                    id="newConnections"
                                    value={values.newConnections}
                                    name={"newConnections"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}

                                />
                            </div>

                            <div className="col-md-6 px-3">
                                <label htmlFor="unitNC" className="form-label font-14 font-medium black-v2 mb-3" style={{paddingTop:"18px"}}>
                                    Unit
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    id="unitNC"
                                    value={values.unitNC}
                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                    name={"unitNC"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="per second">per second</option>
                                    <option value="per minute">per minute</option>

                                </select>
                            </div>

                            <div className="col-md-6 px-3">
                                <label htmlFor="connectionDuration" className="form-label font-14 font-medium black-v2 mb-3">
                                    Average connection duration Value
                                </label>
                                <input
                                    type="text"
                                    style={{paddingTop:"9px"}}                                    
                                    className="form-control custom-form text-field font-14 font-medium mb-3"
                                    id="connectionDuration"
                                    value={values.connectionDuration}
                                    name={"connectionDuration"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}

                                />
                            </div>

                            <div className="col-md-6 px-3">
                                <label htmlFor="unitCD" className="form-label font-14 font-medium black-v2 mb-3">
                                    Unit
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    id="unitCD"
                                    value={values.unitCD}
                                    style={{ marginTop: "-1px", paddingTop: "8px", paddingBottom: "8px" }}
                                    name={"unitCD"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="seconds">seconds</option>
                                    <option value="minutes">minutes</option>

                                </select>
                            </div>

                            <div className="col-md-6 px-3">
                                <label htmlFor="reqPerSecond" className="form-label font-14 font-medium black-v2 mb-3">
                                    Average number of requests per second per ALB
                                </label>
                                <input
                                    type="text"
                                    style={{paddingTop:"9px"}}
                                    className="form-control custom-form text-field font-14 font-medium"
                                    id="reqPerSecond"
                                    value={values.reqPerSecond}
                                    name={"reqPerSecond"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}

                                />
                            </div>

                            <div className="col-md-6 px-3">
                                <label htmlFor="ruleEval" className="form-label font-14 font-medium black-v2 mb-3">
                                    Average number of rule evaluations per request
                                </label>
                                <input
                                    type="text"
                                    style={{paddingTop:"9px"}}
                                    className="form-control custom-form text-field font-14 font-medium"
                                    id="ruleEval"
                                    value={values.ruleEval}
                                    name={"ruleEval"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}

                                />
                            </div> </>}

                            {values.type === "Network" &&
                                <>
                                    <div>
                                        <label htmlFor="loadBalancer" className="form-label font-14 font-medium black-v2 mb-3">
                                            Number of Network Load Balancers
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control custom-form text-field font-14 font-medium"
                                            id="loadBalancer"
                                            value={values.loadBalancer}
                                            name={"loadBalancer"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} disabled={isViewer}

                                        />
                                    </div>

                                    <div >
                                        <h6 htmlFor="loadBalancer" className="form-label label-color  font-semibold" style={{ "margin-top": "3%", "margin-bottom": "2%" }}>
                                            Load Capacity Units (LCUs)
                                        </h6>
                                    </div>

                                    <div >
                                        <h6 htmlFor="loadBalancer" className="form-label label-color  font-semibold" style={{ "margin-top": "1%", "margin-bottom": "4%" }}>
                                            TCP Traffic
                                        </h6>
                                    </div>

                                    <div className="col-md-6 px-3">
                                        <label htmlFor="NLBforTCP" className="form-label font-14 font-medium black-v2 mb-3">
                                            Processed bytes per NLB for TCP Value
                                        </label>
                                        <input
                                            type="text"
                                            style={{paddingTop:"9px"}}
                                            className="form-control custom-form text-field font-14 font-medium mb-3"
                                            id="NLBforTCP"
                                            value={values.NLBforTCP}
                                            name={"NLBforTCP"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} disabled={isViewer}

                                        />
                                    </div>

                                    <div className="col-md-6 px-3">
                                        <label htmlFor="unitTCP" className="form-label font-14 font-medium black-v2 mb-3">
                                            Unit
                                        </label>
                                        <select
                                            className="form-select custom-form font-semibold font-14 form-select-sm"
                                            id="unitTCP"
                                            value={values.unitTCP}
                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                            name={"unitTCP"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} disabled={isViewer}
                                        >
                                            <option value="GB per hour">GB per hour</option>
                                            <option value="GB per month">GB per month</option>
                                            <option value="TB per hour">TB per hour</option>
                                            <option value="TB per month">TB per month</option>
                                        </select>
                                    </div>


                                    <div className="col-md-6 px-3">
                                        <label htmlFor="newTCPconn" className="form-label font-14 font-medium black-v2 mb-3">
                                            Average number of new TCP connections
                                        </label>
                                        <input
                                            type="text"
                                            style={{paddingTop:"9px"}}
                                            className="form-control custom-form text-field font-14 font-medium mb-3"
                                            id="newTCPconn"
                                            value={values.newTCPconn}
                                            name={"newTCPconn"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} disabled={isViewer}

                                        />
                                    </div>

                                    <div className="col-md-6 px-3">
                                        <label htmlFor="unitTCPconn" className="form-label font-14 font-medium black-v2 mb-3">
                                            Unit
                                        </label>
                                        <select
                                            className="form-select custom-form font-semibold font-14 form-select-sm"
                                            id="unitTCPconn"
                                            value={values.unitTCPconn}
                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                            name={"unitTCPconn"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} disabled={isViewer}
                                        >
                                            <option value="per second">per second</option>
                                            <option value="per minute">per minute</option>

                                        </select>
                                    </div>

                                    <div className="col-md-6 px-3">
                                        <label htmlFor="TCPconnDuration" className="form-label font-14 font-medium black-v2 mb-3">
                                            Average TCP connection duration value
                                        </label>
                                        <input
                                            type="text"
                                            style={{paddingTop:"9px"}}
                                            className="form-control custom-form text-field font-14 font-medium"
                                            id="TCPconnDuration"
                                            value={values.TCPconnDuration}
                                            name={"TCPconnDuration"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} disabled={isViewer}

                                        />
                                    </div>

                                    <div className="col-md-6 px-3">
                                        <label htmlFor="unitTCPconnDur" className="form-label font-14 font-medium black-v2 mb-3">
                                            Unit
                                        </label>
                                        <select
                                            className="form-select custom-form font-semibold font-14 form-select-sm"
                                            id="unitTCPconnDur"
                                            value={values.unitTCPconnDur}
                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                            name={"unitTCPconnDur"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} disabled={isViewer}
                                        >
                                            <option value="seconds">seconds</option>
                                            <option value="minutes">minutes</option>

                                        </select>
                                    </div>

                                    <div >
                                        <h6 htmlFor="loadBalancer" className="form-label label-color  font-semibold" style={{ "margin-top": "3%", "margin-bottom": "3%" }}>
                                            UDP Traffic
                                        </h6>
                                    </div>


                                    <div className="col-md-6 px-3">
                                        <label htmlFor="NLBforUDP" className="form-label font-14 font-medium black-v2 mb-3">
                                            Processed bytes per NLB for UDP Value
                                        </label>
                                        <input
                                            type="text"                                        
                                            style={{paddingTop:"9px"}}
                                            className="form-control custom-form text-field font-14 font-medium mb-3"
                                            id="NLBforUDP"
                                            value={values.NLBforUDP}
                                            name={"NLBforUDP"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} disabled={isViewer}

                                        />
                                    </div>

                                    <div className="col-md-6 px-3">
                                        <label htmlFor="unitUDP" className="form-label font-14 font-medium black-v2 mb-3">
                                            Unit
                                        </label>
                                        <select
                                            className="form-select custom-form font-semibold font-14 form-select-sm"
                                            id="unitUDP"
                                            value={values.unitUDP}
                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                            name={"unitUDP"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} disabled={isViewer}
                                        >
                                            <option value="GB per hour">GB per hour</option>
                                            <option value="GB per month">GB per month</option>
                                            <option value="TB per hour">TB per hour</option>
                                            <option value="TB per month">TB per month</option>
                                        </select>
                                    </div>

                                    <div className="col-md-6 px-3">
                                        <label htmlFor="newUDPflows" className="form-label font-14 font-medium black-v2 mb-3">
                                            Average number of new UDP Flows
                                        </label>
                                        <input
                                            type="text"
                                            style={{paddingTop:"9px"}}
                                            className="form-control custom-form text-field font-14 font-medium mb-3"
                                            id="newUDPflows"
                                            value={values.newUDPflows}
                                            name={"newUDPflows"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} disabled={isViewer}

                                        />
                                    </div>

                                    <div className="col-md-6 px-3">
                                        <label htmlFor="unitUDPflows" className="form-label font-14 font-medium black-v2 mb-3">
                                            Unit
                                        </label>
                                        <select
                                            className="form-select custom-form font-semibold font-14 form-select-sm"
                                            id="unitUDPflows"
                                            value={values.unitUDPflows}
                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                            name={"unitUDPflows"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} disabled={isViewer}
                                        >
                                            <option value="per second">per second</option>
                                            <option value="per minute">per minute</option>

                                        </select>
                                    </div>

                                    <div className="col-md-6 px-3">
                                        <label htmlFor="UDPflowDuration" className="form-label font-14 font-medium black-v2 mb-3">
                                            Average UDP Flow duration
                                        </label>
                                        <input
                                            type="text"
                                            style={{paddingTop:"9px"}}
                                            className="form-control custom-form text-field font-14 font-medium mb-3"
                                            id="UDPflowDuration"
                                            value={values.UDPflowDuration}
                                            name={"UDPflowDuration"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} disabled={isViewer}

                                        />
                                    </div>

                                    <div className="col-md-6 px-3">
                                        <label htmlFor="unitUDPflowDur" className="form-label font-14 font-medium black-v2 mb-3">
                                            Unit
                                        </label>
                                        <select
                                            className="form-select custom-form font-semibold font-14 form-select-sm"
                                            id="unitUDPflowDur"
                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                            value={values.unitUDPflowDur}
                                            name={"unitUDPflowDur"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} disabled={isViewer}
                                        >
                                            <option value="seconds">seconds</option>
                                            <option value="minutes">minutes</option>

                                        </select>
                                    </div>

                                    <div >
                                        <h6 htmlFor="loadBalancer" className="form-label label-color  font-semibold" style={{ "margin-top": "2%", "margin-bottom": "2%" }}>
                                            TLS Traffic
                                        </h6>
                                    </div>

                                    <div className="col-md-6 px-3">
                                        <label htmlFor="NLBforTLS" className="form-label font-14 font-medium black-v2 mb-3">
                                            Processed bytes per NLB for TLS Value
                                        </label>
                                        <input
                                            type="text"
                                            style={{paddingTop:"10px"}}
                                            className="form-control custom-form text-field font-14 font-medium mb-3"
                                            id="NLBforTLS"
                                            value={values.NLBforTLS}
                                            name={"NLBforTLS"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} disabled={isViewer}

                                        />
                                    </div>

                                    <div className="col-md-6 px-3">
                                        <label htmlFor="unitTLS" className="form-label font-14 font-medium black-v2 mb-3">
                                            Unit
                                        </label>
                                        <select
                                            className="form-select custom-form font-semibold font-14 form-select-sm"
                                            id="unitTLS"
                                            value={values.unitTLS}
                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                            name={"unitTLS"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} disabled={isViewer}
                                        >
                                            <option value="GB per hour">GB per hour</option>
                                            <option value="GB per month">GB per month</option>
                                            <option value="TB per hour">TB per hour</option>
                                            <option value="TB per month">TB per month</option>
                                        </select>
                                    </div>

                                    <div className="col-md-6 px-3">
                                        <label htmlFor="newTLSconn" className="form-label font-14 font-medium black-v2 mb-3">
                                            Average number of new TLS connections
                                        </label>
                                        <input
                                            type="text"
                                            style={{paddingTop:"10px"}}
                                            className="form-control custom-form text-field font-14 font-medium mb-3"
                                            id="newTLSconn"
                                            value={values.newTLSconn}
                                            name={"newTLSconn"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} disabled={isViewer}

                                        />
                                    </div>

                                    <div className="col-md-6 px-3">
                                        <label htmlFor="unitTLSconn" className="form-label font-14 font-medium black-v2 mb-3">
                                            Unit
                                        </label>
                                        <select
                                            className="form-select custom-form font-semibold font-14 form-select-sm"
                                            id="unitTLSconn"
                                            value={values.unitTLSconn}
                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                            name={"unitTLSconn"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} disabled={isViewer}
                                        >
                                            <option value="per second">per second</option>
                                            <option value="per minute">per minute</option>

                                        </select>
                                    </div>

                                    <div className="col-md-6 px-3">
                                        <label htmlFor="TLSconnDuration" className="form-label font-14 font-medium black-v2 mb-3">
                                            Average TLS connection duration Value
                                        </label>
                                        <input
                                            type="text"
                                            style={{paddingTop:"10px"}}                 
                                            className="form-control custom-form text-field font-14 font-medium mb-3"
                                            id="TLSconnDuration"
                                            value={values.TLSconnDuration}
                                            name={"TLSconnDuration"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} disabled={isViewer}

                                        />
                                    </div>

                                    <div className="col-md-6 px-3">
                                        <label htmlFor="unitTLSconnDur" className="form-label font-14 font-medium black-v2 mb-3">
                                            Unit
                                        </label>
                                        <select
                                            className="form-select custom-form font-semibold font-14 form-select-sm"
                                            id="unitTLSconnDur"
                                            value={values.unitTLSconnDur}
                                            style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                            name={"unitTLSconnDur"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} disabled={isViewer}
                                        >
                                            <option value="seconds">seconds</option>
                                            <option value="minutes">minutes</option>

                                        </select>
                                    </div>
                                </>
                            }
                        </>
                    </div>
                </div>
            </>
        );
    } catch (error) {
        //console.log(error);
    }
}