

export default function AwsSDKS3({ values, onChangeOfConfiguration, isViewer }) {
    try {
        return (
            <>
            
                    <div
                        class="accordion-body p-0 estimation-container">
                        <div className="row py-4 mx-4 px-5" hidden={(values.isPriceAvailable == "No") ? false : true}>
                            <div className="col-md-6 px-3"><span className="font-semibold font-14 text-danger">{values.isPriceAvailable == "No" ? "The Price is not available for the region / the configuration that you have selected might be not available in the region" : null}</span></div>
                        </div>
                        <div className="row py-4 mx-4 px-5">
                            <div className="col-md-6 px-3">
                                <label htmlFor="region1" className="form-label font-14 font-medium black-v2 mb-3">
                                    Region
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm mb-3"
                                    id="region"
                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                    value={values.region}
                                    name={"region"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="ap-south-2">ap-south-2</option><option value="ap-southeast-3">ap-southeast-3</option><option value="ap-southeast-1">ap-southeast-1</option><option value="us-gov-east-1">us-gov-east-1</option><option value="eu-west-2">eu-west-2</option><option value="af-south-1">af-south-1</option><option value="ap-northeast-1">ap-northeast-1</option><option value="ap-southeast-2">ap-southeast-2</option><option value="us-east-2">us-east-2</option><option value="eu-central-1">eu-central-1</option><option value="ca-central-1">ca-central-1</option><option value="ap-south-1">ap-south-1</option><option value="ap-northeast-3">ap-northeast-3</option><option value="sa-east-1">sa-east-1</option><option value="ap-east-1">ap-east-1</option><option value="eu-west-1">eu-west-1</option><option value="eu-south-1">eu-south-1</option><option value="ap-southeast-4">ap-southeast-4</option><option value="eu-north-1">eu-north-1</option><option value="eu-south-2">eu-south-2</option><option value="us-west-1">us-west-1</option><option value="us-west-2">us-west-2</option><option value="me-south-1">me-south-1</option><option value="eu-central-2">eu-central-2</option><option value="us-gov-west-1">us-gov-west-1</option><option value="ap-northeast-2">ap-northeast-2</option><option value="me-central-1">me-central-1</option><option value="us-east-1">us-east-1</option><option value="eu-west-3">eu-west-3</option>
                                </select>
                            </div>
                            <div className="col-md-6 px-3">
                                <label htmlFor="bucketName" className="form-label font-14 font-medium black-v2 mb-3">
                                    Bucket Name
                                </label>
                                <input
                                    style={{paddingTop:"9px"}}
                                    type="text"
                                    className="form-control custom-form text-field font-14 font-medium mb-3"
                                    id="bucketName"
                                    value={values.bucketName}
                                    name={"bucketName"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}

                                />
                            </div>

                            <div className="col-md-6 px-3">
                                <label htmlFor="standardStorage" className="form-label font-14 font-medium black-v2 mb-3">
                                    S3 Standard storage
                                </label>
                                <input
                                    style={{paddingTop:"9px"}}
                                    type="text"
                                    className="form-control custom-form text-field font-14 font-medium mb-3"
                                    id="standardStorage"
                                    value={values.standardStorage}
                                    name={"standardStorage"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}

                                />
                            </div>
                            <div className="col-md-6 px-3">
                                <label htmlFor="standardStorageunit" className="form-label font-14 font-medium black-v2 mb-3">
                                    Unit
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    id="standardStorageunit"
                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                    value={values.standardStorageunit}
                                    name={"standardStorageunit"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="gb">GB per month</option>
                                    <option value="tb">TB per month</option>
                                </select>
                            </div>


                            <div>
                                <label htmlFor="dataMoved" className="form-label font-14 font-medium black-v2 mb-3">
                                    How will data be moved into S3 Standard?
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    id="dataMoved"
                                    value={values.dataMoved}
                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "px", marginBottom :"-15px"}}
                                    name={"dataMoved"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="specified_amount">The specified amount of data is already stored in S3 Standard</option>
                                    <option value="put_copy_post">PUT, COPY, POST requests to S3 Standard</option>
                                </select>
                            </div>
                        </div>
                        <div className="row py-4 mx-4 px-5">

                            {values.dataMoved === "put_copy_post" &&
                                <>
                                    <h6 style={{marginTop:"-26px"}} className="mb-3">S3 Standard Average Object Size</h6>
                                    <div className="col-md-6 px-3">
                                        <label htmlFor="AverageObjectSize" className="form-label font-14 font-medium black-v2 mb-3">
                                            Value
                                        </label>
                                        <input
                                        style={{paddingTop : "9px"}}
                                            type="text"
                                            className="form-control custom-form text-field font-14 font-medium mb-3"
                                            id="AverageObjectSize"
                                            value={values.AverageObjectSize}
                                            name={"AverageObjectSize"}
                                            onChange={(e) => {
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} disabled={isViewer}
                                        />
                                    </div>

                                    <div className="col-md-6 px-3">
                                        <label htmlFor="ObjectSizeUnit" className="form-label font-14 font-medium black-v2 mb-3">
                                            Unit
                                        </label>
                                        <select
                                            className="form-select custom-form font-semibold font-14 form-select-sm"
                                            id="ObjectSizeUnit"
                                            value={values.ObjectSizeUnit}
                                            name={"ObjectSizeUnit"}
                                            style={{ marginTop: "1px", paddingTop: "9px", paddingBottom: "8px" }}
                                            onChange={(e) => {
                                                console.log("sagin", e)
                                                onChangeOfConfiguration(e, values.instanceName);
                                            }} disabled={isViewer}
                                        >
                                            <option value="bytes">Bytes</option>
                                            <option value="mb">MB</option>
                                            <option value="kb">KB</option>
                                        </select>
                                    </div></>}
                            {/* this is for the unit for storage amount */}

                            <div className="col-md-6 px-3">
                                <label htmlFor="putRequest" className="form-label font-14 font-medium black-v2 mb-3">
                                    PUT, COPY, POST, LIST requests to S3 Standard
                                </label>
                                <input
                                    style={{marginTop:"17px", paddingTop:"9px"}}
                                    type="text"
                                    className="form-control custom-form text-field font-14 font-medium mb-3"
                                    id="putRequest"
                                    value={values.putRequest}
                                    name={"putRequest"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}

                                />
                            </div>
                            <div className="col-md-6 px-3">
                                <label htmlFor="getRequest" className="form-label font-14 font-medium black-v2 mb-3" style={{paddingTop:"18px"}}>
                                    GET, SELECT, and all other requests from S3 Standard
                                </label>
                                <input
                                    style={{paddingTop:"9px"}}
                                    type="text"
                                    className="form-control custom-form text-field font-14 font-medium"
                                    id="getRequest"
                                    value={values.getRequest}
                                    name={"getRequest"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                />
                            </div>
                            <h6 style={{marginTop :"10px"}}className="mb-3">Data returned by S3 Select(Ongoing monthly volume of data returned by S3 Select requests)</h6>
                            <div className="col-md-6 px-3">
                                <label htmlFor="dataReturned" className="form-label font-14 font-medium black-v2 mb-3">
                                    Value
                                </label>
                                <input
                                style={{paddingTop:"9px"}}
                                    type="text"
                                    className="form-control custom-form text-field font-14 font-medium"
                                    id="dataReturned"
                                    value={values.dataReturned}
                                    name={"dataReturned"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}

                                />
                            </div>
                            <div className="col-md-6 px-3">
                                <label htmlFor="dataReturnedUnit" className="form-label font-14 font-medium black-v2 mb-3">
                                    Unit
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    id="dataReturnedUnit"
                                    value={values.dataReturnedUnit}
                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                    name={"dataReturnedUnit"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="gb">GB per month</option>
                                    <option value="tb">TB per month</option>
                                </select>
                            </div>
                            <h6 style={{marginTop :"10px"}}className="mb-3">Data scanned by S3 Select(Ongoing monthly volume of data scanned by S3 Select requests)</h6>  
                            <div className="col-md-6 px-3">
                                <label htmlFor="dataScanned" className="form-label font-14 font-medium black-v2 mb-3">
                                    Value
                                </label>
                                <input
                                style={{paddingTop:"9px"}}
                                    type="text"
                                    className="form-control custom-form text-field font-14 font-medium"
                                    id="dataScanned"
                                    value={values.dataScanned}
                                    name={"dataScanned"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                />
                            </div>
                            <div className="col-md-6 px-3">
                                <label htmlFor="dataScannedUnit" className="form-label font-14 font-medium black-v2 mb-3">
                                    Unit
                                </label>
                                <select
                                    className="form-select custom-form font-semibold font-14 form-select-sm"
                                    id="dataScannedUnit"
                                    value={values.dataScannedUnit}
                                    style={{ marginTop: "1px", paddingTop: "8px", paddingBottom: "8px" }}
                                    name={"dataScannedUnit"}
                                    onChange={(e) => {
                                        onChangeOfConfiguration(e, values.instanceName);
                                    }} disabled={isViewer}
                                >
                                    <option value="gb">GB per month</option>
                                    <option value="tb">TB per month</option>
                                </select>

                            </div>

                        </div>
                    </div>
               
            </>
        );
    } catch (error) {
        //console.log(error);
    }
}