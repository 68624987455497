
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { useOrgContext } from "../../../../ContextProvider/OrgContext"
import { retrieveBranchApi } from "../../../../Service/api";

export default function RepoConfigureComponent(
  {configureDetails , labelSubmit, userSelectedRepo}
) {
    const {  
      orgId,
      envId,
      workloadId,
      providerId, 
      lastBuildID,
      lastBuildDate,
      LastBuildMessage,
      viewMode,
      commitData
    } = useOrgContext();
    const [branchDetails, setBranchDetails] = useState([])

    const [userId, setuserId] = useState(localStorage.getItem('userId'));
    const [configuredBranch, setConfiguredBranch] = useState({
      branch_id: configureDetails.data.repositoryData.branchData.branch_id  ? configureDetails.data.repositoryData.branchData.branch_id : "",
      branch_name: configureDetails.data.repositoryData.branchData.branch_name ? configureDetails.data.repositoryData.branchData.branch_name : ""
    })
    
    console.log(configureDetails, "configureDetails") 
    useEffect(()=>{
        console.log(commitData , "commitData")
    },[commitData])
    useEffect(() => {
        retrieveBranch()
    }, [userSelectedRepo])
    // Retrieve Branch Details for the particular repo 
    async function retrieveBranch() {
        // let payload = {
        //   organizationId: "28da4a71-14f5-47ce-a349-35840ebf2dcb",
        //   repoId: "175c8327-d4b7-4e1a-a37e-4d9bca4aa2c7",
        //   userId: "7fdee781-f37a-423b-b504-0b9c15b687fa"
        // }
        let payload = {
          organizationId: orgId,
          repoId: userSelectedRepo.repository_id,
          userId: userId
        }
        console.log(payload, "payloadddd")
        let res = await retrieveBranchApi(payload)
        console.log(res, "Response")
        if (res.success) {
          if (res.data?.length !== 0) {
            setBranchDetails(res.data)
          }
        } else {
          console.log("Branch Error")
        }
    }
    // This Function is used to Save the Account Configured 
    function submitConfig() {
      console.log(configuredBranch, "branchDetails") 
      
      // Update the configureDetails with the new branch data
      const updatedConfigureDetails = {
          ...configureDetails,
          data: {
              ...configureDetails.data,
              repositoryData: {
                  ...configureDetails.data.repositoryData,
                  branchData: {
                    branch_id: configuredBranch.branch_id,
                    branch_name: configuredBranch.branch_name
                  }
              }
          }
      };

      console.log(updatedConfigureDetails, "Updated Configured Details")
      
      // Call labelSubmit with the updated configuration
      labelSubmit(updatedConfigureDetails);
  }

    function onchangeBranch(e) {
        const selectedOption = e.target.options[e.target.selectedIndex];
        const obj = {
          branch_id: selectedOption.value,
          branch_name: selectedOption.text
        };
        console.log(obj, "ONchange");
        setConfiguredBranch(obj);
    }

    return (
        <>
            <div className="col-md-3 mt-3 ps-1 pe-3 content-right-container" 
                style={{
                    "transition": "transform 0.3s ease-in-out"
                }}
            >
                <div className="recommended-container pe-3">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="config-container">
                                <p className="mb-3 font-18 font-bold black-v2">Configurations</p>
                                <div className="row">
                                    <div className="col-md-6 mb-4 ">
                                        <label
                                            className="form-label font-14 font-medium black-v2"
                                            htmlFor="Service"
                                        >
                                            Service
                                        </label>
                                        <select
                                            id="Service"
                                            className="form-select form-control custom-form font-14 font-medium"
                                            disabled={true}
                                        >
                                            <option selected>{configureDetails.data.serviceName}</option>
                                        </select>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                        <label
                                            className="form-label font-14 font-medium black-v2"
                                            htmlFor="Branch"
                                        >
                                            Branch
                                        </label>
                                        <select
                                            id="Branch"
                                            className="form-select form-control custom-form font-14 font-medium"
                                            onChange={onchangeBranch}
                                            onBlur={submitConfig}
                                            value={configuredBranch.branch_id}
                                            disabled = {viewMode}
                                        >
                                            <option value="">Select a branch</option>
                                            {branchDetails?.map((val, index) => (
                                                <option key={val.branch_id} value={val.branch_id}>
                                                    {val.branch_name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-12 mb-4">
                                        <label
                                            className="form-label font-14 font-medium black-v2"
                                            htmlFor="Description"
                                        >
                                            Description
                                        </label>
                                        <textarea
                                            className="form-control custom-form custom-textarea font-14 font-regular h-154"
                                            placeholder="Enter Description"
                                            id="Description"
                                            value={configureDetails.data.description}
                                            disabled={true}
                                        />
                                    </div>

                                    <div className="col-md-12 mb-4 pb-3">
                                        <p className="font-14 font-medium black-v2 mb-3">Build</p>
                                        <p className="font-14 font-medium black-v2 mb-2">
                                            Build#: <span className="secondary-text-color-v5">{commitData}</span>
                                        </p>
                                        <p className="font-14 font-medium black-v2 mb-2">
                                            Last Build:{" "}
                                            <span className="secondary-text-color-v5">{lastBuildDate}</span>
                                        </p>
                                        <p className="font-14 font-medium black-v2 mb-2">
                                            Message:{" "}
                                            <span className="secondary-text-color-v5">{LastBuildMessage}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}