import { useContext, useEffect, useState } from "react";
import { CloudGenContext } from "./ManageTemplates";
import { approveTemplates, cloneTemplates, deleteTemplates, fetchTemplates, getAllOrgApi } from "../../Service/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { fetchAllOrganization } from "../../Service/api";
import { OrgContext } from "../../ContextProvider/OrgContext";
import WorkbechPopup from "../Organization/Workbench/WorkBench/PopupMaintanence/WorkBenchPopUp";

function CloudGenTemplates({ fetchAllTemplates }) {

  //PS_05 - To get data from manage templates using context
  const {
    cloudGenTemplates,
    awsCloudGenTemplate,
    azureCloudGenTemplate,
    providerTab,
    setProviderTab,
    awsTemplatesCount,
    azureTemplatesCount,
    organizationData,
    searchTerm,
    workloadData,
    environmentData,
    setTemplateOrganizationId,
    templateOrganizationID,
    setChosenWorkloadId,
    popupMsg,
    setPopUpMsg,
    setAwsTemplatesCount,
    setAzureTemplatesCount
  } = useContext(CloudGenContext);

  useEffect(() => {
    console.log("CloudGen Context Data:", {
      cloudGenTemplates,
      awsCloudGenTemplate,
      azureCloudGenTemplate,
      awsTemplatesCount,
      azureTemplatesCount,
      workloadData,
      environmentData,
      organizationData,
      templateOrganizationID,
      

      
    });
  }, [cloudGenTemplates, awsCloudGenTemplate, azureCloudGenTemplate, awsTemplatesCount, azureTemplatesCount]);

  //PS_04 
  const [awsExpanded, setAwsExpanded] = useState(false);
  const [azureExpanded, setAzureExpanded] = useState(false);
  const [successPopup, setSuccessPopUp] = useState(false)
  const [isAdmin, setIsAdmin] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate()
  const [selectedEnvironment, setSelectedEnvironment] = useState("")
  const [selectedOrganization, setSelectedOrganization] = useState("")
  const [selectedWorkload, setSelectedWorkload] = useState("")
  const [cloneTemplateId, setCloneTemplateId] = useState("")
  const [isToastPopupOpen, setIsToastPopUpOpen] = useState(false)

  const userId = localStorage.getItem('userId');
  console.log("userid", userId)

  const { userRoles,setworkloadId,setProviderName,setEnvId,setEnvName,setproviderId,orgId,setOrgId } = useContext(OrgContext)


  //PS_38
  useEffect(() => {
    const isAdminValue = localStorage.getItem('isAdmin');
    console.log(isAdminValue, "adminValue")
    setIsAdmin(isAdminValue);
    // fetchOrganization()
  }, []);

  //PS_16 -PS_21
  async function approveTemplate(template, isApprove) {
    const payload = {
      "userId": userId,
      "organizationId": template.orgId,
      "templateId": template.templateId,
      "envId": template.envId,
      "isApproveRemove": isApprove

    };

    try {
      const response = await approveTemplates(payload);
      console.log(response, "response");

      if (response.status === 200 && response.data.body.success) {
        const { data } = response.data.body;
        console.log(data, "data");

        fetchAllTemplates()

        // setPopUpMsg({ ...popupMsg, ["Msg"]: "Templates approved successfully", ["show"]: true, ["success"]: true })


      } else {
        console.error("Error approving", response.data.body.message);
        // setPopUpMsg({ ...popupMsg, ["Msg"]: "Templates approved Failed", ["show"]: true, ["success"]: false })
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  //PS_22 -PS_26
  async function deleteTemplate(template) {

    const payload = {
      "userId": userId,
      "organizationId": template.orgId,
      "templateId": template.templateId

    }
    try {
      const response = await deleteTemplates(payload);
      console.log(response, "response");

      if (response.status === 200 && response.data.body.success) {
        const { data } = response.data.body;
        console.log(data, "data");

        setSuccessPopUp(true);
        // await fetchTemplates(payload)
        setPopUpMsg({ ...popupMsg, ["Msg"]: "Templates Deleted successfully", ["show"]: true, ["success"]: true })
        fetchAllTemplates()
      } else {
        console.error("Error deleting", response.data.body.message);
        setPopUpMsg({ ...popupMsg, ["Msg"]: "Templates Deleted failed", ["show"]: true, ["success"]: false })
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  //PS_32 -PS_34
  async function cloneTemplate() {

    if(selectedEnvironment){
    setShowModal(true);


    const payload = {
      "userId": userId,
      "envId": selectedEnvironment,
      "templateID": cloneTemplateId
    }
    try {
      console.log(payload , "Clone TEmpate")
      const response = await cloneTemplates(payload);
      console.log(response, "response");

      if (response.status === 200 && response.data.body.success) {
        const { data } = response.data.body;
        console.log(data, "data");
        setShowModal(false);
        setSuccessPopUp(true);
        setPopUpMsg({ ...popupMsg, ["Msg"]: "Templates Cloned successfully", ["show"]: true, ["success"]: true })
      } else {
        console.error("Error cloning", response.data.body.message);
        setPopUpMsg({ ...popupMsg, ["Msg"]: "Templates cloned failed", ["show"]: true, ["success"]: false })
      }
    } catch (error) {
      console.error("Error:", error);
    }
    }
  }

  //PS_35
  const handleEdit = (template) => {
    if (isAdmin == "true") {
      setworkloadId(template.workloadId);
			localStorage.setItem('workloadId', template.workloadId);
			setProviderName(template.providerName)
			localStorage.setItem("providerName",template.providerName)

			setEnvId(template.envId);
			setEnvName(template.envName)
			localStorage.setItem('envId',template.envId);

			setproviderId( template.providerId)
			localStorage.setItem("providerId", template.providerId)

      setOrgId(template.orgId)
      localStorage.setItem("orgId",template.orgId)


      // navigate('/Workbench',
      //   {
      //     state:
      //     {
      //       orgId: template.organizationId,
      //       workloadId: template.workloadId,
      //       envId: template.envId,
      //       envName: template.envName,
      //       language: template.language,
      //       setUTI: template.setUTI,
      //       orgId: template.orgId,
      //       providerId: template.providerId,
      //       providerName: template.providerName
      //     }
      //   })
      navigate('/Workbench')


    }

  }


  //PS_36
  const closeModal = () => {
    setShowModal(false);
  };


  //PS_11 
  useEffect(() => {
    // Bind accordion functionality when the component mounts
    bindAccordion();
    // getOrganizationData()
  }, []);


  //PS_06 - PS_10
  const toggleAccordion = (provider) => {
    console.log(provider, "provider resp")
    if (provider === 'aws') {
      setAwsExpanded(!awsExpanded);
    } else if (provider === 'azure') {
      setAzureExpanded(!azureExpanded);
    }
  };


  //PS_12 
  const bindAccordion = () => {
    const accordionItems = document.querySelectorAll('.accordion-item');
    accordionItems.forEach(item => {
      const button = item.querySelector('.cust-acc-btn');
      const content = item.querySelector('.accordion-collapse');

      button.addEventListener('click', () => {
        const expanded = button.getAttribute('aria-expanded') === 'true';
        button.setAttribute('aria-expanded', !expanded);
        content.classList.toggle('show');
      });
    });
  };


  //PS_13 -PS_14
  const decodeBase64Image = (base64String) => {
    if (!base64String) return null;
    return `data:image/png;base64,${base64String}`;
  };



  //PS_15 
  const renderTemplates = (templates) => {
    const filteredTemplates = filterTemplates(templates);

    if (filteredTemplates.length === 0) {
      return <div className ="color-black"style={{
        "marginBottom": '20px',
        "marginTop": '20px',       // Space above the message
        "paddingLeft": '500px'      // Push text to the right
      }}>
        No templates found matching your search.</div>;
    }

    return filteredTemplates.map((template, index) => (
      <div key={index} className="secondary-template-container">
        <div className="template-header">
          <div className="d-flex justify-content-between px-4 py-2">
            <div className="d-flex align-items-center">

              {/* <img
                src={template.providerName === "AWS" ? "images/aws-black-icon.svg": "images/azure-logo.svg"}

                alt={template.cloudProvider}
                className="me-3 icon-width-30"


              /> */}
               {template.providerName === "AWS" ? (
              <>
                <img src="images/aws-black-icon.svg" alt="aws" className="icon-width-30 light-theme-img"/>
                <img src="images/aws-logo-dark.svg" alt="aws" className="icon-width-30 dark-theme-img"/>
              </>
            ) : (
              <>
                <img src="images/azure-logo.svg" alt="azure" className="icon-width-30 light-theme-img" />
                <img src="images/azure-logo-dark.svg" alt="azure" className="icon-width-30 dark-theme-img" />
              </>
            )}
            
              <div>
                <p className="font-14 font-medium black-v2 mb-0">
                  {template.templateName}{" "}
                  <span className="font-10 font-medium secondary-color ms-1">
                    {template.templateId}
                  </span>
                </p>
                <p className="font-11 font-regular secondary-color mt-1 mb-0">
                  Created at: {new Date(template.createdAt).toLocaleDateString()}
                </p>
                {template.updatedAt && (
                  <p className="font-11 font-regular secondary-color mb-0">
                    Updated: {new Date(template.updatedAt).toLocaleDateString()}
                  </p>
                )}
              </div>
            </div>
            {/* Dropdown menu */}
            <div className="dropdown">
              <button
                className="btn icon-bitton shadow-none p-0"
                type="button"
                id={`dropdownMenuButton${index}`}
                data-bs-toggle="dropdown"
                aria-expanded="false"
                // onClick={(e) => {
                //   const dropdownMenu = document.getElementById(`dropdownMenu${index}`);
                //   dropdownMenu.classList.toggle("show");
                // }}
              >
                <img
                  src="images/three-dots.svg"
                  alt="three-dots"
                />
              </button>
              <ul
                id={`dropdownMenu${index}`}
                className="dropdown-menu custom-template-dropdown"
                aria-labelledby={`dropdownMenuButton${index}`}
              >
                {isAdmin == "true" && (
                  <li className="border-0 font-14 font-medium color-black">
                    {template.approveStatus === true ? (
                      <a className="dropdown-item" href="#" onClick={() => approveTemplate(template, true)}>
                        <img
                          src="images/approve-tick.svg"
                          alt="approval-icon"
                          className="me-2 icon-width-small"
                        />
                        Remove Approval
                      </a>
                    ) : (
                      <a className="dropdown-item" href="#" onClick={() => approveTemplate(template, false)}>
                        <img
                          src="images/approve-tick.svg"
                          alt="approval-icon"
                          className="me-2 icon-width-small"
                        />
                        Approve
                      </a>
                    )}
                  </li>
                )}
                {isAdmin == "true" && (
                  <li className="border-0 font-14 font-medium color-black">
                    <a className="dropdown-item" href="#" onClick={() => { 
                      handleEdit(template) }}>
                      <img
                        src="images/edit-icon.svg"
                        alt="edit-icon"
                        className="me-2 icon-width-small"
                      />
                      Edit
                    </a>
                  </li>
                )}
                {isAdmin == "true" && (
                  <li className="border-0 font-14 font-medium color-black">
                    <a className="dropdown-item" href="#" onClick={() => deleteTemplate(template)}>
                      <img
                        src="images/delete-icon.svg"
                        alt="delete"
                        className="me-2 icon-width-14"
                      />
                      Delete
                    </a>
                  </li>
                )}
                {template.approveStatus === true && (
                  <li className="border-0 font-14 font-medium color-black">
                    <a className="dropdown-item" href="#"
                      onClick={() => {
                        setShowModal(true)
                        // fetchOrganization(template.orgId)
                        setTemplateOrganizationId(template.orgId)
                        setCloneTemplateId(template.templateId);
                      }
                      }
                    >
                      <img
                        src="images/clone-img.svg"
                        alt="clone-icon"
                        className="me-2 icon-width-small"
                      />
                      Clone
                    </a>
                  </li>
                )}
              </ul>
            </div>

          </div>
        </div>
        <div className="template-body px-4 py-3">
          <div className="d-flex justify-content-center align-items-center height-100">
            <img
              src={template.templateImage || "images/template sample img.svg"}
              alt="template-img"
              style={{ maxWidth: '100%', maxHeight: '120px' }} // Adjust these values as needed
            />
          </div>
          {template.approveStatus === true && (
            <p className="mb-0 font-11 font-medium success-v5 text-end mt-2">
              <img
                src="images/approve-tick.svg"
                alt="tick"
                className="me-2 icon-width-small"
              />
              Approved
            </p>
          )}
        </div>
      </div>
    ));
  };


  //PS_39 -PS_40
  // const filterTemplates = (templates) => {
  //   if (!searchTerm) return templates;
    
  //   const searchData=templates.filter(template =>
  //     template?.templateName?.toLowerCase()?.includes(searchTerm.toLowerCase())
  //   );

  //   const searchCount = searchData.length
    
    
  //   return searchData
  // };

  const filterTemplates = (templates) => {
    if (!searchTerm) return templates;

    const filteredTemplates = templates.filter(template =>
      template?.templateName?.toLowerCase()?.includes(searchTerm.toLowerCase())
    );

    // const awsCount = filteredTemplates.filter(template => template.providerName === 'AWS').length;
    // const azureCount = filteredTemplates.filter(template => template.providerName === 'Azure').length;

    // setAwsTemplatesCount(awsCount);
    // setAzureTemplatesCount(azureCount);

    return filteredTemplates;
  };

  
  const updateTemplateCounts = () => {
    const filteredAWSTemplates = filterTemplates(awsCloudGenTemplate);
    const filteredAzureTemplates = filterTemplates(azureCloudGenTemplate);

    setAwsTemplatesCount(filteredAWSTemplates.length);
    setAzureTemplatesCount(filteredAzureTemplates.length);
  };

  useEffect(() => {
    updateTemplateCounts();
  }, [searchTerm, awsCloudGenTemplate, azureCloudGenTemplate]);



  return (
    <>
      

      <div className="tab-pane fade show active" id="all-tab-pane" role="tabpanel" aria-labelledby="all-tab" tabIndex={0}>
        <div className="accordion" id="accordionExample-11">
          {/* AWS Section */}
          <div className="accordion-item body-background insights-accordion border-0 mt-3 pt-1 mb-2">
            <div className="insights-container mb-1">
              <div className="d-flex justify-content-between align-items-center">
                <h2 className="accordion-header d-flex align-items-center" id="headingTwo-2">
                  <button
                    className={`cust-acc-btn d-flex align-items-center gap-2 font-14 btn border-0 ${awsExpanded ? '' : 'collapsed'}`}
                    type="button"
                    onClick={() => toggleAccordion('aws')}
                  >
                    <span className="mx-3">
                      
                      <img src="images/aws-black-icon.svg" alt="aws" class="icon-width-30 light-theme-img"/>
                      <img src="images/aws-logo-dark.svg" alt="aws" class="icon-width-30 dark-theme-img"/>
                    </span>
                    <span className="primary-color font-18 font-bold">AWS</span>
                  </button>
                </h2>
                <div className="d-flex align-items-center">
                  <img src="images/template-icon.svg" alt="template-icon" className="me-1" />
                  <p className="mb-0 font-12 font-medium secondary-text-color">
                    Templates <span className="font-16 font-medium black-v2 ms-2">{awsTemplatesCount}</span>
                  </p>
                </div>
              </div>
              <div className={`accordion-collapse ${awsExpanded ? 'show' : 'collapse'}`}>
                <div className="accordion-body custom-border-top pt-4 mt-4">
                  <div class="scrl-container">
                    <div className="d-flex gap-4">
                      {renderTemplates(awsCloudGenTemplate)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          {showModal && (
            <div
              className="modal fade show"
              id="staticBackdrop-4"
              tabIndex={-1}
              aria-labelledby="staticBackdropLabel"
              aria-modal="true"
              role="dialog"
              style={{ display: "block", background: "rgba(0,0,0,0.6)", opacity: 1 }}
            >
              <div className="modal-dialog  modal-dialog-centered justify-content-center">
                <div className="modal-content border-0">
                  <div className="modal-header border-0 p-4 ">
                    <h1
                      className="modal-title font-20 font-bold black-v2"
                      id="staticBackdropLabel"
                    >
                      Clone Architecture
                    </h1>
                    <button
                      type="button"
                      className="btn shadow-none"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={closeModal}
                    >
                      <img
                        src="images/close-icon.svg"
                        alt="close-icon"
                        className="icon-hover shadow-none modal-close-filter"
                      />
                    </button>
                  </div>
                  <div className="modal-body px-4 pt-0">
                    <div className="my-3">
                      <label
                        className="form-label font-14 font-medium black-v2"
                        htmlFor="Status"
                      >
                        Organization <span className="danger-red">*</span>
                      </label>
                      
                  <select
                    className="form-select custom-dropdown shadow-none custom-border-box font-14 font-regular black-v2 custom-select"
                    aria-label="Default select example"
                    id="Status"
                    value={selectedOrganization}
                    onChange={(e) =>{ 
                      setTemplateOrganizationId(e.target.value) 
                       setSelectedOrganization(e.target.value)}}
                  >
                    <option value="">Select</option>
                    {organizationData?.length > 0 && organizationData.map(organization => (
                      <option key={organization.orgId} value={organization.orgId}>
                        {organization.orgName}
                      </option>
                    ))}
                  </select>
                    </div>
                    <div className="my-3">
                      <label
                        className="form-label font-14 font-medium black-v2"
                        htmlFor="Status-4"
                      >
                        Workload <span className="danger-red">*</span>
                      </label>
                      <select
                        className="form-select custom-dropdown shadow-none custom-border-box font-14 font-regular black-v2 custom-select"
                        aria-label="Default select example"
                        id="Status-4"
                        value={selectedWorkload}
                        onChange={(e) => {
                          setChosenWorkloadId(e.target.value)
                          setSelectedWorkload(e.target.value)}}
                      >
                        <option selected="">Select</option>
                        {workloadData.map(workload => (
                          <option key={workload.workloadId} value={workload.workloadId}>{workload.workloadName}</option>
                        ))}
                      </select>
                    </div>
                    <div className="my-3">
                      <label
                        className="form-label font-14 font-medium black-v2"
                        htmlFor="Status-5"
                      >
                        Environment
                        <span className="danger-red">*</span>
                      </label>
                      <select
                        className="form-select custom-dropdown shadow-none custom-border-box font-14 font-regular black-v2 custom-select"
                        aria-label="Default select example"
                        id="environmentData"
                        value={selectedEnvironment}
                        onChange={(e) => {
                          setSelectedEnvironment(e.target.value)}}
                      >
                        <option selected="">Select</option>
                        {environmentData.map(environment => (
                          <option key={environment.envId} value={environment.envId}>{environment.envName}</option>
                        ))}
                      </select>
                    </div>
                    <div className="d-flex justify-content-end align-items-center mt-4 mb-2 gap-3">
                      <button
                        type="button"
                        className="button outline-button text-nowrap py-12"
                        data-bs-dismiss="modal"
                        onClick={closeModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="button primary-button text-nowrap px-4 py-12"
                        onClick={cloneTemplate}
                        disabled={
                          !selectedOrganization || 
                          !selectedWorkload || 
                          !selectedEnvironment}
                      >
                        Clone
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          )}

          {/* Azure Section */}
          <div className="accordion-item body-background insights-accordion border-0 mt-3 pt-1 mb-2">
            <div className="insights-container mb-1">
              <div className="d-flex justify-content-between align-items-center">
                <h2 className="accordion-header d-flex align-items-center" id="headingTwo-20">
                  <button
                    className={`cust-acc-btn d-flex align-items-center gap-2 font-14 btn border-0 ${azureExpanded ? '' : 'collapsed'}`}
                    type="button"
                    onClick={() => toggleAccordion('azure')}
                  >
                    <span className="mx-3">
                      <img src="images/azure-logo.svg" alt="logo" className="icon-width-30" />
                    </span>
                    <span className="primary-color font-18 font-bold">Azure</span>
                  </button>
                </h2>
                <div className="d-flex align-items-center">
                  <img src="images/template-icon.svg" alt="template-icon" className="me-1" />
                  <p className="mb-0 font-12 font-medium secondary-text-color">
                    Templates <span className="font-16 font-medium black-v2 ms-2">{azureTemplatesCount}</span>
                  </p>
                </div>
              </div>
              <div className={`accordion-collapse ${azureExpanded ? 'show' : 'collapse'}`}>
                <div className="accordion-body custom-border-top pt-4 mt-4">
                  <div class="scrl-container">
                    <div className="d-flex gap-4">
                      {renderTemplates(azureCloudGenTemplate)}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>




    </>
  )
}

export default CloudGenTemplates;