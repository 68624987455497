// Pacakage Imports
import React, { useState, useCallback, useEffect, useMemo, useRef } from "react";
import jsYaml from 'js-yaml';
import axios from "axios";
import * as base64 from "base-64";
import * as htmlToImage from "html-to-image";
import {
	ReactFlow,
	ReactFlowProvider,
	Background,
	Controls,
	MiniMap,
	useNodesState,
	useEdgesState,
	NodeResizer,
	Handle,
	addEdge
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';

import '../B2A/reactflow.css'
import { useLocation, useNavigate } from 'react-router-dom';
import AWSB2ASideBar from "./AWSB2ASideBar";
import '../B2A/style.css'

import Draggable from "react-draggable";
import { fetchAllVpcApi, 
	generateARMContentApi,
	generateAWSTerraformerApi,
	generateCloudFormationContentApi,
	get_all_regionApi,
	get_all_resources_aws, 
	get_all_tags_b2a_Api, 
	handleCloneSaveApi } from "../../../Service/api";
import CloneModal from "./B2APopupComponent/CloneEnvironment";
import B2APopup from "./B2APopupComponent/PopUp";
import Workloads from "../Workload_Grid";
import SideBar from "../../sidebar/SideBar";
import { useOrgContext } from "../../../ContextProvider/OrgContext";
import Loader from "../../loader/loader";

const AWSB2AComponent = () => {
	// State Variable Declarations 
	const { orgId , providerName, colorMode } = useOrgContext()
	const [userId, setuserId] = useState(localStorage.getItem('userId'));
	const navigate = useNavigate();
	const location = useLocation()
	const reactFlowWrapper = useRef(null);
	const [selectedVpc, setSelectedVpc] = useState('');
	const [selectedTag, setSelectedTag] = useState({ Key: "", Value: "" });
	const [vpcs, setVpc] = useState([]);
	const [showOrphan, setShowOrphan] = useState(false);
	const [templateType, setTemplateType] = useState('');
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [regions, setRegions] = useState([]);
	const [awsResources, setAwsResources] = useState(null);
	const [allResources, setAllResources] = useState([]); // All resources
	const [nodes, setNodes, onNodesChange] = useNodesState([]);
	const [edges, setEdges, onEdgesChange] = useEdgesState([]);
	const [resourceData, setResources] = useState([]);
	const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);
	const [selectedConnector, setSelectedConnector] = useState(null);
	const [selectedRegion, setSelectedRegion] = useState(null);
	const [b2aConnectorId, setB2AConnectorId] = useState('');

	const [selectedProvider, setSelectedProvider] = useState(null);
	const [tags, setTags] = useState([]);
	const [trackedServieces, setTrackedServices] = useState([]);
	const [subnetView, setSubnetView] = useState(false); // State variable to toggle subnet view
	const [cloneModal ,setCloneModal] = useState(false)
	const [configureDetails, setConfigureDetails] = useState({ id: "" });
	const [popupMsg, setPopUpMsg] = useState({"Msg":"",show:false,success:false})
	const [isLoading , setIsLoading] = useState(false)
	const [showDownloadModal, setShowDownloadModal] = useState(false)
	const [selectedFormat, setSelectedFormat] = useState("")
	const [downloadDisabled , setDownloadDisabled] = useState(false)
	let NodeData = []


	// const PADDING = 20;
	// const REGION_WIDTH = 2000;
	// const REGION_HEIGHT = 1000;
	// const MIN_VPC_WIDTH = 300;
	// const MIN_VPC_HEIGHT = 200;
	// const MIN_SUBNET_WIDTH = 200;
	// const MIN_SUBNET_HEIGHT = 150;
	// const SERVICE_WIDTH = 100;
	// const SERVICE_HEIGHT = 60;

	// Page Onload useEffect to Ivoke Region api Call and Pre set the values user selected valuse in the GRID component 
	useEffect(()=>{
		try {

		if(location.state){
			const {
				connector_id,
				connector_name,
				tags,
				region,
				cloudproviderId,
				providerName,
				b2a_connector_id,
				editMode,
			} = location.state
				console.log(location.state , "State here")
				if(connector_id){
					setSelectedConnector({
						connectorId: connector_id,
						connectorName: connector_name,
					});
					setSelectedRegion({ name: region , displayName :region });
					setSelectedTag(tags);
					setB2AConnectorId(b2a_connector_id);
					setSelectedProvider({ providerId: cloudproviderId , providerName : providerName });
					fetchRegions(connector_id, cloudproviderId) 
				} 
				else if(cloudproviderId &&providerName ){
					setSelectedProvider({ providerId: cloudproviderId , providerName : providerName });

				}
		}
		} catch (error) {
			console.log(error , "Error") 
		}
	},[location])
	/**
	* React Flow Variables Intialization
	*/
	// React Flow Some Times Throws the ResizeObserver loop completed with undelivered notifications" ||
    //"ResizeObserver loop limit exceeded
	//TO Remove that use this Below Code 
	useEffect(() => {
		const errorHandler = (e) => {
			if (
				e.message.includes(
				"ResizeObserver loop completed with undelivered notifications" ||
					"ResizeObserver loop limit exceeded"
				)
			) {
				const resizeObserverErr = document.getElementById(
				"webpack-dev-server-client-overlay"
				);
				if (resizeObserverErr) {
				resizeObserverErr.style.display = "none";
				}
			}
			};
			window.addEventListener("error", errorHandler);

			return () => {
			window.removeEventListener("error", errorHandler);
			};
		}, []);


	useEffect(() => {
		console.log(nodes, "nodesss")
	}, [nodes])

	// When User Changes the Region
	useEffect(() => {
		if (selectedRegion) {
			console.log(selectedRegion.name, "dfsfdsfsdfs")
			fetchTags(selectedRegion.name);
		}
	}, [selectedRegion]);
	// When user Changes the Selected Tags 
	useEffect(()=>{
		if(selectedTag.Key!="" && selectedConnector && selectedProvider){
			fetchVpcs(selectedRegion);
		}
	},[selectedTag])
	// fetchRegions Function is used to Retreive the Functions based on the Connector
	const fetchRegions = async (connectorId, cloudproviderId) => {
		try {
			const payload = {
				userId,
				organizationId: orgId,
				connectorId,
				cloudproviderId:cloudproviderId ? cloudproviderId : selectedProvider?.providerId ?selectedProvider?.providerId :"",
			};
				console.log(payload , "payload from Regions" )

			const response = await get_all_regionApi(payload);

				console.log(response , "Responsess from Regions" )
			if (response.success) {
				setRegions(response.data);
			}
		} catch (error) {
			console.error('Error fetching regions:', error);
		}
	};
	// fetchTags Function is used to Retreive the Tags based on the selected Region
	const fetchTags = async (regionName) => {
		try {
			const payload = {
				userId,
				connectorId: selectedConnector.connectorId,
				organizationId: orgId,
				providerType: selectedProvider.providerId,
				region: regionName,
			};
			console.log(payload , "Payloadd for Tags")

			const response = await get_all_tags_b2a_Api(payload);

				console.log(response , "response for the Get ALL Tags API")
			if (response.success) {
				setTags(response.data);
			}
		} catch (error) {
			console.error('Error fetching tags:', error);
		}
	};
	// handleBackToB2A Function is used to Navigate Back the User to the B2A Grid
	const handleBackToB2A = () => {
		navigate('/B2AGrid', {
			state: {
				providerName: selectedProvider.providerName,
				cloudproviderId: selectedProvider.providerId,

			}
			})

	};
	// fetchTags Function is used to Retreive the Vpcs based on the selected Tags
	const fetchVpcs = async (region) => {
		try {
			if(selectedConnector.connectorId && selectedProvider.providerId ){
			let payload = {
				"userId": userId,
				"connectorId": selectedConnector.connectorId,
				"organizationId": orgId,
				"providerType": selectedProvider.providerId,
				"region": selectedRegion.name,
				"tags":selectedTag
				}
			console.log(payload , "Payload for VPC ")
				// setIsLoading(true)
			let response = await fetchAllVpcApi(payload)
				// setIsLoading(false)
			console.log("fetchAllVpcApi" , response)

			if(response.success){
				setVpc(response.data)
				console.log(response.data , "REsponse Data here ")
				if (response.data.length > 0) {
					console.log("Fetch Resources Is triggering");
					fetchResources();
				}
				}
				}
		} catch (error) {
			console.error("Error fetching VPCs:", error);
		}
	};





	// fetchResources Function is used to Retreive the AWS Resources From the Tenant 
	// in the Form Of node structure 

	const fetchResources = async () => {
		if (!selectedRegion) return;
		try {
			// let payload ={
			// organizationId: "123",
			// userId: "1234",
			// connectorId:selectedConnector.connectorId ,
			// region: selectedRegion,
			// cloudproviderId:selectedProvider.providerId ,
			// ...(selectedVpc && { VpcId: selectedVpc }),
			// ...(Object.keys(selectedTag).length > 0 && { tags: selectedTag })
			// }
			// console.log(payload , "Payload for the get_all_resources_aws")
			// let response = await get_all_resources_aws(payload)
			// console.log(response , "response For the Get_All_resources")

			// const response = await axios.post('http://localhost:8080/get_all_resources_aws', {
			// organizationId: "123",
			// userId: "1234",
			// connectorId: "1f47c8a1-9c2d-4e8b-2b3a5-8d72f6e25d4c3b",
			// region: selectedRegion,
			// cloudproviderId: "123",
			// ...(selectedVpc && { VpcId: selectedVpc }),
			// ...(Object.keys(selectedTag).length > 0 && { tags: selectedTag })
			// });
			let payload = {
				userId:userId,
				connectorId:selectedConnector.connectorId,
				organizationId:orgId,
				cloudproviderId: selectedProvider.providerId,
				region:selectedRegion.name,
				vpc:selectedVpc,
				tags:selectedTag
			}
			console.log(payload , "Payload for the AWS service Retreival")
			setIsLoading(true)
			let res = await get_all_resources_aws(payload)
			setIsLoading(false)
			console.log(res , "get_all_resources_aws responsessss")
			if(res.success){
				if(res.data.orphans != {} &&res.data.vpcs?.length!=0 ){
					setAwsResources(res.data);
				}
				else{

				}
			}else{
				console.log("Structre Modifed")
			}


		} catch (error) {
			console.error("Error fetching resources: AWS", error);

		} 
	};

	// Orphans Filtering is Handled Here 
	const filterOrphanResources = () => {
		if (showOrphan) {
			// Filter orphan resources (those without a VPC binding)
			return allResources.filter(resource => !resource.vpcId);
		}
		return allResources; // Show all resources when showOrphan is false
	};
	// Orphans Button is Clicked
	useEffect(() => {
		// Update nodes based on whether showOrphan is checked or not
		const filteredResources = filterOrphanResources();
		// Convert resources to nodes format for ReactFlow
		const updatedNodes = filteredResources.map(resource => ({
			id: resource.id,
			data: { label: resource.name },
			position: { x: resource.x, y: resource.y }
		}));
		setNodes(updatedNodes);
	}, [showOrphan, allResources]);

	// Orphans Button is Clicked
	const generateTerraformContent = async () => {
		try {
			let payload = {
				"userId": userId,
				"connectorId": selectedConnector.connectorId,
				"region": selectedRegion,
				"tag": selectedTag
			};
			let res = await generateAWSTerraformerApi(payload);
			if (res.success) {
				console.log(res.data, "Data");
				const url = res.data;
				const response = await fetch(url);

				// Check the content type of the response
				const contentType = response.headers.get('content-type');

				if (contentType && contentType.includes('application/json')) {
					// If it's JSON, parse and return it
					const jsonData = await response.json();
					return JSON.stringify(jsonData, null, 2); // Pretty-print the JSON
				} else if (contentType && contentType.includes('application/zip')) {
					// If it's a zip file, handle it accordingly
					const blob = await response.blob();
					// You might want to save the zip file or process it further
					// For now, we'll just return a message
					return "Zip file received. Size: " + blob.size + " bytes";
				} else {
					// Handle other content types or unexpected responses
					return "Unexpected response type: " + contentType;
				}
			} else {
				return "error in generateTerraformContent"
			}
		} catch (err) {
			console.log(err, "error in generateTerraformContent");
			return err.message || "An error occurred";
		}
	};
	// This Function Downloads the cloud formation Code for the respective connector and Region and Tags
	const generateCloudFormationContent = async () => { 
		try{

			
		let payload = {
			"userId": userId,
			"connectorId": selectedConnector.connectorId,
			"region": selectedRegion,
			"tag": selectedTag
		}
		let res = await generateCloudFormationContentApi(payload) 
		if(res.success ){
			console.log(res.data ,"Data")
			return res.data
		}
		}catch(err){
			console.log(err , "error in generateCloudFormationContent ") 
			}
	};


	// DownLoad File
	const downloadFile = async (templateType) => {
		try {
			let content, fileName;
			setDownloadDisabled(true)
			switch (templateType) {
				case 'terraform':
					content = await generateTerraformContent();
					fileName = 'terraform_config.tf';
					break;
				case 'cloudformation':
					const s3UrlCf = await generateCloudFormationContent();
					content = await fetchContentFromS3(s3UrlCf);
					// console.log(content , "Content")
					fileName = 'cloudformation_template.yaml';
					break;

				default:
					throw new Error('Invalid template type');
			}

			const blob = new Blob([content], { type: 'text/plain' });
			const downloadLink = document.createElement('a');
			downloadLink.href = URL.createObjectURL(blob);
			downloadLink.download = fileName;

			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink);
			URL.revokeObjectURL(downloadLink.href);
			setDownloadDisabled(false)
			setShowDownloadModal(false);
			setSelectedFormat('');
			setPopUpMsg({...popupMsg , ["Msg"]:"Code Downloaded" , ["show"]:true ,["success"]:true })


		} catch (error) {
			setDownloadDisabled(false)
			setPopUpMsg({...popupMsg , ["Msg"]:"Error In Code Download" , ["show"]:true ,["success"]:false })
			console.error(`Error downloading ${templateType} file:`, error);
		}
		};

	// Function to fetch content from S3 URL
	const fetchContentFromS3 = async (s3Url) => {
		try {
			const response = await fetch(s3Url);
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
			return await response.text();
		} catch (error) {
			console.error('Error fetching content from S3:', error);
			throw error;
		}
		};


	/**
	* LocationBind function is to render the node in service node custom Type
	*/
	const locationBind = ({ data, id }) => {
		console.log(data, id, "Here")
		return (
			<>
				<NodeResizer style={{ "border": 'unset'}} />
				<div className={`locationMain ${id}`}>
					<div
						className="container-hover"
						id={id + "@location"}
						style={{
							display: "flex",
							padding: "11px 10px",
						}}
					>
						{data.element}
						<label
							className="locationLabel"
							id={id + "@nodeLabel"}
                            style={{ color: colorMode === "dark" ? "#FFFFFF" : "black" }}

							

						>
							{data.labelName || data.serviceName}
						</label>
					</div>
				</div>
			</>
		);
	};
	/**
	* SerivceBind function is to render the node in service node custom Type
	*/
	const serviceBind = ({ data, id }) => {
		console.log(id, "id Herere")
		console.log(data, "Data Here!")
		return (
			<>

				<div
				// style={{ position: 'relative', border: '2px solid #000', padding: '10px', borderRadius: '5px' }}
				>
					<span id={id + "1Source"}>
						<Handle id={`${id}leftSource`}
							type="source" position="left"
						/>
						<Handle id={`${id}topSource`} type="source" position="top" />
					</span>
					<span id={id + "1Target"}>
						<Handle id={`${id}leftTarget`} type="target" position="left" />
						<Handle id={`${id}topTarget`} type="target" position="top" />
					</span>
					<div style={{ position: "relative", margin: "-1px 0px 0px 0px" }}>
						{data.element}
					</div>
					<label
						className="nodeLabel"
						id={id + "@nodeLabel"}
						style={{ position: "absolute", color: colorMode === "dark" ? "#FFFFFF" : "black" }}

					>
						{data.serviceName}
					</label>
					<span id={id + "2Source"}>
						<Handle id={`${id}rightSource`} type="source" position="right" />
						<Handle id={`${id}bottomSource`} type="source" position="bottom" />
					</span>
					<span id={id + "2Target"}>
						<Handle id={`${id}rightTarget`} type="target" position="right" />
						<Handle id={`${id}bottomTarget`} type="target" position="bottom" />
					</span>
				</div>
			</>
		);
	};
	// Placeholder functions for content generation


	/**
	* Draggable Functionalits
	*/
	function download(filename, text) {
		//console.log("xmlText", text);

		var element = document.createElement("a");
		element.setAttribute(
			"href",
			"data:text/plain;charset=utf-8," + encodeURIComponent(text)
		);
		element.setAttribute("download", filename);

		element.style.display = "none";
		document.body.appendChild(element);

		element.click();

		document.body.removeChild(element);
	}

	/** Draggable Functionalotes Ends Here */
	// Fields Refactoring 
	function serviceFieldsRefactor(params) {
		try {
			if (params && Array.isArray(params)) {
				const seenFields = new Set(); // To track unique field identifiers
				return params.reduce((accumulator, field) => {
					// Create a unique identifier for the field based on fieldName and fieldType
					const fieldIdentifier = `${field.fieldName}-${field.fieldType}`;

					// If the field has not been seen before, process it
					if (!seenFields.has(fieldIdentifier)) {
						seenFields.add(fieldIdentifier);

						let refactoredField = {
							dependsOn: null,
							fieldName: field.fieldName,
							fieldType: field.fieldType,
							inputValue: "",
							required: true,
							UserValue: ""
						};

						// If 'value' exists in the original field, use it for 'inputValue'
						if ('value' in field) {
							try {
								const parsedValue = JSON.parse(field.value);
								refactoredField.inputValue = Array.isArray(parsedValue) && parsedValue.length > 0 ? parsedValue[0] : "";
							} catch (e) {
								refactoredField.inputValue = field.value;
							}
						}

						// If 'userValue' exists in the original field, use it for 'UserValue'
						if ('userValue' in field) {
							refactoredField.UserValue = field.userValue;
						}

						accumulator.push(refactoredField);
					}

					return accumulator;
				}, []);
			}
			return [];
		} catch (error) {
			console.log(error);
			return [];
		}
	}
	// Helper to create a node
	const createNode = ( position, size, serviceData, nodeType) => {
		try{
		console.log(serviceData , "ServiceData")
			const dimensionsMap = {
				"Account": 400,
				"Region": 350,
				"VPC": 300,
				"Availability_Zone": 250,
				"Subnet": 200,
				"Security_group": 150
		};
		let ConfigureDetail = {
			subServieConfig: {
				Action: {
					create: {},
					delete: {},
					update: {}
				},
				type: "create"
			}
		}
		const actionType = ConfigureDetail.subServieConfig.type;
		// Iterate through the fields in serviceData

		serviceData?.fields?.forEach(field => {
		// Add each field to the corresponding action object
		ConfigureDetail.subServieConfig.Action[actionType][field.fieldName] = field.value;
		});
		let nodeIdentity = nodeType;
		let nodeStyle = {
			position: "absolute",
			zIndex: serviceData.serviceName === "vpc" ? "-100000" : "100010",
			width: size.width,
			height: size.height,
			className: "resizeLoop",
			color: getColorForService(serviceData.serviceName)
		};

		let htmlElement = (
			<div>
				<div className="container-hover"
					onMouseEnter={(e) => {
						// Show tooltip
						const tooltip = document.createElement('div');
						tooltip.innerHTML = `<strong>${serviceData.serviceName}</strong><br>${serviceData.serviceDR}`;
						tooltip.style.position = 'fixed';
						tooltip.style.top = `${e.clientY + 10}px`;
						tooltip.style.left = `${e.clientX + 10}px`;
						tooltip.style.backgroundColor = 'black';
						tooltip.style.color = 'grey';
						tooltip.style.padding = '8px';
						tooltip.style.borderRadius = '4px';
						tooltip.style.zIndex = '100';
						tooltip.style.whiteSpace = 'pre-wrap';
						tooltip.style.maxWidth = '200px';
						tooltip.className = 'custom-tooltip';
						document.body.appendChild(tooltip);
					}}
					onMouseLeave={() => {
						// Hide tooltip
						const tooltips = document.getElementsByClassName('custom-tooltip');
						while (tooltips.length > 0) {
							tooltips[0].parentNode.removeChild(tooltips[0]);
						}
					}}
				>
					<img
						id={serviceData.id}
						width={25}
						height={25}
						src={`${serviceData.imageName}`}
						className={["VPC", "Region", "Subnet", "Security_group"].includes(serviceData.serviceName) ? "vpcImageStyle" : "img"}
						alt="service-logo"
						onError={(e) => {
							e.target.onerror = null; // Prevents looping
							e.target.src = `./images/Workbench/AIArchitecture/Azure/AzureLogo.svg`; // Set to your default image path
						}}
					/>
				</div>
			</div>
		);
		// Creation of Unique Entittes here to Difffernetiate Each Nodes 
	// Here Label Names , Image ServiceName and service Data Refactoring is performed Here
		let id = serviceData.serviceName + "_" + Math.random()
		let serviceFields = serviceFieldsRefactor(serviceData.fields)
		let nodeDataForCount = [...NodeData];
		let countForLabel = nodeDataForCount.filter(data => data === serviceData.serviceName).length + 1;
		let labelName = `${serviceData.serviceName} ${countForLabel}`;
		let imageServiceName = `${serviceData.serviceName}_${countForLabel}`
		NodeData.push( serviceData.serviceName)
			return {
				id: id,
				type: nodeIdentity,
				instanceName: labelName,
				position: {
					x: isNaN(position.x) ? 0 : position.x,
					y: isNaN(position.y) ? 0 : position.y
				},
				// parentNode: "",
				style: nodeStyle,
				data: {
					element: htmlElement,
					resourceName: serviceData.category_name ? serviceData.category_name : "Others",
					serviceName: serviceData.serviceName,
					imageName: serviceData.imageName,
					labelName : labelName,
					instanceName: labelName,
					imageServiceName: imageServiceName,
					serviceFamily :serviceData?.serviceFamily || "Others",
					fields: serviceFields || [],
					ConfigureDetail: ConfigureDetail,
					description: serviceData.description || '',
					children: undefined,
					repositoryData: {
						repository_name: "",
						repository_id: "",
						branchData: {
							branch_id: "",
							branch_name: ""
						}
					},
					Tags: [
						{
							"key": "cloudgen",
							"value": true,
							defaultTag: true
						} 
					]
					}
			};
		}
		catch(err){
			console.log(err , "Error in the Create Node")
		}
	};
	// Helper function to get color for service
	function getColorForService(serviceName) {
		const colorMap = {
			"VNET": "#8C4FFF",
			"Account": "#CD2264",
			"Availability_Zone": "#6610f2",
			"Resource_Group": "#00A4A6",
			"Subnet": "#DD3522",
			"Security_group": "#DD3522"
		};
		return colorMap[serviceName] || "#000000";
	} 

	// This is the Main function which Prepares the Node to be Binded on the Design area 

	const processAwsResources = useCallback(() => {
		console.log(selectedVpc , "selectedVpcselectedVpc")
		if (!awsResources && selectedVpc == '') return { nodes: [], edges: [] };
		console.log("processAwsResources", awsResources)
		const nodes = [];
		const edges = [];
		let id = 0;
		let trackedServices = []; // Array to store tracked services

		// Constants for layout and spacing
		const PADDING = 15;
		const SERVICE_SPACING = 15; // Spacing between individual services inside subnets
		const VERTICAL_SPACE_ABOVE = 15; // Space above the first row of services
		const MIN_VPC_WIDTH = 300;
		const MIN_VPC_HEIGHT = 300;
		const MIN_SUBNET_WIDTH = 200;
		const MIN_SUBNET_HEIGHT = 200;
		const SERVICE_WIDTH = 25;
		const SERVICE_HEIGHT = 25;
		const MAX_SERVICES_PER_ROW = 5; // Maximum services per row inside a subnet
		const ORPHAN_PADDING = 10;

		let maxX = 0;
		let maxY = 0;
		console.log(selectedVpc, "selectedVpc")

		// Process VPCs
		const vpcNodes = (awsResources?.vpcs || [])
			.filter(vpc => vpc.ai_configuration && ( vpc.configuration.VpcId === selectedVpc))
			.filter(vpc => (!selectedVpc || vpc.configuration.VpcId === selectedVpc))
			.map((vpc, vpcIndex) => {
				console.log("Invoked Inside")
				let vpcWidth = MIN_VPC_WIDTH;
				let vpcHeight = MIN_VPC_HEIGHT;
				let vpcSubnodes = [];

				// Process Subnets For the Node Constructions 
				if (vpc.subservice?.subnets) {
					const filteredSubnets = vpc.subservice.subnets.filter(subnet => subnet.ai_configuration);
					console.log(filteredSubnets, "filteredSubnets")
					const subnetsPerRow = Math.min(5, filteredSubnets.length); // 5 subnets per row
					let subnetWidth = MIN_SUBNET_WIDTH;
					let subnetHeight = MIN_SUBNET_HEIGHT;

					// Track the heights of previous subnets for vertical alignment
					let subnetYPositions = [];

					filteredSubnets.forEach((subnet, subnetIndex) => {
						// Retrieve services within the subnet
						const services = subnet.subservice || {};
						const serviceKeys = Object.keys(services);
						const totalServices = serviceKeys.reduce((sum, key) => sum + (services[key].filter(s => s.ai_configuration).length || 0), 0);
						console.log(services, serviceKeys, "	")

						// Calculate position for each subnet
						const subnetX = (subnetIndex % subnetsPerRow) * (subnetWidth + PADDING) + PADDING;
						const subnetY = Math.floor(subnetIndex / subnetsPerRow) * (subnetHeight + PADDING) + PADDING;

						// Store the height of this subnet for the next iterations
						subnetYPositions.push(subnetY + subnetHeight + PADDING); // Add the height plus padding

						// Node Creation Dependencies For Subnet 
						let position = { x: subnetX, y: subnetY, z: -100001 }
						let size = { width: subnetWidth, height: subnetHeight }

						let serviceData = {
							serviceName: subnet.service_name || "Subnet",
							imageName: subnet.service_icon ? subnet.service_icon :"./images/Workbench/AIArchitecture/AWS/subnet.svg",
							fields: subnet.ai_configuration?.fields?.fields ?subnet.ai_configuration.fields.fields : subnet.ai_configuration.fields || [],
							labelName: subnet.name,
							serviceFamily : subnet.category_name,
							serviceDR : subnet.serviceDR || subnet.service_name 
						}
						let nodeType = "location"
						// Creation of Node Performed Here
						const subnetNode = createNode(position, size, serviceData, nodeType);

						vpcSubnodes.push(subnetNode);

						if (subnetView == true) {
							// When subnetView is true, group services into a single node
							const serviceCounts = {}; // Track counts of each service type
							console.log(serviceKeys , "serviceKeys")
							serviceKeys.forEach(serviceType => {
								services[serviceType].forEach(service => {
									console.log(service , "Service Configuration")
									if (service.ai_configuration) {
										// Increment count for this service type
										serviceCounts[serviceType] = (serviceCounts[serviceType] || 0) + 1;
										trackedServices.push(service);
									}
								});
							});
							console.log(serviceCounts, "serviceCounts")
							// Render a single node for each service type with count
							Object.entries(serviceCounts).forEach(([serviceType, count], index) => {
								// Node Creation Dependencies For Subnet 
								console.log(serviceType, "serviceType")
								let position = {
									x: subnetX + PADDING,
									y: subnetY + VERTICAL_SPACE_ABOVE + PADDING + index * (SERVICE_HEIGHT + SERVICE_SPACING),
									z: 100000
								}
								let size = { width: SERVICE_WIDTH, height: SERVICE_HEIGHT }
								console.log(services , "Services hereere")
								console.log(services[index] , "Here")

								// let serviceData = {
								// serviceName: subnet.service_name || serviceType,
								// imageName: subnet.service_icon ? subnet.service_icon :"./images/Workbench/AIArchitecture/AWS/subnet.svg",
								// fields: subnet.ai_configuration?.fields?.fields ?subnet.ai_configuration.fields.fields : subnet.ai_configuration.fields || [],
								// labelName: subnet.name,
								// serviceFamily : subnet.category_name
								// }
								// Assuming services and serviceType are defined and valid
								if (services && services[serviceType]) {
				
									const service = services[serviceType][index];
									console.log(service , "Service Here" , serviceType , index , "WOrk ON")
									// Use the optional chaining operator to avoid undefined errors
									if(service){
									serviceData = {
										serviceName: service.service_name ? service.service_name : "" || serviceType,
										imageName: service["service_icon"] || "",
										fields: service.ai_configuration?.fields?.fields
											? service.ai_configuration.fields.fields
											: (service.ai_configuration?.fields || []),
										labelName: service["service_name"] || service.name || "",
										serviceFamily: service.category_name || "",
										serviceDR : service["serviceDR"]
									};

									// Additional check if fields is still undefined
									if (!serviceData.fields) {
										serviceData.fields = [];
									}

									console.log(serviceData);
									let nodeType = "service"
									// Creation of Node Performed Here
									console.log(serviceData , 'Servicedatatat')
									const serviceNode = createNode(position, size, serviceData, nodeType);

									vpcSubnodes.push(serviceNode);
									}
								} else {
									// Handle case if serviceType is not in services or services is undefined
									console.error("Service type is not defined in the provided services object or services is undefined.");
								}
								
							});
						} else {
							// Create individual nodes for each service, with spacing applied
							let serviceIndex = 0; // Keep track of service index
							console.log(services, "services here")
							serviceKeys.forEach(serviceType => {
								services[serviceType].forEach(service => {
									console.log(service, "ServiceYYYY")
									if (service.ai_configuration) {
										// Correctly positioning the services inside the specific subnet
										const row = Math.floor(serviceIndex / MAX_SERVICES_PER_ROW);
										const col = serviceIndex % MAX_SERVICES_PER_ROW;

										// Calculate serviceX and serviceY
										const serviceX = PADDING + col * (SERVICE_WIDTH + SERVICE_SPACING) + subnetX;
										const serviceY = subnetY + VERTICAL_SPACE_ABOVE + PADDING + row * (SERVICE_HEIGHT + SERVICE_SPACING); // Add vertical space above
										// Node Creation Dependencies
										let position = { x: serviceX, y: serviceY, z: 100000 }
										let size = { width: SERVICE_WIDTH, height: SERVICE_HEIGHT }
										let serviceData = {
											serviceName: service.service_name || serviceType || service.name,
											imageName: service.service_icon || service.serviceImage,
											fields:service.ai_configuration?.fields?.fields ?service.ai_configuration.fields.fields : service?.ai_configuration?.fields || [],
											labelName: service.name,
											serviceFamily : service.category_name,
											serviceDR: service.serviceDR ? service.serviceDR : service.serviceName

										}
										let nodeType = "service"
										const individualNode = createNode(position, size, serviceData, nodeType);
										console.log(individualNode, "individualNode")
										vpcSubnodes.push(individualNode);
										serviceIndex++;
									}
								});
							});

							// Adjust subnet size based on number of rows of services
							const rows = Math.ceil(totalServices / MAX_SERVICES_PER_ROW);
							subnetHeight = Math.max(MIN_SUBNET_HEIGHT, (SERVICE_HEIGHT + SERVICE_SPACING) * rows + VERTICAL_SPACE_ABOVE + PADDING);

							// Set the height for the subnet node
							subnetNode.style.height = subnetHeight;

							// Update the height of the subnet for the next positioning
							subnetYPositions[subnetIndex] = subnetY + subnetHeight + PADDING;
						}
					});

					// Calculate the size of the VPC based on the subnets' sizes
						vpcWidth = Math.max(MIN_VPC_WIDTH, (subnetWidth + PADDING) * subnetsPerRow + PADDING * 2);
						vpcHeight = Math.max(MIN_VPC_HEIGHT, (subnetHeight + PADDING) * Math.ceil(filteredSubnets.length / subnetsPerRow) + PADDING * 2);
		
						let vpcX = (vpcIndex % 2) * (vpcWidth + PADDING * 2);
						let vpcY = Math.floor(vpcIndex / 2) * (vpcHeight + PADDING * 2);
		
						// Ensure vpcY is a valid number
						vpcY = isNaN(vpcY) ? 0 : vpcY;
		
						maxX = Math.max(maxX, vpcX + vpcWidth);
						maxY = Math.max(maxY, vpcY + vpcHeight);
		
						// Node Creation Dependencies
						let position = { x: vpcX, y: vpcY, z: -100002 }
						let size = { width: vpcWidth, height: vpcHeight }
						
						let serviceData = {
							serviceName: vpc.service_name || "VPC",
							imageName: vpc.service_icon ? vpc.service_icon : "./images/Workbench/AIArchitecture/AWS/vpc.svg",
							fields: vpc.ai_configuration.fields || [],
							labelName: vpc.name,
							serviceFamily : vpc.category_name,
							serviceDR: vpc.serviceDR ? vpc.serviceDR :  vpc.serviceName


						}
						let nodeType = "location"
						const vpcNode = createNode(position, size, serviceData, nodeType);
		
						nodes.push(vpcNode);
						console.log(vpcNode , "VPC Nodeeeeee")
						console.log(vpcSubnodes , "vpcSubnodes Nodeeeeee")
						vpcSubnodes.forEach(subnode => {
							if(subnode){
							subnode["parentNode"] = vpcNode.id;
							nodes.push(subnode);
							}
						});
		
						// Handle orphan nodes positioning
						let orphanX = vpcX + vpcWidth + ORPHAN_PADDING; // Start to the right of the VPC
						let orphanY = vpcY; // Align with the VPC's Y position
						let orphanStartX = orphanX; // Remember the starting X position for new rows

					// Process orphan resources (if any)
					if (showOrphan && awsResources.orphans) {
						let orphanCount = 0; // Count orphans to manage row positioning
						Object.entries(awsResources.orphans).forEach(([resourceType, resources]) => {
							resources.forEach(resource => {
								if (resource.ai_configuration && Object.keys(resource.ai_configuration).length > 0) {
									// Node Creation Dependencies
									let position = { x: orphanX, y: orphanY, z: 100000 }
									let size = { width: SERVICE_WIDTH, height: SERVICE_HEIGHT }
									let serviceData = {
										serviceName: resource.service_name || "Unknown",
										imageName: resource.service_icon || resource.serviceImage,
										fields: resource.ai_configuration.fields || [],
										labelName: resource.name,
										serviceFamily : resource.category_name,
										serviceDR: resource.serviceDR ? resource.serviceDR : resource.serviceName


									}
									let nodeType = "service"
									const orphanNode = createNode(position, size, serviceData, nodeType);

									nodes.push(orphanNode);
									orphanCount++;

									orphanX += SERVICE_WIDTH + PADDING; // Move to the right for the next orphan

									// Move to the next row if the maximum number of orphans per row is reached
									if (orphanCount % MAX_SERVICES_PER_ROW === 0) {
										orphanX = orphanStartX; // Reset to the start of orphan area
										orphanY += SERVICE_HEIGHT + ORPHAN_PADDING; // Move down to the next row
									}
								}
							});
						});
					}
					return vpcNode;
				}
			});

		console.log("Final Tracked Services", trackedServices)
		return { nodes, edges, maxX, maxY, trackedServices };
		}, [awsResources, showOrphan, selectedVpc, selectedTag, subnetView]);

	useEffect(() => {
		const { nodes, edges , trackedServices } = processAwsResources();
		console.log(trackedServices , "trackedServices")
		setTrackedServices(trackedServices)
		setNodes(nodes);
		setEdges(edges);
	}, [processAwsResources, showOrphan]);

	// Helper function to find a resource by node id
	const findResourceByNodeId = (nodeId, awsResources) => {
		const [resourceType, resourceId] = nodeId.split('-');
		if (resourceType === 'ec2') {
			return awsResources.vpcs.flatMap(vpc =>
				vpc.subservice.subnets.flatMap(subnet =>
					subnet.subservice.ec2_instances.find(ec2 => ec2.configuration.InstanceId === resourceId)
				)
			)[0];
		}
		// Add more cases for other resource types as needed
		return null;
	};

	/**
	* This Below function performs the Onchange Operation for the 
	* Regions , And Setstate teh Null values to the Selected Tags and VPC
	* set the Nodes to [] 
	* 
	*/
	function handleRegions (e){
		setSelectedRegion(
			{ name: e.target.value , displayName :e.target.value })
		setNodes([])
		setTags([])
		setSelectedTag({ Key: "", Value: "" })
		setVpc([])
		setSelectedVpc("")

		}


	// const CustomNode = ({ data } ) => (
	// <div style={{
	// width: '100%',
	// height: '100%',
	// border: data.type === 'region' ? '2px solid #000' : '1px solid #ddd',
	// borderRadius: '5px',
	// padding: '10px',
	// overflow: 'hidden',
	// }}>
	// <div style={{ fontSize: '12px', fontWeight: 'bold' }}>
	// {data.label}
	// </div>
	// {/* Render ai_configuration fields here if needed */}
	// </div>
	// );
	// const nodeTypes = {
	// customNode: CustomNode
	// };

		// Tags Based Filtering of Resources
	const filterResourcesByTags = useCallback(() => {
		if (!awsResources || !selectedTag || Object.keys(selectedTag).length === 0) return awsResources;

		const filteredResources = JSON.parse(JSON.stringify(awsResources)); // Deep clone

		filteredResources.vpcs = filteredResources.vpcs.map(vpc => {
			vpc.securityGroups = vpc?.securityGroups?.map(sg => {
				sg.resources = sg.resources.filter(resource => {
					return Object.entries(selectedTag).every(([key, value]) =>
						resource.tags && resource.tags[key] === value
					);
				});
				return sg;
			});
			return vpc;
		});

		return filteredResources;
	}, [awsResources, selectedTag]);

	useEffect(() => {
		const filteredResources = filterResourcesByTags();
			console.log(filteredResources , "filteredResources")
		const { nodes, edges } = processAwsResources(filteredResources);
			console.log(nodes , "nodess")
		setNodes(nodes);
		setEdges(edges);
	}, [awsResources, selectedTag, filterResourcesByTags, processAwsResources]);

	useEffect(() => {
		const { nodes, edges } = processAwsResources();
		setNodes(nodes);
		setEdges(edges);
	}, [awsResources, processAwsResources]);

	// During the Overall Subnet View The user should able to Double click the Node To render the Sub services 
	const doubleClickLabel = (e, node) => {
		console.log("DoubleClick", node);
		console.log(trackedServieces, "trackedServieces");
	
		// Filter out only the service that matches the clicked node's serviceName
		const filteredServices = trackedServieces.filter((val) => val.service_name === node.data.serviceName);
		console.log(filteredServices , "here")
		setConfigureDetails({
			id: node.data.type,
			count: filteredServices.length,
			serviceName: node.data.serviceName, // You should use the serviceName of the clicked node
			trackedServieces: filteredServices
		});
	};
	
	function labelSubmit(configureValues) {
		if (configureValues = "exit") {
			setConfigureDetails({ id: "" })
		}
	}

	const nodeTypes = useMemo(
		() => ({
			service: serviceBind,
			location: (props) => locationBind(props, nodes),
		}),
		[]
	);
	const handleTagChange = (e) => {
		const [key, value] = e.target.value.split(':');
		setSelectedTag({ Key: key, Value: value });
	};
	/**
	* Draggable Functionalits
	*/

		/**
		* PS_62 - PS_66 XML Donwload Functionality
		*/
		function XMLDownload() {
			// PS_64 - Node Data formation 
			let nodeData = {
				nodes: nodes,
				edges: edges,
			};

			let stringifyNode = JSON.stringify(nodeData);
			stringifyNode = stringifyNode.replace(/&/g, "#@#@#");
			let architecutureName = "Test"
			let provider = "AWS"
			let technology = "Terraform"
			download(
				`${architecutureName}.xml`,
				`<?xml version="1.0" encoding="UTF-8" ?><cloudgen><${provider + "-" + technology
				}>` +
				stringifyNode +
				`</${provider + "-" + technology}></cloudgen>`
			);
			}
		function download(filename, text) {
			//console.log("xmlText", text);

			var element = document.createElement("a");
			element.setAttribute(
				"href",
				"data:text/plain;charset=utf-8," + encodeURIComponent(text)
			);
			element.setAttribute("download", filename);

			element.style.display = "none";
			document.body.appendChild(element);

			element.click();

			document.body.removeChild(element);
			}

		// PS_72 
			// DownLoadImage Function Download the Architecture Image in the specifed format 

			async function DownLoadImage(type) {
				try {
					document.getElementById("fittoscreen").click();

					const architectureElement = document.getElementById("myArchitecture");
					architectureElement.style.backgroundColor = "white";

					let dataUrl;
					if (type === "Jpeg") {
						dataUrl = await htmlToImage.toJpeg(architectureElement);
					} else if (type === "Png") {
						// Convert the Entire architecture ot dataURl
						dataUrl = await htmlToImage.toPng(architectureElement);

					} else {
						throw new Error("Unsupported image type");
					}

					const link = document.createElement("a");
					link.download = `${providerName}.${type.toLowerCase()}`;
					link.href = dataUrl;
					link.click();

					setTimeout(() => {
						architectureElement.style.backgroundColor = "transparent";
					}, 3000);
				} catch (error) {
					console.log(`Error in downloadImage: ${error}`);
				}
			}
	/** Draggable Functionalotes Ends Here */

	return (
		<>

			<div className="container-fluid">
				<div className="row">

					<SideBar/>

					<div className="col-md-9 px-4 pt-4 right-container-scroll" >
						<div className="row px-3"
							style={{position:"absolute"}}
						>
							{
							popupMsg.show == true && popupMsg.Msg !=""?
							<B2APopup
							popupMsg ={popupMsg}
							setPopUpMsg ={setPopUpMsg}
							/>
							:<></>
							}
							<div className="col-md-12">
								<div className="d-flex justify-content-between align-items-center mb-4 pb-2">
									<div className="d-flex align-items-center gap-3">
										<button
											type="button"
											className="btn custom-outline-button-v2 p-3 lh-0 font-medium font-14 black-v2"
											onClick={handleBackToB2A}
										>
											Back to B2A
										</button>
										<p className="font-20 font-bold black-v2 mb-0">
											AWS Infrastructure
										</p>
									</div>
									<div className="combine-button">
										<button
											className="font-14 font-regular primary-color deploy-btn"
											onClick={() => {
												// Set the Subnet View to False Before Cloning 
												setSubnetView(false)
												setCloneModal(true)
											}}
												// disabled = {nodes.length==0? true : false}
										>
											<img
												src="images/clone-icon.svg"
												alt="clone"
												className="me-2"
											/>
											Clone
										</button>

										<button
											className="font-14 font-regular primary-color deploy-btn"
											onClick={() => setShowDownloadModal(true)}
										>
											<img
												src="images/download-icon-purple.svg"
												alt="download"
												className="me-2"
											/>
											Download
										</button>
									</div>
								</div>
							</div>


							<div className="col-md-12">
								<div className="row">
									<div className="col-md-4 mb-3 px-3 pb-3">
										<label
											className="form-label font-14 font-medium black-v2"
											htmlFor="Region-new1"
										>
											Region<span className="required">*</span>
										</label>
										<select
											id="Region-new1"
											className="form-select form-control custom-form font-14 font-regular"
											value={selectedRegion?.name ? selectedRegion.name : "" }
											onChange={(e) =>handleRegions(e)}
										>
											<option value="">Select Region</option>
											{regions?.map((region) => (
												<option key={region.name} value={region.name}>
													{region.displayName}
												</option>
											))}
										</select>
									</div>

									<div className="col-md-4 mb-3 px-3 pb-3">
										<label className="form-label font-14 font-medium black-v2" htmlFor="Connector-new">
											Tags
										</label>
										<select
											id="Connector-new"
											className="form-select form-control custom-form font-14 font-regular"
											value={`${selectedTag.Key}:${selectedTag.Value}`}
											onChange={(e)=>{
												handleTagChange(e)
												// Empty
												setVpc([])
												setNodes([])
											}
											}
										>
											<option value="">Select Tag</option>
											{tags.map((tag, index) => {
											const key = tag["Key"]
											const value = tag["Value"];
											return (
												<option key={index} value={`${key}:${value}`}>
												{`${key}: ${value}`}
												</option>
											);
											})}
										</select>
									</div>


									<div className="col-md-4 mb-3 px-3 pb-3">
										<label className="form-label font-14 font-medium black-v2" htmlFor="VPC">
											VPC
										</label>
										<select
											id="VPC"
											className="form-select form-control custom-form font-14 font-regular"
											value={selectedVpc}
											onChange={(e) => {
												if(e.target.value == ""){
													// setNodes([])
												}
												setSelectedVpc(e.target.value)
											}}
										>
											<option value="">Select VPC</option>
											{vpcs.map((vpc, index) => (
												<option key={index} value={vpc.VpcId}>
													{vpc.VpcId}
												</option>
											))}
										</select>
									</div>

									<div class="col-md-4 mb-4">


										<div className="col-md-4 mb-4">
											<div className="d-flex align-items-center">
												<input
													type="checkbox"
													id="show-orphan-checkbox"
													className="form-check-input custom-checkbox shadow-none mt-0"
													checked={showOrphan}
													onChange={(e) => setShowOrphan(e.target.checked)} // Update state on change
												/>
												<label className="font-14 font-medium ms-2 black-v2 service-checkbox" htmlFor="show-orphan-checkbox">
													Show Orphan
												</label>
											</div>
										</div>
									</div>
									<div class="col-md-4 mb-4">
										<div class="d-flex align-items-center">
											<input type="checkbox" id="service-checkbox-1" class="form-check-input custom-checkbox shadow-none mt-0"
												onClick={() => {
													setSubnetView(!subnetView)
												}}
											></input>
											<label class="font-14 font-medium ms-2 black-v2 service-checkbox" for="service-checkbox-1">Show Overall Subnet View</label>
										</div>
									</div>


								</div>
							</div>
							{/* React Flow diagram */}
							{isLoading ? <Loader/> :
							<div className="workbench-content" style={{position: "relative" }} >
								<ReactFlowProvider>
									<div>
										<div className="reactflow-wrapper  content-center-container"
											style={{ position: 'absolute' }}
											ref={reactFlowWrapper}
										>

<Draggable handle="#moveable"
	defaultPosition={{ x: 350, y: 370 }}
	style={{width: '30%' }}>
	<div className="tools d-flex rounded shadow-sm"
		style={{ zIndex: "10",position: 'relative'}}
	>
		<button className="btn drag-btn" id="moveable">
			<img
				data-bs-toggle="tooltip"
				data-bs-placement="bottom"
				title="Drag"
				src="images/drag-img.svg"
				draggable={false}
				alt="Drag"
			/>
		</button>
		<button
			className="btn tool-btn"
			onClick={() => {
				document.documentElement
					.getElementsByClassName(
						"react-flow__controls-button react-flow__controls-zoomin"
					)[0]
					.click();
			}}
		>
			<img
				data-bs-toggle="tooltip"
				data-bs-placement="bottom"
				title="Zoom in"
				src="images/zoom-in.svg"
				className
			/>
		</button>
		<button
			className="btn tool-btn"
			onClick={() => {
				document.documentElement
					.getElementsByClassName(
						"react-flow__controls-button react-flow__controls-zoomout"
					)[0]
					.click();
			}}
		>
			<img
				data-bs-toggle="tooltip"
				data-bs-placement="bottom"
				title="Zoom out"
				src="images/zoom-out.svg"
				className
			/>
		</button>
		<button
			id="fittoscreen"
			className="btn tool-btn"
			onClick={() => {
				document.documentElement
					.getElementsByClassName(
						"react-flow__controls-button react-flow__controls-fitview"
					)[0]
					.click();
			}}
		>
			<img
				data-bs-toggle="tooltip"
				data-bs-placement="bottom"
				title="Fit to screen"
				src="images/fit-to-screen.svg"
				className
			/>
		</button>



		{/* Download dropdown */}
		<div className="dropdown d-flex">
			<button
				className="btn tool-btn"
				type="button"
				id="dropdownDOWNLOAD"
				data-bs-toggle="dropdown"
				disabled={nodes.length === 0}
				aria-expanded="false"
			>
				<img
					data-bs-toggle="tooltip"
					data-bs-placement="bottom"
					title="Download"
					src="images/download.svg"
					alt="Download"
				/>
			</button>
			<ul className="dropdown-menu profile-menu border-0 py-1 px-2 shadow-sm">
				<li className="border-grey pb-1">
					<button onClick={(()=>{XMLDownload()})} className="dropdown-item font-semibold font-14 profile-dropdown py-2 px-2">
						XML
					</button>
				</li>
				<li className="pt-1 pb-1 border-grey">
					<button onClick={() => DownLoadImage("Png")} className="dropdown-item font-semibold font-14 py-2 px-2 profile-dropdown">
						PNG
					</button>
				</li>
				<li className="pt-1">
					<button onClick={() => DownLoadImage("Jpeg")} className="dropdown-item font-semibold font-14 py-2 px-2 profile-dropdown">
						JPG
					</button>
				</li>
			</ul>
		</div>


	</div>
	</Draggable>
	<ReactFlow 

		nodes={nodes}
		edges={edges}
		onNodesChange={onNodesChange}
		onEdgesChange={onEdgesChange}
		onConnect={onConnect}
		fitView
		id="myArchitecture"
		nodeTypes ={nodeTypes}
		onNodeDoubleClick={(e, node) => {
			// TO Enable once Subnet View is Turned On
			if (subnetView == true) {
				doubleClickLabel(e, node);
			}
		}}
		onPaneClick={()=>{
			setConfigureDetails({
				id: "",
			})
			}}
	>
		<Background />
		<Controls />
	</ReactFlow>
	</div>
	</div>
	</ReactFlowProvider>
	{
		configureDetails.id != "" ?
			<AWSB2ASideBar
				configureDetails={configureDetails}
				labelSubmit={labelSubmit}

			/> :
			<></>


	}
		</div>

		}

		</div>

		</div>

		</div>
		{/* Download Modal */}
		<div className={`modal fade ${showDownloadModal ? 'show d-block' : ''}`}
			style={{ display: showDownloadModal ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
			<div className="modal-dialog modal-dialog-centered justify-content-center">
				<div className="modal-content border-0">
				<div className="modal-header border-0 p-4">
					<h1 className="modal-title font-20 font-bold black-v2" id="downloadModalLabel">Download Architecture</h1>
					<button type="button" className="btn shadow-none" onClick={() => setShowDownloadModal(false)} aria-label="Close">
					<img src="images/close-icon.svg" alt="close-icon" className="icon-hover shadow-none modal-close-filter" />
					</button>
				</div>
				<div className="modal-body px-4 pt-0">
					{/* Add your download options here */}
					<div className="my-3">
					<label className="form-label font-14 font-medium black-v2" htmlFor="downloadFormat">Download Format <span className="danger-red">*</span></label>
					<select
						className="form-select custom-dropdown shadow-none custom-border-box font-14 font-regular black-v2 custom-select"
						id="downloadFormat"
						value={selectedFormat}
						onChange={(e) => setSelectedFormat(e.target.value)}
					>
						<option value="">Select</option>
						<option value="terraform">Terraform</option>
						<option value="cloudformation">Cloud Formation</option>
						{/* Add more format options as needed */}
					</select>
					</div>

					<div className='d-flex justify-content-end align-items-center mt-4 mb-2 gap-3'>
					<button type='button' className='button outline-button text-nowrap py-12' onClick={() => setShowDownloadModal(false)}>Cancel</button>
					<button type='button' className='button primary-button text-nowrap px-4 py-12' onClick={()=>{downloadFile(selectedFormat)}} 
						disabled={!selectedFormat || downloadDisabled}
					>
						Download
					</button>
					</div>
				</div>
				</div>
			</div>
			</div>
		{/** Clone Modal Popup Compoent */}
		{
			cloneModal ?
			<>
				<CloneModal
				showCloneModal = {cloneModal}
				setShowCloneModal = {setCloneModal}
				nodes = {nodes}
				edges ={edges}
				setPopUpMsg ={setPopUpMsg}
				popupMsg={popupMsg}

				/>
			</> 
			:
			<></>
		}
		</div>
		</>
	);
};

export default AWSB2AComponent;
