import React, { useState, useEffect, useRef, useContext } from 'react';
import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';
import MonacoEditor from 'react-monaco-editor';
import { approveDeploy, createTemplateKb, deployarmcodeApi, deploycfcode, deploycfcodeApi, deploytfcode, fetchApproversList, fetchARMCode, fetchCfCode, fetchTfCode, requestForDeploy } from '../../Service/api';
import DeployPopUps from './DeployPopUps';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import SamplePopUp from './SamplePop'
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { OrgContext, useOrgContext } from '../../ContextProvider/OrgContext';
import { WorkbenchNav } from '../Organization/WorkBenchNav';
import DeploySideBar from './DeploySideBar';

import WorkbechPopup from "../Organization/Workbench/WorkBench/PopupMaintanence/WorkBenchPopUp";
import { FormContext } from '../Organization/Workload_Grid';
import DeployLoader from './DeployLoader';



function DeployModuleCodeEditor() {

  console.log("entered comp")


  const { orgId, envId, approvalStatus, setApprovalStatus, selectedLangforDownload, envName,setSelectedLangforDownload,setapprovalId } = useOrgContext()
  // const { formData } = useContext(FormContext);
  // const language = formData.language


  const [code, setCode] = useState('static data');
  const [regenerateComments, setRegenerateComments] = useState('');
  const [terminal, setTerminal] = useState(null);
  const [fitAddon, setFitAddon] = useState(null);
  const terminalRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [isDeployed, setIsDeployed] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(true);
  const [error, setError] = useState("")
  const [deploystatus, setDeployStatus] = useState(false)
  const [deployTerminalValue, setDeployTerminalValue] = useState({
    terraformCode: '',
    armCode: '',
    cloudFormationCode: ''
  })


  const [tfFiles, setTfFiles] = useState({});
  const [isAdmin, setIsAdmin] = useState('')
  const [admindata, setAdminData] = useState([])
  const [projectAdminData, setProjectAdminData] = useState([])
  const [selectedAdmin, setSelectedAdmin] = useState('');
  const [selectedProjectAdmin, setSelectedProjectAdmin] = useState('');
  const [regenerationText, setRegenerationText] = useState('')
  const [regenerateText, setRegenerateText] = useState(regenerationText);
  const dropdownRef = useRef(null);
  const [comments, setComments] = useState("");
  const [popupMsg, setPopUpMsg] = useState({ "Msg": "", show: false, success: false })
  const [deploy, setDeploy] = useState(false)
  const [isPlan, setIsPlan] = useState(false)
  const [isApply, setIsApply] = useState(false)
  const [isDestroy, setIsDestroy] = useState(false)
  const [downloadPopup, setDownloadPopup] = useState(false)
  const [selectDownloadLanguage, setSelectDownloadLanguage] = useState("Select")
  const [architectureDeployedPopup, setArchitectureDeployedPopup] = useState(false)
  const [planPopup, setPlanPopup] = useState(false)
  const [destroyedPopup, setDestroyedPopup] = useState(false)
  const [createdPopup, setCreatedPopup] = useState(false)
  const [selectSaveArchitecture, setSelectSaveArchitecture] = useState(false)
  const [downloadFileName, setDownloadFileName] = useState("")
  const darkTheme = localStorage.getItem('darkTheme')
  const[serviceCount,setServiceCount]=useState("")
  console.log(darkTheme, "darkTheme")




  const navigate = useNavigate()

  const location = useLocation()
  console.log(location, "location for deploy")
  const { activeItem, approvalId } = location.state || {};

  console.log(activeItem, approvalId, "CTIVEiTEM")
  console.log(selectedLangforDownload, "selectedLangforDownload in Deploy")

  const requestedApprovalId = location.state?.requestedApprovalId ?? [];
  const approversLanguage = location.state?.language ?? [];

  const [approvalLanguage, setApprovalLanguage] = useState(approversLanguage)
  console.log(envName, "envName")



  const selectedAccount = location.state?.selectedAccount ?? []
  const isRegenerate = location.state?.isRegenerate ?? []
  const isCodeGenerator = location.state?.isCodeGenerator ?? []


  const [selectedLanguage, setSelectedLanguage] = useState(selectedLangforDownload)
  const [languageforDownload, setlanguageforDownload] = useState(selectedLangforDownload)

  console.log(selectedLanguage.toLowerCase(), "selectedLanguage")


  console.log(requestedApprovalId, "requestApproval")

  const userId = localStorage.getItem('userId');
  console.log("userid", userId)
  const [deployLoader, setDeployLoader] = useState(false)


  useEffect(() => {
    const isAdminValue = localStorage.getItem('isAdmin');
    console.log(isAdminValue, "adminValue")
    setIsAdmin(isAdminValue);
  }, []);

  useEffect(() => {
    approversSelectedLnaguage()
    console.log(approvalLanguage, "approvalLanguage check")
  }, [approvalLanguage])

  console.log(approvalLanguage, "approvalLanguage outside useEffect")


  console.log(isAdmin, "isAdmin")

  console.log(approvalStatus, "approvalStatus", "selected")


  //PS_44 
  useEffect(() => {
    initializeTerminal();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  //PS_45
  const initializeTerminal = () => {
    if (terminalRef.current && !terminal) {
      const newTerminal = new Terminal();
      const newFitAddon = new FitAddon();
      newTerminal.loadAddon(newFitAddon);
      newTerminal.open(terminalRef.current);
      setTerminal(newTerminal);
      setFitAddon(newFitAddon);
    }
  };

  //PS_46-PS_47
  const handleResize = () => {
    if (fitAddon) {
      fitAddon.fit();
    }
  };

  //PS_41-PS_43 
  const handleDeploy = () => {
    if (selectedLanguage.toLowerCase() == "arm") {

      setModalType('arm');
      setShowModal(true);
    } else if (selectedLanguage.toLowerCase() == "cloudformation") {
      deployCfCode();
    } else {
      setModalType('deployed')
      setIsDeployed(true);
      // Handle other languages' deployment
    }
    setShowSuccessPopup(true);
    setTimeout(() => {
      setShowSuccessPopup(false);
    }, 2000);
  };

  //PS_48
  const handleModalClose = () => {
    setShowModal(false);
    setModalType('');
  };

  //PS_49
  const handleARMDeploy = () => {
    setIsDeployed(true);
    setShowModal(false);

    setShowSuccessPopup(true);
    setTimeout(() => {
      setShowSuccessPopup(false);
    }, 2000);
  };

  //PS_50
  useEffect(() => {
    fetchSelectedCode()
    handleApproversList()
  }, [selectedLanguage])

  useEffect(() => {

    fetchSelectedCode()
    console.log("useeffect for download lang", selectedLangforDownload, "fjkf")
  }, [selectedLangforDownload])

  useEffect(() => {
    const formData = localStorage.getItem('formdata');

    if (formData) {
      const formDataObj = JSON.parse(formData);

      const { workloadName } = formDataObj;
      const newValue = `${workloadName}_${envName}`;
      console.log("checkformdata", workloadName, envName)

      setDownloadFileName(newValue);
    }
  }, []);

  console.log((selectedLangforDownload.toLowerCase()), "checkfor")
  console.log(languageforDownload, "languageforDownload")

  console.log(selectedLanguage, selectedLangforDownload, "check for langauges")




  //PS_18-PS_24
  const fetchSelectedCode = async (regenerateComments = "") => {
    console.log("Fetch SelectedCode Triggered")
    debugger
    const isRegenerateNotEmpty = regenerateComments.trim() !== "";
    const terminalValue = deployTerminalValue.terraformCode || deployTerminalValue.armCode || deployTerminalValue.cloudFormationCode;
    // Terraform
    const payload = {
      "organizationId": orgId,
      "accountId": selectedAccount,
      "userId": userId,
      // "regenerateComments": terminalValue ? `${regenerateComments}, ${terminalValue}` : regenerateComments,
      "regenerateComments": regenerateComments,
      "isGenerate": isRegenerateNotEmpty ? true : isRegenerate
    }

    //Arm
    const payloadArm = {
      "organizationId": orgId,
      "accountId": selectedAccount,
      "userId": userId,
      // "regenerateComments": terminalValue ? `${regenerateComments}, ${terminalValue}` : regenerateComments,
      "regenerateComments": regenerateComments,
      "isGenerate": isRegenerate
    }

    try {
      
      console.log(payload, "selectedLang", selectedLanguage)
      if (selectedLangforDownload == 'arm' || selectedLangforDownload == 'Arm') {

        setDeployLoader(true)
        const armCode = await fetchARMCode(payloadArm);
        console.log("Arm code", armCode)
        setCode(armCode.data.body.data.code)
        setApprovalStatus(armCode.data.body.data.isapproved)
        setDeploy(true)

        setDeployLoader(false)
        // setPopUpMsg({ ...popupMsg, ["Msg"]: "Arm code fetched successfully", ["show"]: true, ["success"]: true })



      } else if (selectedLangforDownload.toLowerCase() == 'cloudformation' || selectedLangforDownload.toLowerCase() == 'cloud formation') {
        setDeployLoader(true)
        const cfFiles = await fetchCfCode(payload);
        if (cfFiles.data.body.data && cfFiles.data.body.data.code != null) {

          if (cfFiles.data.body.data && Array.isArray(cfFiles.data.body.data.code)) {
            setCode(cfFiles.data.body.data.code[0] ? cfFiles.data.body.data.code[0] : "")

          } else {

            setCode(cfFiles.data.body.data.code);
          }

          setApprovalStatus(cfFiles.data.body.data.isapproved)
          setDeploy(true)
          
        }
        setDeployLoader(false)
        const tfFiles = await fetchTfCode(payload);
        // setPopUpMsg({ ...popupMsg, ["Msg"]: "Cloud formation code fetched successfully", ["show"]: true, ["success"]: true })


      } else if (selectedLangforDownload == 'Terraform' || selectedLangforDownload == 'terraform') {
        setDeployLoader(true)
        const tfFiles = await fetchTfCode(payload);

        console.log("files in else ", tfFiles.data.body.data)
        console.log(tfFiles.data.body.data["main.tf"], "Code")

        setCode(tfFiles.data.body.data["main.tf"])
        setApprovalStatus(tfFiles.data.body.data.isapproved)

        setTfFiles(tfFiles.data.body.data)

        if (tfFiles.data.body.data.isDestroy == true) {
          const destroybtnEnable = tfFiles.data.body.data.isDestroy
          setIsApply(destroybtnEnable)
        }
        // setPopUpMsg({ ...popupMsg, ["Msg"]: "Terraform code fetched successfully", ["show"]: true, ["success"]: true })
        setDeploy(true)
        setDeployLoader(false)

        const cfFiles = await fetchCfCode(payload);
       

      }


    } catch (error) {
      setDeployLoader(false)

      console.log('Error fetching code:', error);
    }
  };

  console.log(tfFiles, "tf files")
  console.log(isPlan, isApply, isDestroy, "plan,apply,destroy")
  console.log(selectedLangforDownload, "selectedLangforDownload")



  //PS_43-PS_44
  const deployTerraformCode = async (mode) => {
    const payload = {
      "organizationId": orgId,
      "userId": userId,
      "accountId": selectedAccount,
      "mode": mode
    }
    try {
      setDeployLoader(true)
      const response = await deploytfcode(payload);
      setDeployLoader(false)
      if (response.status === 200) {
        setDeployTerminalValue(prevState => ({
          ...prevState, // Spread the previous state
          terraformCode: response.data.body.data.terminalData // Update terraformCode

        }));
        //setCode("static data");

        const activePlan = response.data.body.data.isPlan
        setIsPlan(activePlan)

        const activeApply = response.data.body.data.isApply
        setIsApply(activeApply)

        const activeDestroy = response.data.body.data.isDestroy
        setIsDestroy(activeDestroy)

        const serviceCount = response.data.body.data.serviceCount
        setServiceCount(serviceCount)

        setDeployStatus(true)
        setPopUpMsg({ ...popupMsg, ["Msg"]: "Terraform code deployed successfully", ["show"]: true, ["success"]: true })

        console.log((mode == "terraform destroy"), "mode")

        if (activeDestroy && mode == "terraform destroy") {
          setDestroyedPopup(true)
          setTimeout(() => setDestroyedPopup(false), 3000);

        } else if (activeApply && mode == "terraform apply") {
          setCreatedPopup(true);
          setTimeout(() => setCreatedPopup(false), 3000);

        } else if (activePlan && mode == "terraform plan") {
          setPlanPopup(true);
          setTimeout(() => setPlanPopup(false), 3000);
        }


      } else {
        // throw new Error('Error fetching terraform code data');
        setPopUpMsg({ ...popupMsg, ["Msg"]: "Terraform code deployed failed", ["show"]: true, ["success"]: false })

      }
    } catch (err) {
      setDeployLoader(false)
      // postErrorLog(err.message || err.toString());
      setError(err);
    }
  };



  //PS_44-PS_46
  const deployCfCode = async () => {
    const payload = {
      "organizationId": orgId,
      "accountId": selectedAccount,
      "userId": userId,
      "regenerateComments": regenerateComments,

    }
    try {
      setDeployLoader(true)
      const response = await deploycfcodeApi(payload);
      setDeployLoader(false

      )
      if (response.status === 200) {
        setDeployTerminalValue(prevState => ({
          ...prevState, // Spread the previous state
          cloudFormationCode: response.data.body.data.terminalData // Update terraformCode

        }));

        setDeployStatus(true)

        setArchitectureDeployedPopup(true);

        setPopUpMsg({ ...popupMsg, ["Msg"]: "CloudFormation code deployed successfully", ["show"]: true, ["success"]: true })


      } else {
        //throw new Error('Error fetching cloudFormation code data');
        setPopUpMsg({ ...popupMsg, ["Msg"]: "CloudFormation code deployed failed", ["show"]: true, ["success"]: false })

      }
    } catch (err) {
      setDeployLoader(false)
      // postErrorLog(err.message || err.toString());
      setError(err);
    }
  };

  //PS_47-PS_48
  const deployArmCode = async (mode) => {

    const payload = {
      "organizationId": orgId,
      "userId": userId,
      "accountId": selectedAccount,
      "deployOption": mode
    }
    try {
      setDeployLoader(true)
      const response = await deployarmcodeApi(payload);
      setDeployLoader(false)
      if (response.status === 200) {
        setDeployTerminalValue(prevState => ({
          ...prevState, // Spread the previous state
          armCode: response.data.body.data.terminalData // Update terraformCode

        }));

        setDeployStatus(true)

        setArchitectureDeployedPopup(true);

        setPopUpMsg({ ...popupMsg, ["Msg"]: "Arm code deployed successfully", ["show"]: true, ["success"]: true })

      } else {
        throw new Error('Error fetching cloudFormation code data');
        setPopUpMsg({ ...popupMsg, ["Msg"]: "Arm code deployed failed", ["show"]: true, ["success"]: false })

      }
    } catch (err) {
      setDeployLoader(false)
      setError(err);
    }
  };


  const isRegenerateNotEmpty = regenerateComments.trim() !== "";
  const terminalValue = deployTerminalValue.terraformCode || deployTerminalValue.armCode || deployTerminalValue.cloudFormationCode;
  const payload = {
    "organizationId": orgId,
    "accountId": selectedAccount,
    "userId": userId,
    "regenerateComments": terminalValue ? `${regenerateComments}, ${terminalValue}` : regenerateComments,
    "isGenerate": isRegenerateNotEmpty ? true : isRegenerate
  }

  //PS_49
  const handleDownload = async () => {
    debugger
    const zip = new JSZip();

    if (selectedLanguage == 'arm' || selectedLangforDownload == 'Arm') {
      zip.file("template.yaml", code);
    } else if (selectedLanguage.toLowerCase() == 'cloudformation' || selectedLangforDownload.toLowerCase() == 'cloud formation') {
      zip.file("template.yaml", code);
    } else if (selectedLanguage == 'Terraform' || selectedLangforDownload == 'terraform') {
      Object.entries(tfFiles).forEach(([filename, content]) => {
        // if (filename !== 'isapproved' && filename !== 'isDestroy') { // Skip the 'isapproved' key
        //   zip.file(`${filename}`, content);
        // }
        if (filename === 'main.tf' || filename === 'provider.tf') {
          zip.file(`${filename}`, content);
        }
      });
    }

    const content = await zip.generateAsync({ type: "blob" });
    saveAs(content, `${downloadFileName}.zip`);
    setPopUpMsg({ ...popupMsg, ["Msg"]: "Downloaded successfully", ["show"]: true, ["success"]: true })

  };

  const approversSelectedLnaguage = async () => {
    console.log("entered the approverfunc")

    setDeployLoader(true)

    try {
      if (approvalLanguage === 'arm') {
        const armCode = await fetchARMCode(payload);
        setCode(armCode.data.body.data.code)


      } else if (approvalLanguage?.toLowerCase == 'cloudformation') {
        console.log("entered cf download")
        const cfFiles = await fetchCfCode(payload);
        setCode(cfFiles.data.body.data.code)
        console.log(cfFiles, "download cf file")

      } else if (approvalLanguage?.toLowerCase() == 'terraform') {
        console.log("entered tf download")
        const tfFiles = await fetchTfCode(payload);
        setCode(tfFiles.data.body.data["main.tf"])

      }
      setDeployLoader(false)
    } catch {
      setDeployLoader(false)
      console.log('Error fetching code:', error);
    }


  };

  //PS_50-PS_51
  const handleApproveRequest = async (status, comments = "") => {
    console.log("entered")
    const approvalIdToSend = requestedApprovalId == "" ? approvalId : requestedApprovalId
    console.log(approvalIdToSend, "approvalIdToSend")

    const payload = {
      "organizationId": orgId,
      "userId": userId,
      "approvalId": approvalIdToSend,
      "status": status,
      "comments": comments
    }
    try {
      setDeployLoader(true)
      const response = await approveDeploy(payload);
      if (response.status === 200) {
        // setCfCode(response.data.body.data.terminalData);

        console.log("Approved successfully")
        setPopUpMsg({ ...popupMsg, ["Msg"]: "Approved successfully", ["show"]: true, ["success"]: true })



        setTimeout(() => {
          const closeButton = document.getElementById('close');
          if (closeButton) {
            closeButton.click();
          }
        }, 2000);

        navigate('/ApprovalGrid')
        localStorage.removeItem('approvalId')
        setSelectedLangforDownload("")
        setapprovalId('')

      } else {
        throw new Error('Error approving request  data');
        setPopUpMsg({ ...popupMsg, ["Msg"]: " Approval failed", ["show"]: true, ["success"]: false })

      }
      setDeployLoader(false)
    } catch (err) {
      // postErrorLog(err.message || err.toString());
      setDeployLoader(false)

      setError(err);
    }
  };

  //PS_52-PS_53
  const handleApproversList = async () => {
    const payload = {

      "userId": userId,
      "envId": "983e583c-40c9-4f72-af17-d88ac2d97953",
      "orgId": ""
    }
    try {
      console.log("entered for approvers")
      const response = await fetchApproversList(payload);
      if (response.status === 200) {
        setAdminData(response.data.body.data.Admin)
        setProjectAdminData(response.data.body.data.ProjectAdmin)

      } else {
        throw new Error('Error fetching cloudFormation code data');
      }
    } catch (err) {
      // postErrorLog(err.message || err.toString());
      setError(err);
    }
  };

  //PS_54 -PS_56
  const handleRequestForDeploy = async (adminId, projectAdminId) => {
    console.log("entered request")
    const payload = {
      "organizationId": orgId,
      "envId": envId,
      "userId": userId,
      "adminId": adminId,
      "projectAdminId": projectAdminId
    }

    try {
      setDeployLoader(true)

      const response = await requestForDeploy(payload);
      if (response.status === 200) {
        setShowSuccessPopup(true)
        setSelectedAdmin('');
        setSelectedProjectAdmin('');

        setTimeout(() => {
          const closeButton = document.getElementById('close-button');
          if (closeButton) {
            closeButton.click();
          }
        }, 2000);
        setPopUpMsg({ ...popupMsg, ["Msg"]: "Request for deploy successfull", ["show"]: true, ["success"]: true })


        console.log("sucessfully raised request")

      } else {
        throw new Error('Error fetching cloudFormation code data');
        setPopUpMsg({ ...popupMsg, ["Msg"]: "Request for deploy failed", ["show"]: true, ["success"]: false })

      }
      setDeployLoader(false)

    } catch (err) {
      // postErrorLog(err.message || err.toString());
      setDeployLoader(false)

      setError(err);
    }
  };

  ///PS_57
  const handleCancel = () => {
    setRegenerateText('');
    // Close the dropdown
    if (dropdownRef.current) {
      dropdownRef.current.click();
    }
  };


  // PS_58
  const handleRegenerateChange = (e) => {
    setRegenerateText(e.target.value);
  };

  //PS_59 -PS_61 : Implement regenerate functionality here
  const handleRegenerate = async () => {
    console.log('Regenerating with text:', regenerateText);
    // Close the dropdown after regenerating
    if (dropdownRef.current) {
      dropdownRef.current.click();
    }
    // Which set the Regernerate Comments in the Workbench 
    setRegenerationText(regenerateText)
    setRegenerateComments(regenerateText)
    await fetchSelectedCode(regenerateText);
    setPopUpMsg({ ...popupMsg, ["Msg"]: "Code Regenerated Successful", ["show"]: true, ["success"]: true })
    setRegenerateText('');


  };

  console.log(isAdmin, "check admin")
  console.log(requestedApprovalId.length === 0, "request stat")
  console.log(isCodeGenerator, "isCodeGenerator")
  console.log(!isCodeGenerator, "NotisCodeGenerator")


  console.log((approvalStatus === false && (isCodeGenerator || isCodeGenerator.length === 0)), "proceed button condition")
  console.log(approvalStatus === false && isAdmin == 'true', "proceedButton")
  console.log(approvalStatus, "appro")
  console.log((selectedLanguage.toLowerCase() == "arm" || selectedLanguage == "CloudFormation") && !isCodeGenerator, "deploy button rendering")


  function renderPlanbtn() {
    if (!isCodeGenerator) {
      if (selectedLanguage.toLowerCase() === "arm" || selectedLanguage.toLowerCase() === "cloudformation") {
        if (isAdmin === "true" || approvalStatus == true) {
          console.log("entered deploy btn")
          return (
            <button
              type="button"
              className="button primary-button text-nowrap py-3 px-4"
              onClick={handleDeploy}
            >
              Deploy
            </button>
          );
        }
      } else {
        if (isAdmin === "true" || approvalStatus == true) {
          console.log("entered for  tf plan")
          return (
            <div className="combine-button">
              <button
                className="font-14 font-medium primary-color-v4 deploy-btn"
                onClick={() => deployTerraformCode('terraform plan')}
              >
                Plan
              </button>
              <button
                className="font-14 font-medium primary-color deploy-btn"
                onClick={() => deployTerraformCode('terraform apply')}
                disabled={!isPlan}
              >
                Apply
              </button>
              <button
                className="font-14 font-medium danger-red deploy-btn"
                onClick={() => deployTerraformCode('terraform destroy')}
                disabled={!isApply}
              >
                Destroy
              </button>
            </div>
          );
        }
      }
    }

    // If none of the above conditions are met, show the Proceed button
    // if (approvalStatus === false && (isCodeGenerator || isCodeGenerator.length === 0)) {
    //   console.log("entered proceed btn")
    else {
      if (approvalStatus == false && (isCodeGenerator || isCodeGenerator.length === 0)) {
        return (

          <button
            type="button"
            className="button primary-button text-nowrap py-3 px-4"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop-1"
            hidden={isAdmin == "true"}
          >
            Proceed
          </button>

        );
      }
    }
  }



  function handleDownloadPopup() {
    setDownloadPopup(true)

  }

  const closeDeletePopup = () => {
    setDownloadPopup(false)

  }


  const saveArchitectureToKb = async () => {
    const payload = {
      "userId": userId,
      "template_kb_name": "spot check five",
      "cloudProviderId": "0a5816fa-5f85-4fd9-b716-62e4395c7b4a",
      "architectureTypeId": "d8818236-6406-4e7d-9b8e-2de96cd12f73",
      "businessDescription": "spot check five",
      "architectureDescrption": "spot check five",
      "cloudformationCode": deployTerminalValue.cloudFormationCode,
      "terraformCode": deployTerminalValue.terraformCode,
      "armCode": deployTerminalValue.armCode,
      "templateKbId": "e32bd6a0-19d2-4d71-aee2-34c56a03a149"
    }
    try {
      setDeployLoader(true)
      const response = await createTemplateKb(payload);
      setDeployLoader(false)
    } catch (err) {
      setDeployLoader(false)
      // postErrorLog(err.message || err.toString());
      setError(err);
    }
  };


  console.log(requestedApprovalId.length === 0 && approvalId === undefined, "approve button rendering")
  console.log(approvalId, "approvalId")
  console.log(requestedApprovalId, selectDownloadLanguage, "requestedApprovalId")
  console.log(selectDownloadLanguage, "selectDownloadLanguage")




  return (
    <>

      {/* header starts */}
      {/* header ends */}
      <div className="container-fluid" style={{ height: "100vh" }}>
        <WorkbenchNav />


        <div className="row mt-4">
          {/* sidebar-menu start */}


          <DeploySideBar
            activeItemName={activeItem}
          />



          <div className="col-md-9 mt-2 px-32">
            {
              deployLoader == true ?
                <DeployLoader /> :
                <></>
            }
            <div className="d-flex justify-content-between align-items-center mb-3">
              {/* <h4 className="font-18 font-medium black-v2 m-0">Code Generation</h4> */}
              {isCodeGenerator
                ? <h4 className="font-18 font-medium black-v2 m-0">Code Generation</h4>
                : <h4 className="font-18 font-medium black-v2 m-0">Deploy</h4>
              }

              {!approvalId && (
                <div className="d-flex gap-4">
                  <button type="button"
                    className="button primary-button text-nowrap px-4"
                    onClick={handleDownload}
                    hidden={!isCodeGenerator}
                  >

                    <img src="images/download-icon.svg"
                      alt="download" className="me-1" /> Download
                  </button>

                  {renderPlanbtn()}


                </div>
              )}



              <div
                className="modal fade show"
                id="staticBackdrop-1"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                //aria-hidden="true"
                style={{ backgroundColor: "rgba(0,0,0,0.6)" }}
              >
                <div className="modal-dialog  modal-dialog-centered justify-content-center">
                  <div className="modal-content approvel-dialog border-0">
                    <div className="modal-header border-0 p-32 pb-4">
                      <h1
                        className="modal-title font-20 font-bold black-v2"
                        id="staticBackdropLabel"
                      >
                        <img src="images/alert-icon.svg" alt="alert-icon" /> Approval Needed
                      </h1>
                      <button
                        type="button"
                        className="btn shadow-none p-0"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        id='close-button'
                      >
                        <img
                          src="images/close-icon.svg"
                          alt="close-icon"
                          className="icon-hover shadow-none modal-close-filter"
                        />
                      </button>
                    </div>
                    <div className="modal-body px-32 pt-0">
                      <p className="font-16 font-regular secondary-text-color-v4 mb-32">
                        Approval needed to proceed to further actions.
                      </p>
                      <div className="my-4">
                        <label
                          className="form-label font-14 font-medium black-v2"
                          htmlFor="Status-21"
                        >
                          Select Admin
                          <span className="danger-red">*</span>
                        </label>
                        <select
                          className="form-select custom-dropdown shadow-none custom-border-box font-14 font-regular black-v2 custom-select"
                          aria-label="Default select example"
                          id="Status-21"
                          value={selectedAdmin}
                          onChange={(e) => setSelectedAdmin(e.target.value)}
                        >
                          <option value="">Select</option>
                          {admindata.map((admin, index) => (
                            <option key={index} value={admin.user_id}>{admin.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="my-4">
                        <label
                          className="form-label font-14 font-medium black-v2"
                          htmlFor="Status-24"
                        >
                          Select project Admin
                        </label>
                        {/* <input type="text" class="form-control custom-dropdown shadow-none" id="Status-24"
                                  placeholder="Enter URL"> */}
                        <select
                          className="form-select custom-dropdown shadow-none custom-border-box font-14 font-regular black-v2 custom-select"
                          aria-label="Default select example"
                          id="Status-24"
                          value={selectedProjectAdmin}
                          onChange={(e) => setSelectedProjectAdmin(e.target.value)}
                        >
                          <option value="">Select</option>
                          {projectAdminData.map((admin, index) => (
                            <option key={index} value={admin.user_id}>{admin.name}</option>
                          ))}
                        </select>
                      </div>

                      <div className="d-flex justify-content-end align-items-center mt-5 mb-2 gap-3">
                        <button
                          type="button"
                          className="button outline-button text-nowrap px-4"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn button primary-button text-nowrap px-3"
                          disabled={!selectedAdmin}
                          onClick={() => { handleRequestForDeploy(selectedAdmin, selectedProjectAdmin) }}
                        >
                          Send Request
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>




              {showModal && (
                <DeployPopUps
                  onClose={handleModalClose}
                  modalType={modalType}
                  onDeploy={modalType === 'arm' ? handleARMDeploy : undefined}

                />
              )}

              {showModal && (
                <DeployPopUps
                  onClose={handleModalClose}
                  modalType={modalType}
                  onDeploy={modalType === 'deployed'}
                />
              )}




            </div>


            {showModal && (
              <DeployPopUps
                onClose={() => setShowModal(false)}
                modalType={modalType}
                onDeploy={deployArmCode}
              />
            )}


            <div className="generate-code-container position-relative">
              <div className="monaco-editor-wrapper" style={{ position: 'relative', height: '600px' }}>
                {isCodeGenerator ? (
                  <MonacoEditor
                    width="100%"
                    height="100%"
                    language={selectedLanguage === 'terraform' ? 'hcl' : 'json'}
                    theme={darkTheme == "true" ? 'vs-dark' : 'vs-light'}
                    value={
                      code !== "static data" ? code : "" // Show code if it's not "static data"
                    }
                    options={{ readOnly: true }}
                  />
                ) : (
                  <MonacoEditor
                    width="100%"
                    height="100%"
                    language={selectedLanguage === 'terraform' ? 'hcl' : 'json'}
                    theme={darkTheme == "true" ? 'vs-dark' : 'vs-light'}
                    value={
                      (deploystatus === true ?
                        (deployTerminalValue.terraformCode || deployTerminalValue.armCode || deployTerminalValue.cloudFormationCode) || ""
                        : "")
                    }
                    options={{ readOnly: true }}
                  />
                )}
                <div className="regenerate-container" style={{
                  position: 'absolute',
                  bottom: '10px',
                  right: '10px',
                  zIndex: 1000
                }}>

                  {/* Regenerate */}
                  {/* {!approvalStatus && isCodeGenerator && ( */}
                  { isCodeGenerator && (
                    <div className="dropdown cust-dropdown-pos">
                      <button
                        type="button"
                        className="btn cust-dropdown d-flex justify-content-between align-items-center gap-3 chatbot-btn shadow-none"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        data-bs-auto-close="outside"
                        ref={dropdownRef}
                        // changed
                        style={{ height: "55px", width: "55px", borderRadius: "68%" }}
                      >
                        <img src="images/chatbot-icon.svg" alt="chatbot-icon" />
                        <img
                          src="images/cross-icon-white.svg"
                          alt="cross-icon-white"
                          className="d-none"
                        />
                      </button>


                      <div
                        className="dropdown-menu body-bg chatbot-dropdown p-3 cust-drop-width mb-4"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <label
                          className="form-label font-12 font-bold color-black"
                          htmlFor="Regenerate"
                        >
                          Regenerate
                        </label>
                        <textarea
                          className="form-control chatbot-textarea font-11 font-medium mb-3 color-black"
                          placeholder="Describe what would you like to regenerate"
                          id="Regenerate"
                          value={regenerateText}
                          onChange={handleRegenerateChange}
                        />
                        <div className="d-flex justify-content-end align-items-center gap-3">
                          <button
                            type="button"
                            className="button cancel-button lh-1 py-2"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn regenerate-btn shadow-none" style={{ borderRadius: "70%" }}
                            onClick={handleRegenerate}
                          >
                            <img src="images/send-arrow-icon.svg" alt="send-arrow-icon" />
                          </button>
                        </div>
                        <div className="position-relative">
                          <img
                            src="images/white-polygon-icon.svg"
                            alt="polygon-icon"
                            className="pointer-pos light-theme-img"
                          />
                          <img
                            src="images/polygon-dark.svg"
                            alt="polygon-icon"
                            className="pointer-pos dark-theme-img"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Regenerate */}
                </div>
                {/* </div> */}
              </div>
            </div>

            <div className="d-flex justify-content-end  gap-4">

              <button type="button" className="button primary-button text-nowrap px-4"
                onClick={() => handleApproveRequest("Approved", comments)}

                hidden={(requestedApprovalId.length === 0 && approvalId === undefined) || !isCodeGenerator}
              >
                <img src="images/download-icon.svg" alt="download" className="me-1" /> Approve
              </button>
              <button
                type="button"
                className="button primary-button text-nowrap py-3 px-4"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop-21"
                //onClick={handleApproveRequest}
                hidden={(requestedApprovalId.length === 0 && approvalId === undefined) || !isCodeGenerator}

                style={{ "width": "100px" }}

              >
                Reject
              </button>
            </div>

            {/* {localStorage.getItem('isAdmin') === '1' && ( */}
            {isAdmin == "true" || isAdmin == true && (
              <>
                {/* <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="d-flex gap-4">
                  <button
                    type="button"
                    className="button primary-button text-nowrap py-3 px-4"
                    onClick={handleDeploy}
                  >
                    Deploy
                  </button>
                </div>
                </div> */}


              </>
            )}


            <SamplePopUp handleApproveRequest={handleApproveRequest} />

            {downloadPopup && (
              <div
                className="modal fade show"
                id="staticBackdrop-17"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden={!downloadPopup}
                style={{ display: 'block', backgroundColor: "rgba(0,0,0,0.6)" }}

              >
                <div className="modal-dialog  modal-dialog-centered justify-content-center">
                  <div className="modal-content approvel-dialog height-250 border-0">
                    <div className="modal-header border-0 p-32 ">
                      <h1
                        className="modal-title font-20 font-bold black-v2"
                        id="staticBackdropLabel"
                      >
                        Download Code
                      </h1>
                      <button
                        type="button"
                        className="btn shadow-none p-0"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={closeDeletePopup}
                      >
                        <img
                          src="images/close-icon.svg"
                          alt="close-icon"
                          className="icon-hover shadow-none modal-close-filter"
                        />
                      </button>
                    </div>
                    <div className="modal-body px-32 pt-0">
                      <div className="my-4 mt-0">
                        {/* dropdown */}
                        <label
                          className="form-label font-14 font-medium black-v2"
                          htmlFor="Organization"
                        >
                          IaC
                          <span className="danger-red">*</span>
                        </label>
                        <div className="dropdown">
                          <button
                            type="button"
                            className="btn custom-dropdown w-100 d-flex justify-content-between align-items-center gap-3 font-12 font-regular color-black shadow-none"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"

                          >

                            {selectDownloadLanguage}
                            <img src="images/drop-down-arrow.svg" alt="dropdown-arrow" />
                          </button>
                          <ul
                            className="dropdown-menu body-bg custom-shadow border-0 w-100 p-0"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li className="font-regular black-v2 font-12 p-2 custom-list"
                              onClick={() => {
                                const language = 'terraform';
                                setSelectDownloadLanguage(language);
                                handleDownload(language);
                              }}
                            >
                              <span className="d-flex">
                                <img
                                  src="images/terraform-img.svg"
                                  alt="terraform-img"
                                  className="me-1 icon-width"
                                />
                                Terraform
                              </span>
                            </li>
                            <li className="font-regular black-v2 font-12 p-2 custom-list"
                              onClick={() => {
                                const language = 'cloudFormation';
                                setSelectDownloadLanguage(language);
                                handleDownload(language);
                              }}



                            >
                              <span className="d-flex ">
                                <img
                                  src="images/cloud-formation-img.svg"
                                  alt="cloud-logo"
                                  className="me-1 icon-width"
                                />
                                Cloud Formation
                              </span>
                            </li>
                            <li className="font-regular black-v2 font-12 p-2 custom-list"
                              onClick={() => {
                                const language = 'arm';
                                setSelectDownloadLanguage(language);
                                handleDownload(language);
                              }}



                            >
                              <span className="d-flex">
                                <img src="images/arm-img.svg"
                                  alt="arm-img"
                                  className="me-1 icon-width" />
                                ARM
                              </span>
                            </li>
                          </ul>
                        </div>
                        {/* dropdown */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {destroyedPopup && (
              <div class="modal fade show"
                id="staticBackdrop-12"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden={!destroyedPopup}
                style={{ display: 'block', backgroundColor: "rgba(0,0,0,0.6)" }}
              >
                <div class="modal-dialog modal-dialog-centered justify-content-center">
                  <div class="modal-content border-0">
                    <div class="modal-header d-flex justify-content-end border-0 pb-0 ">
                      <button type="button" class="btn shadow-none" data-bs-dismiss="modal" aria-label="Close"><img
                        src="images/close-icon.svg" alt="close-icon"
                        class="icon-hover shadow-none modal-close-filter" /></button>
                    </div>
                    <div class="modal-body text-center pt-0 p-32">
                      {/* <img src="images/destroyed-delete-icon.svg" alt="approved-tick" /> */}
                      <h6 class="font-20 font-bold black-v2 mt-4">Services has been destroyed</h6>

                      <p class="font-16 font-regular secondary-text-color-v4 mt-4">{serviceCount} Services has been destroyed
                        successfully
                      </p>


                      <div class="d-flex justify-content-center align-items-center mt-32">
                        <button type="button" class="button primary-button px-5 py-12"
                          data-bs-dismiss="modal">OK</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {planPopup && (
              <div class="modal fade show"
                id="staticBackdrop-12"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden={!planPopup}
                style={{ display: 'block', backgroundColor: "rgba(0,0,0,0.6)" }}
              >
                <div class="modal-dialog modal-dialog-centered justify-content-center">
                  <div class="modal-content border-0">
                    <div class="modal-header d-flex justify-content-end border-0 pb-0 ">
                      <button type="button" class="btn shadow-none" data-bs-dismiss="modal" aria-label="Close">
                        <img
                          src="images/close-icon.svg" alt="close-icon"
                          class="icon-hover shadow-none modal-close-filter" />
                      </button>
                    </div>
                    <div class="modal-body text-center pt-0 p-32">
                      {/* <img src="images/destroyed-delete-icon.svg" alt="approved-tick" /> */}
                      <h6 class="font-20 font-bold black-v2 mt-4">Services has been Planned</h6>

                      <p class="font-16 font-regular secondary-text-color-v4 mt-4">{serviceCount} Services has been Planned
                        successfully
                      </p>


                      <div class="d-flex justify-content-center align-items-center mt-32">
                        <button type="button" class="button primary-button px-5 py-12"
                          data-bs-dismiss="modal">OK</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {createdPopup && (
              <div class="modal fade show"
                id="staticBackdrop-12"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden={!createdPopup}
                style={{ display: 'block', backgroundColor: "rgba(0,0,0,0.6)" }}
              >
                <div class="modal-dialog modal-dialog-centered justify-content-center">
                  <div class="modal-content border-0">
                    <div class="modal-header d-flex justify-content-end border-0 pb-0 ">
                      <button type="button" class="btn shadow-none" data-bs-dismiss="modal" aria-label="Close"><img
                        src="images/close-icon.svg" alt="close-icon"
                        class="icon-hover shadow-none modal-close-filter" /></button>
                    </div>
                    <div class="modal-body text-center pt-0 p-32">
                      {/* <img src="images/destroyed-delete-icon.svg" alt="approved-tick" /> */}
                      <h6 class="font-20 font-bold black-v2 mt-4">Services has been Created</h6>

                      <p class="font-16 font-regular secondary-text-color-v4 mt-4">{serviceCount} Services has been created
                        successfully
                      </p>


                      <div class="d-flex justify-content-center align-items-center mt-32">
                        <button type="button" class="button primary-button px-5 py-12"
                          data-bs-dismiss="modal">OK</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {architectureDeployedPopup && (
              <div
                className="modal fade show"
                id="staticBackdrop-11"
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                tabIndex={-1}
                aria-labelledby="staticBackdropLabel"
                aria-hidden={!architectureDeployedPopup}
                style={{ display: 'block', backgroundColor: "rgba(0,0,0,0.6)" }}

              >
                <div className="modal-dialog modal-dialog-centered justify-content-center">
                  <div className="modal-content border-0">
                    <div className="modal-header d-flex justify-content-end border-0 pb-0 ">
                      <button
                        type="button"
                        className="btn shadow-none"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        onClick={() => { setArchitectureDeployedPopup(false) }}
                      >
                        <img
                          src="images/close-icon.svg"
                          alt="close-icon"
                          className="icon-hover shadow-none modal-close-filter"
                        />
                      </button>
                    </div>
                    <div className="modal-body text-center pt-0 p-32">
                      <img src="images/approved-tick.svg" alt="approved-tick" />
                      <h6 className="font-20 font-bold black-v2 mt-3">
                        Architecture has been deployed
                      </h6>
                      <div className="mt-4">
                        <input
                          className="form-check-input custom-checkbox shadow-none me-2"
                          type="checkbox"
                          defaultValue=""
                          id="check-list"
                          checked={selectSaveArchitecture} // Bind checked state
                          onChange={() => {
                            setSelectSaveArchitecture(prevState => !prevState);
                            saveArchitectureToKb()
                          }}
                        />
                        <label
                          htmlFor="check-list"
                          className="font-16 font-regular secondary-color"
                        >
                          Add current architecture to Knowledge Base
                        </label>
                      </div>
                      <div className="d-flex justify-content-center align-items-center mt-4">
                        <button
                          type="button"
                          className="button primary-button px-5 py-12"
                          data-bs-dismiss="modal"
                          onClick={() => { setArchitectureDeployedPopup(false) }}
                        >
                          OK
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            )}








          </div>

        </div>
      </div>
    </>
  )
}
export default DeployModuleCodeEditor;