//PS_01
import React, { useState, useEffect, useContext } from 'react';
//PS_02
import SideBar from '../sidebar/SideBar';
//PS_03
import { FormContext } from './Workload_Grid';
import { useNavigate } from 'react-router-dom';

import { getAllRolesApi,searchUsersApi,createWorkloadApi,updateWorkloadApi,getAllRulesApi} from '../../Service/api';
import { OrgContext } from '../../ContextProvider/OrgContext';
import { useLocation } from 'react-router-dom';
import PopupComponent from '../Toast/toastPopup';
import ToastPopup from '../Toast/toastPopup';


const MemberDetails = () => {
    console.log("Rendering MemberDetails"); // This should print when the component is rendered
    //PS_05
    const { formData, setFormData } = useContext(FormContext);
    const navigate=useNavigate();

console.log("sggggg",formData);
//PS_06 state variables
const [userInput, setUserInput] = useState({ userId: '', userName: '' });
    const [emailInput, setEmailInput] = useState('');
    const [role, setRole] = useState([]);
	// const [popupMsg, setPopUpMsg] = useState({"Msg":"",show:false,success:false})

    const [roles, setRoles] = useState([]);
    const [members, setMembers] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [searchTerm, setSearchTerm] = useState(''); 
    const [selectedUser, setSelectedUser] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc'); // State for sorting order
    const [toastMsg, setToastMsg] = useState({ show: false, message: '', success: true });

    const {
        userRoles,
        orgId,
        providerId,
        architectures,
        canCreateWorkload,
        canDeleteWorkload,
        canViewWorkload,
        setuserRoles,
        setRepository_id,setrepository_name,setPulseIdentification,

        setworkloadId,setEnvName,setUTI,

        // Add organization and provider fields here
        providerName,       
        orgData, isProjectAdmin, setIsProjectAdmin,          
        setOrgData,        
        setArchitectures,
    
        envId,             // Environment ID
        workloadId,        // Workload ID
        aiArchitecture,    
        viewMode,          
        envName,           
        language,          
        archType,          
        business,          
        rulebook,          
        archDesc,          
        services,
    
        lastBuildID,       
        lastBuildDate,     
        lastBuildMessage,
    
        repoId,            
        repoName,
    
        PulseOrganization_id,
        PulseVertical_id,
        PulseVertical_name,
        PulseOrganization_name,
        PulseIdentification,            setproviderId,setProviderName
,
    
        setEnvId           // Function to update environment ID
    } = useContext(OrgContext);

    const [userId, setuserId] = useState(localStorage.getItem('userId'));

    //PS_11
    const [editMemberData, setEditMemberData] = useState({ 
        userId: '', 
        username: '', 
        email: '', 
        role: { roleName: '', roleId: '' } // Include roleId
    });
    
    useEffect(() => {
        loadRoles()
        handleSearchUsers()
        loadRules()
    }, [userInput.userName,editMemberData.username]);
    useEffect(() => {
       handleSave()
    }, [members]);
    const [users, setUsers] = useState([]); 
    console.log("final",formData);
    const location = useLocation()
    
    useEffect(() => {
        // if (formData?.workloadId && formData?.member) {
            console.log("in mem");
            // Set your actual state variables based on formData
            setMembers(formData.member.reduce((acc, member) => {
                if (member.memberUserId !== "") {
                    acc.push({
                        userId: member.memberUserId,
                        username: member.memberUserName,
                        email: member.memberEmail,
                        role: {
                            roleId: member.roleId,
                            roleName: member.memberRole
                        }
                    });
                }
                return acc;
            }, []));
        // }
    }, []);
    const [checkedRules2, setCheckedRules2] = useState([]);

    const loadRules = async () => {
        const payload = {
          userId: userId,
          orgId: orgId,
        };
    
        try {
          const response = await getAllRulesApi(payload); // Call the API function
          if (response.success) {
            console.log("Response fetched successfully");
            const data = response.data;
    
            // Prepare rulebook based on the structure of the response data
            const rulebook = Object.keys(data).map((category) => ({
                categoryId: category, // Use category name as ID (or modify if you have a specific ID)
                categoryName: category,
                ruleName: data[category].map((rule) => ({
                    rulebook_id: rule.ruleId, // Assuming ruleId is what you want to keep
                    check: rule.check,
                    ruleDesc: rule.ruleDesc,
                })),
            }));
            
            // Create a new state variable to store rules with check = true
            const checkedRules = rulebook.map(category => ({
                categoryId: category.categoryId,
                categoryName: category.categoryName,
                ruleName: category.ruleName.filter(rule => rule.check) // Filter rules where check is true
            }));
            
            // Set the checkedRules state variable (assuming you have a state setter function)
            setCheckedRules2(checkedRules);
            console.log("checkedRules",checkedRules);
            
    
        
          } else {
            console.error("Error fetching rules");
          }
        } catch (error) {
          console.error("An error occurred while fetching rules:", error);
        }
      };
      console.log("checkedRules",checkedRules2);

      console.log(envName, "envName");
    const handleSearchUsers = async () => {
        const payload = {
            searchQuery:userInput.userName?userInput.userName:editMemberData.username 
        };
        console.log("search",userInput);

        try {
            const response = await searchUsersApi(payload); // Call the API function
            console.log(response , "response for  searchUsersApi")
            if (response?.body?.data && response?.body?.data?.length > 0) {
                // response.body.data =  response.body.data.forEach(element => {
                //     if
                // });
                setUsers(response?.body?.data); // Set fetched users
            } else {
                setUsers([]); // Clear users if no results
            }
        } catch (error) {
            console.error('An error occurred while searching for users:', error);
       }
    };
//PS_12
const handleAddMember = () => {
    if (userInput.userId && emailInput && role.roleName) { // Check userId instead of userInput
        const newMember = {
            username: userInput.userName, // Access userName from userInput
            email: emailInput,
            role:{roleName:role.roleName,
            roleId:role.roleId},
            userId: userInput.userId // Access userId from userInput
        };
        setMembers((prev) => [...prev, newMember]);

        // Reset the fields after adding
        setUserInput({ userId: '', userName: '' }); // Reset to initial structure
        setEmailInput('');
        setRole({roleId:'',roleName:''});
    }
  
};
//PS_13
    const handleEditMember = (index) => {
        const memberToEdit = members[index];
        setEditIndex(index);
        setEditMemberData(memberToEdit);
    };
//PS_14
const handleSaveEdit = () => {
    if (editIndex !== null) {
        const updatedMembers = members.map((member, index) =>
            index === editIndex ? { ...member, ...editMemberData } : member // Merge member with editMemberData
        );
        setMembers(updatedMembers);
        setEditIndex(null);
        setEditMemberData({ userId: '', username: '', email: '', role: { roleId: '', roleName: '' } }); // Reset
    } 
};
    console.log("members",members);
//PS_15
    const handleDeleteMember = (index) => {
        setMembers((prev) => prev.filter((_, i) => i !== index));
    };
    const loadRoles = async () => {
        const payload = {
            userId: userId,
            orgId: orgId,
        };
    
        try {
            const response = await getAllRolesApi(payload); // Call the API function
            if (response.body.success) {
                console.log('Roles fetched successfully:', response?.data);
                
                // Filter out roles with roleName equal to 'admin' and capitalize the first letter of each roleName
                const filteredRoles = response?.body?.data
                    .filter(r => r.roleName !== 'admin')
                    .map(r => ({
                        ...r,
                        roleName: r.roleName.charAt(0).toUpperCase() + r.roleName.slice(1) // Capitalize first letter
                    }));
                
                setRoles(filteredRoles); // Update state with filtered roles
            }
            else {
                console.error('Error fetching roles');
            }
        } catch (error) {
            console.error('An error occurred while fetching roles:', error);
        }
    };

    const handleUserSelect = (user) => {
       
        // Set userId and userName in state
        setUserInput({ userId: user.userId, userName: user.userName });
        setSelectedUser(user.userName);
        setEmailInput(user.email);
        setUsers([]);
         // Clear dropdown after selection
    };
  
    const handleUserSelect2 = (user) => {
    setEditMemberData({ 
        userId: user.userId, // Set userId
        username: user.userName, // Set username
        email: user.email // Assuming you also want to store email
    });
    setUsers([]); // Clear dropdown after selection
};
//PS_14
const handleRoleChange = (e) => {
    const selectedRole = roles.find(r => r.roleName === e.target.value); // Find the selected role
    if (selectedRole) {
        setRole({ roleId: selectedRole.roleId, roleName: selectedRole.roleName }); // Set both roleId and roleName
    } else {
        setRole({ roleId: '', roleName: '' }); // Reset if no valid selection
    }
};
const handleRoleChange2 = (e) => {
    const selectedRole = roles.find(r => r.roleName === e.target.value); // Find the selected role
    if (selectedRole) {
        setEditMemberData({
            ...editMemberData,
            role: { roleId: selectedRole.roleId, roleName: selectedRole.roleName } // Set both roleId and roleName
        });
    } else {
        setEditMemberData({
            ...editMemberData,
            role: { roleId: '', roleName: '' } // Reset if no valid selection
        });
    }
};
const handleSave = () => {
    // Constructing the members structure for formData
    const newMembers = members.map(member => ({
        memberUserId: member.userId,
        memberUserName: member.username,
        memberEmail: member.email,
        roleId: member.role.roleId,
        memberRole: member.role.roleName
    }));
console.log("testt",newMembers);
    // Update form data with new members
    setFormData(prevFormData => ({
        ...prevFormData,
        member: newMembers // Set the constructed members
    }));
    // handleCreateWorkload()


};
const [isReadyToNavigate, setIsReadyToNavigate] = useState(false);

//PS_29
const handleCreateWorkload = async () => {
    const newMembers = members?.map(member => ({
        memberUserId: member.userId,
        memberUserName: member.username,
        memberEmail: member.email,
        roleId: member.role.roleId,
        memberRole: member.role.roleName
    }));
    // Use formData to construct the payload
    const payload = {
        userId: userId,
        orgId: orgId,
        workloadName: formData.workloadName,
        cloudProviderId: formData.cloudProviderId,
        cloudProvider: formData.cloudProvider,
        language: formData.language,
        business: formData.business,
        architectureType: formData.architectureType,
        architectureId: formData.architectureId,
        // Check if formData.rulebook exists; if not, use checkedRules
        rulebook: formData?.rulebook && formData.rulebook[0]?.categoryId 
            ? formData.rulebook 
            : checkedRules2.map(category => ({
                categoryId: category.categoryId,
                categoryName: category.categoryName,
                ruleName: category.ruleName.filter(rule => rule.rulebook_id).map(rule => ({
                    rulebook_id: rule.rulebook_id,
                    rule: rule.rule, // Assuming 'rule' is the description you want to keep
                })),
            })),
        createArchitectureWithAI: formData.createArchitectureWithAI,
        describeArchitecture: formData.describeArchitecture,
        services: formData.services, // This will include the services from formData
        environments: formData.environments,
        repoDetails: formData.repoDetails,
        scmToolName: formData.scmToolName,
        scmCreds: formData.scmCreds,
        member: newMembers,
        isProjectAdmin: isProjectAdmin
    };
    console.log("payload",payload);
//PS_30
    try {
        const response = await createWorkloadApi(payload); // Call the API function
        if (response.body.success) {

            // setPopUpMsg({...popupMsg , ["Msg"]:"Commit Successfull" , ["show"]:true ,["success"]:true })
            setToastMsg({ show: true, message: 'workload created successful!', success: true });
console.log("yesss",formData.createArchitectureWithAI);
if( formData.createArchitectureWithAI){
            const { data } = response?.body; // Destructure data from response
            console.log(data, "API response data");
            // Set the workload ID and save it to localStorage
            setworkloadId(data?.workloadId);
            localStorage.setItem('workloadId', data?.workloadId);
            setProviderName(formData.cloudProvider);
            // localStorage.setItem("providerName", cloudProvider);

            // Set environment details based on API response
            const environmentDetails = data?.environmentDetails;
            if (environmentDetails && environmentDetails.length > 0) {
                setEnvId(environmentDetails[0].environment_id); // Example: using first environment
                setEnvName(environmentDetails[0].environment_name);
                localStorage.setItem('envId', environmentDetails[0].environment_id);
                console.log('Selected Environment:', environmentDetails[0].environment_name);
            }
            setPulseIdentification(false);
            setproviderId(formData.cloudProviderId);
            // localStorage.setItem("providerId", selectedProviderId);
            console.log(formData, "FormData Here");

            // Set UTI
            setUTI({ Key: "UTI", Value: environmentDetails[0].uniqueTag });

            // Always set isReadyToNavigate to true
            setIsReadyToNavigate(true);
        }else{
            setFormData({
                workloadId:"",
                userId: '',
                orgId: '', 
                workloadName: '',
                cloudProviderId: '',
                cloudProvider: '',
                language: '',
                business: '',
                architectureType: '',
                architectureId: '',
                rulebook: [
                    {
                        categoryId: '',
                        categoryName: '',
                        ruleName: [
                            {
                                rulebook_id: '',
                                rule: ''
                            }
                        ]
                    }
                ],
                createArchitectureWithAI: false,
                describeArchitecture: '',
                services: [
                        {
                            categoryId: '',
                            categoryName: '',
                            service: [
                                {
                                    serviceId: '',
                                    serviceName: ''
                                }
                            ]
                        }
                ],
                environments: [],
               
                scmToolName: '',
                repoDetails:[],
                scmCreds: {
                    access_token: ''
                },
                member: [
                    {
                        memberUserId: '',
                        memberUserName: '',
                        memberEmail: '',
                        roleId:'',
                        memberRole: ''
                    }
                ]
            });
            navigate('/workloadsGrid')
            console.log('Workload updated successfully:', response?.data);
        }
        }  else {
            // setPopUpMsg({...popupMsg , ["Msg"]:response.message , ["show"]:true ,["success"]:false })
            setToastMsg({ show: true, message: 'repo name alreay exists', success: false });

            console.error('Error creating workload');
            console.log('Error creating workload: ' + (response?.body?.message || 'Unknown error'));
        }
       
    } catch (error) {
        // setPopUpMsg({...popupMsg , ["Msg"]:error.message , ["show"]:true ,["success"]:false })
        setToastMsg({ show: true, message:error?.response?.data?.message, success: false });
        console.error('An error occurred while creating workload:', error?.response?.data?.message);

        console.error('An error occurred while creating workload:', error);
        console.log('An error occurred while creating workload');
    }
};
useEffect(() => {
    if (isReadyToNavigate) {
        console.log("Navigating with Workload ID:", workloadId);
        console.log("Navigating with Environment ID:", envId);
        console.log(formData, "FormData WB");
        
        localStorage.setItem('formdata', JSON.stringify(formData));
        navigate('/Workbench');

        // Reset states after navigating
        setIsReadyToNavigate(false);
        // Optionally reset workloadId and envId if needed
        // setworkloadId(null);
        // setEnvId(null);
    }
}, [isReadyToNavigate]); // Only depend on isReadyToNavigate

const formattedRepositories = formData?.repoDetails.map(repo => ({
    repoId: repo.repositoryId,
    repositoryName: repo.name || "default-repo-name", // Use a default name if null
    techstack: JSON.stringify(repo.techStack) // Convert techStack array to JSON string
}));
//PS_26
const handleUpdateWorkload = async () => {
    const newMembers = members.map(member => ({
        memberUserId: member.userId,
        memberUserName: member.username,
        memberEmail: member.email,
        roleId: member.role.roleId,
        memberRole: member.role.roleName
    }));
    const payload = {
        workloadId:formData.workloadId,
        userId : userId,
        orgId: orgId, 
        workloadName: formData.workloadName,
        cloudProviderId: formData.cloudProviderId,
        cloudProvider: formData.cloudProvider,
        language: formData.language,
        business: formData.business,
        architectureType: formData.architectureType,
        architectureId: formData.architectureId,
        rulebook: formData.rulebook,
        createArchitectureWithAI: formData.createArchitectureWithAI,
        describeArchitecture: formData.describeArchitecture,
        services: formData.services, // This will include the services from formData
        environments: formData.environments,
        repoDetails:formData.repoDetails,

        // repoId:  formData?.repoDetails[0].repositoryId, // Replace with the actual repo you want to use
        // repositoryName:  formData?.repoDetails[0].name, // Use a default name if null
        // techstack:  formData?.repoDetails[0].techStack ,
                scmToolName: formData.scmToolName,
        scmCreds: formData.scmCreds,
        member:newMembers
    };
//PS_27
    try {
        const response = await updateWorkloadApi(payload); // Call the API function
        if (response.body.success) {
            setFormData({
                workloadId:"",
                userId: '',
                orgId: '', 
                workloadName: '',
                cloudProviderId: '',
                cloudProvider: '',
                language: '',
                business: '',
                architectureType: '',
                architectureId: '',
                rulebook: [
                    {
                        categoryId: '',
                        categoryName: '',
                        ruleName: [
                            {
                                rulebook_id: '',
                                rule: ''
                            }
                        ]
                    }
                ],
                createArchitectureWithAI: false,
                describeArchitecture: '',
                services: [
                        {
                            categoryId: '',
                            categoryName: '',
                            service: [
                                {
                                    serviceId: '',
                                    serviceName: ''
                                }
                            ]
                        }
                ],
                environments: [],
               
                scmToolName: '',
                repoDetails:[],
                scmCreds: {
                    access_token: ''
                },
                member: [
                    {
                        memberUserId: '',
                        memberUserName: '',
                        memberEmail: '',
                        roleId:'',
                        memberRole: ''
                    }
                ]
            });
            navigate('/workloadsGrid')

            console.log('Workload updated successfully:', response?.data);
         
        } else {
            console.error('Error updating workload');
           
        }
    } catch (error) {
        console.error('Failed to update workload:', error);
     
    }
};

    //PS_17 Function to handle sorting
    const handleSortByUsername = () => {
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc'; // Toggle sort order
        const sorted = [...members].sort((a, b) => {
            const nameA = a.username.toLowerCase();
            const nameB = b.username.toLowerCase();

            if (newSortOrder === 'asc') {
                return nameA < nameB ? -1 : 1;
            } else {
                return nameA > nameB ? -1 : 1;
            }
        });
setMembers(sorted)
        setSortOrder(newSortOrder); // Update sort order for next click
    };

    return (
        <>
         {toastMsg.show && (
            <ToastPopup 
                toastMsg={toastMsg}
                setToastMsg={setToastMsg}
            />
        )}
        <div className="container-fluid">
        <div className="">

            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                    <SideBar/>
        <div className="col-md-9 px-4 right-container-scroll">
            
      
            <div className="tab-content" id="pills-tabContent">
           
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
              
                    <div className="row px-3">
                    {/* {popupMsg?.show == true ? */}

                    {/* <PopupComponent popupMsg={popupMsg} setPopUpMsg={setPopUpMsg} />   :null}                  */}
                        <div className="col-md-12">
                        <p className="font-32 font-bold black-v2 mb-4 pb-3">{formData.workloadId?"Edit Workload":"Create Workload"}</p>
                            <div className="row justify-content-center">
                                <div className="col-md-12 col-lg-8 mb-4">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center flex-column gap-3">
                                            <div className="step-num completed d-flex justify-content-center align-items-center position-relative">
                                                <p className="font-medium font-16 m-0">1</p>
                                            </div>
                                            <span className="font-14 font-medium step-text">Workload Details</span>
                                        </div>

                                        <div className="d-flex align-items-center flex-column gap-3">
                                            <div className="step-num completed d-flex justify-content-center align-items-center position-relative step-line-1 step-completed">
                                                <p className="font-medium font-16 m-0">2</p>
                                            </div>
                                            <span className="font-14 font-medium step-text">Environments</span>
                                        </div>

                                        <div className="d-flex align-items-center flex-column gap-3">
                                            <div className="step-num completed d-flex justify-content-center align-items-center position-relative step-line-2 step-completed">
                                                <p className="font-medium font-16 m-0">3</p>
                                            </div>
                                            <span className="font-14 font-medium step-text">Repository & CI/CD</span>
                                        </div>

                                        <div className="d-flex align-items-center flex-column gap-3">
                                            <div className="step-num active d-flex justify-content-center align-items-center position-relative step-line-3 step-completed">
                                                <p className="font-medium font-16 m-0">4</p>
                                            </div>
                                            <span className="font-14 font-medium step-text active">Members</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                                <div class="row"></div>
                            <div className="col-md-12" style={{ position: 'relative' }}>
                                                <p className="font-20 font-bold black-v2 mb-3 pb-1">Members</p>
                                         
                            <div className="table-responsive table-border">
                                <table className="table custom-table">
                                    <thead>
                                        <tr>
                                            <th className="text-start border-0" onClick={handleSortByUsername}>
                                                User Name
                                                <span class="cursor-pointer margin-left-2"><img  src="./images/table-sort.svg" alt="sort"  onClick={()=>{handleSortByUsername()}} style={{ cursor: 'pointer' }}/></span></th>
                                            <th className="text-start border-0">Email</th>
                                            <th className="text-start border-0">Role</th>
                                            <th className="text-center border-0">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* Input Fields Row */}
                                        <tr>
                                        <td>
                                        <input
                type="text"
                className="form-control custom-form font-14 font-medium"
                placeholder="Enter User Name"
                value={userInput.userName} // Use userName for display
                onChange={(e) => setUserInput({ ...userInput, userName: e.target.value })}
            />
            {users.length > 0 && (
                <ul className="dropdown-menu cust-drop-menu custom-form  show" style={{ position: 'absolute', zIndex: 1000 }}>
                    {users.map((user, index) => (
                        <li key={index} className="dropdown-item black-v2 " onClick={() => handleUserSelect(user)}>
                            {user.userName} ({user.email}) {/* Display username and email */}
                        </li>
                    ))}
                </ul>
            )}
            </td>

            {/* Dropdown for displaying users */}
          
            <td>
                <input
                    type="text"
                    className="form-control custom-form font-14 font-medium"
                    placeholder="Enter Email"
                    value={emailInput}
                    onChange={(e) => setEmailInput(e.target.value)}
                />
            </td>
                                            <td>
                                            <select
            className="form-select form-control custom-form font-medium font-14"
            value={role.roleName} // Use roleName for display
            onChange={handleRoleChange} // Use the new handler
        >
            <option value="">Choose Role</option>
            {roles.map(r => (
                <option key={r.roleId} value={r.roleName}>{r.roleName}</option>
            ))}
        </select>
                                            </td>
                                            <td className="text-center">
                                                <button
                                                    type="button"
                                                    onClick={handleAddMember}
                                                    className={`btn action-btn d-flex align-items-center justify-content-center border-0 ${!(userInput && emailInput && role) ? 'disabled' : ''}`}
                                                    disabled={!(userInput && emailInput && role.roleName)}
                                                >
                                                    <img src="images/add-plus-icon.svg" alt="add-action-icon" />
                                                </button>
                                            </td>
                                        </tr>

                                        {/* Display Members */}
                                        {members.map((member, index) => (
                                            <tr key={index}>
                                                {editIndex === index ? (
                                                    <>
                                                        {/* Inline Edit Fields */}
                                                        <td>
                                                        <input
                type="text"
                className="form-control custom-form font-14 font-medium"
                value={editMemberData.username} // Display selected username
                onChange={(e) => setEditMemberData({ ...editMemberData, username: e.target.value })} // Update username
            />
            {users.length > 0 && (
                <ul className="dropdown-menu cust-drop-menu custom-form  show" style={{ position: 'absolute', zIndex: 1000 }}>
                    {users.map((user, index) => (
                        <li key={index} className="dropdown-item black-v2" onClick={() => handleUserSelect2(user)}>
                            {user.userName} ({user.email}) {/* Display username and email */}
                        </li>
                    ))}
                </ul>
            )}
            
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                className="form-control custom-form font-14 font-medium"
                                                                value={editMemberData.email}
                                                                onChange={(e) => setEditMemberData({ ...editMemberData, email: e.target.value })}
                                                            />
                                                        </td>
                                                        <td>
                                                        <select
    className="form-select form-control custom-form font-medium font-14"
    value={editMemberData?.role?.roleName} // Use roleName for display
    onChange={handleRoleChange2} // Use the new handler
>
    <option value="">Choose Role</option>
    {roles.map(r => (
        <option key={r.roleId} value={r.roleName}>{r.roleName}</option>
    ))}
</select>
                                                        </td>
                                                        <td className="d-flex justify-content-center gap-3">
                                                            {/* Save and Cancel Buttons for Editing */}
                                                            <a href="#"  onClick={handleSaveEdit} >
                                                            <img src="/images/tick-large-icon.svg" alt="tick-icon" width={16} height={16}></img>
                                                            </a>
 <a href="#"onClick={() => setEditIndex(null)}>
 <img src="/images/cross-large-icon.svg" alt="cross-icon" width={10} height={10}></img>
 </a>
                                                            
                                                        </td>

                                                     
                                                    </>
                                                ) : (
                                                    <>
                                                        {/* Display Member Details */}
                                                        <td>{member?.username}</td>
                                                        <td>{member?.email}</td>
                                                        <td>{member?.role?.roleName}</td>
                                                       
                                                        <td className="d-flex justify-content-center gap-3">
                                    {/* Edit Action */}
                                    <a href="#"><img src="/images/edit-icon.svg" alt="edit-icon" onClick={() => handleEditMember(index)} /></a>
 <a href="#" onClick={() => handleDeleteMember(index)}>
                                                    <img src="/images/delete-icon.svg" alt="delete-icon" />
                                                </a>
                     

                                    {/* Delete Action */}
                                  
                                </td>
                                                    </>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

        
                            </div>

                        </div>
                        <div className='d-flex justify-content-between align-items-center my-4 gap-3'>
                            {/* Cancel Button */}
                            <button type='button'  onClick={()=>{ navigate('/repositoryDetails')}}
                                className='button cancel-button px-4 py-3 lh-1'>Cancel</button>

                            {/* Create Workload Button */}
                            {members.length > 0 && (
                                <button type='button' 
                                    onClick={() => formData?.workloadId?handleUpdateWorkload():handleCreateWorkload()} 
                                    className='btn button primary-button px32 py3 lh1'> {formData?.workloadId?"Update workload":"Create Workload"}</button> 
                            )}
                </div>
                </div>

                       
                        </div>
                        

                    </div> 
                     
                </div>
                {/* Footer Buttons */}
              
                </div>
                
             
                </div>
              
                </div>
                </div>
                </div>
                </div>
                </div>
                </>
            );
};

export default MemberDetails; // Ensure this line exports the component correctly