import { useContext, useEffect, useState } from "react";
import { ZebPulseContext } from "./ManageTemplates";
import { approveZebPulseTemplates, cloneZebPulseTemplates } from "../../Service/api";
import { useNavigate } from "react-router-dom";
import { OrgContext } from "../../ContextProvider/OrgContext";
import WorkbechPopup from "../Organization/Workbench/WorkBench/PopupMaintanence/WorkBenchPopUp";

function ZebPulseTemplates({ fetchZebPulseTemplateData }) {

    //PS_05
    const {
        zebPulseTemplates,
        awsZebPulseTemplate,
        azureZebPulseTemplate,
        providerTab,
        setProviderTab,
        awsZebPulseTemplatesCount,
        azureZebPulseTemplatesCount,
        searchTerm,
        organizationData,
        workloadData,
        environmentData,
        setTemplateOrganizationId,
        templateOrganizationID,
        setChosenWorkloadId,
        popupMsg,
        setPopUpMsg,
        setAwsZebPulseTemplatesCount,
        setAzureZebPulseTemplatesCount
    } = useContext(ZebPulseContext);

    useEffect(() => {
        console.log("ZebPulse Context Data:", {
            zebPulseTemplates,
            awsZebPulseTemplate,
            azureZebPulseTemplate,
            awsZebPulseTemplatesCount,
            azureZebPulseTemplatesCount,
            organizationData,
            environmentData,
            templateOrganizationID
        });
    }, [zebPulseTemplates, awsZebPulseTemplate, azureZebPulseTemplate, awsZebPulseTemplatesCount, azureZebPulseTemplatesCount]);

    // PS_04
    const [awsExpanded, setAwsExpanded] = useState(false);
    const [azureExpanded, setAzureExpanded] = useState(false);
    const [successPopup, setSuccessPopUp] = useState(false)
    const [isAdmin, setIsAdmin] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate()
    const [selectedOrganization, setSelectedOrganization] = useState("");
    const [selectedWorkload, setSelectedWorkload] = useState("");
    const [selectedEnvironment, setSelectedEnvironment] = useState("");
    const [cloneTemplateId, setCloneTemplateId] = useState("")

    const userId = localStorage.getItem('userId');
    console.log("userid", userId)

    const { userRoles } = useContext(OrgContext)


    //PS_62
    useEffect(() => {
        const isAdminValue = localStorage.getItem('isAdmin');
        setIsAdmin(isAdminValue);
    }, []);



    //PS_32 -PS_35 
    async function approveZebPulseTemplate(template, isApprove) {
        const payload = {
            "userId": userId,
            "templateId": template.templateId,
            "envId": template.envId,
            "removeApproval": isApprove,
            "organizationId": template.orgId,


        };

        try {
            const response = await approveZebPulseTemplates(payload);
            console.log(response, "response");

            if (response.status === 200 && response.data.body.success) {
                const { data } = response.data.body;
                console.log(data, "data");

                // setPopUpMsg({...popupMsg , ["Msg"]:"Zeb Pulse Templates approved successfully" , ["show"]:true ,["success"]:true })
                fetchZebPulseTemplateData()
            } else {
                console.error("Error approving", response.data.body.message);
                // setPopUpMsg({...popupMsg , ["Msg"]:"Zeb Pulse Templates approved failed" , ["show"]:true ,["success"]:false })
            }
        } catch (error) {
            console.error("Error:", error);
        }
    }



    //PS_54 - PS_57
    async function cloneZebPulseTemplate() {
        if (selectedEnvironment) {
            const payload = {
                "userId": userId,
                "envId": selectedEnvironment,
                "templateId": cloneTemplateId
            }
            try {
                const response = await cloneZebPulseTemplates(payload);
                console.log(response, "response");

                if (response.status === 200 && response.data.body.success) {
                    const { data } = response.data.body;
                    console.log(data, "data");
                    setShowModal(false);
                    setPopUpMsg({ ...popupMsg, ["Msg"]: "Zeb Pulse Templates Cloned successfully", ["show"]: true, ["success"]: true })


                } else {
                    console.error("Error cloning", response.data.body.message);
                    setPopUpMsg({ ...popupMsg, ["Msg"]: "Zeb Pulse Templates clone failed", ["show"]: true, ["success"]: false })

                }
            } catch (error) {
                console.error("Error:", error);
            }
        }
    }



    //PS_22
    useEffect(() => {
        bindAccordion();
    }, []);


    //PS_17- PS_21
    const toggleAccordion = (provider) => {
        if (provider === 'aws') {
            setAwsExpanded(!awsExpanded);
        } else if (provider === 'azure') {
            setAzureExpanded(!azureExpanded);
        }
    };

    //PS_23
    const bindAccordion = () => {
        const accordionItems = document.querySelectorAll('.accordion-item');
        accordionItems.forEach(item => {
            const button = item.querySelector('.cust-acc-btn');
            const content = item.querySelector('.accordion-collapse');

            button.addEventListener('click', () => {
                const expanded = button.getAttribute('aria-expanded') === 'true';
                button.setAttribute('aria-expanded', !expanded);
                content.classList.toggle('show');
            });
        });
    };

    // const handleToggle = (providerId) => {
    //     setProviderTab(providerId);
    // };

    //PS_24
    const decodeBase64Image = (base64String) => {
        if (!base64String) return null;
        return `data:image/png;base64,${base64String}`;
    };

    //PS_26
    const renderTemplates = (templates) => {
        const filteredTemplates = filterTemplates(templates);

        if (filteredTemplates.length === 0) {
            return <div className="color-black" style={{
                "marginBottom": '20px',
                "marginTop": '20px',       // Space above the message
                "paddingLeft": '500px'      // Push text to the right
            }}>
                No templates found matching your search.</div>;
        }

        return (

            <>

                {filteredTemplates.map((template, index) => (
                    <div key={index} className="secondary-template-container">
                        <div className="template-header">
                            <div className="d-flex justify-content-between px-4 py-2">
                                <div className="d-flex align-items-center">
                                    {/* <img
                                        src={template.cloudProvider === "AWS" ? "images/aws-black-icon.svg" : "images/azure-logo.svg"}
                                        alt={template.cloudProvider}
                                        className="me-3 icon-width-30"
                                    /> */}
                                    {template.cloudProvider === "AWS" ? (
                                        <>
                                            <img src="images/aws-black-icon.svg" alt="aws" className="icon-width-30 light-theme-img" />
                                            <img src="images/aws-logo-dark.svg" alt="aws" className="icon-width-30 dark-theme-img" />
                                        </>
                                    ) : (
                                        <>
                                            <img src="images/azure-logo.svg" alt="azure" className="icon-width-30 light-theme-img" />
                                            <img src="images/azure-logo-dark.svg" alt="azure" className="icon-width-30 dark-theme-img" />
                                        </>
                                    )}
                                    <div>
                                        <p className="font-14 font-medium black-v2 mb-0">
                                            {template.pulseVerticalName}{" "}
                                            <span className="font-10 font-medium secondary-color ms-1">
                                                {template.templateId}
                                            </span>
                                        </p>
                                        <p className="font-11 font-regular secondary-color mt-1 mb-0">
                                            Created at: {new Date(template.createAt).toLocaleDateString()}
                                        </p>
                                        {template.updatedAt && (
                                            <p className="font-11 font-regular secondary-color mb-0">
                                                Updated: {new Date(template.updatedAt).toLocaleDateString()}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                {/* Dropdown menu */}
                                <div className="dropdown">
                                    <button
                                        className="btn icon-bitton shadow-none p-0"
                                        type="button"
                                        id={`dropdownMenuButton${index}`}
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    // onClick={(e) => {
                                    //     const dropdownMenu = document.getElementById(`dropdownMenu${index}`);
                                    //     dropdownMenu.classList.toggle("show");
                                    // }}
                                    >
                                        <img
                                            src="images/three-dots.svg"
                                            alt="three-dots"
                                        />
                                    </button>
                                    <ul
                                        id={`dropdownMenu${index}`}
                                        className="dropdown-menu custom-template-dropdown"
                                        aria-labelledby={`dropdownMenuButton${index}`}
                                    >
                                        {isAdmin == "true" && (
                                            <li className="border-0 font-14 font-medium color-black">
                                                {template.approvalStatus === true ? (
                                                    <a className="dropdown-item" href="#" onClick={() => approveZebPulseTemplate(template, true)}>
                                                        <img
                                                            src="images/approve-tick.svg"
                                                            alt="approval-icon"
                                                            className="me-2 icon-width-small"
                                                        />
                                                        Remove Approval
                                                    </a>
                                                ) : (
                                                    <a className="dropdown-item" href="#" onClick={() => approveZebPulseTemplate(template, false)}>
                                                        <img
                                                            src="images/approve-tick.svg"
                                                            alt="approval-icon"
                                                            className="me-2 icon-width-small"
                                                        />
                                                        Approve
                                                    </a>
                                                )}
                                            </li>
                                        )}
                                        {/* { (
                                            <li className="border-0 font-14 font-medium color-black">
                                                <a className="dropdown-item" href="#" onClick={() => { 
                                                    if(isAdmin != "true" || isAdmin != true){
                                                        approveZebPulseTemplate(template, true)
                                                    }
                                                    handleEdit(template) 
                                                    }}>
                                                    <img
                                                        src="images/edit-icon.svg"
                                                        alt="edit-icon"
                                                        className="me-2 icon-width-small"
                                                    />
                                                    Edit
                                                </a>
                                            </li>
                                        )} */}

                                        {/* {template.approvalStatus === true && ( */}
                                        <li className="border-0 font-14 font-medium color-black">
                                            <a className="dropdown-item" href="#" onClick={() => {
                                                console.log("Cloneeeee Clicked in the Pulse", template)
                                                setShowModal(true)
                                                setTemplateOrganizationId(template.organizationId)
                                                setCloneTemplateId(template.templateId)
                                            }

                                            }
                                            >
                                                <img
                                                    src="images/clone-img.svg"
                                                    alt="clone-icon"
                                                    className="me-2 icon-width-small"
                                                />
                                                Clone
                                            </a>
                                        </li>
                                        {/* )} */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="template-body px-4 py-3">
                            <div className="d-flex justify-content-center align-items-center height-100">
                                <img
                                    src={decodeBase64Image(template.templateImage) || "images/template sample img.svg"}
                                    alt="template-img"
                                    style={{ maxWidth: '100%', maxHeight: '120px' }}
                                />
                            </div>
                            {/* {template.approvalStatus === true && ( */}
                            <p className="mb-0 font-11 font-medium success-v5 text-end mt-2">
                                <img
                                    src="images/approve-tick.svg"
                                    alt="tick"
                                    className="me-2 icon-width-small"
                                />
                                Approved
                            </p>
                            {/* )} */}
                        </div>
                    </div>
                ))};
            </>



        )
    };

    //PS_63
    const handleEdit = (template) => {
        if (isAdmin == "true") {
            navigate('/Workbench',
                {
                    state:
                    {
                        orgId: template.organizationId,
                        workloadId: template.organizationId,
                        envId: template.environmentId,
                        envName: template,
                        language: template,
                        setUTI: template,
                        orgId: template.organizationId,
                        providerId: template,
                        providerName: template.cloudProvider
                    }
                })


        }

    }

    //PS_64
    const closeModal = () => {
        setShowModal(false);
    };


    //PS_65 -PS_66
    const filterTemplates = (templates) => {
        if (!searchTerm) return templates;
        return templates.filter(template =>
            template?.pulseVerticalName?.toLowerCase()?.includes(searchTerm.toLowerCase())
        );
    };

    const updateTemplateCounts = () => {
        const filteredAWSTemplates = filterTemplates(awsZebPulseTemplate);
        const filteredAzureTemplates = filterTemplates(azureZebPulseTemplate);

        setAwsZebPulseTemplatesCount(filteredAWSTemplates.length);
        setAzureZebPulseTemplatesCount(filteredAzureTemplates.length);
    };

    useEffect(() => {
        updateTemplateCounts();
    }, [searchTerm, awsZebPulseTemplate, azureZebPulseTemplate]);



    return (
        <>

            <div className="tab-pane fade show active" id="all-tab-pane" role="tabpanel" aria-labelledby="all-tab" tabIndex={0}>
                <div className="accordion" id="accordionExample-11">
                    {/* AWS Section */}
                    <div className="accordion-item body-background insights-accordion border-0 mt-3 pt-1 mb-2">
                        <div className="insights-container mb-1">
                            <div className="d-flex justify-content-between align-items-center">
                                <h2 className="accordion-header d-flex align-items-center" id="headingTwo-2">
                                    <button
                                        className={`cust-acc-btn d-flex align-items-center gap-2 font-14 btn border-0 ${awsExpanded ? '' : 'collapsed'}`}
                                        type="button"
                                        onClick={() => toggleAccordion('aws')}
                                    >
                                        <span className="mx-3">
                                            <img src="images/aws-black-icon.svg" alt="aws" class="icon-width-30 light-theme-img" />
                                            <img src="images/aws-logo-dark.svg" alt="aws" class="icon-width-30 dark-theme-img" />
                                        </span>
                                        <span className="primary-color font-18 font-bold">AWS</span>
                                    </button>
                                </h2>
                                <div className="d-flex align-items-center">
                                    <img src="images/template-icon.svg" alt="template-icon" className="me-1" />
                                    <p className="mb-0 font-12 font-medium secondary-text-color">
                                        Templates <span className="font-16 font-medium black-v2 ms-2">{awsZebPulseTemplatesCount}</span>
                                    </p>
                                </div>
                            </div>
                            <div className={`accordion-collapse ${awsExpanded ? 'show' : 'collapse'}`}>
                                <div className="accordion-body custom-border-top pt-4 mt-4">
                                    <div class="scrl-container">
                                        <div className="d-flex gap-4">
                                            {renderTemplates(awsZebPulseTemplate)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* clone popup */}
                    {showModal && (
                        <div
                            className="modal fade show"
                            id="staticBackdrop-4"
                            tabIndex={-1}
                            aria-labelledby="staticBackdropLabel"
                            aria-modal="true"
                            role="dialog"
                            style={{ display: "block", background: "rgba(0,0,0,0.6)", opacity: 1 }}
                        >
                            <div className="modal-dialog  modal-dialog-centered justify-content-center">
                                <div className="modal-content border-0">
                                    <div className="modal-header border-0 p-4 ">
                                        <h1
                                            className="modal-title font-20 font-bold black-v2"
                                            id="staticBackdropLabel"
                                        >
                                            Clone Architecture
                                        </h1>
                                        <button
                                            type="button"
                                            className="btn shadow-none"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={closeModal}
                                        >
                                            <img
                                                src="images/close-icon.svg"
                                                alt="close-icon"
                                                className="icon-hover shadow-none modal-close-filter"
                                            />
                                        </button>
                                    </div>
                                    <div className="modal-body px-4 pt-0">
                                        <div className="my-3">
                                            <label
                                                className="form-label font-14 font-medium black-v2"
                                                htmlFor="Status"
                                            >
                                                Organization <span className="danger-red">*</span>
                                            </label>

                                            <select
                                                className="form-select custom-dropdown shadow-none custom-border-box font-14 font-regular black-v2 custom-select"
                                                aria-label="Default select example"
                                                id="Status"
                                                value={selectedOrganization}
                                                onChange={(e) => {
                                                    setTemplateOrganizationId(e.target.value)
                                                    setSelectedOrganization(e.target.value)
                                                }}
                                            >
                                                <option value="">Select</option>
                                                {organizationData?.length > 0 && organizationData.map(organization => (
                                                    <option key={organization.orgId} value={organization.orgId}>
                                                        {organization.orgName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="my-3">
                                            <label
                                                className="form-label font-14 font-medium black-v2"
                                                htmlFor="Status-4"
                                            >
                                                Workload <span className="danger-red">*</span>
                                            </label>
                                            <select
                                                className="form-select custom-dropdown shadow-none custom-border-box font-14 font-regular black-v2 custom-select"
                                                aria-label="Default select example"
                                                id="Status-4"
                                                value={selectedWorkload}
                                                onChange={(e) => {
                                                    console.log(e.target.value, "EEEEeE")
                                                    setChosenWorkloadId(e.target.value)
                                                    setSelectedWorkload(e.target.value)
                                                }}
                                            >
                                                <option selected="">Select</option>
                                                {workloadData.map(workload => (
                                                    <option key={workload.workloadId} value={workload.workloadId}>{workload.workloadName}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="my-3">
                                            <label
                                                className="form-label font-14 font-medium black-v2"
                                                htmlFor="Status-5"
                                            >
                                                Environment
                                                <span className="danger-red">*</span>
                                            </label>
                                            <select
                                                className="form-select custom-dropdown shadow-none custom-border-box font-14 font-regular black-v2 custom-select"
                                                aria-label="Default select example"
                                                id="environmentData"
                                                value={selectedEnvironment}
                                                onChange={(e) => {
                                                    setSelectedEnvironment(e.target.value)
                                                }}
                                            >
                                                <option selected="">Select</option>
                                                {environmentData.map(environment => (
                                                    <option key={environment.envId} value={environment.envId}>{environment.envName}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="d-flex justify-content-end align-items-center mt-4 mb-2 gap-3">
                                            <button
                                                type="button"
                                                className="button outline-button text-nowrap py-12"
                                                data-bs-dismiss="modal"
                                                onClick={closeModal}
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                type="button"
                                                className="button primary-button text-nowrap px-4 py-12"
                                                onClick={() => {

                                                    cloneZebPulseTemplate()

                                                }
                                                }
                                                disabled={
                                                    !selectedOrganization ||
                                                    !selectedWorkload ||
                                                    !selectedEnvironment}
                                            >
                                                Clone
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    )}

                    {/* Azure Section */}
                    <div className="accordion-item body-background insights-accordion border-0 mt-3 pt-1 mb-2">
                        <div className="insights-container mb-1">
                            <div className="d-flex justify-content-between align-items-center">
                                <h2 className="accordion-header d-flex align-items-center" id="headingTwo-20">
                                    <button
                                        className={`cust-acc-btn d-flex align-items-center gap-2 font-14 btn border-0 ${azureExpanded ? '' : 'collapsed'}`}
                                        type="button"
                                        onClick={() => toggleAccordion('azure')}
                                    >
                                        <span className="mx-3">
                                            <img src="images/azure-logo.svg" alt="logo" className="icon-width-30" />
                                        </span>
                                        <span className="primary-color font-18 font-bold">Azure</span>
                                    </button>
                                </h2>
                                <div className="d-flex align-items-center">
                                    <img src="images/template-icon.svg" alt="template-icon" className="me-1" />
                                    <p className="mb-0 font-12 font-medium secondary-text-color">
                                        Templates <span className="font-16 font-medium black-v2 ms-2">{azureZebPulseTemplatesCount}</span>
                                    </p>
                                </div>
                            </div>
                            <div className={`accordion-collapse ${azureExpanded ? 'show' : 'collapse'}`}>
                                <div className="accordion-body custom-border-top pt-4 mt-4">
                                    <div class="scrl-container">
                                        <div className="d-flex gap-4">
                                            {renderTemplates(azureZebPulseTemplate)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ZebPulseTemplates;