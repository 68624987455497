
import React, { useState, useCallback, useEffect, useMemo, useRef } from "react";
import jsYaml from 'js-yaml';
import axios from "axios";
import {
	ReactFlow,
	Handle,
	useNodesState,
	useEdgesState,
	Background,
	Controls,
	addEdge,
	NodeResizer,
	ReactFlowProvider

} from '@xyflow/react';
import Draggable from "react-draggable";
import '../B2A/style.css'
import '../B2A/reactflow.css'
import '@xyflow/react/dist/style.css';

import { useLocation, useNavigate } from 'react-router-dom';
import * as base64 from "base-64";
import { fetchLocationApi, fetchTagsAzureApi, generateARMContentApi, generateTerraformContentApi, get_all_regionApi, get_all_resourceGroupApi, get_all_resources_azure } from "../../../Service/api";
import { useB2AContext } from "../../../ContextProvider/B2AContext";
import CloneModal from "./B2APopupComponent/CloneEnvironment";
import * as htmlToImage from "html-to-image";
import B2APopup from "./B2APopupComponent/PopUp";
import SideBar from "../../sidebar/SideBar";
import { useOrgContext } from "../../../ContextProvider/OrgContext";
import Loader from "../../loader/loader";


const AzureB2A = () => { 
	// const {orgId,userId,providerId ,connectorId} = useB2AContext()

	const navigate = useNavigate();
	const reactFlowWrapper = useRef(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [nodes, setNodes, onNodesChange] = useNodesState([]);
	const [edges, setEdges, onEdgesChange] = useEdgesState([]);
	const [azureResources , setazureResources] = useState({})

	// Updated State Variable 

	const { orgId , providerName , colorMode} = useOrgContext()
	const [userId, setuserId] = useState(localStorage.getItem('userId'));
	const location = useLocation();

	const [regions, setRegions] = useState([]);
	const [selectedRegion, setSelectedRegion] = useState("");
	const [tags, setTags] = useState([]);
	const [selectedTag, setSelectedTag] = useState("");
	const [resourceGroups, setResourceGroups] = useState([]);
	const [selectedResourceGroup, setSelectedResourceGroup] = useState("");
	const [selectedConnector, setSelectedConnector] = useState({});
	const [selectedProvider, setSelectedProvider] = useState({});
	const [b2AConnectorId, setB2AConnectorId] = useState("");
	const [templateType, setTemplateType] = useState('');
	// Clone 
	const [cloneModal ,setCloneModal] = useState(false)
	const [popupMsg, setPopUpMsg] = useState({"Msg":"",show:false,success:false})
	const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);
	const [isLoading , setIsLoading] = useState(false)
	const [showDownloadModal, setShowDownloadModal] = useState(false)
	const [selectedFormat, setSelectedFormat] = useState("")


	/**
		* React Flow Variables Intialization
		*/
		// React Flow Some Times Throws the ResizeObserver loop completed with undelivered notifications" ||
    //"ResizeObserver loop limit exceeded
		//TO Remove that use this Below Code 
		useEffect(() => {
			const errorHandler = (e) => {
				if (
					e.message.includes(
					"ResizeObserver loop completed with undelivered notifications" ||
						"ResizeObserver loop limit exceeded"
					)
				) {
					const resizeObserverErr = document.getElementById(
					"webpack-dev-server-client-overlay"
					);
					if (resizeObserverErr) {
					resizeObserverErr.style.display = "none";
					}
				}
				};
				window.addEventListener("error", errorHandler);

				return () => {
				window.removeEventListener("error", errorHandler);
				};
		}, []);

	// Input Field Funcitonalites and use Effect Declaraltions 
	useEffect(() => {
		if (location.state) {
			const {
			connector_id,
			connector_name,
			tags,
			region,
			cloudproviderId,
			providerName,
			b2a_connector_id,
				} = location.state;
				console.log(location.state , "Location State")
				setSelectedConnector({
			connectorId: connector_id,
			connectorName: connector_name,
				});
				setSelectedRegion(region);
				setSelectedTag(tags);
				setB2AConnectorId(b2a_connector_id);
				setSelectedProvider({ providerId: cloudproviderId, providerName: providerName });

		}
			}, [location]);
			useEffect(()=>{
		console.log(resourceGroups , "resourceGroups")
			},[resourceGroups])
			useEffect(() => {
		if (selectedRegion!= "" && selectedRegion != undefined || null) {
			fetchTags(selectedRegion);
			fetchResourceGroups(selectedRegion);
			setSelectedResourceGroup("")
			// setSelectedTag("")
		}
			}, [selectedRegion]);
			useEffect(()=>{
		if(selectedConnector.connectorId
			&& selectedProvider.providerId ){
			fetchRegions()
		}
			},[selectedConnector, selectedProvider])

			useEffect(()=>{
				console.log(selectedTag , "SelectedTag")
		if(selectedTag?.Key && selectedTag["Key"]!=""){
			setSelectedResourceGroup("")
			setResourceGroups([])
			fetchResourceGroups()
		}
			},[selectedTag])

			useEffect(()=>{
		if(selectedResourceGroup != "" && 
			selectedConnector.connectorId != "" &&
			selectedProvider.providerId != "" 
		){
			fetchResources()
		}
			},[selectedResourceGroup, selectedConnector])
	/**
		* Region Retreival 
		*/
		console.log("tagsss",selectedTag);

	const fetchRegions = async () => {
		try {
		if(selectedConnector.connectorId && selectedProvider.providerId){
			const payload = {
			userId,
			organizationId: orgId,
			connectorId: selectedConnector.connectorId,
			cloudproviderId: selectedProvider.providerId,
				};
				console.log(payload , "Paylaodd")
				const response = await fetchLocationApi(payload);
				console.log(response , "Response for the Fetch LOCsiton ")
				if (response.success) {
			setRegions(response.data);
				}
			}
		} catch (error) {
			console.error('Error fetching regions:', error);
		}
			};
	/** 
		* Fetch Tags is used to retrieve the Tags Based on the Regions 
	*/

	const fetchTags = async (region) => {
		try {
		if(selectedConnector.connectorId && selectedProvider.providerId){
			const payload = {
			userId,
			organizationId: orgId,
			connectorId: selectedConnector.connectorId,
			location: region,
			providerType: selectedProvider.providerId,
				};
				console.log(payload , "Tags Payloaddd")
				const response = await fetchTagsAzureApi(payload);
				console.log(response, "Tags Responseee")
				if (response.success) {
			setTags(response.data);
				}
		}
		} catch (error) {
			console.error("Error fetching tags:", error);
		}
			};
	/** 
		* Retrieval of ResourceGroup based on the Regions 
	*/
	const fetchResourceGroups = async (region) => {
		try {
			if(selectedConnector.connectorId && 
				selectedRegion || region &&
					selectedProvider.providerId){
				const payload = {
				userId,
				organizationId: orgId,
				connectorId: selectedConnector.connectorId,
				location: region ? region :selectedRegion,
				providerType: selectedProvider.providerId,
				tags :selectedTag
					};
					console.log(payload , "Resourcegroup	Payload")
					const response = await get_all_resourceGroupApi(payload);
					console.log(response , "ResourceGroup reees")
					if (response.success) {
				setResourceGroups(response.data);
					}
			}
		} catch (error) {
			console.error("Error fetching resource groups:", error);
		}
			};




	/**
	* fetchResources Function is Used to Retreive the Resources for Azure 
	* Based on the Tags , Region and resourceGroup 
	*/

	const fetchResources = async () => {
		try {
			let payload = {
			userId:userId,
			connectorId:selectedConnector.connectorId,
			organizationId:orgId,
			cloudproviderId: selectedProvider.providerId,
			location:selectedRegion,
			resourceGroup:selectedResourceGroup,
			tags:selectedTag
		}
		console.log(payload , "Payload for the Fetch resources")
		setIsLoading(true)
		let res = await get_all_resources_azure(payload)
		setIsLoading(false)

		console.log(res , "response for the get_all_resources_azure")
		if(res.success){
			setazureResources(res.data) 

		}
		// let resource = {
		// "resourceGroupName": "AVA-EUS-AVAHUB-RG-DEV",
		// "subservice": {
		// "App Service Plan": [
		// {
		// "ai_configuration": {
		// "fields": [
		// {
		// "fieldName": "Instance Type",
		// "fieldType": "text",
		// "value": "B1"
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "dropdown",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "radio",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "check box",
		// "value": ""
		// }
		// ],
		// "subNetName": "",
		// "vnetName": ""
		// },
		// "configuration": {},
		// "name": "AVA-EUS-AVAHUB-ASP-TRG01-DEV",
		// "serviceImage": "https://zeb-aps1-cloudgen-s3-dev.s3.ap-south-1.amazonaws.com/SelfServiceIcon/aws/testing.png"
		// },
		// {
		// "ai_configuration": {
		// "fields": [
		// {
		// "fieldName": "Instance Type",
		// "fieldType": "text",
		// "value": "B2"
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "dropdown",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "radio",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "check box",
		// "value": ""
		// }
		// ],
		// "subNetName": "",
		// "tags": {
		// "APPLICATION": "AVAHUB",
		// "CLOUDGEN": "FALSE",
		// "ENV": "DEV"
		// },
		// "vnetName": ""
		// },
		// "configuration": {},
		// "name": "AVA-EUS-AVAHUB-ASP-BE-DEV",
		// "serviceImage": "https://zeb-aps1-cloudgen-s3-dev.s3.ap-south-1.amazonaws.com/SelfServiceIcon/aws/testing.png"
		// },
		// {
		// "ai_configuration": {
		// "fields": [
		// {
		// "fieldName": "Instance Type",
		// "fieldType": "text",
		// "value": "B2"
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "dropdown",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "radio",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "check box",
		// "value": ""
		// }
		// ],
		// "subNetName": "",
		// "tags": {
		// "APPLICATION": "AVAHUB",
		// "CLOUDGEN": "FALSE",
		// "ENV": "DEV"
		// },
		// "vnetName": ""
		// },
		// "configuration": {},
		// "name": "AVA-EUS-AVAHUB-ASP-FE-DEV",
		// "serviceImage": "https://zeb-aps1-cloudgen-s3-dev.s3.ap-south-1.amazonaws.com/SelfServiceIcon/aws/testing.png"
		// },
		// {
		// "ai_configuration": {
		// "fields": [
		// {
		// "fieldName": "Instance Type",
		// "fieldType": "text",
		// "value": "B2"
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "dropdown",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "radio",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "check box",
		// "value": ""
		// }
		// ],
		// "subNetName": "",
		// "tags": {
		// "APPLICATION": "AVAHUB",
		// "CLOUDGEN": "FALSE",
		// "ENV": "DEV"
		// },
		// "vnetName": ""
		// },
		// "configuration": {},
		// "name": "AVA-EUS-AVAHUB-ASP-TRG2-DEV",
		// "serviceImage": "https://zeb-aps1-cloudgen-s3-dev.s3.ap-south-1.amazonaws.com/SelfServiceIcon/aws/testing.png"
		// }
		// ],
		// "App Service- Web App": [
		// {
		// "ai_configuration": {
		// "fields": [
		// {
		// "fieldName": "Instance Type",
		// "fieldType": "text",
		// "value": "App Service- Web App"
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "dropdown",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "radio",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "check box",
		// "value": ""
		// }
		// ],
		// "subNetName": "",
		// "vnetName": ""
		// },
		// "configuration": {},
		// "name": "AVA-EUS-AVAHUB-APPSVC-FE-DEV",
		// "serviceImage": "https://zeb-aps1-cloudgen-s3-dev.s3.ap-south-1.amazonaws.com/SelfServiceIcon/aws/testing.png"
		// },
		// {
		// "ai_configuration": {
		// "fields": [
		// {
		// "fieldName": "Instance Type",
		// "fieldType": "text",
		// "value": "App Service- Web App"
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "dropdown",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "radio",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "check box",
		// "value": ""
		// }
		// ],
		// "subNetName": "",
		// "vnetName": ""
		// },
		// "configuration": {},
		// "name": "AVA-EUS-AVAHUB-APPSVC-TRG01-DEV",
		// "serviceImage": "https://zeb-aps1-cloudgen-s3-dev.s3.ap-south-1.amazonaws.com/SelfServiceIcon/aws/testing.png"
		// },
		// {
		// "ai_configuration": {
		// "fields": [
		// {
		// "fieldName": "Instance Type",
		// "fieldType": "text",
		// "value": "App Service- Web App"
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "dropdown",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "radio",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "check box",
		// "value": ""
		// }
		// ],
		// "subNetName": "",
		// "vnetName": ""
		// },
		// "configuration": {},
		// "name": "AVA-EUS-AVAHUB-APPSVC-BE-DEV",
		// "serviceImage": "https://zeb-aps1-cloudgen-s3-dev.s3.ap-south-1.amazonaws.com/SelfServiceIcon/aws/testing.png"
		// },
		// {
		// "ai_configuration": {
		// "fields": [
		// {
		// "fieldName": "Instance Type",
		// "fieldType": "text",
		// "value": "App Service- Web App"
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "dropdown",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "radio",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "check box",
		// "value": ""
		// }
		// ],
		// "subNetName": null,
		// "vnetName": null
		// },
		// "configuration": {},
		// "name": "AVA-EUS-AVAHUB-APPSVC-TRG2-DEV",
		// "serviceImage": "https://zeb-aps1-cloudgen-s3-dev.s3.ap-south-1.amazonaws.com/SelfServiceIcon/aws/testing.png"
		// }
		// ],
		// "Azure Container Registry": [
		// {
		// "ai_configuration": {
		// "fields": [
		// {
		// "fieldName": "Instance Type",
		// "fieldType": "text",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "dropdown",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "radio",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "check box",
		// "value": ""
		// }
		// ],
		// "subNetName": "",
		// "vnetName": ""
		// },
		// "configuration": {},
		// "name": "AVAEUSAVAHUBACRDEV",
		// "serviceImage": "https://zeb-aps1-cloudgen-s3-dev.s3.ap-south-1.amazonaws.com/SelfServiceIcon/aws/testing.png"
		// }
		// ],
		// "Key Vault": [
		// {
		// "ai_configuration": {
		// "fields": [
		// {
		// "fieldName": "Instance Type",
		// "fieldType": "text",
		// "value": "Key Vault"
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "dropdown",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "radio",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "check box",
		// "value": ""
		// }
		// ],
		// "subNetName": "",
		// "tags": {
		// "APPLICATION": "AVAHUB",
		// "CLOUDGEN": "FALSE",
		// "ENV": "DEV"
		// },
		// "vnetName": ""
		// },
		// "configuration": {},
		// "name": "AVA-EUS-AVAHUB-KV-DEV",
		// "serviceImage": "https://zeb-aps1-cloudgen-s3-dev.s3.ap-south-1.amazonaws.com/SelfServiceIcon/aws/testing.png"
		// }
		// ],
		// "Private Endpoint": [
		// {
		// "ai_configuration": {
		// "fields": [
		// {
		// "fieldName": "Instance Type",
		// "fieldType": "text",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "dropdown",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "radio",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "check box",
		// "value": ""
		// }
		// ],
		// "subNetName": "AVA-EUS-AVAHUB-SNET2-DEV",
		// "vnetName": "AVA-EUS-AVAHUB-VNET-DEV"
		// },
		// "configuration": {},
		// "name": "AVA-EUS-AVAHUB-PE-DB-DEV",
		// "serviceImage": "https://zeb-aps1-cloudgen-s3-dev.s3.ap-south-1.amazonaws.com/SelfServiceIcon/aws/testing.png"
		// },
		// {
		// "ai_configuration": {
		// "fields": [
		// {
		// "fieldName": "Instance Type",
		// "fieldType": "text",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "dropdown",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "radio",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "check box",
		// "value": ""
		// }
		// ],
		// "subNetName": "AVA-EUS-AVAHUB-SNET1-DEV",
		// "vnetName": "AVA-EUS-AVAHUB-VNET-DEV"
		// },
		// "configuration": {},
		// "name": "AVA-EUS-AVAHUB-PE-APPSVCBE-DEV",
		// "serviceImage": "https://zeb-aps1-cloudgen-s3-dev.s3.ap-south-1.amazonaws.com/SelfServiceIcon/aws/testing.png"
		// }
		// ],
		// "SQL Server": [
		// {
		// "ai_configuration": {
		// "fields": [
		// {
		// "fieldName": "Instance Type",
		// "fieldType": "text",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "dropdown",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "radio",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "check box",
		// "value": ""
		// }
		// ],
		// "subNetName": "",
		// "vnetName": ""
		// },
		// "configuration": {},
		// "name": "ava-eus-avahub-sqlsvr-dev",
		// "serviceImage": "https://zeb-aps1-cloudgen-s3-dev.s3.ap-south-1.amazonaws.com/SelfServiceIcon/aws/testing.png"
		// }
		// ],
		// "Storage Account": [
		// {
		// "ai_configuration": {
		// "fields": [
		// {
		// "fieldName": "Instance Type",
		// "fieldType": "text",
		// "value": "Storage Account"
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "dropdown",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "radio",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "check box",
		// "value": ""
		// }
		// ],
		// "subNetName": "",
		// "vnetName": ""
		// },
		// "configuration": {},
		// "name": "avaeusavahubstgdev",
		// "serviceImage": "https://zeb-aps1-cloudgen-s3-dev.s3.ap-south-1.amazonaws.com/SelfServiceIcon/aws/testing.png"
		// }
		// ],
		// "network_interfaces": [
		// {
		// "ai_configuration": {
		// "fields": [
		// {
		// "fieldName": "Instance Type",
		// "fieldType": "text",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "dropdown",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "radio",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "check box",
		// "value": ""
		// }
		// ],
		// "subNetName": "AVA-EUS-AVAHUB-SNET1-DEV",
		// "vnetName": "AVA-EUS-AVAHUB-VNET-DEV"
		// },
		// "configuration": {},
		// "name": "AVA-EUS-AVAHUB-NIC-PEAPPSVCBE-DEV",
		// "serviceImage": "https://zeb-aps1-cloudgen-s3-dev.s3.ap-south-1.amazonaws.com/SelfServiceIcon/aws/testing.png",
		// "servicename": "Network Interface card"
		// }
		// ],
		// "vnet": [
		// {
		// "ai_configuration": {
		// "fields": [
		// {
		// "fieldName": "Instance Type",
		// "fieldType": "text",
		// "value": "Virtual Network"
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "dropdown",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "radio",
		// "value": ""
		// },
		// {
		// "fieldName": "BucketName",
		// "fieldType": "check box",
		// "value": ""
		// }
		// ],
		// "subNetName": "AVA-EUS-AVAHUB-SNET2-DEV",
		// "tags": {
		// "APPLICATION": "AVAHUB",
		// "CLOUDGEN": "TRUE",
		// "ENV": "DEV"
		// },
		// "vnetName": "AVA-EUS-AVAHUB-VNET-DEV"
		// },
		// "name": "AVA-EUS-AVAHUB-VNET-DEV",
		// "serviceImage": "https://zeb-aps1-cloudgen-s3-dev.s3.ap-south-1.amazonaws.com/SelfServiceIcon/aws/testing.png",
		// "servicename": "Virtual Network",
		// "subservice": {
		// "security_groups": [],
		// "subnets": [
		// {
		// "ai_configuration": {
		// "fields": [],
		// "subNetName": "AVA-EUS-AVAHUB-SNET2-DEV",
		// "vnetName": "AVA-EUS-AVAHUB-VNET-DEV"
		// },
		// "name": "AVA-EUS-AVAHUB-SNET2-DEV",
		// "serviceImage": "https://zeb-aps1-cloudgen-s3-dev.s3.ap-south-1.amazonaws.com/SelfServiceIcon/aws/testing.png",
		// "servicename": "Network Interface card",
		// "subservice": {
		// "network_interfaces": [
		// {
		// "ai_configuration": {
		// "fields": [],
		// "subNetName": "AVA-EUS-AVAHUB-SNET2-DEV",
		// "vnetName": "AVA-EUS-AVAHUB-VNET-DEV"
		// },
		// "configuration": {},
		// "name": "AVA-EUS-AVAHUB-NIC-PEDB-DEV",
		// "serviceImage": "https://zeb-aps1-cloudgen-s3-dev.s3.ap-south-1.amazonaws.com/SelfServiceIcon/aws/testing.png",
		// "servicename": "Network Interface card"
		// }
		// ]
		// }
		// }
		// ]
		// }
		// }
		// ]
		// }
		// }
		// setazureResources(resource) 
		} catch (error) {
			console.log(error , "errr in fetch resources")
		}



	}

	// Helper function to get color for service
	function getColorForService(serviceName) {
		const colorMap = {
			"VNET": "#8C4FFF",
			"Account": "#CD2264",
			"Availability_Zone": "#6610f2",
			"Resource_Group": "#00A4A6",
			"Subnet": "#DD3522",
			"Security_group": "#DD3522"
		};
		return colorMap[serviceName] || "#000000";
	} 

	useEffect(()=>{
		console.log(nodes , "Nodes")
	},[nodes])
	/** 
	* ProcessAzureResources This function serves the Pupose of Creation of Node of each services and
	* Binding of the Architecture
	*/
	// Fields Refactoring 
	function serviceFieldsRefactor(params) {
		try {
			if (params && Array.isArray(params)) {
				const seenFields = new Set(); // To track unique field identifiers
				return params.reduce((accumulator, field) => {
					// Create a unique identifier for the field based on fieldName and fieldType
					const fieldIdentifier = `${field.fieldName}-${field.fieldType}`;

					// If the field has not been seen before, process it
					if (!seenFields.has(fieldIdentifier)) {
						seenFields.add(fieldIdentifier);

						let refactoredField = {
							dependsOn: null,
							fieldName: field.fieldName,
							fieldType: field.fieldType,
							inputValue: "",
							required: true,
							UserValue: ""
						};

						// If 'value' exists in the original field, use it for 'inputValue'
						if ('value' in field) {
							try {
								const parsedValue = JSON.parse(field.value);
								refactoredField.inputValue = Array.isArray(parsedValue) && parsedValue.length > 0 ? parsedValue[0] : "";
							} catch (e) {
								refactoredField.inputValue = field.value;
							}
						}

						// If 'userValue' exists in the original field, use it for 'UserValue'
						if ('userValue' in field) {
							refactoredField.UserValue = field.userValue;
						}

						accumulator.push(refactoredField);
					}

					return accumulator;
				}, []);
			}
			return [];
		} catch (error) {
			console.log(error);
			return [];
		}
	}
	let NodeData = []

	// Helper to create a node
	const createNode = ( position, size, serviceData, nodeType) => {
		console.log(serviceData , "Service Data")
		let ConfigureDetail = {
			subServieConfig: {
				Action: {
					create: {},
					delete: {},
					update: {}
				},
				type: "create"
			}
		}
		const actionType = ConfigureDetail.subServieConfig.type;
		// Iterate through the fields in serviceData
		serviceData.fields.forEach(field => {
		// Add each field to the corresponding action object
		ConfigureDetail.subServieConfig.Action[actionType][field.fieldName] = field.value;
		});
		console.log(ConfigureDetail);
		const dimensionsMap = {
			"Account": 400,
			"Region": 350,
			"VPC": 300,
			"Availability_Zone": 250,
			"Subnet": 200,
			"Security_group": 150
		};

		let nodeIdentity = nodeType;
		let nodeStyle = {
			position: "absolute",
			zIndex: serviceData.serviceName === "Resource_Group" || "Resource Group" ? "-100000" : "VNET" || "Virtual Network" ? "100001" : "Subnet" ? "100002" : "100010",
			width: size.width,
			height: size.height,
			className: "resizeLoop",
			color: getColorForService(serviceData.serviceName)
		};

		let htmlElement = (
			<div>
				<div className="container-hover"
					onMouseEnter={(e) => {
						// Show tooltip
						const tooltip = document.createElement('div');
						tooltip.innerHTML = `<strong>${serviceData.serviceName}</strong><br>${serviceData.serviceDR}`;
						tooltip.style.position = 'fixed';
						tooltip.style.top = `${e.clientY + 10}px`;
						tooltip.style.left = `${e.clientX + 10}px`;
						tooltip.style.backgroundColor = 'black';
						tooltip.style.color = 'grey';
						tooltip.style.padding = '8px';
						tooltip.style.borderRadius = '4px';
						tooltip.style.zIndex = '100';
						tooltip.style.whiteSpace = 'pre-wrap';
						tooltip.style.maxWidth = '200px';
						tooltip.className = 'custom-tooltip';
						document.body.appendChild(tooltip);
					}}
					onMouseLeave={() => {
						// Hide tooltip
						const tooltips = document.getElementsByClassName('custom-tooltip');
						while (tooltips.length > 0) {
							tooltips[0].parentNode.removeChild(tooltips[0]);
						}
					}}
				>
					<img
						id={serviceData.id}
						width={25}
						height={25}
						src={`${serviceData.imageName}`}
						className={["VPC", "Region", "Subnet", "Security_group"].includes(serviceData.serviceName) ? "vpcImageStyle" : "img"}
						alt="service-logo"
						onError={(e) => {
							e.target.onerror = null; // Prevents looping
							e.target.src = `./images/Workbench/AIArchitecture/Azure/AzureLogo.svg`; // Set to your default image path
						}}
					/>
				</div>
			</div>
		);
		let id = serviceData.serviceName + "_" + Math.random()
		let serviceFields = serviceFieldsRefactor (serviceData.fields)
		let nodeDataForCount = [...NodeData];
		let countForLabel = nodeDataForCount.filter(data => data === serviceData.serviceName).length + 1;
		let labelName = `${serviceData.serviceName} ${countForLabel}`;
		let imageServiceName = `${serviceData.serviceName}_${countForLabel}`
		NodeData.push( serviceData.serviceName)
		return {
			id: id,
			type: nodeIdentity,
			instanceName : labelName,
			position: {
				x: isNaN(position.x) ? 0 : position.x,
				y: isNaN(position.y) ? 0 : position.y
			},
			// parentNode: "",
			style: nodeStyle,
			data: {
				element: htmlElement,
				resourceName: serviceData.serviceName,
				serviceName: serviceData.serviceName,
				imageName: serviceData.imageName,
				imageServiceName: imageServiceName,
				instanceName : labelName,
				fields: serviceFields || [],
				ConfigureDetail: ConfigureDetail,
				description: serviceData.serviceDR || '',
				serviceFamily :serviceData?.serviceFamily || "Others",
				children: undefined,
				repositoryData: {
					repository_name: "",
					repository_id: "",
					branchData: {
						branch_id: "",
						branch_name: ""
					}
				},
				Tags: [
					{
						"key": "cloudgen",
						"value": true,
						defaultTag: true
					} 
				]
			}
		};
		};

	// processAzureResources
	const processAzureResources = () => {
		if (!azureResources || Object.keys(azureResources).length === 0) {
			setNodes([]);
			setEdges([]);
			return;
		}
		console.log(azureResources, "azureResources")
		const nodes = [];
		const edges = [];
		let id = 0;

		// Constants for layout and spacing
		const PADDING = 30;
		const SERVICE_SPACING = 15;
		const VERTICAL_SPACE_ABOVE = 30;
		const VERTICAL_SPACE_BETWEEN = 30;
		const MIN_RG_WIDTH = 700;
		const MIN_VNET_WIDTH = 400;
		const MIN_VNET_HEIGHT = 400;
		const MIN_SUBNET_WIDTH = 300;
		const MIN_SUBNET_HEIGHT = 300;
		const SERVICE_WIDTH = 25;
		const SERVICE_HEIGHT = 25;
		const MAX_SERVICES_PER_ROW = 5;
		const MAX_VNETS_PER_ROW = 4; // Max VNETs in a row
		const MAX_SUBNETS_PER_ROW = 5; // Max Subnets in a row

		// Define the resource group
		const resourceGroup = azureResources.resourceGroup[0];
		const resourceGroupName = resourceGroup ? resourceGroup.name : 'Unknown Resource Group';

		let rgWidth = MIN_RG_WIDTH;
		let rgHeight = VERTICAL_SPACE_ABOVE;
		const rgSubnodes = [];

		// Handle VNETs and Subnets
		if (azureResources.subservice?.vnet) {
			const vnets = azureResources.subservice.vnet;
			let vnetX = PADDING;
			let vnetY = VERTICAL_SPACE_ABOVE;
			let maxVnetHeightInRow = MIN_VNET_HEIGHT;
			let trackVnetNodes = [];

			vnets.forEach((vnet, vnetIndex) => {
				let vnetWidth = MIN_VNET_WIDTH;
				let vnetHeight = VERTICAL_SPACE_ABOVE;
				const vnetSubnodes = [];
				let subnetX = vnetX + PADDING;
				let subnetY = vnetY + VERTICAL_SPACE_ABOVE;

				if (vnetIndex % MAX_VNETS_PER_ROW === 0 && vnetIndex !== 0) {
					let index = vnetIndex - MAX_VNETS_PER_ROW;
					vnetY = trackVnetNodes[index].position.y + trackVnetNodes[index].size.height + VERTICAL_SPACE_BETWEEN;
					vnetX = PADDING;
					maxVnetHeightInRow = MIN_VNET_HEIGHT;
					subnetX = vnetX + PADDING;
					subnetY = vnetY + VERTICAL_SPACE_ABOVE;
				}

				// Handle Subnets and Services within the Subnet
				if (vnet.subservice?.subnets) {
					const subnets = vnet.subservice.subnets;
					let totalSubnetHeight = VERTICAL_SPACE_ABOVE;
					let maxSubnetWidthInRow = 0;
					let currentSubnetRowHeight = 0;

					subnets.forEach((subnet, subnetIndex) => {
						let subnetWidth = MIN_SUBNET_WIDTH;
						let subnetHeight = MIN_SUBNET_HEIGHT;
						const subnetServices = [];

						if (subnetIndex % MAX_SUBNETS_PER_ROW === 0 && subnetIndex !== 0) {
							subnetX = vnetX + PADDING;
							subnetY += currentSubnetRowHeight + VERTICAL_SPACE_BETWEEN;
							totalSubnetHeight += currentSubnetRowHeight + VERTICAL_SPACE_BETWEEN;
							currentSubnetRowHeight = 0;
							maxSubnetWidthInRow = 0;
						}

						if (subnet.subservice) {
							let serviceX = subnetX + PADDING;
							let serviceY = subnetY + VERTICAL_SPACE_ABOVE + 5;
							let maxServiceX = PADDING;
							let maxServiceY = PADDING;

							Object.entries(subnet.subservice).forEach(([serviceType, service], serviceIndex) => {
								if (serviceIndex % MAX_SERVICES_PER_ROW === 0 && serviceIndex !== 0) {
									serviceX = subnetX + PADDING;
									serviceY += SERVICE_HEIGHT + SERVICE_SPACING;
								}

								const position = { x: serviceX, y: serviceY };
								const size = { width: SERVICE_WIDTH, height: SERVICE_HEIGHT };
								const serviceData = {
									serviceName: service.servicename || 'Unknown',
									imageName: service.serviceImage || './default-service-icon.png',
									fields: subnet.ai_configuration.fields || [],
									serviceFamily: service.category_name || 'Others',
									serviceDR: service.serviceDR || service.name
								};
								const nodeType = "service";

								const serviceNode = createNode(position, size, serviceData, nodeType);
								subnetServices.push(serviceNode);

								serviceX += SERVICE_WIDTH + SERVICE_SPACING;
								maxServiceX = Math.max(maxServiceX, serviceX);
								maxServiceY = Math.max(maxServiceY, serviceY + SERVICE_HEIGHT);
							});

							subnetWidth = Math.max(subnetWidth, maxServiceX - subnetX + PADDING);
							subnetHeight = Math.max(subnetHeight, maxServiceY - subnetY + PADDING);
						}

						const position = { x: subnetX, y: subnetY };
						const size = { width: subnetWidth, height: subnetHeight };
						const serviceData = {
							serviceName: subnet.servicename || "Subnet",
							imageName: subnet.serviceImage || "./images/Workbench/AIArchitecture/Azure/Subnet.svg",
							fields: subnet.ai_configuration.fields || [],
							serviceFamily: subnet.category_name || "Network",
							serviceDR: subnet.serviceDR || subnet.name
						};
						const nodeType = "location";

						const subnetNode = createNode(position, size, serviceData, nodeType);

						vnetSubnodes.push(subnetNode);
						vnetSubnodes.push(...subnetServices);

						currentSubnetRowHeight = Math.max(currentSubnetRowHeight, subnetHeight);
						maxSubnetWidthInRow = subnetX + subnetWidth - vnetX + PADDING;
						subnetX += subnetWidth + PADDING;
					});

					vnetHeight = Math.max(vnetHeight, totalSubnetHeight + currentSubnetRowHeight);
					vnetWidth = Math.max(vnetWidth, maxSubnetWidthInRow);
				}

				const position = { x: vnetX, y: vnetY };
				const size = { width: vnetWidth, height: vnetHeight };
				const serviceData = {
					serviceName: vnet.servicename || "VNET",
					imageName: vnet.serviceImage || "./images/Workbench/AIArchitecture/Azure/Virtual_Network.png",
					fields: vnet.ai_configuration.fields || [],
					serviceFamily: vnet.category_name || "Network",
					serviceDR: vnet.serviceDR || vnet.name
				};
				const nodeType = "location";

				const vnetNode = createNode(position, size, serviceData, nodeType);

				rgSubnodes.push(vnetNode);
				rgSubnodes.push(...vnetSubnodes);
				trackVnetNodes.push(vnetNode);

				vnetX += vnetWidth + PADDING;
				maxVnetHeightInRow = Math.max(maxVnetHeightInRow, vnetHeight);
				rgWidth = Math.max(rgWidth, vnetX);
				rgHeight = Math.max(rgHeight, vnetY + vnetHeight + VERTICAL_SPACE_BETWEEN);
			});
		}

		// Services outside the VNET and Subnet which will also be placed inside the Resource group
		if (azureResources.subservice) {
			let serviceX = PADDING;
			let serviceY = rgHeight + VERTICAL_SPACE_ABOVE;
			let maxServiceX = PADDING;
			let maxServiceY = PADDING;

			Object.entries(azureResources.subservice).forEach(([serviceType, service], serviceIndex) => {
				// Processing services outside the VNET
				if (serviceType !== 'vnet') {
					// Orphan services calculation
					if (serviceIndex % MAX_SERVICES_PER_ROW === 0 && serviceIndex !== 0) {
						serviceX = PADDING;
						serviceY += SERVICE_HEIGHT + SERVICE_SPACING;
					}
					// Retrival of servicename
					let serviceName = serviceType.split('_')[0]
					const serviceData = {
						serviceName: serviceName || 'Unknown',
						imageName: service.serviceImage || '',
						fields: service.ai_configuration.fields || [],
						serviceFamily: service.category_name || service.name,
						serviceDR: service.serviceDR ? service.serviceDR : service.name
					};
					const position = { x: serviceX, y: serviceY };
					const size = { width: SERVICE_WIDTH, height: SERVICE_HEIGHT };
					const nodeType = "service";

					const serviceNode = createNode(position, size, serviceData, nodeType);

					nodes.push(serviceNode);

					maxServiceX = Math.max(maxServiceX, serviceX + SERVICE_WIDTH);
					maxServiceY = Math.max(maxServiceY, serviceY + SERVICE_HEIGHT);

					serviceX += SERVICE_WIDTH + SERVICE_SPACING;
				}
			});

			// Modification of the Resource group width and height based on the service rendering
			rgWidth = Math.max(rgWidth, maxServiceX + PADDING);
			rgHeight = Math.max(rgHeight, maxServiceY + PADDING);
		}

		// Resource Group creation
		const serviceData = {
			serviceName: resourceGroup.service_name,
			imageName: resourceGroup.service_icon || "./images/Workbench/AIArchitecture/Azure/Resource_Group.svg",
			fields: resourceGroup.ai_configuration?.fields || [],
			serviceFamily: resourceGroup.category_name || "Network",
			serviceDR: resourceGroup.serviceDR || resourceGroupName
		};
		const position = { x: 0, y: 0 };
		const size = { width: rgWidth, height: rgHeight };
		const nodeType = "location";

		const rgNode = createNode(position, size, serviceData, nodeType);

		nodes.push(rgNode);
		rgSubnodes.forEach(subnode => {
			subnode.parentNode = rgNode.id;
			nodes.push(subnode);
		});

		setNodes(nodes);
		setEdges(edges);
		};
	useEffect(()=>{
		if(azureResources!= {}){
		processAzureResources()
		}
	},[azureResources])
	/**
		* LocationBind function is to render the node in service node custom Type
			*/
	const locationBind = ({ data, id }) => {
		console.log(data, id, "Here")
		return (
			<>
				<NodeResizer style={{ "border": 'unset'}} />
				<div className={`locationMain ${id}`}>
					<div
						className="container-hover"
						id={id + "@location"}
						style={{
							display: "flex",
							padding: "11px 10px",
							color: "black", color: colorMode === "dark" ? "#FFFFFF" : "black"

						}}
					>
						{data.element}
						<label
							className="locationLabel"
							id={id + "@nodeLabel"}
								style={{ color: colorMode === "dark" ? "#FFFFFF" : "black" }}


						>
							{data.serviceName}
						</label>
					</div>
				</div>
			</>
		);
	};
	/**
		* SerivceBind function is to render the node in service node custom Type
		*/
	const serviceBind = ({ data, id }) => {
		console.log(id, "id Herere")
		console.log(data, "Data Here!")
		return (
			<>

				<div
				// style={{ position: 'relative', border: '2px solid #000', padding: '10px', borderRadius: '5px' }}
				>
					<span id={id + "1Source"}>
						<Handle id={`${id}leftSource`}
							type="source" position="left"
						/>
						<Handle id={`${id}topSource`} type="source" position="top" />
					</span>
					<span id={id + "1Target"}>
						<Handle id={`${id}leftTarget`} type="target" position="left" />
						<Handle id={`${id}topTarget`} type="target" position="top" />
					</span>
					<div style={{ position: "relative", margin: "-1px 0px 0px 0px" }}>
						{data.element}
					</div>
					<label
						style={{ position: "absolute", color: colorMode === "dark" ? "#FFFFFF" : "black" }}

						className="nodeLabel"
						id={id + "@nodeLabel"}

					>
						{data.serviceName}
					</label>
					<span id={id + "2Source"}>
						<Handle id={`${id}rightSource`} type="source" position="right" />
						<Handle id={`${id}bottomSource`} type="source" position="bottom" />
					</span>
					<span id={id + "2Target"}>
						<Handle id={`${id}rightTarget`} type="target" position="right" />
						<Handle id={`${id}bottomTarget`} type="target" position="bottom" />
					</span>
				</div>
			</>
		);
		};
	/**
	* Node Type Rendering Here
	*/
	const nodeTypes = useMemo(
		() => ({
			service: serviceBind,
			location: (props) => locationBind(props, nodes),
		}),
		[]
	);
	// This function Generates the ARM content

	const generateARMContent = async () => {
		try {
		// let payload = {
		// "userId": "",
		// "connectorId": "6f4383e8-4bd8-4c3d-a466-5b1c7aa663d6",
		// "resourceGroup": "AVA-EUS-ZEBPULSE-OPENAI-RG-STAGE",
		// "region" : "",
		// "tag" : ""
		// }
		let payload = {
			"userId": userId,
			"connectorId": selectedConnector.connectorId,
			"resourceGroup": selectedResourceGroup,
			"region" : selectedRegion,
			"tag" : selectedTag
		}
		console.log(payload , "Payload for the ARM COde Generation")
		let res = await generateARMContentApi(payload) 
		if(res.success ){
			console.log(res.data ,"Data")
			const url = res.data;
				const response = await fetch(url);
				const jsonData = await response.json();
				return JSON.stringify(jsonData, null, 2); // Pretty-print the JSON
		}
		} catch (error) {
			console.log(error , "error") 
		}
		// Implementation for ARM content generation
	};
	const [downloadDisabled , setDownloadDisabled] = useState(false)
	// Download File Function used to Download Defined Language Code 
	const downloadFile = async (templateType) => {
		console.log(templateType , "templateTyp")
		setDownloadDisabled(true)
		try {
			let content, fileName;
			switch (templateType) {
				case 'terraform':
					content = await generateTerraformContent();
					fileName = 'terraform_config.tf';
					break;
				case 'ARM':
					content = await generateARMContent();
					fileName = 'arm_template.yaml';
					break;
				default:
					throw new Error('Invalid template type');
			}

			const blob = new Blob([content], { type: 'text/plain' });
			const downloadLink = document.createElement('a');
			downloadLink.href = URL.createObjectURL(blob);
			downloadLink.download = fileName;

			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink);
			URL.revokeObjectURL(downloadLink.href);
			setShowDownloadModal(false);
				setDownloadDisabled(false)

			setSelectedFormat('');
			setPopUpMsg({...popupMsg , ["Msg"]:"Code Downloaded" , ["show"]:true ,["success"]:true })
		} catch (error) {
			setPopUpMsg({...popupMsg , ["Msg"]:"Error In Code Download" , ["show"]:true ,["success"]:false })
			setDownloadDisabled(false)
			console.error(`Error downloading ${templateType} file:`, error);
		}
	};



	// Terrafom Content s
	const generateTerraformContent = async () => {
		try {
			// let payload = {
			// "userId": "",
			// "connectorId": "6f4383e8-4bd8-4c3d-a466-5b1c7aa663d6",
			// "resourceGroup": "AVA-EUS-ZEBPULSE-OPENAI-RG-STAGE",
			// "region" : "",
			// "tag" : ""
			// }
		let payload = {
			"userId": userId,
			"connectorId": selectedConnector.connectorId,
			"resourceGroup": selectedResourceGroup,
			"region" : selectedRegion,
			"tag" : selectedTag
			}
			console.log(payload , "Payload for the Terraform  COde Generation")
		let res = await generateTerraformContentApi(payload) 
		if(res.success || res.status ==200 ){
			console.log(res.terraform_code ,"Data")
			return res.terraform_code
		}
		} catch (error) {
			console.log(error , "error") 
		}
			// Implementation for ARM content generation

	};

	const handleBackToB2A = () => {
		navigate('/B2AGrid', {
			state: {
				providerName: selectedProvider.providerName,
				cloudproviderId: selectedProvider.providerId,

			}
			})

	};
	const handleTagChange = (e) => {
		const [key, value] = e.target.value.split(':');
		setSelectedTag({ Key: key, Value: value });
			};
	/**
	* Draggable Functionalits
	*/

	/**
	* PS_62 - PS_66 XML Donwload Functionality
	*/
	function XMLDownload() {
		// PS_64 - Node Data formation 
		let nodeData = {
			nodes: nodes,
			edges: edges,
		};

		let stringifyNode = JSON.stringify(nodeData);
		stringifyNode = stringifyNode.replace(/&/g, "#@#@#");
		let architecutureName = "Test"
		let provider = "Azure"
		let technology = "Terraform"
		download(
			`${architecutureName}.xml`,
			`<?xml version="1.0" encoding="UTF-8" ?><cloudgen><${provider + "-" + technology
			}>` +
			stringifyNode +
			`</${provider + "-" + technology}></cloudgen>`
		);
		}
	function download(filename, text) {
		//console.log("xmlText", text);

		var element = document.createElement("a");
		element.setAttribute(
			"href",
			"data:text/plain;charset=utf-8," + encodeURIComponent(text)
		);
		element.setAttribute("download", filename);

		element.style.display = "none";
		document.body.appendChild(element);

		element.click();

		document.body.removeChild(element);
		}

	// PS_72 
		// DownLoadImage Function Download the Architecture Image in the specifed format 

	async function DownLoadImage(type) {
		try {
			document.getElementById("fittoscreen").click();

			const architectureElement = document.getElementById("myArchitecture");
			architectureElement.style.backgroundColor = "white";

			let dataUrl;
			if (type === "Jpeg") {
				dataUrl = await htmlToImage.toJpeg(architectureElement);
			} else if (type === "Png") {
				// Convert the Entire architecture ot dataURl
				dataUrl = await htmlToImage.toPng(architectureElement);

			} else {
				console.log("Unsupported image type");
			}

			const link = document.createElement("a");
			link.download = `${providerName}.${type.toLowerCase()}`;
			link.href = dataUrl;
			link.click();

			setTimeout(() => {
				architectureElement.style.backgroundColor = "transparent";
			}, 3000);
		} catch (error) {
			console.log(`Error in downloadImage: ${error}`);
		}
	}
	/** Draggable Functionalotes Ends Here */

	return (

		<div className="container-fluid">
			<div className="row">
				<SideBar/>

				<div className="col-md-9 px-4 pt-4 right-container-scroll">
					<div className="row px-3" style={{position:"absolute"}}>
					{
				popupMsg.show == true && popupMsg.Msg !=""?
				<B2APopup
				popupMsg ={popupMsg}
				setPopUpMsg ={setPopUpMsg}
				/>
				:<></>
				}

					<div className="col-md-12">
					<div className="d-flex justify-content-between align-items-center mb-4 pb-2">
						<div className="d-flex align-items-center gap-3">
						<button
							type="button"
							className="btn custom-outline-button-v2 p-3 lh-0 font-medium font-14 black-v2"
							onClick={handleBackToB2A}
						>
							Back to B2A
						</button>
						<p className="font-20 font-bold black-v2 mb-0">
							Azure Infrastructure
						</p>
						</div>
						<div className="combine-button">
						<button
							className="font-14 font-regular primary-color deploy-btn"
							onClick={() => {
							setCloneModal(true)
							}}
						>
							<img
							src="images/clone-icon.svg"
							alt="clone"
							className="me-2"
							/>
							Clone
						</button>

						<button
							className="font-14 font-regular primary-color deploy-btn"
							onClick={() => setShowDownloadModal(true)}
						>
							<img
							src="images/download-icon-purple.svg"
							alt="download"
							className="me-2"
							/>
							Download
						</button>
						</div>
					</div>
					</div>

					{/* Download Modal */}
					<div className={`modal fade ${showDownloadModal ? 'show d-block' : ''}`}
						style={{ display: showDownloadModal ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
					<div className="modal-dialog modal-dialog-centered justify-content-center">
						<div className="modal-content border-0">
						<div className="modal-header border-0 p-4">
							<h1 className="modal-title font-20 font-bold black-v2" id="downloadModalLabel">Download Architecture</h1>
							<button type="button" className="btn shadow-none" onClick={() => setShowDownloadModal(false)} aria-label="Close">
							<img src="images/close-icon.svg" alt="close-icon" className="icon-hover shadow-none modal-close-filter" />
							</button>
						</div>
						<div className="modal-body px-4 pt-0">
							{/* Add your download options here */}
							<div className="my-3">
							<label className="form-label font-14 font-medium black-v2" htmlFor="downloadFormat">Download Format <span className="danger-red">*</span></label>
							<select
								className="form-select custom-dropdown shadow-none custom-border-box font-14 font-regular black-v2 custom-select"
								id="downloadFormat"
								value={selectedFormat}
								onChange={(e) => setSelectedFormat(e.target.value)}
							>
								<option value="">Select</option>
								<option value="terraform">Terraform</option>
								<option value="ARM">ARM</option>
								{/* Add more format options as needed */}
							</select>
							</div>

							<div className='d-flex justify-content-end align-items-center mt-4 mb-2 gap-3'>
							<button type='button' className='button outline-button text-nowrap py-12' onClick={() => setShowDownloadModal(false)}>Cancel</button>
							<button type='button' className='button primary-button text-nowrap px-4 py-12' onClick={()=>{downloadFile(selectedFormat)}} 
							disabled={!selectedFormat || downloadDisabled}

							>
								Download
							</button>
							</div>
						</div>
						</div>
					</div>
					</div>
						{/** Clone Architecture Pop Up */} 
			{
				cloneModal ?
				<>
				<CloneModal
					showCloneModal = {cloneModal}
					setShowCloneModal = {setCloneModal}
					nodes = {nodes}
					edges ={edges}
					setPopUpMsg ={setPopUpMsg}
					popupMsg={popupMsg}

				/>
				</>:<>
				</>
			}
				<div className="col-md-12">
					<div className="row">
						<div className="col-md-4 mb-3 px-3 pb-3">
							<label
								className="form-label font-14 font-medium black-v2"
								htmlFor="Region-new1"
							>
								Location<span className="required">*</span>
							</label>
							<select
								id="Region-new1"
								className="form-select form-control custom-form font-14 font-regular"
								value={selectedRegion}
								onChange={(e) => {
									setSelectedRegion(e.target.value)
									setTags([])
									setResourceGroups([])

								}}
							>
								<option value="">Select Location</option>
								{
									regions?.map((region) => (
										<option key={region.name} value={region.name}>
										{region.displayName}
										</option>
									))
								}
							</select>
						</div>

						<div className="col-md-4 mb-3 px-3 pb-3">
							<label
								className="form-label font-14 font-medium black-v2"
								htmlFor="Connector-new"
							>
								Tags
							</label>
							<select
								id="Connector-new"
								className="form-select form-control custom-form font-14 font-regular"
								value={`${selectedTag?.Key}:${selectedTag?.Value}`}
								onChange={(e)=>{
									handleTagChange(e)
									setResourceGroups([])
								}
								}
							>
								<option value="">Select Tag</option>
								{tags.map((tag, index) => {
								const key = tag["Key"]
								const value = tag["Value"];
								return (
									<option key={index} value={`${key}:${value}`}>
									{`${key}: ${value}`}
									</option>
								);
								})}
							</select>
						</div>



						<div className="col-md-4 mb-3 px-3 pb-3">
							<label className="form-label font-14 font-medium black-v2" htmlFor="Resource-Group">
								Resource Group
							</label>
							<select
								id="Resource-Group"
								className="form-select form-control custom-form font-14 font-regular"
								value={selectedResourceGroup}
								onChange={(e) =>{ 
									setSelectedResourceGroup(e.target.value)}
								}
							>
								<option value="">Select Resource Group</option>
								{resourceGroups.map((rg, index) => (
									<option key={index} value={rg.name}>
										{`${rg.name} (${rg.location})`}
									</option>
								))}
							</select>
						</div>


					</div>
				</div>

				{/* React Flow diagram */}

				{isLoading ? <Loader/> :
				<div className="workbench-content" style={{ position: "relative" }} >
					<ReactFlowProvider>
						<div>
							<div className="reactflow-wrapper content-center-container"
								style={{ position: 'absolute' }}
								ref={reactFlowWrapper}
							>
								<Draggable handle="#moveable"
									defaultPosition={{ x: 350, y: 370 }}
									style={{width: '30%' }}>
									<div className="tools d-flex rounded shadow-sm"
										style={{ zIndex: "10",position: 'relative'}}
									>
										<button className="btn drag-btn" id="moveable">
											<img
												data-bs-toggle="tooltip"
												data-bs-placement="bottom"
												title="Drag"
												src="images/drag-img.svg"
												draggable={false}
												alt="Drag"
											/>
										</button>
										<button
											className="btn tool-btn"
											onClick={() => {
												document.documentElement
													.getElementsByClassName(
														"react-flow__controls-button react-flow__controls-zoomin"
													)[0]
													.click();
											}}
										>
											<img
												data-bs-toggle="tooltip"
												data-bs-placement="bottom"
												title="Zoom in"
												src="images/zoom-in.svg"
												className
											/>
										</button>
										<button
											className="btn tool-btn"
											onClick={() => {
												document.documentElement
													.getElementsByClassName(
														"react-flow__controls-button react-flow__controls-zoomout"
													)[0]
													.click();
											}}
										>
											<img
												data-bs-toggle="tooltip"
												data-bs-placement="bottom"
												title="Zoom out"
												src="images/zoom-out.svg"
												className
											/>
										</button>
										<button
											id="fittoscreen"
											className="btn tool-btn"
											onClick={() => {
												document.documentElement
													.getElementsByClassName(
														"react-flow__controls-button react-flow__controls-fitview"
													)[0]
													.click();
											}}
										>
											<img
												data-bs-toggle="tooltip"
												data-bs-placement="bottom"
												title="Fit to screen"
												src="images/fit-to-screen.svg"
												className
											/>
										</button>



										{/* Download dropdown */}
										<div className="dropdown d-flex">
											<button
												className="btn tool-btn"
												type="button"
												id="dropdownDOWNLOAD"
												data-bs-toggle="dropdown"
												disabled={nodes.length === 0}
												aria-expanded="false"
											>
												<img
													data-bs-toggle="tooltip"
													data-bs-placement="bottom"
													title="Download"
													src="images/download.svg"
													alt="Download"
												/>
											</button>
											<ul className="dropdown-menu profile-menu border-0 py-1 px-2 shadow-sm">
												<li className="border-grey pb-1">
													<button onClick={(()=>{XMLDownload()})} className="dropdown-item font-semibold font-14 profile-dropdown py-2 px-2">
														XML
													</button>
												</li>
												<li className="pt-1 pb-1 border-grey">
													<button onClick={() => DownLoadImage("Png")} className="dropdown-item font-semibold font-14 py-2 px-2 profile-dropdown">
														PNG
													</button>
												</li>
												<li className="pt-1">
													<button onClick={() => DownLoadImage("Jpeg")} className="dropdown-item font-semibold font-14 py-2 px-2 profile-dropdown">
														JPEG
													</button>
												</li>
											</ul>
										</div>


									</div>
								</Draggable>
								<ReactFlow
									nodes={nodes}
									edges={edges}
									nodeTypes={nodeTypes}
									fitView
									onNodesChange={onNodesChange}
									onEdgesChange={onEdgesChange}
									onConnect={onConnect}
									id="myArchitecture"

								>
									<Background />
									<Controls />
								</ReactFlow>
							</div>
						</div>
					</ReactFlowProvider>

				</div>
					}
				</div>
				</div>

			</div>




		</div>
	);
};

export default AzureB2A;
