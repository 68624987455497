import React, { useState,useEffect ,useContext} from 'react';
import { createAzureConnectorApi,testAzureConnectorApi ,updateAzureConnectorApi} from '../../Service/api';
import { OrgContext } from '../../ContextProvider/OrgContext';

const AzureConnectorModal = ({ showModal, onClose,connectorDetails,onError,provider }) => {
        //PS_33 state variable
    const [formData, setFormData] = useState({
        connectorName: '',
        tenantId: '',
        subscriptionId: '',
        clientId: '',
        clientSecret: '',
        expireDate: '',
        description: ''
    });

    const {
        userRoles,
        orgId,
        providerId,
        architectures,
        canCreateWorkload,
        canDeleteWorkload,
        canViewWorkload,
        setuserRoles,
    
        // Add organization and provider fields here
        providerName,       
        orgData,           
        setOrgData,        
        setArchitectures,
    
        envId,             // Environment ID
        workloadId,        // Workload ID
        aiArchitecture,    
        viewMode,          
        envName,           
        language,          
        archType,          
        business,          
        rulebook,          
        archDesc,          
        services,
    
        lastBuildID,       
        lastBuildDate,     
        lastBuildMessage,
    
        repoId,            
        repoName,
    
        PulseOrganization_id,
        PulseVertical_id,
        PulseVertical_name,
        PulseOrganization_name,
        PulseIdentification,
    
        setEnvId           // Function to update environment ID
    } = useContext(OrgContext);

    const [userId, setuserId] = useState(localStorage.getItem('userId'));

    //PS_39
    useEffect(() => {
        if (connectorDetails) {
            console.log("details",connectorDetails);
               // Convert expireDate to yyyy-MM-dd format if it exists
               const formattedExpireDate = connectorDetails.expireDate
               ? new Date(connectorDetails.expireDate).toISOString().split('T')[0]
               : '';

            setFormData({
                connectorName: connectorDetails.connectorName || '',
                tenantId: connectorDetails.tenantId || '',
                subscriptionId: connectorDetails.subscriptionId || '',
                clientId:  connectorDetails.clientId || '',
                clientSecret:  connectorDetails.clientSecret || '',
                expireDate: formattedExpireDate  || '',
                description: connectorDetails.description || '',
            });
        }
    }, [connectorDetails]); // Dependency array includes connectorDetails

    //PS_34 Handle input change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
//PS_35

    const handleSaveDetails = async () => {
        try {
            // Prepare the payload object
            const payload = {
                userId: userId,
                orgId: orgId,
                providerId: provider
            };
    
            for (const key in formData) {
                if (formData.hasOwnProperty(key)) {
                    // Check if the key is not 'expireDate' or 'description'
                    if (key !== 'expireDate' && key !== 'description' && key !== 'connectorName') {
                        // Encode the value and set it in the payload
                        payload[key] = btoa(String(formData[key])); // Ensure it's a string
                    } else {
                        // Directly assign the value without encoding
                        payload[key] = formData[key];
                    }
                }
            }
    
            // Log the Base64 encoded payload to check its structure
            console.log("Encoded Payload:", payload);
    
            // Make the API call with the constructed payload
            const response = await createAzureConnectorApi(payload);
            
            if (response.success) {
                console.log('Azure connector created successfully:', response.data);
                
            } else {
                console.error('Error creating Azure connector');
               
            }
        } catch (error) {
            console.error('Failed to create Azure connector:', error);
            onError(error.message || 'An unexpected error occurred.'); 

        }finally{
        onClose()}
    };
    //PS_35    
    const [testConnectionSuccessful, setTestConnectionSuccessful] = useState(false);

    const handleTestConnection = async () => {
        try {
            // Prepare the payload object
            const payload = {
                userId: userId,
                orgId: orgId ,
            };

            // Loop through formData and encode each relevant field
            const fieldsToEncode = ['tenantId', 'subscriptionId', 'clientId', 'clientSecret', 'connectorName'];

            fieldsToEncode.forEach(field => {
                if (formData[field]) {
                    payload[field] = btoa(String(formData[field])); // Ensure it's a string and encode
                }
            });

            // Log the Base64 encoded payload to check its structure
            console.log("Encoded Payload:", payload);

            // Make the API call with the constructed payload
            const response = await testAzureConnectorApi(payload);
            
            if (response.data.testResult === "Pass") {
                console.log('AWS connector tested successfully:', response.data);
                setTestConnectionSuccessful(true); // Set success state
                
            } else {
                setTestConnectionSuccessful(false); // Set failure state
                onError('An unexpected error occurred.');
  
            } 
        } catch (error) {
            console.error('Failed to test Azure connector:', error);
            onError(error.message || 'An unexpected error occurred.');
            setTestConnectionSuccessful(false); // Set failure state on error
        }
    };  const isFormValid = () => {
        return formData.connectorName && 
               formData.tenantId && 
               formData.subscriptionId && 
               formData.clientId && 
               formData.clientSecret && 
               formData.expireDate&&
               formData.description // Add any other required fields here
    };

    const handleUpdateAzureConnector = async () => {
        try {
            // Prepare the payload object
            const payload = {
                userId:userId,
                orgId: orgId,
                connectorId: connectorDetails?.connectorId,
            };
    
            for (const key in formData) {
                if (formData.hasOwnProperty(key)) {
                    // Check if the key is not 'expireDate' or 'description'
                    if (key !== 'expireDate' && key !== 'description'&& key !== 'connectorName') {
                        // Encode the value and set it in the payload
                        payload[key] = btoa(String(formData[key])); // Ensure it's a string
                    } else {
                        // Directly assign the value without encoding
                        payload[key] = formData[key];
                    }
                }
            }
    
            // Log the Base64 encoded payload to check its structure
            console.log("Encoded Payload:", payload);
    
            // Make the API call with the constructed payload
            const response = await updateAzureConnectorApi(payload);
            
            if (response.status === 200) {
                console.log('Azure connector updated successfully:', response.data);
              
            } else {
                console.error('Error updating Azure connector');
               
            }
        } catch (error) {
            console.error('Failed to update Azure connector:', error);
            onError(error.message || 'An unexpected error occurred.'); 

        
        }
        onClose()

    };
    
    
  const today = new Date();
  const minDate = new Date(today);
 //minDate.setMonth(today.getMonth() - 1); // 6 months ago
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() + 1); // Set max date to one year from today

  // Format dates to YYYY-MM-DD for input value
  const formatDate = (date) => {
      return date.toISOString().split('T')[0];
  };                                                                               
  const handleChange2 = (e) => {
      const { name, value } = e.target;
      console.log("name",name);
      console.log("value",value);
      const enteredDate = new Date(value);
        
      // Check if the entered date exceeds the maximum allowed date
      if (enteredDate > maxDate) {
          console.error("Entered date exceeds maximum allowed date.");
          return; // Do not update state if the date is invalid
      }
      setFormData({
          ...formData,
          [name]: value
      });
  };
    return (
        <div className={`modal fade ${showModal ? 'show' : ''}`}  style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden={!showModal}>
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content modal-medium-container border-0">
                    <div className="modal-header border-0 py-4 px-32">
                        <h1 className="modal-title font-20 font-medium black-v2 d-flex align-items-center" id="staticBackdropLabel">
                            <span className="icon-bg-primary d-flex justify-content-center align-items-center me-2">
                                <img src="images/azure-img.svg" alt="azure" className="icon-width-24" />
                            </span>
                            {connectorDetails?"Edit Azure Connect":  "Add Azure Connect"}
                        </h1>
                        <button type="button" className="btn shadow-none p-0" onClick={onClose} aria-label="Close">
                            <img src="images/close-icon.svg" alt="close-icon" className="icon-hover shadow-none modal-close-filter" />
                        </button>
                    </div>
                    <div className="modal-body p-32 pt-0">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row mb-4">
                                    <div className="col-md-6 mb-3 px-2">
                                        <label className="form-label font-14 font-medium black-v2" htmlFor="connectorName">Connector Name <span className="danger-red">*</span></label>
                                        <input type="text"
                                            id="connectorName"
                                            name="connectorName"
                                            value={formData.connectorName}
                                            onChange={handleChange}
                                            className="form-control custom-form custom-dropdown font-14 font-medium py-3 black-v2"
                                            placeholder="Enter Connector Name" />
                                    </div>

                                    <div className="col-md-6 mb-4 px-2">
                                        <label className="form-label font-14 font-medium black-v2" htmlFor="tenantId">Tenant ID <span className="danger-red">*</span></label>
                                        <input type="text"
                                            id="tenantId"
                                            name="tenantId"
                                            value={formData.tenantId}
                                            onChange={handleChange}
                                            className="form-control custom-form custom-dropdown font-14 font-medium py-3 black-v2"
                                            placeholder="Enter Tenant ID" />
                                    </div>

                                    <div className="col-md-6 mb-4 px-2">
                                        <label className="form-label font-14 font-medium black-v2" htmlFor="subscriptionId">Subscription ID <span className="danger-red">*</span></label>
                                        <input type="text"
                                            id="subscriptionId"
                                            name="subscriptionId"
                                            value={formData.subscriptionId}
                                            onChange={handleChange}
                                            className= "form-control custom-form custom-dropdown font-14 font-medium py-3 black-v2"
                                            placeholder= "Enter Subscription ID" />
                                    </div>

                                    <div className ="col-md-6 mb-4 px-2">
                                        <label className ="form-label font-14 font-medium black-v2" htmlFor ="clientId">Client ID <span className ="danger-red">*</span></label>
                                        <input type ="text"
                                            id ="clientId"
                                            name ="clientId"
                                            value ={formData.clientId}
                                            onChange ={handleChange}
                                            className ="form-control custom-form custom-dropdown font-14 font-medium py-3 black-v2"
                                            placeholder ="Enter Client ID" />
                                    </div>

                                    <div className ="col-md-6 mb-4 px-2">
                                        <label className ="form-label font-14 font-medium black-v2" htmlFor ="clientSecret">Client Secret <span className ="danger-red">*</span></label>
                                        <input type ="text"
                                            id ="clientSecret"
                                            name ="clientSecret"
                                            value ={formData.clientSecret}
                                            onChange ={handleChange}
                                            className ="form-control custom-form custom-dropdown font-14 font-medium py-3 black-v2"
                                            placeholder ="Enter Client Secret" />
                                    </div>

                                    <div className ="col-md-6 mb-4 px-2">
                                        <label className ="form-label font-14 font-medium black-v2" htmlFor ="expireDate">Expiration Date <span className ="danger-red">*</span></label>
                                        <input
                type="date"
                id="expireDate"
                name="expireDate"
                value={formData.expireDate}
                onChange={handleChange2}
                min={formatDate(minDate)} // Set minimum date
                max={formatDate(maxDate)} // Set maximum date
                className="form-control custom-dropdown shadow-none py-3 font-14 font-regular black-v2"
            />
                                    </div>

                                    <div className ="col-md-12 mb-4 px-2">
                                        <label className ="form-label font-14 font-medium black-v2">Description </label>
                                        <textarea
                                            rows={3}
                                            placeholder ="Enter Description"
                                            name ="description"
                                            value ={formData.description}
                                            onChange ={handleChange}
                                            id ="description"
                                            className ="form-control custom-form custom-dropdown article-grey-colorarea font-14 font-medium black-v2"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Modal Footer */}
                        <div className ="d-flex justify-content-between align-items-center my-0">
                            <button type ="button" className ="button outline-button px-4 py-12" onClick={onClose}>Cancel</button>
                            <div className="d-flex gap-2">
            {/* Test Connection button */}
            <button 
                type="button" 
                className="button secondary-outline-button px-4 py-12"
                onClick={handleTestConnection}
                disabled={!isFormValid()} // Enable only if all fields are filled
            >
                Test Connection
            </button>
            
            {/* Save Details button */}
            <button 
                type="button" 
                onClick={() => { connectorDetails?.connectorId ? handleUpdateAzureConnector() : handleSaveDetails(); }}
                disabled={!testConnectionSuccessful || !isFormValid()} // Enable only if test connection passed and all fields are filled
                className="btn button primary-button px-4 py-12"
            >
                Save Details
            </button>
        </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
};

export default AzureConnectorModal;