
import React, { useEffect, useState } from 'react';

export default function AWSB2ASideBar(props) {
    const { id, count, serviceName, trackedServieces } = props.configureDetails;
    const [activeTab, setActiveTab] = useState('configure');

    useEffect(() => {
        // Any side effects can be handled here
        console.log(trackedServieces , "Tracked Services")
    }, [props]);

    return (
        <div className="col-md-3 mt-3" style={{position:"absolute", right:0}}>
            <div className="row">
                <div className="col-md-12">
                    <div className="config-container">
                        <p className="mb-3 font-18 font-bold black-v2">Service Details</p>
                        <div className="row">
                            <label className="form-label font-14 font-medium black-v2">
                                Total Count of {serviceName}: {trackedServieces.length}
                            </label>
                            {trackedServieces.map((service, index) => (
                                <div key={index} className="mb-3">
                                    <img 
                                        src={service.service_icon} 
                                        alt={service.name} 
                                        style={{width: '25px', height: '25px', marginRight: '8px'}}
                                    />
                                    <label className="form-label font-14 font-medium black-v2">
                                        {service.name}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}