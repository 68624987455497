// Workbech Import statements
import React, { useMemo, useState, useRef, useCallback, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import * as base64 from "base-64";
import * as htmlToImage from "html-to-image";
// import "../../ComponentCSS/reactflow.css"
// import "../../ComponentCSS/workbench.css"

import {
    ReactFlow,
    ReactFlowProvider,
    Background,
    useNodesState,
    useEdgesState,
    addEdge,
    Controls,
    Handle,
    MarkerType,
    NodeResizer,
    useReactFlow,
    applyNodeChanges,
    applyEdgeChanges

} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import moment from "moment";

import Draggable from "react-draggable";
import ConfigureComponent from "./ConfigureComponent";
import SideBarComponent from "./SideBarComponent";
import CommitPopup from "./PopupMaintanence/CommitPopup";
import VersionHistoryPopUp from "./PopupMaintanence/VersionHistory";
import AccountConfigureComponent from "./AccountConfigureComponent";
import SaveTemplatePopup from "./PopupMaintanence/SaveTemplate";
import { useOrgContext } from "../../../../ContextProvider/OrgContext";
import { aiArchitectureGenerationApi, fetchdriftApi, getAccountDetailsApi, getarchitectureByIDApi, saveArchietctureApi } from "../../../../Service/api";
import ReviewDrifts from "./ReviewDrifts";
import { RegenerateButton } from "./PopupMaintanence/RegenerateButton";
import ToastMessage from "./PopupMaintanence/ToastMessage";
import processAwsNodesFromResponse from "./Helper/ArchitectureAWS";
import processAzureNodesFromResponse from "./Helper/ArchitectureAzure";
import WorkbechPopup from "./PopupMaintanence/WorkBenchPopUp";
import AssignTags from "./PopupMaintanence/AssignTags";
import { FormContext } from "../../Workload_Grid";
import { EstimationContext, useEstimation } from "../../../../ContextProvider/EstimationContext";
import WorkbenchLoader from "../WorkBenchLoader";
import WorkbenchPopupComponent from "./PopupMaintanence/WorkBenchPopUpComponent";





function Workbench() {
    const { 
        orgId, 
        envId,
        setEnvId,
        workloadId,
        providerId,
        providerName,
        envName,
        setEnvName,
        PusleIdentificaiton,
        workloadRole,
        UTI,
        colorMode,
        userRoles
    } = useOrgContext();
    
    const { formData, setFormData } = useContext(FormContext);
    console.log(formData , "FormData")
    const [userId, setuserId] = useState(localStorage.getItem('userId'));
    console.log(providerId , "providerId")
    console.log(providerName , "providerName")
    console.log(orgId , "orgId")
    console.log(userId , "userId")
    console.log(envId , "envId")
    console.log(UTI , "UTI")
    console.log(colorMode , "colorMode")
    

    const {setEstimationNodes} = useContext(EstimationContext)
    
    console.log('Organization ID:', orgId);
    console.log("envId", envId)
    const initialNodes = [];
    const initialEdges = [];
    const reactFlowWrapper = useRef(null);
    const { screenToFlowPosition } = useReactFlow();
    const [nodes, setNodes , onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges , onEdgesChange] = useEdgesState(initialEdges);
    const reactFlowInstance = useReactFlow();
    
    /**
     * Draggable Stack Maintance for the Undo and Redo funcionalites
     */
    const [undoStack, setUndoStack] = useState([]);
    const [redoStack, setRedoStack] = useState([]);
    /**
     * Workbench State Variables 
     */
    const [configureDetails, setConfigureDetails] = useState({ id: "" });

    const [accountStatus, setAccountStatus] = useState({});

    const [stateForCount, setStateForCount] = useState([]);
    const [isCommitModalOpen, setIsCommitModalOpen] = useState(false);
    const [isVersionHistoryModalOpen, setIsVersionHistoryModalOpen] = useState(false);
    const [isSaveTemplateModalOpen, setIsSaveTemplateModalOpen] = useState(false)
    const [templateProviderType, setTemplateProviderType] = useState(providerName ? providerName :"Azure")
    const [connectedSourceNodes, setConnectedSourceNodes] = useState([])
    const [repositories, setRepositories] = useState([]);
    const [selectedEnv , setSelectedEnv] = useState({ 
        environment_id :envId,
        environment_name :envName})
    const navigate = useNavigate();
    const[isToastPopupOpen , setIsToastPopUpOpen] =  useState(false)
    const [istoastMessage , setToastMessage] = useState({success :"" , failure :""})
    const [viewMode,setViewMode] = useState(workloadRole.toLowerCase() == 'viewer' ? true : false)

    // Review Drifts Scenario
    const [reviewDriftsData, setreviewDriftsData]=useState([])
    const [isReviewDriftModelOpen,setIsReviewDriftModelOpen] = useState(false)
    const [reviewDrifts, setReviewDrifts] = useState([      
      ])

    // Regenerate State Variables
    const [regenerationText , setRegenerationText] = useState('')
   
     
    const [preventSaving,setPreventSaving] = useState(false)
    const [imageUrl, setImageUrl] = useState('');
	const [popupMsg, setPopUpMsg] = useState({"Msg":"",show:false,success:false})
    const [showLoader, setShowLoader] = useState(false)
    const [showAssignTags, setShowAssignTags] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteNodeService, setDeleteService] =  useState({})
    const { getNode } = useReactFlow();
    const [deletedNode, setDeletedNode] = useState([]);

    useEffect(()=>{
        console.log("sagin purpose",edges)
    },[edges])
 
    // React Flow Some Times Throws the ResizeObserver loop completed with undelivered notifications" ||
    //"ResizeObserver loop limit exceeded
    //TO Remove that  use this Below Code 
     //  PS_02 -  PS_10 
    // Drift scenario 
    useEffect(()=>{
        if(!envId||!providerId ){
            navigate('/workloadsGrid')
        }
        fetchDrift()
    },[])
    useEffect(() => {
        const errorHandler = (e) => {
            if (
                e.message.includes(
                "ResizeObserver loop completed with undelivered notifications" ||
                    "ResizeObserver loop limit exceeded"
                )
            ) {
                const resizeObserverErr = document.getElementById(
                "webpack-dev-server-client-overlay"
                );
                if (resizeObserverErr) {
                resizeObserverErr.style.display = "none";
                }
            }
            };
            window.addEventListener("error", errorHandler);
        
            return () => {
            window.removeEventListener("error", errorHandler);
            };
    }, []);
    // Workbench Functionalities 

    // UseEffect 
    useEffect(()=>{
        
        getArchitectureById()
        fetchDrift()
       
    },[envId])
    useEffect(()=>{
        fetchAccountDetails()
    },[providerId])
    // Change of Architecture when the user Switches from Once Architecture to Another
    useEffect(()=>{
        if(selectedEnv.environment_id != envId 
                && 
                selectedEnv.environment_id
                ){
                setEnvId(selectedEnv.environment_id)
                setEnvName(selectedEnv.environment_name)
        }
    },[selectedEnv])
    useEffect(()=>{
        if(preventSaving ==false){
        saveArchitecture()
        
        let estimationNodes = nodes.filter((val) => val.data.serviceName !== "Account");
        setEstimationNodes(estimationNodes)
        }
        
    },[nodes, edges])
    
    /**
     * Save Architecture Function Which is Triggered on the every action Performed in the Workbench allowing 
     * the user to save all the architecture Drafted 
    */
    async function saveArchitecture(params) {
    try {
        if(viewMode == false){
        console.log("Save Architecture is Triggered")
        console.log(nodes , "Nodesss")
        let nodeData = {
               nodes,
               edges
              };
        console.log(nodeData , "NodeData")
        if(nodeData["nodes"].length!=0){
            let payload  = {
                userId :userId,
                orgId:orgId,
                envId: envId,
                xml:JSON.stringify(nodeData)
            }
            console.log(payload, "Payload in Save Architecture")
            let res  = await saveArchietctureApi(payload)
            console.log(res ,"Response from Save architecture")
            if(params == 0) {
                console.log("Saveed Arch")
            }
        } 
        }
       
    }
    catch(error) {
        console.log(error)
    }}
      
    /**
     * Get Architecture By Id Function Triggered Once the user is Navigated to Workbench 
    */
    async function getArchitectureById() {
        try {
            console.log("Triggering the GetArchitectureByID");
            if (envId !== "") {
                let payload = {
                    userId,
                    orgId,
                    envId,
                };
                try {
                    console.log(payload, "in Get Architecture");
                    // if (isReviewDriftModelOpen == false) {
                    //     setShowLoader(true);
                    // }
                    setShowLoader(true);
                    let jsonData = await getarchitectureByIDApi(payload);
                    setShowLoader(false);
                    console.log(jsonData, "JSON Data");
                    if (jsonData.success) {
                        let parsedJson = JSON.parse(jsonData.data);
                        console.log("parsedjson", parsedJson);
    
                        
                        // Replace this part in your code
                        const processedNodes = parsedJson.nodes.map(node => {
                            console.log(node, "Node");
                        
                            const nodeElement = createNodeElement(node);
                            console.log(nodeElement, "Node Element");
                        
                            // Check if UTI tag already exists To avaoid Duplications this scenario 
                            // This scenario is maily Handled for if a architecture is been Drafted in th B2A and Cloned here 
                            // That architecture will not have the UTI associated with that
                            console.log(node.data.Tags , 'Tags before')
                            let updatedTags = (node.data.Tags || []).map(tag => {
                                if (tag.key === UTI.Key) {
                                    // If UTI key exists, update its value
                                    return { ...tag, value: UTI.Value };
                                }
                                return tag;
                            });
                            
                            // If no UTI tag was found and updated, add a new one
                            if (!updatedTags.some(tag => tag.key === UTI.Key)) {
                                updatedTags.push({ key: UTI.Key, value: UTI.Value });
                            }
                            console.log(updatedTags , "Updatedd Tags ")
                            
                            // While Cloning Sometimes the Service Family might not be retrieved 
                            let serviceFamily = node.data.serviceFamily ? node.data.serviceFamily : "Other"
                        
                            return {
                                ...node,
                                type: nodeElement.type,
                                style: nodeElement.style,
                                data: {
                                    ...node.data,
                                    element: nodeElement.element,
                                    Tags: updatedTags,
                                    serviceFamily: serviceFamily
                                },
                            };
                        });
                            // Set State the Nodes
                        setNodes(processedNodes);
                        setEdges(parsedJson.edges);
                        
                    } else {
                        console.log(formData , "FormData Here to Initate AI")
                        if(formData.createArchitectureWithAI 
                            // && jsonData.message == 
                            // "Environment not found, inactive, or has no architecture data"
                        ){
                        aiArchitectureGeneration()
                        }else{
                        console.error("Failed to fetch architecture data");
                        if(jsonData.message == "Environment not found, inactive, or has no architecture data"){
                            setNodes([])
                        }
                        }
                    }
                } catch (error) {
                    console.error("Error in getArchitectureById:", error);
                }
            } else {
                console.error("envId is empty");
            }
        } catch (err) {
            console.log(err, "Error in the Get Architecture");
        }
    }
    /** AI Architecture Retrival */
    // UseEffect 
    useEffect(()=>{
        if(regenerationText!= ""){
            aiArchitectureGeneration()
        }
    },[regenerationText])
    /** 
     * aiArchitectureGeneration Function Triggers the AI Archiecture Endpoint
     * 
    */
    async function aiArchitectureGeneration() {
     try {
        let payload = {
            userId : userId ,
            orgId :orgId ,
            cloudProviderId :providerId, 
            cloudProviderName :providerName ,
            cloudProvider :providerName ,
            language : formData.language?formData.language :"Terraform",
            business :formData.business,
            arch_type :formData.architectureType,
            architectureType :formData.architectureType,
            rulebook : formData.rulebook,
            archDesc:formData.describeArchitecture,
            describeArchitecture:formData.describeArchitecture,
            services:formData.services,
            regenerationText :regenerationText, 
            nodes : nodes

        }
        // let payload  ={
        //     "userId": "79cf5603-d871-421a-8bdf-5bb8e1a24a4f",
        //     "orgId": "28da4a71-14f5-47ce-a349-35840ebf2dcb",
        //     "cloudProviderId": "0a5816fa-5f85-4fd9-b716-62e4395c7b4a",
        //     "cloudProviderName": "AWS",
        //     "language": "terraform",
        //     "business": "Its distribution network in 1997 with two fulfillment centers in Seattle and New Castle, Delaware. Amazon has several types of distribution facilities consisting of cross-dock centers, fulfillment centers, sortation centers, delivery stations, Prime now hubs, and Prime air hubs. There are 75 fulfillment centers and 25 sortation centers with over 125,000 employees.",
        //     "arch_type": "EKS",
        //     "rulebook": [
        //       {
        //         "categoryId": "ccatId",
        //         "catehgoryName": "name",
        //         "ruleName": [
        //           {
        //             "ruleId": "124",
        //             "rule": "rule"
        //           }
        //         ]
        //       }
        //     ],
        //     "archDesc": "Create a architecture with Lambdas count 10 and Pods of count 3 for the large data handling purpose. It shoud be a boxed architecture over 309 services handling worldwide.Its distribution network in 1997 with two fulfillment centers in Seattle and New Castle, Delaware. Amazon has several types of distribution facilities consisting of cross-dock centers, fulfillment centers, sortation centers, delivery stations",
        //     "services": [
        //       {
        //         "categoryId": "ccatId",
        //         "catehgoryName": "name",
        //         "service": [
        //           {
        //             "serviceId": "124",
        //             "serviceName": "rule"
        //           }
        //         ]
        //       }
        //     ],
        //     "regenerate":regenerationText
        //   }
        console.log("aiArchitectureGeneration Triggers" , payload)

        // if(isReviewDriftModelOpen == false){
        //     setShowLoader(true)
        // }
        setShowLoader(true)
        let res = await aiArchitectureGenerationApi(payload)
        console.log(res , "Responseseee from AI Architecture")
        setShowLoader(false)
        if(res.success){
            if(typeof(res.data) == "string"){
                res.data = JSON.parse(res.data)
            }
            processAIArchitecture(res.data)
        }
        else{
			setPopUpMsg({...popupMsg , ["Msg"]:"Architecture Generation Failed" , ["show"]:true ,["success"]:false })
        }
        return true
    } catch (error) {
        console.log(error , "Error")    
        setShowLoader(false)
		setPopUpMsg({...popupMsg , ["Msg"]:"Architecture Generation Failed" , ["show"]:true ,["success"]:false })


    }
        
    }
    // processAIArchitecture Function is used to Make a API Call to Retreive the AI generated Node
    const processAIArchitecture = (res) => {
          console.log("Processing AI architecture" , res);
          try {
            let dynamicNodes = [];
            if (providerName == 'Azure') {
              dynamicNodes = processAzureNodesFromResponse(res);
            } else {
              // Default to AWS if not Azure
              dynamicNodes = processAwsNodesFromResponse(res);
            }
            setNodes(dynamicNodes);
            
          } catch (error) {
            console.error('Error processing AI architecture:', error);
            // Handle error (e.g., set default nodes or show error message)
          }
      };
    // Helper function to create the node element
    function createNodeElement(node) {
        // Verify it Belongs to the Service Name 
      let isLocation = ["Account",
         "Region",
         "VPC", 
        "Availability_Zone",
        "Availability Zone",
         "Subnet",
         "Security_group" , 
        "Resource_Group" , 
        "VNET"].includes(node.data.serviceName);

        if(node.type == "location"){
            isLocation = true
        }
      let nodeStyle = {
        position: "absolute",
        zIndex: node.data.serviceName === "VPC" ? "-100000" : "100010",
      };
    
      if (isLocation) {
        const zIndexMap = {
          "Account": "-100005",
          "Region": "-100004",
          "VPC": "-100003",
          "Availability_Zone": "-100002",
          "Availability Zone": "-100002",
          "Subnet": "-100001",
          "Security_group": "-100000"
        };
    
        const dimensionsMap = {
          "Account": 400,
          "Region": 350,
          "VPC": 300,
          "Availability_Zone": 250,
          "Availability Zone": 250,
          "Subnet": 200,
          "Security_group": 150
        };
        let styleData =  node.style
        nodeStyle = {
          ...nodeStyle,
          ...styleData
        };
      }
    
      const imageClassName = isLocation ? "vpcImageStyle" : "img";
    
      return {
        element: (
            <div className="container-hover"
            onMouseEnter={(e) => {
                // Show tooltip
                const tooltip = document.createElement('div');
                tooltip.innerHTML = `<strong>${node.data.serviceName}</strong><br>${node.data.description}`;
                tooltip.style.position = 'fixed';
                tooltip.style.top = `${e.clientY + 10}px`; // Position below cursor
                tooltip.style.left = `${e.clientX + 10}px`; // Position right of cursor
                tooltip.style.backgroundColor = 'black';
                tooltip.style.color = 'grey';
                tooltip.style.padding = '8px';
                tooltip.style.borderRadius = '4px';
                tooltip.style.zIndex = '100';
                tooltip.style.whiteSpace = 'pre-wrap';
                tooltip.style.maxWidth = '200px'; // Adjust as needed
                tooltip.className = 'custom-tooltip'; // Add class for additional styling
                document.body.appendChild(tooltip);
              }}
              onMouseLeave={() => {
                // Hide tooltip
                const tooltips = document.getElementsByClassName('custom-tooltip');
                while (tooltips.length > 0) {
                  tooltips[0].parentNode.removeChild(tooltips[0]);
                }
              }}
            >
              <img
                id={node.id}
                width={25}
                height={25}
                src={node.data.imageName}
                className={imageClassName}
                alt="service-logo"
                onError={(e) => {
                    e.target.onerror = null; // Prevents looping
                    e.target.src = providerName == "Azure" 
                        ? "./images/Workbench/AIArchitecture/Azure/AzureLogo.svg"
                        : "./images/Workbench/AIArchitecture/AWS/aws-logo.svg";
                }}
                
              />
            </div>
        ),
        style: nodeStyle,
        type: isLocation ? "location" : "service"
      };
    }

    /**
     * ^^^^^^^^^^^^ Node Funtionalities Handler Start  ^^^^^^^^^^^^^^^^^^^
     */
  
    /**
     * SerivceBind function  is to render the node in service node custom Type
     */
    
    const serviceBind = ({ data, id }) => {
        return (
            <div>
                {/* Source handles */}
                <span id={id + "1Target"}>
                    <Handle id={`${id}leftTarget`} type="target" position="left" />
                    <Handle id={`${id}topTarget`} type="target" position="top" />
                </span>
                <span id={id + "1Source"}>
                    <Handle id={`${id}leftSource`} type="source" position="left" />
                    <Handle id={`${id}topSource`} type="source" position="top" />
                </span>

               
                {/* Rest of your component */}
                <div style={{ position: "relative", margin: "-1px 0px 0px 0px" }}>
                    {data.element}
                </div>
                <label
                    style={{ position: "absolute", color: colorMode === "dark" ? "#FFFFFF" : "black" }}
                    className="nodeLabel"
                    id={id + "@nodeLabel"}
                >
                    {data.imageServiceName}
                </label>
                <span id={id + "2Target"}>
                    <Handle id={`${id}rightTarget`} type="target" position="right" />
                    <Handle id={`${id}bottomTarget`} type="target" position="bottom" />
                </span>
                <span id={id + "2Source"}>
                    <Handle id={`${id}rightSource`} type="source" position="right" />
                    <Handle id={`${id}bottomSource`} type="source" position="bottom" />
                </span>
               
            </div>
        );
    };

    /**
    * LocationBind function  is to render the node in service node custom Type
     */
    const locationBind = ({ data, id }) => {
        console.log(data, id, "Here")
        return (
            <>
                <NodeResizer style={{ "border": 'unset'}} />
                <div className={`locationMain ${id}`}>
                    <div
                        className="container-hover"
                        id={id + "@location"}
                        style={{
                            display: "flex",
                            padding: "11px 10px",
                        }}
                    >
                        {data.element}
                        <label
                            className="locationLabel"
                            id={id + "@nodeLabel"}
                            style={{ color: colorMode === "dark" ? "#FFFFFF" : "black" }}
                        >
                            {data.imageServiceName}
                        </label>
                    </div>
                </div>
            </>
        );
    };

    /**
     * PC_WB_10
     */
    const nodeTypes = useMemo(
        () => ({
            service: serviceBind,
            location: (props) => locationBind(props, nodes),
        }),
        []
    );

    /**
     * Edges Connection is Handled Below 
     * Handled the Step Connection of the edges 
     */
    // const onConnect = (params) => {
    //     // if (viewMode != true) {
    //         console.log(params, "Params in Edges");
    //         let edgeStyle = {
    //             id: params.source + params.sourceHandle + params.target + params.targetHandle + Math.random(),
    //             sourceHandle: params.sourceHandle,
    //             targetHandle: params.targetHandle,
    //             source: params.source,
    //             target: params.target,
    //             configure: {},
    //             type: 'step',
    //             labelBgBorderRadius: 4,
    //             labelBgStyle: { color: '#fff', fillOpacity: 0.7 },
    //             markerEnd: {
    //                 type: MarkerType.ArrowClosed,
    //             },
    //         }
    //         setEdges([...edges, edgeStyle])
            
    //         // setLatestChange((data) => data.concat("0"))
    //         //console.log(latestChange);
    //     // }
    //     saveArchitecture()
    // }; 

    
    // const onConnect = useCallback((params) => {
    //     if (viewMode != true) {
    //     console.log(params, "Params in Edges");
        
    //     // Ensure the source and target are in the correct order
    //     const { source, target, sourceHandle, targetHandle } = params;
        
    //     let edgeStyle = {
    //         id: `${source}${sourceHandle}${target}${targetHandle}${Math.random()}`,
    //         source,
    //         target,
    //         targetHandle,
    //         sourceHandle,
    //         configure: {},
    //         type: 'step',
    //         labelBgBorderRadius: 4,
    //         labelBgStyle: { color: '#fff', fillOpacity: 0.7 },
    //         markerEnd: {
    //             type: MarkerType.ArrowClosed,
    //         },
    //     };
        
    //     setEdges((prevEdges) => [...prevEdges, edgeStyle]);
    // }
    
        
    // }, [setEdges]);

    
    
    const onConnect = useCallback((params) => {
        if (viewMode !== true) {
            console.log(params, "Params in Edges");
    
            let { source, target, sourceHandle, targetHandle } = params;
            console.log(source  , target  , "Source & Target")
            console.log(sourceHandle  , targetHandle  , "hande;s")

            // No need to swap, as the handle types are now clearly defined
            const edgeStyle = {
                id: `${source}${sourceHandle}${target}${targetHandle}${Math.random()}`,
                source,
                target,
                sourceHandle,
                targetHandle,
                configure: {},
                type: 'step',
                labelBgBorderRadius: 4,
                labelBgStyle: { color: '#fff', fillOpacity: 0.7 },
                markerEnd: {
                    type: MarkerType.ArrowClosed,
                },
            };
    
            setEdges((prevEdges) => [...prevEdges, edgeStyle]);
        }
    }, [viewMode]);

     useEffect(()=>{
            console.log(edges , "Edgesss")
        },[edges])
    

    /**
     * PC_WB_09
     * onDragover function will be called when the user drags the nodes over the workbench area;
     */
    const onDragOver = useCallback((event) => {
        console.log("Drag Over Triggered ")
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
                                            

    }, []);
    /** Handling of Node Delete Scenario  */
    
    const onNodesDelete = useCallback((deletedNodes) => {
        console.log(deletedNodes, "Node");

        // Remove the Tool Tip 
        const tooltips = document.getElementsByClassName('custom-tooltip');
        while (tooltips.length > 0) {
            tooltips[0].parentNode.removeChild(tooltips[0]);
        }

        deletedNodes.forEach(node => {
            if (node.data.serviceName != "Account") {
                const nodeType = node.data.ConfigureDetail.subServieConfig.type;
                console.log(nodeType, "NodeType")
                if (nodeType === "create") {
                    console.log("Normal Node is Deleted");
                    setDeletedNode(prevDeletedNodes => Array.isArray(prevDeletedNodes) ? [...prevDeletedNodes, node] : [node]);
                    pushToUndoStack({ type: 'deleteNode', node });
                } else if (nodeType === "update" || nodeType === "delete") {
                    setDeleteService(node);
                    setShowDeleteModal(true);
                }
            } else {
                setDeletedNode(prevDeletedNodes => Array.isArray(prevDeletedNodes) ? [...prevDeletedNodes, node] : [node]);
                pushToUndoStack({ type: 'deleteNode', node });
            }
        });

        // Update nodes and edges
        setNodes((nds) => {
            const deletedNodeIds = deletedNodes.map(node => node.id);
            const newNodes = nds.filter(node => !deletedNodeIds.includes(node.id));
            pushToUndoStack(newNodes, edges);
            return newNodes;
        });

        setEdges((eds) => {
            const deletedNodeIds = deletedNodes.map(node => node.id);
            const newEdges = eds.filter(edge => !deletedNodeIds.includes(edge.source) && !deletedNodeIds.includes(edge.target));
            pushToUndoStack(nodes, newEdges);
            return newEdges;
        });
    }, [nodes, edges]);
    /**
     * Function Which Converts the S3 Image to an base64 image 
     * 
     */
    // Function to convert image to base64
    function imageToBase64(img) {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        return canvas.toDataURL();
    }

    // Function to load image and convert to base64
    function loadImageAsBase64(url) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = "anonymous";  // This is important for CORS
            img.onload = () => resolve(imageToBase64(img));
            img.onerror = (err) => reject(err);
            img.src = url;
        });
    }
    /**
     * PC_WB_08
     * the onDrop function will be called when the user drops a node in the wokbench;
     */
    
    const onDrop = useCallback(
    (event) => {
        
        event.preventDefault();
        if(viewMode == false){
        let serviceData;
        // Log the data being transferred
        const jsonData = event.dataTransfer.getData('application/json');
        const childName = event.dataTransfer.getData('application/childName');
        console.log("Data from transfer:", { jsonData, childName });

        // Attempt to parse JSON data
        if (jsonData) {
        try {
            serviceData = JSON.parse(jsonData);
            console.log("Parsed serviceData:", serviceData);
        } catch (error) {
            console.log("JSON parsing failed:", error);
        }
        }

        // If no serviceData was parsed, check for Account node
        if (!serviceData && childName === 'Account') {
        console.log("Account node detected");
        serviceData = {
            id: 'account-node-' + Math.random().toString(36),
            childName: 'Account',
            parentName: 'Account',
            nodeType: 'location',
            imageName: './images/Workbench/ServiceImages/AWS/Security/AWS-Organizations_Account_light-bg.png',
            serviceName: 'Account',
            containsService: true,
        };
        console.log("Created Account serviceData:", serviceData);
        } else if (!serviceData) {
        console.log("No valid service data found in the drop event");
        return; // Early exit if no valid data
        }

        // Calculate drop position
        const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
        });
        console.log("Calculated position:", position);

        let nodeDataForCount = [...stateForCount];
        let countForLabel = nodeDataForCount.filter(data => data === serviceData.parentName).length + 1;
        let labelName = `${serviceData.parentName} ${countForLabel}`;
        let imageServiceName = `${serviceData.serviceName}_${countForLabel}`

        setStateForCount(prev => [...prev, serviceData.parentName]);

        const isAccount = serviceData.serviceName === "Account";
        
        // Determine node identity
        let nodeIdentity = serviceData.containsService ? "location" : "service";
        
        let htmlElement = "";
        let nodeStyle = {
        position: "absolute",
        zIndex: serviceData.serviceName === "vpc" ? "-100000" : "100010",
        };

        // Set node style based on service name location Binding Purposes
        if (["Account", "Region", "VPC",
             "Availability_Zone",
             "Availability Zone",
             "Subnet", "Security_group" , "Resource_Group" , "Virtual Network" , "Resource Group"].includes(serviceData.serviceName)) {
        const zIndexMap = {
            "Account": "-100005",
            "Region": "-100004",
            "VPC": "-100003",
            "Availability_Zone": "-100002",
            "Availability Zone": "-100002",
            "Subnet": "-100001",
            "Security_group": "-100000" ,
            "Virtual Network" :"-100002",
            "Resource Group" :"-100003"
        };

        const dimensionsMap = {
            "Account": 400,
            "Region": 350,
            "VPC": 300,
            "Availability_Zone": 250,
            "Availability Zone": 250,
            "Subnet": 200,
            "Security_group": 150,
            "Virtual Network": 300,
             "Resource Group" : 350
        };

        nodeStyle = {
            position: "absolute",
            zIndex: zIndexMap[serviceData.serviceName] || "100010",
            width: dimensionsMap[serviceData.serviceName],
            height: dimensionsMap[serviceData.serviceName],
            className: "resizeLoop",
            color: getColorForService(serviceData.serviceName),
            border: "1px solid",
            borderStyle: "dashed",
        };
        nodeIdentity = "location"
        }

        // Set HTML element based on node identity
        switch (nodeIdentity) {
    
        default:
            htmlElement = (
            <div>
                <div className="container-hover"
                 onMouseEnter={(e) => {
                    // Show tooltip
                    const tooltip = document.createElement('div');
                    tooltip.innerHTML = `<strong>${serviceData.serviceName}</strong><br>${serviceData.serviceDR}`;
                    tooltip.style.position = 'fixed';
                    tooltip.style.top = `${e.clientY + 10}px`; // Position below cursor
                    tooltip.style.left = `${e.clientX + 10}px`; // Position right of cursor
                    tooltip.style.backgroundColor = 'black';
                    tooltip.style.color = 'grey';
                    tooltip.style.padding = '8px';
                    tooltip.style.borderRadius = '4px';
                    tooltip.style.zIndex = '100';
                    tooltip.style.whiteSpace = 'pre-wrap';
                    tooltip.style.maxWidth = '200px'; // Adjust as needed
                    tooltip.className = 'custom-tooltip'; // Add class for additional styling
                    document.body.appendChild(tooltip);
                  }}
                  onMouseLeave={() => {
                    // Hide tooltip
                    const tooltips = document.getElementsByClassName('custom-tooltip');
                    while (tooltips.length > 0) {
                      tooltips[0].parentNode.removeChild(tooltips[0]);
                    }
                  }}
                
                >
             
                <img
                    id={serviceData.id}
                    width={25}
                    height={25}
                    src={`${serviceData.imageName}`}
                    className={["VPC", "Account", "Availability_Zone", "Region", "Subnet", "Security_group"].includes(serviceData.serviceName) ? "vpcImageStyle" : "img"}
                    alt="service-logo"
                />
                </div>
            </div>
            );
            break;
        }
        // Construct the new node
        // if(serviceData.imageName.test(s3UrlRegex))
        // {
            // serviceData.imageName = loadImageAsBase64(serviceData.imageName)
        // }
        
        const newNode = {
          id: serviceData.id,
          type: nodeIdentity,
          position: position,
          style: nodeStyle,
          data: {
            element: htmlElement,
            resourceName: isAccount ? isAccount : labelName,
            serviceName: serviceData.serviceName,
            imageName: serviceData.imageName,
            imageServiceName: imageServiceName,
            instanceName:imageServiceName,

            fields: serviceData.fields || [],
            serviceFamily : serviceData.serviceFamily,
            ConfigureDetail: {
              subServieConfig: {
                Action: {
                  create: {},
                  delete: {},
                  update: {}
                },
                type: "create"
              }
            },
            description: serviceData.description || '',
            children: isAccount ? [] : undefined,
            repositoryData: {
              repository_name: "",
              repository_id: "",
              branchData: {
                branch_id: "",
                branch_name: ""
              }
            },
            Tags: [
              {
                "key": "cloudgen",
                "value": true,
                defaultTag: true
              },
              ...(UTI["Key"] && UTI["Value"] !== "" ? [
                {
                  "key": UTI["Key"],
                  "value": UTI["Value"],
                  defaultTag: true
                }
              ] : [])
            ]
          },
          instanceName:imageServiceName
        };
        if(serviceData.serviceName == "Account"){
        // Account Detials Data Maintance 
        newNode["data"]["ConfigureDetail"] = {
            accountId :"",
            accountName:"",
            cloudproviderId:"",
            permanentAccountId:""
        }
        }
        if(serviceData.serviceName == "Resource_Group" && providerName == "Azure"){
            newNode["data"]["Resource_Group"] = {
                    "Name":""
                }
        }
        setPreventSaving(false)
        // Update nodes state
        setNodes((nds) => {
        const newNodes = nds.concat(newNode);
        pushToUndoStack(newNodes, edges);
        return newNodes;
        });
    }
    },
    [reactFlowInstance, stateForCount, accountStatus, edges]
    );
    useEffect(()=>{
        console.log(nodes , "Nodesss")
    },[nodes])
    // Helper function to get color for service
    function getColorForService(serviceName) {
    const colorMap = {
        "VPC": "#8C4FFF",
        "Account": "#CD2264",
        "Availability_Zone": "#6610f2",
        "Region": "#00A4A6",
        "Subnet": "#0d6efd",
        "Security_group": "#DD3522"
    };
    return colorMap[serviceName] || "#000000";
    }
      


    /**
     * PC_WB_12
     * the following function will be called when the user double click on the
     * Node
     */
    
    const doubleClickLabel = (event, node) => {
        console.log("Node double-clicked:", node);
        console.log("Node type:", node.type);
        console.log("Node resourceName:", node.data.resourceName);
        setConfigureDetails(node);
        console.log("Edges in Double Clik", edges);
        // Find connected source nodes
        const connectedSourceNodes = edges.reduce((acc, edge) => {
            if (edge.target === node.id) {
                const sourceNode = nodes.find(n => n.id === edge.source);
                if (sourceNode) {
                    acc.push(sourceNode);
                }
            }
            return acc;
        }, []);
    
        console.log("Connected source nodes:", connectedSourceNodes);
        setConnectedSourceNodes(connectedSourceNodes)
    
        // You can do something with the connectedSourceNodes here,
        // such as setting them in state or passing them to another function
    }


    /**
     * ^^^^^^^^^^^^ Node Funtionalities Handler End  ^^^^^^^^^^^^^^^^^^^
     */
    /**
     * ^^^^^^^^^^^^ Draggable Funtionalities Handler Started  ^^^^^^^^^^^^^^^^^^^
     */
    const pushToUndoStack = (newNodes, newEdges) => {
        setUndoStack((prev) => [...prev, { nodes: newNodes, edges: newEdges }]);
        setRedoStack([]); // Clear redo stack on new action
    };

    // PS_03 -  PS_09 
    // 
    const unDoFunction = () => {
        if (undoStack.length === 0) return;

        const lastState = undoStack[undoStack.length - 1];
        // PS_04  Redo Stack Set state
        setRedoStack((prev) => [...prev, { nodes, edges }]);
        setNodes(lastState.nodes);
        setEdges(lastState.edges);
        setUndoStack((prev) => prev.slice(0, -1));
        
    };
    // PS_10-  PS_17   Redo Function in the Draggable
    const reDoFunction = () => {
        if (redoStack.length === 0) return;

        const lastState = redoStack[redoStack.length - 1];
        setUndoStack((prev) => [...prev, { nodes, edges }]);
        setNodes(lastState.nodes);
        setEdges(lastState.edges);
        setRedoStack((prev) => prev.slice(0, -1));
    };

    /**
     * PS_62 - PS_66  XML Donwload Functionality
     */
    function XMLDownload() {
        //  PS_64  - Node Data formation 
        let nodeData = {
            nodes: nodes,
            edges: edges,
        };

        let stringifyNode = JSON.stringify(nodeData);
        stringifyNode = stringifyNode.replace(/&/g, "#@#@#");
        let architecutureName = "Test"
        let provider = "AWS"
        let technology = "Terraform"
        download(
            `${architecutureName}.xml`,
            `<?xml version="1.0" encoding="UTF-8" ?><cloudgen><${provider + "-" + technology
            }>` +
            stringifyNode +
            `</${provider + "-" + technology}></cloudgen>`
        );
    }

    /** 
     * XML Download Function 
    */
    function download(filename, text) {
        //console.log("xmlText", text);

        var element = document.createElement("a");
        element.setAttribute(
            "href",
            "data:text/plain;charset=utf-8," + encodeURIComponent(text)
        );
        element.setAttribute("download", filename);

        element.style.display = "none";
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }
    // PS_72 
    // DownLoadImage Function Download the Architecture Image in the specifed format 
    
    async function DownLoadImage(type) {
        try {
            document.getElementById("fittoscreen").click();

            const architectureElement = document.getElementById("myArchitecture");
            architectureElement.style.backgroundColor = "white";

            let dataUrl;
            if (type === "Jpeg") {
                dataUrl = await htmlToImage.toJpeg(architectureElement);
            } else if (type === "Png") {
                // Convert the Entire architecture ot dataURl
                dataUrl = await htmlToImage.toPng(architectureElement);
              
            } else {
                throw new Error("Unsupported image type");
            }

            const link = document.createElement("a");
            link.download = `${envName ? envName : providerName ? providerName :"image"}.${type.toLowerCase()}`;
            link.href = dataUrl;
            link.click();

            setTimeout(() => {
                architectureElement.style.backgroundColor = "transparent";
            }, 3000);
        } catch (error) {
            console.log(`Error in downloadImage: ${error}`);
        }
    }
    // const S3_BUCKET_NAME = process.env.REACT_APP_S3_BUCKET_NAME;
    // const S3_REGION = process.env.REACT_APP_S3_REGION;
    // Fetch ImageFromS3
    // useEffect(() => {
    //     fetchImageFromS3();
    // }, []);
    // const fetchImageFromS3 = async () => {
    //     const s3Client = new S3Client({ region: S3_REGION });
    //     const command = new GetObjectCommand({
    //         Bucket: S3_BUCKET_NAME,
    //         Key: 'organization/Avasoft/Avasoft.png'
    //     });

    //     try {
    //         const response = await s3Client.send(command);
    //         const blob = await response.Body.blob();
    //         const url = URL.createObjectURL(blob);
    //         setImageUrl(url);
    //     } catch (error) {
    //         console.error('Error fetching image from S3:', error);
    //     }
    // };
     /** 
     * PS_78 -  PS_82 
     *  viewCommitHistory is Triggered When the user Performs the Commit Action in the Workbench 
     */
     function viewCommitHistory(nodes){
        let parsedJson = JSON.parse(nodes)
        // Process nodes
        const processedNodes = parsedJson.nodes.map(node => {
            const nodeElement = createNodeElement(node);
            return {
              ...node,
              type: nodeElement.type,
              style: nodeElement.style,
              data: {
                ...node.data,
                element: nodeElement.element,
              },
            };
          });
          console.log(processedNodes , "Processed Nodes")
          pushToUndoStack(processedNodes, parsedJson.edges);
          setPreventSaving(true)
          setNodes(processedNodes);
          setEdges(parsedJson.edges); 
          

    }
    /** 
     *  PS_83  - PS_87  
     *  Restore the Commit 
    */
    function restoreCommitHistory(nodes){
        let parsedJson = JSON.parse(nodes)
        // Process nodes
        const processedNodes = parsedJson.nodes.map(node => {
            const nodeElement = createNodeElement(node);
            return {
              ...node,
              type: nodeElement.type,
              style: nodeElement.style,
              data: {
                ...node.data,
                element: nodeElement.element,
              },
            };
          });
          console.log(processedNodes , "Processed Nodes")
          pushToUndoStack(processedNodes, parsedJson.edges);
          setNodes(processedNodes);
          setEdges(parsedJson.edges); 
    }

    // Draggable Functionality ends here


    // Workbench Functionalites 
    /**
     * Label Submit updates the Node During the 
     * user Provided Configuration
     * Which Save the Architecture on the realtime Basis
    */

    function labelSubmit(configureValues) {
       
        const updatedNodes = nodes.map(node => {
            if (node.id === configureValues.id) {
              return { ...node, data: configureValues.data };
            }
            return node;
          });
        console.log(updatedNodes , "updated Nodes!")
        setNodes(updatedNodes); // 
    }
    /**
     * Assign Tags Update which Iterates Through Node and Update the Node
     */
    
    function assignTagsUpdate(tagsValues) {
      let updatedNodes = nodes.map(node => {
        // Get existing tags or initialize an empty array
        const existingTags = node.data.Tags || [];
    
        // Create a new tags array
        const updatedTags = [...existingTags];
    
        // Update or add new tags
        tagsValues.forEach(newTag => {
          const existingTagIndex = updatedTags.findIndex(tag => tag.key === newTag.key);
          if (existingTagIndex !== -1) {
            // Update existing tag
            updatedTags[existingTagIndex] = {
              ...updatedTags[existingTagIndex],
              ...newTag,
              defaultTag: newTag.defaultTag || false
            };
          } else {
            // Add new tag
            updatedTags.push({
              ...newTag,
              defaultTag: newTag.defaultTag || false
            });
          }
        });
    
        // Return updated node
        return {
          ...node,
          data: {
            ...node.data,
            Tags: updatedTags
          }
        };
      });
    
      setNodes(updatedNodes);
      setShowAssignTags(false); 

      
    }

   
    // Drift 

    // Node Delete Scenario 
    const nodeDeleteState = () => {
        let tobedeltedNode = { ...deleteNodeService }
        console.log(tobedeltedNode, "SelectedDeleteNode")
        let node = [...nodes]
        console.log(node, "here")
        // Handling Last Case Scenario in Node !!s
        tobedeltedNode.data.ConfigureDetail.subServieConfig.Action.delete = tobedeltedNode.data.ConfigureDetail.subServieConfig.Action.update
        tobedeltedNode.data.ConfigureDetail.subServieConfig.type = "delete"
        // tobedeltedNode.data.ConfigureDetail.subServieConfig.Action.delete.DeployStatus = true
        // After Yes Clicked 
        node.push(tobedeltedNode)
        console.log(node, "nodeCheck for Deltion")
        setNodes(node)
        saveArchitecture()
    
      }
    async function fetchDrift(){
        try {
            
       
            // let payload = {
            //     "organizationId":"28da4a71-14f5-47ce-a349-35840ebf2dcb",
            //      "userId":"7fdee781-f37a-423b-b504-0b9c15b687fa",
            //      "envId":"935e8980-1f12-4306-a77f-5a4c3698850c",
            //     //  "language":formData.language ? formData.language :"Terraform"
            //      "language":"Terraform"
            //  }
            // PS_04  - Payload for the Drift 
            let payload = {
                "organizationId":orgId,
                 "userId":userId,
                 "envId":envId,
                 "language":formData.language ? formData.language :"Terraform"
             }
            // setIsReviewDriftModelOpen(true)
            console.log(payload , "payload in Driftss")
            let res = await fetchdriftApi(payload)
            console.log(res , "Responses from Drifts")
            // let res = {
            //     "data": [
            //         {
            //             "functions": [
            //                 {
            //                     "configurations": [
            //                         {
            //                             "actual": "eipalloc-05b3baeqwrqerqwr",
            //                             "name": "allocation_id",
            //                             "stateFile": "eipalloc-05b3baa5289b3ef16"
            //                         },
            //                         {
            //                             "actual": "10.0.1.271",
            //                             "name": "private_ip",
            //                             "stateFile": "10.0.1.216"
            //                         }
            //                     ],
            //                     "name": "nat_gateway",
            //                     "id":""
                                
            //                 },
            //                 {
            //                     "configurations": [
            //                         {
            //                             "actual": "eipalloc-05b3baeqwrqerqwr",
            //                             "name": "allocation_id",
            //                             "stateFile": "eipalloc-05b3baa5289b3ef16"
            //                         },
            //                         {
            //                             "actual": "subnet-01f7157rerqerqe",
            //                             "name": "subnet_id",
            //                             "stateFile": "subnet-01f7157b4285c26cc"
            //                         },
            //                         {
            //                             "actual": "10.0.1.271",
            //                             "name": "private_ip",
            //                             "stateFile": "10.0.1.216"
            //                         }
            //                     ],
            //                     "name": "nat_gateway",
            //                     "id":""
            //                 },
            //                 {
            //                     "configurations": [
            //                         {
            //                             "actual": "eipalloc-05b3baeqwrqerqwr",
            //                             "name": "allocation_id",
            //                             "stateFile": "eipalloc-05b3baa5289b3ef16"
            //                         },
            //                         {
            //                             "actual": "subnet-01f7157rerqerqe",
            //                             "name": "subnet_id",
            //                             "stateFile": "subnet-01f7157b4285c26cc"
            //                         },
            //                         {
            //                             "actual": "10.0.1.271",
            //                             "name": "private_ip",
            //                             "stateFile": "10.0.1.216"
            //                         }
            //                     ],
            //                     "name": "nat_gateway"
            //                 }
            //             ],
            //             "icon": "https://zeb-aps1-cloudgen-s3-dev.s3.amazonaws.com/nat-gateway-icon.png",
            //             "service": "NAT Gateway"
            //         },
            //         {
            //             "functions": [
            //                 {
            //                     "configurations": [
            //                         {
            //                             "actual": "vpc-0fc488e039a4a4f59",
            //                             "name": "vpc_id",
            //                             "stateFile": "vpc-12345abcd"
            //                         },
            //                         {
            //                             "actual": "10.0.1.0/24",
            //                             "name": "cidr_block",
            //                             "stateFile": "10.0.1.0/24"
            //                         },
            //                         {
            //                             "actual": "us-west-2a",
            //                             "name": "availability_zone",
            //                             "stateFile": "us-west-2a"
            //                         },
            //                         {
            //                             "actual": "true",
            //                             "name": "map_public_ip_on_launch",
            //                             "stateFile": "true"
            //                         }
            //                     ],
            //                     "name": "public_subnet",
            //                     "id":""
            //                 },
            //                 {
            //                     "configurations": [
            //                         {
            //                             "actual": "vpc-0fc488e039a4a4f59",
            //                             "name": "vpc_id",
            //                             "stateFile": "vpc-12345abcd"
            //                         },
            //                         {
            //                             "actual": "10.0.2.0/24",
            //                             "name": "cidr_block",
            //                             "stateFile": "10.0.2.0/24"
            //                         },
            //                         {
            //                             "actual": "us-west-2b",
            //                             "name": "availability_zone",
            //                             "stateFile": "us-west-2b"
            //                         }
            //                     ],
            //                     "name": "private_subnet",
            //                     "id":""
            //                 }
            //             ],
            //             "icon": "https://zeb-aps1-cloudgen-s3-dev.s3.amazonaws.com/subnet-icon.png",
            //             "service": "Subnet"
            //         },
            //         {
            //             "functions": [
            //                 {
            //                     "configurations": [
            //                         {
            //                             "actual": "10.0.0.0/16",
            //                             "name": "cidr_block",
            //                             "stateFile": "10.0.0.0/16"
            //                         },
            //                         {
            //                             "actual": "true",
            //                             "name": "enable_dns_hostnames",
            //                             "stateFile": "true"
            //                         },
            //                         {
            //                             "actual": "true",
            //                             "name": "enable_dns_support",
            //                             "stateFile": "true"
            //                         }
            //                     ],
            //                     "name": "vpc",
            //                     "id":""
            //                 }
            //             ],
            //             "icon": "https://zeb-aps1-cloudgen-s3-dev.s3.amazonaws.com/vpc-icon.png",
            //             "id": "vpc",
            //             "service": "VPC"
            //         },
            //         {
            //             "functions": [
            //                 {
            //                     "configurations": [],
            //                     "name": "internet_gateway",
            //                     "id":""
            //                 }
            //             ],
            //             "icon": "https://zeb-aps1-cloudgen-s3-dev.s3.amazonaws.com/igw-icon.png",
            //             "id": "internet_gateway",
            //             "service": "Internet Gateway"
            //         },
            //         {
            //             "functions": [
            //                 {
            //                     "configurations": [],
            //                     "name": "public_route_table",
            //                     "id":""
            //                 },
            //                 {
            //                     "configurations": [],
            //                     "name": "private_route_table",
            //                     "id":""
            //                 }
            //             ],
            //             "icon": "https://zeb-aps1-cloudgen-s3-dev.s3.amazonaws.com/route-table-icon.png",
            //             "service": "Route Table"
            //         },
            //         {
            //             "functions": [
            //                 {
            //                     "configurations": [],
            //                     "name": "public_route_table_association",
            //                     "id":""
            //                 },
            //                 {
            //                     "configurations": [],
            //                     "name": "private_route_table_association",
            //                     "id":""
            //                 }
            //             ],
            //             "icon": "https://zeb-aps1-cloudgen-s3-dev.s3.amazonaws.com/route-table-association-icon.png",
            //             "service": "Route Table Association"
            //         }
            //     ],
            //     "message": "terraform drift retrieved successfully",
            //     "success": true
            // }
            if(res.success){
                    if(res.data.length!=0){
                        setReviewDrifts(res.data)
                        setIsReviewDriftModelOpen(true)
                    }
                    else{setReviewDrifts([])
                        setIsReviewDriftModelOpen(false)
                    }
            }

        } catch (error) {
            console.log(error , "Error in Reveiw Drifts API")
        }
    }

    /** 
     *  PS_29- PS_32 
     * Review Drifts Node updation Scenario
     */
    useEffect(()=>{
        if(reviewDriftsData.length!=0 && 
            isReviewDriftModelOpen == false
        ){
             reviewNodeUpdation()
        }
    },[reviewDriftsData])

    
    /**
     *  PS_32 - 41
     * This Function is used to update the Node when user selects the updation Option in the 
     * Review Drift Page 
     */
    
    function reviewNodeUpdation() {
        try {
            let reviewData = [...reviewDriftsData];
            let nodeData = [...nodes];
            console.log(reviewData, "Review Data");

            // Helper function to convert value to string representation
            const valueToString = (value) => {
                if (typeof value === 'object' && value !== null) {
                    return JSON.stringify(value);
                }
                return String(value);
            };

            // Iterate the Node and set the Values to the Node   
            reviewData.forEach(review => {
                review.functions.forEach(func => {
                    let matchingNode = nodeData.find(node => node.id === func.id);

                    if (matchingNode) {
                        // Ensure the necessary structure exists
                        if (!matchingNode.data.ConfigureDetail) {
                            matchingNode.data.ConfigureDetail = {};
                        }
                        if (!matchingNode.data.ConfigureDetail.subServieConfig) {
                            matchingNode.data.ConfigureDetail.subServieConfig = {};
                        }
                        if (!matchingNode.data.ConfigureDetail.subServieConfig.Action) {
                            matchingNode.data.ConfigureDetail.subServieConfig.Action = {};
                        }

                        let type = matchingNode.data.ConfigureDetail.subServieConfig.Action.type || 'create';

                        if (!matchingNode.data.ConfigureDetail.subServieConfig.Action[type]) {
                            matchingNode.data.ConfigureDetail.subServieConfig.Action[type] = {};
                        }

                        func.configurations.forEach(config => {
                            // Update subServieConfig
                            matchingNode.data.ConfigureDetail.subServieConfig.Action[type][config.name] = config.actual;

                            // Update fields
                            let matchingField = matchingNode.data.fields.find(field => field.fieldName === config.name);
                            if (matchingField) {
                                // Convert the value to a string representation
                                matchingField.UserValue = valueToString(config.actual);
                            }
                        });
                    }
                });
            });

            console.log(nodeData, "NodeData");
            // Update the state with the modified nodeData
            setNodes(nodeData);
        } catch (error) {
            console.log(error, "Error in Review Drifts");
        }
    }
    // Asssign Tags Opertions 
    // Toggle the Assign Tags modal
    const toggleAssignTags = () => {
    setShowAssignTags(!showAssignTags);
    };
    // fetchAccountDetails Function is Triggered During the node Click of the Account 
	const fetchAccountDetails = async () => {
				try{
				let payload = {
					"userId": userId,
					"orgId": orgId,
					"providerId":providerId ,
					"search": ""
				}
				let res = await getAccountDetailsApi(payload)
				console.log(res , "getAccountDetailsApi")
				if (res.success) {
					setAccounts(res.data)
					if (res.data.length > 0) {
					
					}
				}
			}
			catch(err){
				console.log(err , "Eroor")
			}
	};

   
    return (
        <>
           
         

            {!isReviewDriftModelOpen ? <> 
                <div class="container-fluid p-0">
                    <div class="content-center-container">
                  
                    <div class="row">
                   
                         
                        <>
                        
                            {/** Repo Side Bar Component */}
                            <SideBarComponent
                            assignTagsUpdate = {assignTagsUpdate}
                            setRepositories = {setRepositories}
                            setSelectedEnv = {setSelectedEnv}
                            showAssignTags = {showAssignTags}
                            setShowAssignTags = {setShowAssignTags}
                            />
                           
                            <>
                            <div
                                                    className="reactflow-wrapper"
                                                    style={{ position: "absolute" , height:"84%"}}

                                                    ref={reactFlowWrapper}
                            >
                                 {
						popupMsg.show == true && popupMsg.Msg !=""?
						<WorkbenchPopupComponent
						popupMsg ={popupMsg}
						setPopUpMsg ={setPopUpMsg}
						/>
						:<></>
					}
                                <Draggable handle="#moveable"
                                    defaultPosition={{ x: 700, y: 530 }}
                                                    >
                                                        <div
                                                            className="tools d-flex rounded shadow-sm"
                                                            style={{ zIndex: "10" , 
                                                                position:"relative", 
                                                                bottom:'0'

                                                            }}
                                                        >
                                                            <button className="btn drag-btn" id="moveable">
                                                                <img
                                                                    data-bs-toggle="tooltip"
                                                                    data-bs-placement="bottom"
                                                                    title="Drag"
                                                                    src="images/drag-img.svg"
                                                                    className
                                                                    draggable={false}
                                                                />
                                                            </button>

                                                            <button
                                                                className="btn tool-btn"
                                                                onClick={() => {
                                                                    unDoFunction();
                                                                }}
                                                                disabled={undoStack.length === 0|| viewMode?true: false}
                                                            >
                                                                <img
                                                                    data-bs-toggle="tooltip"
                                                                    data-bs-placement="bottom"
                                                                    title="Undo"
                                                                    src="images/undo-icon.svg"
                                                                    className
                                                                />
                                                            </button>

                                                            <button
                                                                className="btn tool-btn"
                                                                onClick={() => {
                                                                    reDoFunction();
                                                                }}
                                                                disabled={redoStack.length === 0 || viewMode?true: false }

                                                            >
                                                                <img
                                                                    data-bs-toggle="tooltip"
                                                                    data-bs-placement="bottom"
                                                                    title="Redo"
                                                                    src="images/redo-icon.svg"
                                                                    className
                                                                />
                                                            </button>

                                                            <button
                                                                id="fittoscreen"
                                                                className="btn tool-btn"
                                                                onClick={() => {
                                                                    document.documentElement
                                                                        .getElementsByClassName(
                                                                            "react-flow__controls-button react-flow__controls-fitview"
                                                                        )[0]
                                                                        .click();
                                                                }}
                                                            >
                                                                <img
                                                                    data-bs-toggle="tooltip"
                                                                    data-bs-placement="bottom"
                                                                    title="Fit to screen"
                                                                    src="images/fit-to-screen.svg"
                                                                    className
                                                                />
                                                            </button>

                                                            <button
                                                                className="btn tool-btn"
                                                                onClick={() => {
                                                                    document.documentElement
                                                                        .getElementsByClassName(
                                                                            "react-flow__controls-button react-flow__controls-zoomin"
                                                                        )[0]
                                                                        .click();
                                                                }}
                                                            >
                                                                <img
                                                                    data-bs-toggle="tooltip"
                                                                    data-bs-placement="bottom"
                                                                    title="Zoom in"
                                                                    src="images/zoom-in.svg"
                                                                    className
                                                                />
                                                            </button>

                                                            <button
                                                                className="btn tool-btn"
                                                                onClick={() => {
                                                                    document.documentElement
                                                                        .getElementsByClassName(
                                                                            "react-flow__controls-button react-flow__controls-zoomout"
                                                                        )[0]
                                                                        .click();
                                                                }}
                                                            >
                                                                <img
                                                                    data-bs-toggle="tooltip"
                                                                    data-bs-placement="bottom"
                                                                    title="Zoom out"
                                                                    src="images/zoom-out.svg"
                                                                    className
                                                                />
                                                            </button>

                                                        
                                                            
                                                            <div className="dropdown d-flex">
                                                            <button
                                                                className="btn tool-btn"
                                                                type="button"
                                                                id="dropdownSAVE"
                                                                data-bs-toggle="dropdown"
                                                                aria-expanded="false"
                                                                disabled={viewMode?true: false}
                                                                onClick={() => {
                                                                // Roles Should be Handled 
                                                                }}
                                                                
                                                            >
                                                                <img
                                                                data-bs-toggle="tooltip"
                                                                data-bs-placement="bottom"
                                                                title="Save"
                                                                src="images/save.svg"
                                                                alt="Save"
                                                                />
                                                            </button>
                                                            <ul className="dropdown-menu profile-menu border-0 py-1 px-2 shadow-sm"
                                                                disabled ={viewMode?true: false}
                                                            >
                                                                <li
                                                                className="border-grey pb-1"
                                                                onClick={() => {
                                                                    saveArchitecture(0);
                                                                }}
                                                                >
                                                                <a className="dropdown-item font-semibold font-14 profile-dropdown py-2 px-2">
                                                                    Save
                                                                </a>
                                                                </li>
                                                                <li
                                                                className="pt-1"
                                                                onClick={() => {
                                                                    setIsSaveTemplateModalOpen(true)
                                                                    if (providerName.toLowerCase() == 'aws' && PusleIdentificaiton) {
                                                                    setTemplateProviderType("ZebPulse")
                                                                    } else if (providerName.toLowerCase() =="aws" ) { 
                                                                    setTemplateProviderType("AWS")
                                                                    } else if (providerName.toLowerCase() === 'azure') {
                                                                    setTemplateProviderType("Azure")
                                                                    }
                                                                }}
                                                                disabled = {viewMode ?  viewMode : false}
                                                                >
                                                                <a className="dropdown-item font-semibold font-14 py-2 px-2 profile-dropdown">
                                                                    Save as Template
                                                                </a>
                                                                </li>
                                                            </ul>
                                                            </div>
                                                        
                                                            <div className="dropdown d-flex">
                                                                <button
                                                                    className="btn tool-btn"
                                                                    type="button"
                                                                    id="dropdownDOWNLOAD"
                                                                    data-bs-toggle="dropdown"
                                                                    disabled={nodes.length == 0 ? true : false}
                                                                    aria-expanded="false"
                                                                >
                                                                    <img
                                                                        data-bs-toggle="tooltip"
                                                                        data-bs-placement="bottom"
                                                                        title="Download"
                                                                        src="images/download.svg"
                                                                        
                                                                    />
                                                                </button>
                                                                <ul className="dropdown-menu profile-menu border-0 py-1 px-2 shadow-sm">
                                                                    <li
                                                                        className="border-grey pb-1"
                                                                        onClick={() => {
                                                                            XMLDownload()
                                                                        }}
                                                                    >
                                                                        <a className="dropdown-item font-semibold font-14 profile-dropdown py-2 px-2">
                                                                            XML
                                                                        </a>
                                                                    </li>
                                                                    <li
                                                                        className="pt-1 pb-1 border-grey"
                                                                        onClick={() => {
                                                                            DownLoadImage("Png");

                                                                        }}
                                                                    >
                                                                        <a className="dropdown-item font-semibold font-14 py-2 px-2 profile-dropdown">
                                                                            PNG
                                                                        </a>
                                                                    </li>
                                                                    <li
                                                                        className="pt-1"
                                                                        onClick={() => {
                                                                            DownLoadImage("Jpeg");
                                                                        }}
                                                                    >
                                                                        <a className="dropdown-item font-semibold font-14 py-2 px-2 profile-dropdown">
                                                                            JPG
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <button
                                                                className="btn tool-btn"
                                                                
                                                                // data-bs-target="#commit"
                                                                disabled={
                                                                    viewMode?true: false
                                                                }
                                                                onClick={()=>{
                                                                    setIsCommitModalOpen(true)
                                                                }}

                                                            >
                                                                <img
                                                                    data-bs-toggle="tooltip"
                                                                    data-bs-placement="bottom"
                                                                    title="Commit"
                                                                    src="images/commit.svg"
                                                                    
                                                                />
                                                            </button>
                                                            <button
                                                                className="btn tool-btn"
                                                                disabled={viewMode == true ? true : false}
                                                                onClick={()=>{
                                                                    setIsVersionHistoryModalOpen(true)
                                                                }}
                                                            >
                                                                <img
                                                                    data-bs-toggle="tooltip"
                                                                    data-bs-placement="bottom"
                                                                    style={{ width: "18px" }}
                                                                    title="Commit history"
                                                                    src="images/commit-history-icon.svg"
                                                                    
                                                                />
                                                            </button>
                                                        </div>
                                                    </Draggable>
                                                    <ReactFlow
                                                    nodes={nodes}
                                                    edges={edges}
                                                    onNodesChange={(e)=>{
                                                        if(viewMode!= true){
                                                            onNodesChange(e);
                                                        }
                                                    }}
                                                    onEdgesChange={(e)=>{
                                                        onEdgesChange(e);
                                                    }}
                                                    onConnect={onConnect}
                                                    onDrop={                                                      
                                                        onDrop}
                                                        
                                                    onDragOver={onDragOver}
                                                    onNodeDoubleClick={(e, node) => {
                                                        doubleClickLabel(e, node);
                                                    }}
                                                    onNodesDelete={

                                                       onNodesDelete
                                                    }
                                                    connectionMode="strict"
                                                    deleteKeyCode={["Delete", "Backspace"]}
                                                    fitView
                                                    className="validationflow"
                                                    nodeTypes={nodeTypes}
                                                    id="myArchitecture"
                                                    onPaneClick={(e)=>{
                                                        if(configureDetails.id != ""){
                                                                setConfigureDetails({id:""})
                                                        }
                                                    }}
                                                    // colorMode={colorMode}
                                                    >
                                                    <Controls />
                                                    <Background />
                                                    </ReactFlow>
                                                
                                                {/** Workbench PopUP Handler */}
                                                {isCommitModalOpen ?
                                                <>
                                                    <CommitPopup
                                                        isCommitModalOpen = {isCommitModalOpen} 
                                                        setIsCommitModalOpen = {setIsCommitModalOpen}
                                                        nodes = {nodes}
                                                        edges={edges}
                                                        setIsToastPopUpOpen = {setIsToastPopUpOpen}
                                                        setToastMessage = {setToastMessage}
                                                        popupMsg ={popupMsg}
                                                        setPopUpMsg ={setPopUpMsg}
                                                    />
                                                </>:<></>}
                                                {isVersionHistoryModalOpen ? 
                                                <>
                                                <VersionHistoryPopUp
                                                isVersionHistoryModalOpen = {isVersionHistoryModalOpen}
                                                setIsVersionHistoryModalOpen = {setIsVersionHistoryModalOpen}
                                                viewCommitHistory = {viewCommitHistory}
                                                restoreCommitHistory = {restoreCommitHistory}
                                                />
                                                </>:<></>}
                                                {/* TemplatePopup */}
                                                {isSaveTemplateModalOpen ?<>
                                                    <SaveTemplatePopup
                                                        setIsSaveTemplateModalOpen = {setIsSaveTemplateModalOpen}
                                                        nodes = {nodes}
                                                        edges = {edges}
                                                        templateProviderType = {templateProviderType}
                                                        popupMsg ={popupMsg}
                                                        setPopUpMsg ={setPopUpMsg}
                                                        />
                                                    </>:<></>}
                                                {showLoader ?
                                                <WorkbenchLoader/>:<></>
                                                
                                            }
                                                    
                                                
                                                
                                                
                                                
                             </div>
                            </>
                
                         
                            {/** Configure Component */}
                            {configureDetails.id !== "" ? (
                            configureDetails.data.serviceName !== "Account" ? (
                                <ConfigureComponent
                                configureDetails={configureDetails}
                                repositories={repositories}
                                labelSubmit={labelSubmit}
                                connectedSourceNodes={connectedSourceNodes}
                                />
                            ) : configureDetails.id !== ""  && configureDetails.data.serviceName === "Account" ? (
                                <AccountConfigureComponent
                                configureDetails={configureDetails}
                                labelSubmit={labelSubmit}
                                accounts ={accounts}
                                setAccounts= {setAccounts}


                                />
                            ) : (
                                <></>
                            )
                            ) : (
                            <></>
                            )}
                           
                            {formData.createArchitectureWithAI  == true?
                            <>
                                <RegenerateButton
                                regenerationText = {regenerationText}
                                setRegenerationText= {setRegenerationText}
                                
                                /> 
                            </>:<></>}
                            {isToastPopupOpen ? <>  
                                <ToastMessage
                                setIsToastPopUpOpen = {setIsToastPopUpOpen}
                                istoastMessage = {istoastMessage}

                                />
                            </>:<></>}

                            {showDeleteModal ?
                                    
                                    <div className="modal fade show"  id="deleteConfirmationModal" 
                                    style={{display: showDeleteModal ? 'block' : "none", backgroundColor: 'rgba(0,0,0,0.5)'}} 
                                        data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
                                        aria-labelledby="deleteConfirmationModalLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered justify-content-center">
                                            <div className="modal-content border-0">
                                                <div className="modal-header border-0 py-4 px-32">
                                                    <h1 className="modal-title font-20 font-bold black-v2" id="deleteConfirmationModalLabel">Delete Confirmation</h1>
                                                    <button type="button" className="btn shadow-none" onClick={() => setShowDeleteModal(false)}>
                                                        <img src="images/close-icon.svg" alt="close-icon" className="icon-hover shadow-none modal-close-filter" />
                                                    </button>
                                                </div>
                                                <div className="modal-body px-32 pt-0">
                                                    <p className="font-16 font-medium black-v2 mb-4">Are you sure you want to delete?</p>
                                                    <div className="d-flex justify-content-end align-items-center mt-4 mb-2 gap-3">
                                                        <button type="button" className="button outline-button text-nowrap py-12" onClick={() => {
                                                                 console.log(nodes, "For No")
                                                                 let node = [...nodes]
                                                                 let tobedeltedNode = { ...deleteNodeService }
                                         
                                                                 if (tobedeltedNode.data.ConfigureDetail.subServieConfig.type == "delete") {
                                                                   tobedeltedNode.data.ConfigureDetail.subServieConfig.type = "update"
                                                                   tobedeltedNode.data.ConfigureDetail.subServieConfig.Action.delete = tobedeltedNode.ConfigureDetail.subServieConfig.Action.update
                                                                //    tobedeltedNode.data.ConfigureDetail.subServieConfig.Action.delete.DeployStatus = true
                                                                 }
                                                                 node.push(tobedeltedNode)
                                                                 setNodes(node)
                                                            
                                                            setShowDeleteModal(false)}}>Cancel</button>
                                                        <button 
                                                            type="button" 
                                                            className="button primary-button text-nowrap py-12" 
                                                            onClick={((e)=>{
                                                                nodeDeleteState()
                                                                setShowDeleteModal(false)
                                                            })}
                                                        >
                                                            Yes
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : <></>
                            }
                         
                                                       
                        </> 

                          
                          
                    </div>
                    </div>
                </div>
            </> :<> </> }
           
            {isReviewDriftModelOpen && viewMode == false ? <>
                    <ReviewDrifts
                    reviewDrifts ={reviewDrifts}
                    setIsReviewDriftModelOpen= {setIsReviewDriftModelOpen}
                    setreviewDriftsData = {setreviewDriftsData}
                    restoreCommitHistory = {restoreCommitHistory}
                    />
                </>:<></>}
           
        {showAssignTags && (
				<AssignTags
					orgId={orgId}
					userId={userId}
					onClose={toggleAssignTags}
					handleTagsUpdate={assignTagsUpdate}
                    envId = {envId}
				/>
				)}
           
        
        
        </>
    )
}




export default Workbench;