// PS_05
import React, { useState, useEffect,useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Select from 'react-select';
import SideBar from '../sidebar/SideBar';
// PS_08
import { editOrganizationApi } from '../../Service/api';
import { OrgContext } from '../../ContextProvider/OrgContext';



const EditPrimaryDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;

    //PS_17
    const [inputValue, setInputValue] = useState('');
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [logoUpdated, setLogoUpdated] = useState("false");

    const [organizationFormData, setOrganizationFormData] = useState({
        orgId: '',
        orgName: '',
        industryName: '',
        revenue: '',
        noOfEmployees: '',
        logo: '',
        mail: '',
        teamsUrl: '',
        tags: []
    });
    const [userId, setuserId] = useState(localStorage.getItem('userId'));


    const {
        userRoles,
        orgId,
        providerId,
        architectures,
        canCreateWorkload,
        canDeleteWorkload,
        canViewWorkload,
        setuserRoles,
        setRepository_id,setrepository_name,

    
        // Add organization and provider fields here
        providerName,       
        orgData,           
        setOrgData,        
        setArchitectures,
    
        envId,             // Environment ID
        workloadId,        // Workload ID
        aiArchitecture,    
        viewMode,          
        envName,           
        language,          
        archType,          
        business,          
        rulebook,          
        archDesc,          
        services,
    
        lastBuildID,       
        lastBuildDate,     
        lastBuildMessage,
    
        repoId,            
        repoName,
    
        PulseOrganization_id,
        PulseVertical_id,
        PulseVertical_name,
        PulseOrganization_name,
        PulseIdentification,
    
        setEnvId           // Function to update environment ID
    } = useContext(OrgContext);

    const [employeeOptions] = useState(['Select', '1-500', '501-1000', '1001-2000', '3000-5000', '5001-10000', '>10000']);
    const [industryOptions] = useState(['Select', 'Finance', 'E-commerce', 'Health care', 'Manufacturing', 'IT', 'Retail', 'Media and entertainment', 'Research and development', 'Logistics', 'Telecommunications', 'Education', 'Law Firm', 'Gaming']);
    const [errorMessages, setErrorMessages] = useState({});
    const [logoFile, setLogoFile] = useState(null);
    const [logoPreviewUrl, setLogoPreviewUrl] = useState('');
    const [fileName, setFileName] = useState('');
    const handleChange = (selected) => {
        setSelectedOptions(selected);
    };
    
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent form submission
        }
        if (e.key === 'Enter' && inputValue.trim() !== '') {
            const newTag = inputValue.trim();
            console.log("tagssdsa",newTag);
            // Add the new tag to selected options if it doesn't already exist
            if (!selectedOptions.some(option => option.label === newTag)) {
                setSelectedOptions(prev => [...prev, { value: newTag, label: newTag, name: newTag }]);
               
            }
            setInputValue(''); // Clear input field after adding
            
        }
    };
  
    // PS_18 Load organization details if editing
    useEffect(() => {
        console.log(state);
        if (state && state?.orgId) {
            console.log("sadsdsa", state.orgId);
            // Simulate fetching organization details with mock data
            setOrganizationFormData(state?.orgId);
            setSelectedOptions(
                state?.orgId.tags?.filter(tag => tag.tagName !== "UTI") // Filter out tags with name "UTI"
                    .map(tag => ({
                        value: tag.tagId, // Use tagId as value
                        label: tag.tagName, // Use tagName as label
                        name: tag.tagName // You can also use tagName for name or modify as needed
                    }))
            );
            setLogoPreviewUrl(state.orgId.logo)     
            // setLogoPreviewUrl(state.orgId.logo) 
        }
    }, [state]);    

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setOrganizationFormData({ ...organizationFormData, [name]: value });
    };

    const validateFields = () => {
        let errors = {};
        if (!organizationFormData.orgName) errors.orgName = "Organization Name is required.";
        if (!organizationFormData.industryName || organizationFormData.industryName === "Select") errors.industryName = "Please select an industryName.";
        if (!organizationFormData.revenue) errors.revenue = "Revenue is required.";
        if (!organizationFormData.noOfEmployees || organizationFormData.noOfEmployees === "Select") errors.noOfEmployees = "Please select the Number of employees.";
        
        // Validate Email ID
        if (organizationFormData.mail && !/^[\w-.]+@(avasoft\.com|zeb\.co)$/.test(organizationFormData.mail)) {
            errors.mail = "Email ID must be a valid address ending with @avasoft.com or @zeb.co";
        }

        // Validate Teams URL
        if (organizationFormData.teamsUrl && !/^https?:\/\/.+/.test(organizationFormData.teamsUrl)) {
            errors.teamsUrl = "Teams URL must be a valid link.";
        }

        setErrorMessages(errors);
        return Object.keys(errors)?.length === 0; // Return true if no errors
    };



//PS_19
    const handleSubmit = async (event) => {
        event.preventDefault();
         if (selectedOptions?.length > 0) {
            setOrganizationFormData(prevData => ({
                ...prevData,
                tags: [...prevData.tags, ...selectedOptions] // Merge selected options into tags
            }));
            console.log('Tags updated:', organizationFormData.tags);
        } 
        if (!validateFields()) return;
        const payload = {
            userId: userId, // Replace with actual user ID if needed
            orgId:   orgId,
            orgName: organizationFormData.orgName,
            industryId: organizationFormData.industryName,
            revenue: organizationFormData.revenue,
            noOfEmployeesId: organizationFormData.noOfEmployees,
            logo: organizationFormData.logo, // Ensure this is a base64 string
            tags: selectedOptions.map(option => option.label), 
            teamsUrl: organizationFormData.teamsUrl,
            mail: organizationFormData.mail,
            logoUpdate: logoUpdated=="true"?true:false 
            
        };


        try {
            // Simulate saving organization data
           const response = await editOrganizationApi(payload);
           if (response.success) {
            navigate('/about')
                            } else {
            console.error('Error creating organization');
        }

            console.log("Saving organization data:", organizationFormData);
            // Here you would typically call your API to save the data

            navigate('/about'); // Navigate to Organization Grid
        } catch (error) {
            console.error("Error saving organization:", error);
            // Handle error response appropriately
        }
    };

    

    const MultiValue = ({ data, removeProps }) => (
        <span className="blue-batch font-12 d-flex gap-1  align-items-center font-medium">
            {data.label}
            <a {...removeProps}>
                <img src="images/tags-close-icon.svg" alt="close-icon" className="icon-custom-filter" />
            </a>
        </span>
    );
    // Custom components to limit displayed tags and show count
    const customStyles = {
        control: (base) => ({
            ...base,
            border: '1px solid #ccc',
                boxShadow: 'none',
                borderRadius: '4px',
                width:"1063px",
                minHeight: '50px',
                }),
        multiValue: (base) => ({
            ...base,
            display: 'flex',
            alignItems: 'center',
            marginRight: '5px',
        }),
        multiValueLabel: (base) => ({
            ...base,
            padding: '0',
        }),
        multiValueRemove: (base) => ({
            ...base,
            display: 'none', // Hide default remove button
        }),
        valueContainer: (base) => ({
            ...base,
            paddingRight: selectedOptions?.length > 2 ? '30px' : '10px', // Adjust padding for count display
            position: 'relative',
        }),
    };
    const customComponents = {
        MultiValue,
    };
    const handleLogoChange = (event) => {
        const file = event.target.files?.[0];
    
        if (file) {
            // Validate file size (1MB)
            if (file.size > 1048576) {
                setErrorMessages(prevErrors => ({
                    ...prevErrors,
                    logo: "Upload the logo as JPG or PNG format and not more than 1 MB."
                }));
                return;
            }
    
            // Validate image resolution
            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.onload = () => {
                if (img.width < 32 || img.height < 32) {
                    setErrorMessages(prevErrors => ({
                        ...prevErrors,
                        logo: "Image resolution must be greater than 32 pixels."
                    }));
                    return;
                }
    
                // If validation passes
                setLogoFile(file);
                setLogoUpdated("true");

                setLogoPreviewUrl(URL.createObjectURL(file));
    
                // Convert file to Base64 and update organization form data
                convertFileToBase64(file).then((base64) => {
                    const paddedBase64 = padBase64String(base64);
                    setOrganizationFormData({ ...organizationFormData, logo: paddedBase64 });
                }).catch((error) => {
                    console.error("Error converting file to Base64:", error);
                });
                
                // Clear logo error message when a new file is selected
                setErrorMessages(prevErrors => ({
                    ...prevErrors,
                    logo: ''
                }));
                
                setFileName(file.name); // Set the file name for display
            };
            
            // Clear previous error messages when selecting a new file
            setErrorMessages(prevErrors => ({
                ...prevErrors,
                logo: ''
            }));
            
        }
    };
    // Function to convert file to Base64
    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
    
            reader.onload = () => {
                resolve(reader.result); // This is the Base64 string
            };
    
            reader.onerror = (error) => {
                reject(error);
            };
        });
    };
    const padBase64String = (base64) => {
        const padding = '='.repeat((4 - (base64?.length % 4)) % 4);
        return base64 + padding;
    };

    // PS_21 Handle logo removal
    const handleRemoveLogo = () => {
        setLogoFile(null);
        setLogoUpdated("false");

        setLogoPreviewUrl('');
        setFileName('');
        setOrganizationFormData({ ...organizationFormData, logo: '' });
        const fileInput = document.getElementById('logo');
        fileInput.value = '';
    };
 

    return (
        <div className="container-fluid">
        <div className="">
    
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                    <SideBar/>
                    <div className="col-md-9 right-container-scroll">
            <div className="d-flex justify-content-between align-items-center ps-4 mt-4">
                <p className="mb-0 font-32 font-bold black-v2">About</p>
                </div>
            
            {/* Slide title end here */}

            {/* Page Content Start here */}
            <form onSubmit={handleSubmit}>
                <div className="row add-organization-form">
                    <div className="col-md-12 mt-4 ps-4">
                        <div className="ps-4">
                            <h3 className="font-18 font-medium mb-4 pb-2 black-v2">Primary Details</h3>
                            <div className="row about-section-details">
                            <div className="col-md-4 mb-5 pb-2">
                                <label className="form-label font-14 font-medium" htmlFor="Organization-Name">Organization Name</label>
                                <p className="font-16 font-medium mb-0 black-v2">{organizationFormData.orgName}</p>
                            </div>
                                <div className="col-md-4 mb-5 pb-2">
                                <label className="form-label font-14 font-medium" htmlFor="industryName">Industry<span className="required">*</span></label>
                                    <select 
                                        name="industryName" 
                                        id="industryName" 
                                        className={`form-select form-control custom-form ${errorMessages.industryName ? 'is-invalid' : ''}`} 
                                        value={organizationFormData.industryName} 
                                        onChange={handleInputChange}
                                    >
                                        {industryOptions.map((option, index) => (
                                            <option key={index} value={option}>{option}</option>
                                        ))}
                                    </select>
                                    {errorMessages.industryName && <div className="invalid-feedback">{errorMessages.industryName}</div>}
                                </div>
                                <div className="col-md-4 mb-5 pb-2">
                                <label className="form-label font-14 font-medium" htmlFor="Revenue">Revenue</label>
                                <p className="font-16 font-medium mb-0 black-v2">{organizationFormData.revenue}</p>
                            </div>
                                </div>
                                <div className="row mb-4">
                                <div className="col-md-4 mb-5 pb-2">
                                <label className="form-label font-14 font-medium" htmlFor="noOfEmployees">
            Number of Employees<span className="required">*</span>
        </label>
        <select 
            name="noOfEmployees" 
            id="noOfEmployees" 
            className={`form-select form-control custom-form ${errorMessages.noOfEmployees ? 'is-invalid' : ''}`} 
            value={organizationFormData.noOfEmployees} 
            onChange={handleInputChange}
        >
            {employeeOptions.map((option, index) => (
                <option key={index} value={option}>{option}</option>
            ))}
        </select>
        {errorMessages.noOfEmployees && <div className="invalid-feedback">{errorMessages.noOfEmployees}</div>}
    </div>

    <div className="col-md-3 mb-4">
    <div className="position-relative">
        <label className="form-label font-14 font-medium" htmlFor="logo">
            Organization Logo<span className="required">*</span>
        </label>
        <label className="file-upload-outline position-absolute rounded-3 d-flex align-items-center gap-2 font-14 font-medium">
            Upload Logo
        </label>
        <input 
            type="file" 
            className="form-control custom-file" 
            id="logo" 
            aria-label="upload" 
            accept=".png,.jpg,.jpeg" 
            onChange={handleLogoChange}
        />
        
        {/* Display uploaded logo if available */}
        {logoPreviewUrl && (
            <div className="mt-2">
                <img 
                    src={logoPreviewUrl} 
                    alt="Uploaded Logo" 
                    className="uploaded-partner-logo me-3"
                    style={{ width: '150px', height: 'auto' }} // Adjust width as needed
                /> 
                <img 
                    src="images/cancel-upload-icon.svg" 
                    alt="Remove Logo" 
                    className="ms-2 cursor-pointer"
                    onClick={handleRemoveLogo}
                />
            </div>
        )}
    </div>

    {/* Display error message for Number of Employees */}
    {errorMessages.logo && (
        <div className="invalid-feedback">{errorMessages.logo}</div>
    )}
</div>
</div>
        </div>

                            {/* Tags Section */}
                            <h3 className="font-18 font-medium mt-3 mb-4 black-v2">Tags</h3>
                            <div className="row">
            <div className="col-md-12 mb-4">
                <label className="form-label font-14 font-medium" htmlFor="define-tags">Define Tags</label>
                <Select
                    isMulti
                   className='custom-select-form cust-select-width padding-left'
                    // styles={{ /* Custom styles if needed */ }}
                    // options={options}
                    onChange={handleChange}
                    styles={customStyles}
                    value={selectedOptions} // Set selected options here
                    placeholder="Type and press Enter to add"
                    noOptionsMessage={() => null}
                    onInputChange={(input) => setInputValue(input)} // Update input state
                    inputValue={inputValue} // Control the input value for react-select
                    onKeyDown={handleKeyDown} // Handle key down events for adding tags
                    components={customComponents}
                    />
            </div>

           
        

                            {/* Alert Details Section */}
                            <h3 className='font-18 font-medium mt-3 mb-4 black-v2'>Alert Details</h3>
                            <div className='row'>
                                <div className='col-md-6 mb-4'>
                                    <label htmlFor='mail' className='form-label'>Alert Email ID</label>
                                    <input type='email' id='mail' name='mail' placeholder='Enter Alert Email ID'
                                           value={organizationFormData.mail} onChange={handleInputChange}
                                           className={`form-control ${errorMessages.mail ? 'is-invalid' : ''} custom-form` } />
                                    {errorMessages.mail && <div className='invalid-feedback'>{errorMessages.mail}</div>}
                                </div>

                                <div className='col-md-6 mb-4'>
                                    <label htmlFor='teamsUrl' className='form-label'>Teams Integration</label>
                                    <input type='text' id='teamsUrl' name='teamsUrl' placeholder='Enter team webhook URL'
                                           value={organizationFormData.teamsUrl} onChange={handleInputChange}
                                           className={`form-control ${errorMessages.teamsUrl ? 'is-invalid' : ''} custom-form`} />
                                    {errorMessages.teamsUrl && <div className='invalid-feedback'>{errorMessages.teamsUrl}</div>}
                                </div>
                            </div>

                            {/* Footer Buttons */}
                            <div className='modal-footer border-0 justify-content-end py-4 gap-1'>
                                <button type='button' className='button cancel-button' onClick={() => navigate('/about')}>Cancel</button>
                                <button type='submit' className='button primary-button'>Update</button>
                            </div>

                        </div>
                    </div>
                </div>
            </form>

            {/* Page Content End here */}
        </div>
        </div></div></div></div></div>
    );
};

export default EditPrimaryDetails;