import React, { useState, useEffect } from 'react';
import SideBar from '../sidebar/SideBar';
import Pagination from './Pagination';
import { getAllInvoicesApi } from '../../Service/api';
import moment from 'moment';




const InvoicesGridComponent = () => {
    const [invoices, setInvoices] = useState([]);
    const [invoices2, setInvoices2] = useState([]);
    const [apiResp,setApiResp] = useState([])
   
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    const [providers] = useState(["AWS", "Azure"]);
    const [cloudProvider, setCloudProvider] = useState("AWS"); // Default to Azure
    const [showFilter, setShowFilter] = useState(false);
    
    // Filter states
    const [filterInvoiceId, setFilterInvoiceId] = useState("");
    const [filterStatus, setFilterStatus] = useState("");
    const [filterFromDate, setFilterFromDate] = useState("");
    const [filterFromDate2, setFilterFromDate2] = useState("");
    const [filterToDate, setFilterToDate] = useState("");
    const [filterToDate2, setFilterToDate2] = useState("");

        const [visibleCount, setVisibleCount] = useState(5); // Initial number of invoices to show
    
        const handleLoadMore = () => {
            setVisibleCount(prevCount => prevCount + 5); // Load 5 more invoices
        };
    
console.log("kkk");

    console.log(invoices,"Invoices!!")

    useEffect(() => {
        fetchInvoices();
    }, [cloudProvider]);

    const fetchInvoices = async () => {
        const payload = {
            userId : "c7d86e9d-4819-4f5e-95f8-782d4c6774a3",
            orgId : "403c6921-acc8-47b2-8dc2-839f1a840f98",
            cloudProvider: cloudProvider
        };

        try {
            const response = await getAllInvoicesApi(payload);
            
            
            let modifiedResponse = []


            if (cloudProvider == 'Azure'){

                for(let invoice of response.azureInvoices[0]) {
                    try{
                      
                      const [startDateString, endDateString] = invoice.BillingPeriod.split(' to ');
                      let modifiedRes = invoice
                      modifiedRes.billingPeriodFrom = moment(startDateString).format('MM/DD/YY')
                      modifiedRes.billingPeriodTo = moment(endDateString).format('MM/DD/YY')
                      modifiedResponse.push(modifiedRes)
                    }catch(error) {
                      console.log(error.message)
                    }
                  }
      
            }else{
                
                
                for (let invoice of response.awsInvoices){
                    console.log(invoice,"This is the response of the invoice.")
                    let modifiedRes = {}
                    modifiedRes.billing_period =  invoice?.invoice_details?.billing_period
                    modifiedRes.due_amount = invoice?.invoice_details?.due_amount
                    modifiedRes.S3Url = response.awsInvoices[0]["S3Url"]
                    modifiedResponse.push(modifiedRes)
                 }

            }

          
            
            

      


      


            console.log(modifiedResponse,"This is the modified response.")
            
            // Call the API function
            if (response) {
                setApiResp(modifiedResponse)
                setInvoices(modifiedResponse); // Update state with fetched invoices
            } else {
                console.error('Error fetching invoices');
            }
        } catch (error) {
            console.error('An error occurred while fetching invoices:', error);
        }
    };
    // Handle search input change
    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    // Handle search action
    const handleSearchInput = () => {
        console.log("Searching invoices with query:", searchQuery);
        // Implement actual search logic here
    };

    // Handle filter application
    const applyFilters = () => {
        let newData;
    
        // Check if invoices.azureInvoices is defined and an array
        if (cloudProvider === "AWS") {
            newData = invoices.awsInvoices.filter(invoice => {
                return (
                    (filterInvoiceId ? invoice.invoice_details.invoice_id.includes(filterInvoiceId) : true) &&
                    (filterStatus ? invoice.invoice_details.status === filterStatus : true)
                );
            });
        } else {
            debugger
            newData = apiResp
            if (filterInvoiceId) {
                newData = newData.filter((item) => item.InvoiceId.includes(filterInvoiceId));
              }
          
              if (filterStatus) {
                newData = newData.filter((item) => item.Status === filterStatus);
              }
          
              if (filterFromDate) {
                const from = new Date(filterFromDate);
                newData = newData.filter((item) => {
                  const [periodFrom] = item.BillingPeriod.split(' to ');
                  return new Date(periodFrom) >= from;
                });
              }
          
              if (filterToDate) {
                const to = new Date(filterToDate);
                newData = newData.filter((item) => {
                  const [, periodTo] = item.BillingPeriod.split(' to ');
                  return new Date(periodTo) <= to;
                });
              }
          
              if (filterFromDate2) {
                const from = new Date(filterFromDate2);
                newData = newData.filter((item) => new Date(item.DueDate) >= from);
              }
          
              if (filterToDate2) {
                const to = new Date(filterToDate2);
                newData = newData.filter((item) => new Date(item.DueDate) <= to);
              }
        }
        
    
        
        setInvoices(newData);
        setTotalRecords(newData.length);
        setShowFilter(false); // Close the filter after applying
    };
    const filteredInvoices = Array.isArray(invoices) 
    ? invoices.flat().filter(invoice => 
        cloudProvider === "Azure" && (
            invoice.InvoiceId.toLowerCase().includes(searchQuery.toLowerCase()) ||
            invoice.AccountId.toLowerCase().includes(searchQuery.toLowerCase()) ||
            invoice.BillingPeriod.toLowerCase().includes(searchQuery.toLowerCase()) ||
            invoice.DueAmount.toString().includes(searchQuery) || // Convert number to string for comparison
            invoice.Status.toLowerCase().includes(searchQuery)
        )
    )
    : [];
    


    return (
        <div class="container-fluid">
        <div class="row">
            <SideBar/>
        <div className="col-md-9 right-container-scroll">
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-Workloads" role="tabpanel" aria-labelledby="pills-Workloads-tab">
                    <div className="px-4 mt-4">
                        {/* Table Container Heading */}
                        <div className="d-xl-flex justify-content-between align-items-center py-3">
                            <h2 className="font-32 font-bold black-v2 mb-2 mt-xl-0">Invoices</h2>
                            <div className="d-flex justify-content-center flex-nowrap gap-3">
                                {/* Dropdown for Cloud Providers */}
                                <div className="dropdown">
                                    <button type="button" className="btn custom-dropdown d-flex justify-content-between align-items-center gap-5 font-12 font-regular color-black shadow-none"
                                        id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span className="d-flex"><img src={`images/${cloudProvider.toLowerCase()}-logo.svg`} alt={`${cloudProvider}-img`} className="me-1 icon-width" /> {cloudProvider}</span>
                                        <img src="images/drop-down-arrow.svg" alt="dropdown-arrow" />
                                    </button>
                                    <ul className="dropdown-menu body-bg access-dropdown w-100" aria-labelledby="dropdownMenuButton1">
                                        {providers.map((provider) => (
                                            <li key={provider} className="font-regular color-black font-12 p-2 custom-list"
                                                onClick={() => setCloudProvider(provider)}>
                                                <span className="d-flex"><img src={`images/${provider.toLowerCase()}-logo.svg`} alt={`${provider}-img`} className="me-1 icon-width" /> {provider}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                {/* Filter Button */}
                                <button type="button" className="filter-button px-2 position-relative"
                            id="dropdownMenuClickableInside" data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"><img src="images/filter-blue.svg"
                                alt="filter-icon" /></button>
                                

                                {/* Filter Modal */}
                                
                                    <div className="dropdown-menu dropdown-menu-end border-0 custom-shadow background-white dd-position p-4 custom-filter-width">
                                        <h5 className="font-medium font-16 mb-0 color-black mb-3">Filter</h5>
                                        {/* Filter Fields */}
                                        <div className="row">
                                            {/* Invoice ID Filter */}
                                            <div className="col-md-12 mb-3">
                                                <label className="form-label font-14 font-medium black-v2">Invoice ID</label>
                                                <input type="text"
                                                    value={filterInvoiceId}
                                                    onChange={(e) => setFilterInvoiceId(e.target.value)}
                                                    className="form-control custom-form px-3 py-2"
                                                    placeholder="Enter Invoice ID" />
                                            </div>

                                            {/* Status Filter */}
                                            <div className="col-md-6 mb-3">
                                                <label className="form-label font-14 font-medium black-v2">Status</label>
                                                <select
                                                    value={filterStatus}
                                                    onChange={(e) => setFilterStatus(e.target.value)}
                                                    className="form-select custom-dropdown shadow-none form-field-border py-1 font-14 font-regular black-v2 custom-select"
                                                    aria-label="Default select example">
                                                    <option value="">Select</option>
                                                    <option value="Due">Due</option>
                                                    <option value="Paid">Paid</option>
                                                </select>
                                            </div>

                                            {/* Billing Period Filters */}
                                            <div className="col-md-12 mb-3">Billing Period</div>
                                            <div className="col-md-6 mb-3 px-2">
                                                <label className="form-label font-14 font-medium black-v2">From</label>
                                                <input type="date"
                                                    onChange={(e) => setFilterFromDate(e.target.value)}
                                                    className="form-control custom-dropdown shadow-none py-1 font-regualar black-v2" />
                                            </div>
                                            <div className="col-md-6 mb-3 px-2">
                                                <label className="form-label font-14 font-medium black-v2">To</label>
                                                <input type="date"
                                                    onChange={(e) => setFilterToDate(e.target.value)}
                                                    className="form-control custom-dropdown shadow-none py-1 font-regualar black-v2" />
                                            </div>

                                            <div className="col-md-12 mb-3">Due date</div>
                                            <div className="col-md-6 mb-3 px-2">
                                                <label className="form-label font-14 font-medium black-v2">From</label>
                                                <input type="date"
                                                    onChange={(e) => setFilterFromDate2(e.target.value)}
                                                    className="form-control custom-dropdown shadow-none py-1 font-regualar black-v2" />
                                            </div>
                                            <div className="col-md-6 mb-3 px-2">
                                                <label className="form-label font-14 font-medium black-v2">To</label>
                                                <input type="date"
                                                    onChange={(e) => setFilterToDate2(e.target.value)}
                                                    className="form-control custom-dropdown shadow-none py-1 font-regualar black-v2" />
                                            </div>
                                            

                                            {/* Action Buttons */}
                                            <div className='col-md-12'>
                                                <div className='d-flex justify-content-end align-items-center gap-3'>
                                                    <button onClick={() => { 
                                                        // Clear filters logic here 
                                                        setFilterInvoiceId("");
                                                        setFilterStatus("");
                                                        setFilterFromDate("");
                                                        setFilterToDate("");
                                                        setFilterFromDate2("");
                                                        setFilterToDate2("");
                                                        setInvoices(apiResp)
                                                        }} 
                                                        className='button outline-button px-4'>Clear All</button>
                                                    <button onClick={applyFilters}
                                                        className='btn button primary-button px-3'>Apply</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                {/* Search Input */}
                                <div className='input-group input-search custom-border-box custom-search-box'>
                                    <input type='search'
                                        value={searchQuery}
                                        onChange={handleInputChange}
                                        className='px-3 form-control common-bg border-0 custom-search'
                                        placeholder='Search' />
                                    <button type='button' onClick={handleSearchInput} 
                                        className='btn border-start-0 custom-search'>
                                        <img src='images/search-icon.svg' alt='search' />
                                    </button>
                                </div>
                            </div> 
                        </div> 

                        {/* Invoices Table */}
                        <div className='table-responsive mt-4 table-border'>
                        {cloudProvider === "AWS" ? (
    <>
        {/* AWS Invoices Table */}
        
        <table className='table custom-table'>
            <thead>
                <tr>
                    <th className="position-sort-icon text-start border-0"> Billing Period</th>
                    <th className="position-sort-icon text-start border-0">Due Amount</th>
                    <th className="position-sort-icon text-start border-0">Action</th>
                </tr>
            </thead>

            {/* Table Body for AWS Invoices */}
            <tbody className='manage-work'>
            {Array.isArray(invoices) && invoices.map((invoice) => (
            cloudProvider === "AWS" && ( // Check for AWS provider only
                <tr key={invoice?.invoice_id}>
                    {
                        
                        console.log(invoice,"This is the billing period")
                    }
                    <td>{invoice?.billing_period}</td>
                    <td>
                        {invoice?.due_amount}
                    </td>
                    <td>
                        <button
                            className="btn action-btn edit-action d-flex align-items-center justify-content-center border-0"
                            onClick={() => {
                                const link = document.createElement('a');
                                link.href = invoice.S3Url;
                                link.download = ''; // Optional filename
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            }}
                        >
                            <img src="images/upload-icon.svg" alt="upload-icon" className="w-18" />
                        </button>
                    </td>
                </tr>
            )
        ))}
            </tbody>
        </table>
    </>
) : (
    <>
        {/* Azure Invoices Table */}
        <table className='table custom-table'>
            <thead>
                <tr>
                    <th className="position-sort-icon text-start border-0">Invoice ID</th>
                    <th className="position-sort-icon text-start border-0">Subscription ID</th>
                    <th className="position-sort-icon text-start border-0">Billing Period</th>
                    <th className="position-sort-icon text-start border-0">Due Date</th>
                    <th className="position-sort-icon text-start border-0">Due Amount</th>
                    <th className="position-sort-icon text-start border-0">Status</th>
                    <th className="position-sort-icon text-start border-0">Action</th>
                </tr>
            </thead>

            {/* Table Body for Azure Invoices */}
            <tbody className='manage-work'>
                    {filteredInvoices.slice(0, visibleCount).map((invoice) => ( // Show only visibleCount invoices
                        <tr key={invoice.InvoiceId}>
                            <td>{invoice.InvoiceId}</td>
                            <td>{invoice.AccountId}</td>
                            
                            {
                                console.log(invoice.BillingPeriod ,moment('2024-08-01T00:00:00.0000000Z').format('mm/DD/yy'),"This is the Billing period data.")
                            } {/* Assuming AccountId is the Subscription ID */}
                            <td>{invoice.billingPeriodFrom } - {invoice.billingPeriodTo}</td>
                            <td>
                               
                                {new Date(invoice.DueDate).toLocaleDateString()}
                            </td> {/* Formatting date */}
                            <td>{invoice.DueAmount}</td>
                            {/* Status Indicator */}
                            <td ><span className={`status ${invoice.Status === 'Paid' ? 'active' : 'inactive'} me-2`}></span>{invoice.Status}</td> 
                            {/* Action Button */}
            
                            <td>
                            {invoice.InvoiceURLs && invoice.InvoiceURLs.Invoice ? (
                            
                                 // Check if Invoice URL exists
                        <button 
                            className="btn action-btn edit-action d-flex align-items-center justify-content-center border-0" 
                            onClick={() => {
                                const link = document.createElement('a');
                                link.href = invoice.InvoiceURLs; // Ensure this is a valid URL
                                link.download = ''; // Optional filename
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                            }}
                        > 
                            <img src="images/upload-icon.svg" alt="upload-icon" className="w-18"/>
                        </button>
                    ) : (
                        <span>No Download Available</span> // Optional message when no link is available
                    )}
                            </td> 
                        </tr> 
                    ))}
                </tbody>
        </table>
       
    </>
)}

                        </div> 
                        {visibleCount < filteredInvoices.length && ( // Only show button if there are more invoices to load
                <div className="text-center mt-3">
                <button type="button" className="button secondary-outline-button black-v2" onClick={handleLoadMore}>Load More</button>

           </div>
            )}
                        
{/* <Pagination
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalItems={cloudProvider === "AWS"?mockAWSInvoices.length:mockAzureInvoices.length} // Assuming 'data' is your array of items
                        itemsPerPage={10} // Define how many items you want per page
                    /> */}
                    </div> 
                </div> 
            </div> 
            </div>
            </div></div>
        );
};

export default InvoicesGridComponent;