import { useContext, useState } from "react";
import { EstimationContext } from "../../../ContextProvider/EstimationContext";
export default function Budget({ serviceFamilyArray, currency, isViewMode }) {
    const { setEstimateAsBudget, setSetEstimateAsBudget } = useContext(EstimationContext);
    console.log(serviceFamilyArray, currency, "Here")
    let colorStringArray = [];
    let lastDeg = 0;
    console.log(serviceFamilyArray, currency, "Check Fro GCP");
    for (let values of serviceFamilyArray) {
        // debugger;
        let color =
            values.serviceFamily === "Compute"
                ? "#ED9898"
                : values.serviceFamily === "Resource Group"
                    ? "#989eee"
                    : values.serviceFamily === "Application" || values.serviceFamily === "Application and Integration"
                        ? "#98EDE9"
                        : values.serviceFamily === "Storage" || values.serviceFamily === 'Vaults'
                            ? "#E8ED98"
                            : values.serviceFamily === "Databases" || values.serviceFamily === "Database"
                                ? "#98EDC4"
                                : values.serviceFamily === "Network" 
                                    ? "#ADED98":
                                    values.serviceFamily === "Network and Content Deliverable"
                                    ? "#98C4ED"
                                    : values.serviceFamily === "Other"
                                        ? "#98CEED"
                                        : values.serviceFamily === "SQS"
                                            ? "Tomato"
                                            : values.serviceFamily == "Security & Identity" || values.serviceFamily === "Security and Identity"
                                                ? "#ED98E9"
                                                : values.serviceFamily == "Analytics"
                                                    ? "#a798ed"
                                                    : values.serviceFamily == "Monitoring"
                                                        ? "#775fc4"
                                                        : values.serviceFamily === "Others"
                                                            ? "#808080"
                                                            : values.serviceFamily === "Azure AD"
                                                                ? "#0078D7"
                                                                : values.serviceFamily === "Network"
                                                                    ? "#00A300"
                                                                    : values.serviceFamily === "Compute"
                                                                        ? "#FF8C00"
                                                                        : values.serviceFamily === "Database" || values.serviceFamily === "Databases"
                                                                            ? "#98EDC4"
                                                                            : values.serviceFamily === "Containers" || values.serviceFamily === "Container"
                                                                                ? "#FFD700"
                                                                                : values.serviceFamily === "Storage"
                                                                                    ? "#0066CC"
                                                                                    : values.serviceFamily === "Domain"
                                                                                        ? "#FF69B4"
                                                                                        : values.serviceFamily === "ML" || values.serviceFamily === "AI"
                                                                                            ? "#FF4500"
                                                                                            : values.serviceFamily === "IoT"
                                                                                                ? "#00CED1"
                                                                                                : values.serviceFamily === "Security"
                                                                                                    ? "#FF0000"
                                                                                                    : values.serviceFamily === "Resource"
                                                                                                        ? "#8B4513"
                                                                                                        : values.serviceFamily === "Cost Management"
                                                                                                            ? "#008080"
                                                                                                            : values.serviceFamily === "Management"
                                                                                                                ? "#1E90FF"
                                                                                                                : values.serviceFamily === "Power BI"
                                                                                                                    ? "#FDB813"
                                                                                                                    : values.serviceFamily === "Azure Services"
                                                                                                                        ? "#4D4D4D"
                                                                                                                        : values.serviceFamily === "Cloud Operations"
                                                                                                                            ? "#008080"
                                                                                                                            : values.serviceFamily === "Database" || values.serviceFamily === "Databases"
                                                                                                                                ? "#98EDC4"
                                                                                                                                : values.serviceFamily === "Cloud Monitoring"
                                                                                                                                    ? "#FF4500"
                                                                                                                                    : null;

        let percentageValue = (values.familyPercentage * 360) / 100;

        console.log(percentageValue, "This is the percentage value for the Estimation budget")

        let colorsy = `${color} ${lastDeg}deg ${lastDeg + percentageValue}deg`;

        console.log(colorsy, "colorsy")

        if (colorsy.includes("NaN")) {
            colorsy = colorsy.replace("NaN", "360");
        }
        colorStringArray.push(colorsy);
        lastDeg = lastDeg + percentageValue;
    }

    let joinedColor = colorStringArray.join(",");

    const handleCheckboxChange = (event) => {
        if (!isViewMode) {
            setSetEstimateAsBudget(event.target.checked);
        }
    };


    return (<>
        <div className="budget-container">
            <div className="row">
                <div className="col-md-4 col-xl-4">
                    <div className="chart-container-4">
                        <div
                            className="donut"
                            style={{
                                width: "150px",
                                height: "150px",
                                borderRadius: "50%",
                                background: `conic-gradient(${joinedColor})`,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <div
                                className="hole"
                                style={{
                                    width: "125px",
                                    height: "125px",
                                    borderRadius: "50%",
                                    background: "#fff",
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
                <div className="col-md-8 d-flex align-items-center">
                    <div className="row mx-2">
                        {serviceFamilyArray.map((values, index) => (
                            <div key={index} className="col-md-6 px-4 mb-4 pb-3">
                                <div className="d-flex justify-content-between align-items-center">
                                    <p className="font-14 font-medium secondary-color m-0">
                                        <span
                                            className="legend-square me-3"
                                            style={{
                                                backgroundColor:
                                                    values.serviceFamily === "Compute"
                                                        ? "#ED9898"
                                                        : values.serviceFamily === "Resource Group"
                                                            ? "#989eee"
                                                            : values.serviceFamily === "Application" || values.serviceFamily === "Application and Integration"
                                                                ? "#98EDE9" :
                                                                values.serviceFamily === "Storage"
                                                                    ? "#E8ED98"
                                                                    : values.serviceFamily === "Database" || values.serviceFamily === "Databases"
                                                                        ? "#98EDC4"
                                                                        : values.serviceFamily === "Network"
                                                                            ? "#ADED98"
                                                                           :  values.serviceFamily === "Network and Content Deliverable"
                                                                                ? "#98C4ED"
                                                                            : values.serviceFamily === "Other"
                                                                                ? "#98CEED"
                                                                                : values.serviceFamily === "SQS"
                                                                                    ? "Tomato"
                                                                                    : values.serviceFamily == "Security and Identity" || values.serviceFamily === "Security & Identity"
                                                                                        ? "#ED98E9"
                                                                                        : values.serviceFamily == "Analytics"
                                                                                            ? "#a798ed"
                                                                                            : values.serviceFamily == "Monitoring"
                                                                                                ? "#775fc4"
                                                                                                : values.serviceFamily === "Others"
                                                                                                    ? "#808080"
                                                                                                    : values.serviceFamily === "Azure AD"
                                                                                                        ? "#0078D7"
                                                                                                        : values.serviceFamily === "Network"
                                                                                                            ? "#00A300"
                                                                                                            : values.serviceFamily === "Database" || values.serviceFamily === "Databases"
                                                                                                                ? "#98EDC4"
                                                                                                                : values.serviceFamily === "Containers" || values.serviceFamily === "Container"
                                                                                                                    ? "#FFD700" :
                                                                                                                    values.serviceFamily === "Storage"
                                                                                                                        ? "#0066CC"
                                                                                                                        : values.serviceFamily === "Domain"
                                                                                                                            ? "#FF69B4"
                                                                                                                            : values.serviceFamily === "ML" || values.serviceFamily === "AI"
                                                                                                                                ? "#FF4500"
                                                                                                                                : values.serviceFamily === "IoT"
                                                                                                                                    ? "#00CED1"
                                                                                                                                    : values.serviceFamily === "Security"
                                                                                                                                        ? "#FF0000"
                                                                                                                                        : values.serviceFamily === "Resource"
                                                                                                                                            ? "#8B4513"
                                                                                                                                            : values.serviceFamily === "Cost Management"
                                                                                                                                                ? "#008080"
                                                                                                                                                : values.serviceFamily === "Management"
                                                                                                                                                    ? "#1E90FF"
                                                                                                                                                    : values.serviceFamily === "Power BI"
                                                                                                                                                        ? "#FDB813"
                                                                                                                                                        : values.serviceFamily === "Azure Services"
                                                                                                                                                            ? "#4D4D4D"
                                                                                                                                                            : values.serviceFamily === "Cloud Operations"
                                                                                                                                                                ? "#008080"
                                                                                                                                                                : values.serviceFamily === "Database" || values.serviceFamily === "Databases"
                                                                                                                                                                    ? "#98EDC4"
                                                                                                                                                                    : values.serviceFamily === "Cloud Monitoring"
                                                                                                                                                                        ? "#FF4500"
                                                                                                                                                                        : null,
                                            }}
                                        />

                                        {/* {values.serviceFamily== "Security & Identity"  &&
                                        "Security"}
                                        {values.serviceFamily== "Network and Content Deliverable" &&
                                        "Network"}
                                        {values.serviceFamily== "Databases" &&
                                        "Database"}
                                        {(values.serviceFamily!= "Security & Identity" && values.serviceFamily!= "Network and Content Deliverable" && values.serviceFamily != "Databases") && values.serviceFamily } */}

                                        <span title={values.serviceFamily}>
                                            {values.serviceFamily.length > 8 ? `${values.serviceFamily.slice(0, 5)}...` : values.serviceFamily}
                                        </span>

                                    </p>
                                    <p className="font-16 font-medium color-black m-0" title={values.serviceFamilyCost.toFixed(2)}>
                                        {currency === "USD" ? "$" : "₹"} {(values.serviceFamilyCost.toFixed(2)).length > 4 ? `${(values.serviceFamilyCost.toFixed(2)).slice(0, 3)}..` : values.serviceFamilyCost.toFixed(2)}
                                    </p>
                                </div>
                            </div>
                        ))}
                        <div className="col-md-12">
                            <div className="form-check">
                                <input
                                    className="form-check-input custom-checkbox me-3"
                                    type="checkbox"
                                    id="flexCheckCheckedset"
                                    checked={setEstimateAsBudget}
                                    onChange={handleCheckboxChange}
                                    disabled={isViewMode}
                                />
                                <label
                                    className="form-check-label font-14 font-medium black-v2"
                                    htmlFor="flexCheckCheckedset"
                                >
                                    Set Estimate as Budget
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}