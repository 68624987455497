import React, { useState, useContext, useEffect } from 'react';
import { FormContext } from './Workload_Grid';
import { getAllRulesApi, getAllRuleCategoryApi } from '../../Service/api';
import { OrgContext } from '../../ContextProvider/OrgContext';

// PS_65
const ChooseRulebooksModal = ({ onClose }) => {
    const { formData, setFormData } = useContext(FormContext);
// PS_67 
    const [rulesData, setRulesData] = useState([]); 
    const [ruleCategories, setRuleCategories] = useState([]);
    const [activeTab, setActiveTab] = useState(null);
    const [selectedRules, setSelectedRules] = useState([]);
    const [userId, setuserId] = useState(localStorage.getItem('userId'));

    const {
        userRoles,
        orgId,
        providerId,
        architectures,
        canCreateWorkload,
        canDeleteWorkload,
        canViewWorkload,
        setuserRoles,
        setRepository_id,setrepository_name,

    
        // Add organization and provider fields here
        providerName,       
        orgData,           
        setOrgData,        
        setArchitectures,
    
        envId,             // Environment ID
        workloadId,        // Workload ID
        aiArchitecture,    
        viewMode,          
        envName,           
        language,          
        archType,          
        business,          
        rulebook,          
        archDesc,          
        services,
    
        lastBuildID,       
        lastBuildDate,     
        lastBuildMessage,
    
        repoId,            
        repoName,
    
        PulseOrganization_id,
        PulseVertical_id,
        PulseVertical_name,
        PulseOrganization_name,
        PulseIdentification,
    
        setEnvId           // Function to update environment ID
    } = useContext(OrgContext);
    useEffect(() => {
        loadRules(); // Fetch rules when the component mounts
        loadRuleCategories();
    }, []);
    useEffect(() => {
        // if (formData.workloadId) {
            // If workloadId exists, initialize selected rules based on formData.rulebook
            const initializedRules = formData.rulebook.reduce((acc, rule) => {
                acc[rule.categoryId] = rule.ruleName.map(r => ({
                    ruleId: r.rulebook_id,
                   
                }));
                return acc;
            }, {});

            // Set selected rules based on initializedRules
            const selectedRuleIds = Object.values(initializedRules).flatMap(category =>
                category.map(rule => rule.ruleId)
            );

            setSelectedRules(selectedRuleIds);
            console.log("selectedRuleIds",selectedRuleIds);
            setRulesData(initializedRules); // Set initialized rules data
        // }
    }, [formData.workloadId, formData.rulebook]);
    console.log("selectedRuleIds",selectedRules);

    const loadRules = async () => {
        const payload = {
            userId: userId,
            orgId: orgId,        };

        try {
            const response = await getAllRulesApi(payload); // Call the API function
            if (response.success) {
                console.log('Rules fetched successfully:', response.data);
                setRulesData(response.data); // Update state with fetched rules
            } else {
                console.error('Error fetching rules');
            }
        } catch (error) {
            console.error('An error occurred while fetching rules:', error);
        }
    };

    const loadRuleCategories = async () => {
        const payload = {
            userId:userId,
        };

        try {
            const response = await getAllRuleCategoryApi(payload); // Call the API function
            if (response.success) {
                console.log('Rule categories fetched successfully:', response.data);
                setRuleCategories(response.data); // Update state with fetched rule categories

                // Set active tab to the first category after loading categories
                if (response.data.length > 0) {
                    setActiveTab(response.data[0].ruleCategoryId);
                }
            } else {
                console.error('Error fetching rule categories');
            }
        } catch (error) {
            console.error('An error occurred while fetching rule categories:', error);
        }
    };
// PS_66 
const handleRuleToggle = (ruleId) => {
    setRulesData((prevRulesData) => {
        // Create a copy of the current rules data
        const updatedRulesData = { ...prevRulesData };

        // Get the current category name based on active tab
        const currentCategoryName = ruleCategories.find(category => category.ruleCategoryId === activeTab)?.ruleCategoryName;

        // Check if the current category exists in updatedRulesData
        if (currentCategoryName && Array.isArray(updatedRulesData[currentCategoryName])) {
            updatedRulesData[currentCategoryName] = updatedRulesData[currentCategoryName].map(rule => {
                // Toggle the check value if the rule ID matches
                if (rule.ruleId === ruleId) {
                    return { ...rule, check: !rule.check }; // Toggle check value
                }
                return rule; // Return unchanged rules
            });
        }

        return updatedRulesData; // Return the updated rules data
    });

    // Update selected rules based on current state of rulesData
    setSelectedRules((prevSelected) => {
        // Get currently checked rules from updated rules data
        const currentlyCheckedRules = Object.values(rulesData).flatMap(category =>
            category.map(rule => (rule.check ? rule.ruleId : null)).filter(Boolean)
        );

        if (currentlyCheckedRules.includes(ruleId)) {
            return currentlyCheckedRules.filter((id) => id !== ruleId); // Remove if already selected
        } else {
            return [...currentlyCheckedRules, ruleId]; // Add if not selected
        }
    });
};
    
const handleSave = () => {
    console.log("Saving rules...");

    // Constructing the rulebook structure
    const newRulebook = Object.keys(rulesData).map(category => {
        const rulesInCategory = rulesData[category].filter(rule => rule.check); // Get checked rules
        
        // Get category details based on category name
        const categoryDetails = ruleCategories.find(cat => cat.ruleCategoryName === category);

        return {
            categoryId: categoryDetails ? categoryDetails.ruleCategoryId : null, // Set category ID
            categoryName: categoryDetails ? categoryDetails.ruleCategoryName : category, // Set category name
            ruleName: rulesInCategory.map(rule => ({
                rulebook_id: rule.ruleId,
                rule: rule.ruleDesc
            }))
        };
    }).filter(category => category.ruleName.length > 0); // Filter out categories without selected rules

    // Update form data with new rulebook
    setFormData(prevFormData => ({
        ...prevFormData,
        rulebook: newRulebook // Set the constructed rulebook
    }));

    onClose(); // Close modal after saving
    console.log('Updated Form Data:', { ...formData, rulebook: newRulebook });
};
    return (
        <div className="modal fade show" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }} tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-centered justify-content-center">
                <div className="modal-content border-0">
                    <div className="modal-header border-0 p-4">
                        <h1 className="modal-title font-20 font-medium black-v2" id="staticBackdropLabel">Choose Rulebooks</h1>
                        <button type="button" className="btn shadow-none" onClick={onClose} aria-label="Close">
                            <img src="images/close-icon.svg" alt="close-icon" className="icon-hover shadow-none modal-close-filter" />
                        </button>
                    </div>
                    <div className="modal-body px-4 pt-0">
                        <div className="row">
                            {/* Sidebar Menu Start */}
                            <div className="col-md-3 mt-4 custom-border-right ps-4 pe-1 custom-white">
                                <ul className="nav nav-pills flex-column mb-auto flex-nowrap nav-overflow" id="pills-tab" role="tablist">
                                    {ruleCategories.map(category => (
                                        <li className="nav-item" key={category.ruleCategoryId}>
                                            <button
                                                className={`nav-link side-nav-item modal-menu d-flex align-items-center ${activeTab === category.ruleCategoryId ? 'active' : ''}`}
                                                onClick={() => setActiveTab(category.ruleCategoryId)}
                                            >
                                                {category.ruleCategoryName}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            {/* Sidebar Menu End */}

                            {/* Tab Content Start */}
                            <div className="col-md-9 px-4">
                                <div className="table-responsive table-border custom-table-height">
                                    <table className="table custom-table modal-table">
                                        <thead>
                                            <tr>
                                                <th className="border-0">Select</th>
                                                <th className="text-start border-0">Rules</th>
                                            </tr>
                                        </thead>
                                        <tbody className="manage-work">
    {/* Map through rules based on active tab */}
    {activeTab && rulesData[ruleCategories.find(category => category.ruleCategoryId === activeTab)?.ruleCategoryName]?.map(rule => (
        <tr key={rule.ruleId}>
            <td className="text-start">
                <input
                    className="form-check-input custom-border-box shadow-none"
                    type="checkbox"
                    checked={selectedRules.includes(rule.ruleId)||rule.check} // Check if ruleId exists in selectedRules
                    onChange={() => handleRuleToggle(rule.ruleId)} // Handle toggle for this rule
                />
            </td>
            <td className="text-start">{rule.ruleDesc}</td>
        </tr>
    ))}
</tbody>

                                     </table>
                                </div>
                            </div>
                            {/* Tab Content End */}
                        </div>

                        {/* Footer Buttons */}
                        <div className="d-flex justify-content-end align-items-center my-4 gap-3">
                            <button type="button" className="button outline-button text-nowrap px-4" onClick={onClose}>Cancel</button>
                            <div aria-hidden={selectedRules.length === 0 ? "true" : "false"}>
    <button 
        type="button" 
        onClick={() => { handleSave() }} 
        className={`button primary-button text-nowrap px-3 ${selectedRules.length === 0 ? "disabled" : ""}`}
        disabled={selectedRules.length === 0} // Disable button if no rules are selected
    >
        Proceed
    </button>
</div>                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
};

export default ChooseRulebooksModal; 