//PS_01
import React, { useState, useEffect,useContext } from 'react';
//PS_02
import { useNavigate, useLocation } from 'react-router-dom';
import SideBar from '../sidebar/SideBar';
import { getOrganizationDetailsByIdApi } from '../../Service/api';
import { OrgContext } from '../../ContextProvider/OrgContext';

const AboutPage = () => {
    console.log("sankar");
    //PS_09
    const [organizationDetails, setOrganizationDetails] = useState([]);
    const navigate = useNavigate();
    const {
        userRoles,
        orgId,
        providerId,
        architectures,
        canCreateWorkload,
        canDeleteWorkload,
        canViewWorkload,
        setuserRoles,
        setRepository_id,setrepository_name,

    
        // Add organization and provider fields here
        providerName,       
        orgData,           
        setOrgData,        
        setArchitectures,
    
        envId,             // Environment ID
        workloadId,        // Workload ID
        aiArchitecture,    
        viewMode,          
        envName,           
        language,          
        archType,          
        business,          
        rulebook,          
        archDesc,          
        services,
    
        lastBuildID,       
        lastBuildDate,     
        lastBuildMessage,
    
        repoId,            
        repoName,
    
        PulseOrganization_id,
        PulseVertical_id,
        PulseVertical_name,
        PulseOrganization_name,
        PulseIdentification,
    
        setEnvId           // Function to update environment ID
    } = useContext(OrgContext);

    const [userId, setuserId] = useState(localStorage.getItem('userId'));


    // PS_11 Simulate fetching organization details on component mount
    useEffect(() => {
      // PS_12
        fetchOrganizationDetails();
    }, [organizationDetails]);

    useEffect(() => {
        // Initialize Bootstrap tooltips
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        tooltipTriggerList.forEach(tooltipTriggerEl => {
            new window.bootstrap.Tooltip(tooltipTriggerEl);
        });
    }, []);
// PS_13
    const fetchOrganizationDetails = async () => {
        console.log("guru");
        const payload = {
            userId : userId,
        orgId:   orgId,
        }
        try {
            // Call your API to get organization details by ID
            const response = await getOrganizationDetailsByIdApi(payload); // Call the API function
            if (response.success) {
                console.log("Organization details fetched successfully");
                console.log(response.data); // Log the fetched data
    
                // Update state with organization details
                setOrganizationDetails(response.data); // Ensure you have setOrganizationDetails defined

                // setSelectedOptions(response.data.tags.map(tag => ({
                //     value: tag.tagId, // Use tagId as value
                //     label: tag.tagName, // Use tagName as label
                //     name: tag.tagName // You can also use tagName for name or modify as needed
                // })));       
                // setLogoPreviewUrl(response.data.logo) 
                // // setLogoPreviewUrl(URL.createObjectURL(response.data.logo));

            } else {
                // Handle error
                console.error('Error fetching organization details');
            }
        } catch (error) {
            console.error('An error occurred while fetching organization details', error);
        }
    };
   

  

    return (
        <div className="container-fluid">
        <div className="">
    
            <div className="row">
                <div className="col-md-12">
                    <div className="row">
                    <SideBar/>
        <div className="col-md-9 right-container-scroll">
            <div className="d-flex justify-content-between align-items-center ps-4 mt-4">
                <p className="mb-0 font-32 font-bold black-v2">About</p>
                {/* //PS_12 */}
                <button type="button" className="button primary-button text-nowrap custom-btn-spacing" 
                // onClick={()=>{navigate('/editPrimaryDetails',{state:{orgId:organizationDetails.orgId}})}}
                onClick={()=>{navigate('/editPrimaryDetails',{state:{orgId:organizationDetails}})}}

                >
                    <img src="images/btn-edit-icon.svg" alt="edit-icon" className="me-2"  /> Edit
                </button>
            </div>
            <div className="ps-4 mt-4">
                <div className="d-flex justify-content-between align-items-center py-2">
                    <h2 className="font-20 font-bold black-v2 mb-0">Primary Details</h2>
                </div>
            </div>
            <div className="row add-organization-form">
                <div className="col-md-12 mt-4">
                    <div className="px-4">
                        <div className="row about-section-details">
                            <div className="col-md-4 mb-5 pb-2">
                                <label className="form-label font-14 font-medium" htmlFor="Organization-Name">Organization Name</label>
                                <p className="font-16 font-medium mb-0 black-v2">{organizationDetails.orgName}</p>
                            </div>
                            <div className="col-md-4 mb-5 pb-2">
                                <label className="form-label font-14 font-medium" htmlFor="Revenue">Revenue</label>
                                <p className="font-16 font-medium mb-0 black-v2">{organizationDetails.revenue}</p>
                            </div>
                            <div className="col-md-4 mb-5 pb-2">
                                <label className="form-label font-14 font-medium" htmlFor="Industry">Industry</label>
                                <p className="font-16 font-medium mb-0 black-v2">{organizationDetails.industryName}</p>
                            </div>
                            <div className="col-md-4 mb-5 pb-2">
                                <label className="form-label font-14 font-medium" htmlFor="region">Number of Employees</label>
                                <p className="font-16 font-medium mb-0 black-v2">{organizationDetails.noOfEmployees}</p>
                            </div>
                            <div className="col-md-4 mb-5 pb-2">
                                <label className="form-label font-14 font-medium" htmlFor="region">Organization Logo</label>
                                <p className="mb-0"><img src={organizationDetails.logo}                    style={{ width: '150px', height: 'auto' }} // Adjust width as needed
 alt="partner-logo" className="partner-logo" /></p>
                            </div>
                        </div>

                        <h3 className="font-20 font-bold mt-3 mb-4 black-v2">Tags</h3>
                        <div className="row">
                        <div className="col-md-12 mb-4">
            <label className="form-label font-14 font-medium" htmlFor="define-tags">Define Tags</label>
            <button type="button" 
                    className="btn p-0 ms-1 text-nowrap"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    data-bs-title="Define Tagging policy for the organization, so the resources created are consistent">
                <img src="images/tooltip-icon.svg" alt="tooltip-icon" className="toolttip-icon " />
            </button>
            {/* Displaying tags */}
            {organizationDetails.tags && (
                <div className="d-flex gap-2 selected-tags">
                    {organizationDetails.tags
                        .filter(tag => tag.tagName !== "UTI") // Filter out tags with name "UTI"
                        .map((tag, index) => (
                            <span key={index} 
                                  className="selected-item d-flex gap-3 align-items-center font-12 font-medium">
                                {tag.tagName}
                            </span>
                        ))}
                </div>
            )}
        </div>
                        </div>
                        <div className='row'>
    <div className='col-md-4 mb-5 pb-2 black-v2'>
        <label className='form-label font-14 font-medium' htmlFor='mail'>Alert Email ID</label>
        <p className='mb-0'>{organizationDetails.mail || 'No email provided'}</p>
    </div>

    <div className='col-md-4 mb-5 pb-2 black-v2'>
        <label className='form-label font-14 font-medium' htmlFor='teamsUrl'>Teams Integration</label>
        <p className='mb-0'>{organizationDetails.teamsUrl || 'No Teams URL provided'}</p>
    </div>
</div>

                    </div>
                </div>
            </div>
        </div>
        </div></div></div></div></div>
    );
};

export default AboutPage;