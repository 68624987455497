// PS_01 
import React, { createContext,useState, useEffect,useContext } from 'react';
// PS_02
import DeleteConfirmationModal from './Delete_Popup';
// PS_03
import SideBar from '../sidebar/SideBar';
// PS_04
import { useNavigate } from 'react-router-dom'; 
// PS_05
import {getWorkloadDetailsById, getAllWorkloadsApi ,getAllProvidersApi,deleteWorkloadApi,cloneArchitectureApi,getAllWorkloadByOrgIdApi,getAllEnvByWorkloadIdApi,getAllOrgApi} from '../../Service/api';
// PS_06
import { OrgContext } from '../../ContextProvider/OrgContext';
// PS_07
import moment from 'moment';

import Loader from '../loader/loader';
import Pagination from '../CostManagement/Pagination';

const initialFormData = {
	workloadId: "",
	userId: '',
	orgId: '',
	workloadName: '',
	cloudProviderId: '',
	cloudProvider: '',
	language: '',
	business: '',
	architectureType: '',
	architectureId: '',
	rulebook: [
	  {
		categoryId: '',
		categoryName: '',
		ruleName: [
		  {
			rulebook_id: '',
			rule: ''
		  }
		]
	  }
	],
	createArchitectureWithAI: false,
	describeArchitecture: '',
	services: [
	  {
		categoryId: '',
		categoryName: '',
		service: [
		  {
			serviceId: '',
			serviceName: ''
		  }
		]
	  }
	],
	environments: [],
	scmToolName: '',
	repoDetails: [],
	scmCreds: {
	  accessToken: ''
	},
	member: [
	  {
		memberUserId: '',
		memberUserName: '',
		memberEmail: '',
		roleId: '',
		memberRole: ''
	  }
	]
  };
  
// PS_08 create context
	const FormContext = createContext();

	// PS_09
const FormProvider = ({ children }) => {

	// const [formData, setFormData] = useState({
	// 	workloadId:"",
	// 	userId: '',
	// 	orgId: '', 
	// 	workloadName: '',
	// 	cloudProviderId: '',
	// 	cloudProvider: '',
	// 	language: '',
	// 	business: '',
	// 	architectureType: '',
	// 	architectureId: '',
	// 	rulebook: [
	// 		{
	// 			categoryId: '',
	// 			categoryName: '',
	// 			ruleName: [
	// 				{
	// 					rulebook_id: '',
	// 					rule: ''
	// 				}
	// 			]
	// 		}
	// 	],
	// 	createArchitectureWithAI: false,
	// 	describeArchitecture: '',
	// 	services: [
	// 		{
	// 			categoryId: '',
	// 			categoryName: '',
	// 			service: [
	// 				{
	// 					serviceId: '',
	// 					serviceName: ''
	// 				}
	// 			]
	// 		}
	// 	],
	// 	environments: [],

	// 	scmToolName: '',
	// 	repoDetails:[],
	// 	scmCreds: {
	// 		access_token: ''
	// 	},
	// 	member: [
	// 		{
	// 			memberUserId: '',
	// 			memberUserName: '',
	// 			memberEmail: '',
	// 			roleId:'',
	// 			memberRole: ''
	// 		}
	// 	]
	// });


	// PS_10
	
	const [formData, setFormData] = useState(() => {
		const savedFormData = JSON.parse(localStorage.getItem('formData'));
		return savedFormData ? savedFormData : initialFormData;
	  });

	return (
		<FormContext.Provider value={{ formData, setFormData }}>
			{children}
		</FormContext.Provider>
	);
	};

// PS_11
const Workloads = () => {
	console.log("Rendering WorkloadDetailsPage");
	// PS_12 state variables
	const [workloads, setWorkloads] = useState([]);
	const [providers, setProviders] = useState([]);
	const [cloudProvider, setCloudProvider] = useState("AWS");
	const [searchQuery, setSearchQuery] = useState('');
	const [filterCriteria, setFilterCriteria] = useState({ fromDate: '', toDate: '', status: '' });
	const [currentPage, setCurrentPage] = useState(1);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [itemToDelete, setItemToDelete] = useState(null); // Store item to delete
	const [itemType, setItemType] = useState(''); // Type of item (workload or repo)
	const [selectedProviderId, setselectedProviderId] = useState(''); // Type of item (workload or repo)
	const { formData, setFormData } = useContext(FormContext); 
// PS_13
const {
	userRoles,
	orgId,
	providerId,providerId2, setProviderId2,
	architectures,
	canCreateWorkload,
	canDeleteWorkload,
	canViewWorkload,
	setworkloadId,
	setuserRoles,setproviderId,setProviderName,workloadRole, setworkloadRole,

	// Add organization and provider fields here
	providerName, 
	orgData, 
	setOrgData, 
	setArchitectures,

	envId, // Environment ID
	workloadId, // Workload ID
	aiArchitecture, 
	viewMode, 
	envName, 
	language, 
	archType, 
	business, 
	rulebook, 
	archDesc, 
	services,isProjectAdmin, setIsProjectAdmin,
	setPulseIdentification,
	lastBuildID, 
	lastBuildDate, 
	lastBuildMessage,UTI, setUTI,

	repoId, 
	repoName,

	PulseOrganization_id,
	PulseVertical_id,
	PulseVertical_name,
	PulseOrganization_name,
	PulseIdentification, setEnvName,
	setSelectedLangforDownload,
	setEnvId // Function to update environment ID
} = useContext(OrgContext);

console.log("rolew",workloadRole);
const [isAdmin, setisAdmin] = useState(localStorage.getItem('isAdmin')=="true"?true:false);


	useEffect(() => {
		console.log("printt",formData);

	},[formData])
	// State for clone confirmation modal
	const [showCloneModal, setShowCloneModal] = useState(false);
	const [selectedOrganization, setSelectedOrganization] = useState('');
	const [selectedWorkload, setSelectedWorkload] = useState('');
	const [selectedRepository, setSelectedRepository] = useState('');
	const [selectedEnvironment, setSelectedEnvironment] = useState('');
	const [selectedCloneEnvironment, setSelectedCloneEnvironment] = useState('');
	const [selectedCloneEnvironment2, setSelectedCloneEnvironment2] = useState('');
	const [totalcount, settotalcount] = useState('');



	const [environments2, setEnvironments2] = useState([]);
	const [environments, setEnvironments] = useState([]);

	const [organizations, setOrganizations] = useState([]); 
	const [workloads2, setWorkloads2] = useState([]);
	const [totalPages, settotalPages] = useState('');
	const [userId, setuserId] = useState(localStorage.getItem('userId'));
	console.log(localStorage.getItem('userId'),"userrrr");


	const navigate=useNavigate()



	const recordsPerPage = 10;


	// PS_14
	useEffect(() => {
		loadProviders()
		// Continue with other initial loads
		loadWorkloads();
		loadEnvironments();
		loadOrganizations();
	}, []); // Only run once on mount
	
	useEffect(() => {
		const savedProviderId = localStorage.getItem('providerId') || selectedProviderId;
		if (savedProviderId) {
			fetchWorkloads(savedProviderId); // Use the selected or saved provider ID
		}
	}, [searchQuery]); // Trigger this effect when searchQuery changes
		useEffect(()=>{
			setFormData({
				workloadId:"",
				userId: '',
				orgId: '', 
				workloadName: '',
				cloudProviderId: '',
				cloudProvider: '',
				language: '',
				business: '',
				architectureType: '',
				architectureId: '',
				rulebook: [
					{
						categoryId: '',
						categoryName: '',
						ruleName: [
							{
								rulebook_id: '',
								rule: ''
							}
						]
					}
				],
				createArchitectureWithAI: false,
				describeArchitecture: '',
				services: [
					{
						categoryId: '',
						categoryName: '',
						service: [
							{
								serviceId: '',
								serviceName: ''
							}
						]
					}
				],
				environments: [],

				scmToolName: '',
				repoDetails:[],
				scmCreds: {
					access_token: ''
				},
				member: [
					{
						memberUserId: '',
						memberUserName: '',
						memberEmail: '',
						roleId:'',
						memberRole: ''
					}
				]
			});
		},[])
//PS_17
const [loading, setLoading] = useState(false);

const fetchWorkloads = async (selectedProviderId = localStorage.getItem('providerId'), pageNumber = 1) => {
    console.log("Fetching workloads...");
    const payload = {
        userId: userId,
        orgId: orgId,
        from: filterCriteria.fromDate,
        to: filterCriteria.toDate || new Date().toISOString(),
        status: filterCriteria.status,
        search: searchQuery, // Search query
        providerId: selectedProviderId,
        pagination: String((pageNumber - 1) * recordsPerPage) // Calculate starting index for pagination
    };

    setLoading(true); // Start loading

    try {
        const response = await getAllWorkloadsApi(payload); // Call the API function
        console.log("API call completed");

        if (response.success) {
            setCurrentPage(1)
            console.log('Workloads fetched successfully:', response.data);
            setWorkloads(response.data.gridData); // Update state with fetched workloads
            settotalPages(Math.ceil(parseInt(response.data.gridData.totalCount) / recordsPerPage));
            settotalcount(response.data.totalCount);

            const roles = response.data.gridData.map(workload => workload.role);
            setuserRoles(roles);

            const hasProjectAdminRole = response.data.gridData.some(workload => workload.role === "project admin");

            // Set isProjectAdmin based on the presence of the role
            setIsProjectAdmin(hasProjectAdminRole);
            console.log("Roles:", roles);

            setFilterCriteria({ fromDate: '', toDate: '', status: '' });
        } else {
            console.error('Error fetching workloads');
        }
    } catch (error) {
        console.error('An error occurred while fetching workloads:', error);
    } finally {
        setLoading(false); // Stop loading regardless of success or failure
    }
};
	// PS_18 

	
const loadProviders = async () => {
    console.log("checked providers");
    const payload = {
        userId: userId,
        orgId: orgId,
        type: "general"
    };

    try {
        const response = await getAllProvidersApi(payload); // Call the API function
        console.log("checked providers 2");

        if (response.success) {
            console.log('Providers fetched successfully:', response.data);
            setProviders(response.data); // Update state with fetched providers

            // Find the provider ID for AWS and load workloads
            const awsProvider = response.data.find(provider => provider.providerName === "AWS");
            if (awsProvider) {
                setproviderId(awsProvider.providerId); // Set selected provider ID
				localStorage.setItem('providerId', awsProvider.providerId); // Store orgId in localStorage with a key
				localStorage.setItem('providerName', awsProvider.providerName)
				setCloudProvider(awsProvider.providerName); // Set cloudProvider to providerName
			setselectedProviderId(awsProvider.providerId); // Set selected provider ID
			setProviderName(awsProvider.providerName)

                fetchWorkloads(awsProvider.providerId); // Load workloads for AWS
            }
        } else {
            console.error('Error fetching providers');
        }
    } catch (error) {
        console.error('An error occurred while fetching providers:', error);
    }
};

	// PS_21
	const loadWorkloads = async () => {
		const payload = {
			userId : userId,
			orgId :orgId,
			orgIdGeneral:"",
			connectorId:"",
			type:"clone",
		};
// PS_23
	try {
		console.log(payload , "paylaod for the getAllWorkloadByOrgIdApi")
		const response = await getAllWorkloadByOrgIdApi(payload); // Call the API function
		if (response.success) {
			console.log('Workloads fetched successfully:', response.data);
			setWorkloads2(response.data); // Update state with fetched workloads

		} else {
			console.error('Error fetching workloads');
		}
	} catch (error) {
		console.error('An error occurred while fetching workloads:', error);
	}
	};
	//PS_24
	const loadEnvironments = async (event, cloneData) => {
		const payload = {
			userId: userId,
			orgId: orgId,
			workloadId: cloneData,
			type: event ? "clone" : "budget",
		};
// PS_26
	try {
		console.log(payload , "loadEnvironmentsloadEnvironments")
		const response = await getAllEnvByWorkloadIdApi(payload); // Call the API function
		if (response.success) {
			console.log('Environments fetched successfully:', response.data);
			if (event) {
				setEnvironments2(response.data); // Store in setEnvironments2 if event exists
			} else {
				setEnvironments(response.data); // Store in setEnvironments otherwise
			}
		} else {
			console.error('Error fetching environments');
		}
	} catch (error) {
		console.error('An error occurred while fetching environments:', error);
	}
	};
// PS_27
	const loadOrganizations = async () => {
		const payload = {
			userId : userId,
			orgId : orgId
		};
// PS_28
	try {
		console.log(payload , "Payload in Load Organization")
		const response = await getAllOrgApi(payload); // Call the API function
		if (response.success) {
			setOrganizations(response.data);
		} else {
			console.error('Error fetching organizations');
		}
	} catch (error) {
		console.error('An error occurred while fetching organizations:', error);
	}
	};
	const handleWorkloadChange = (e) => {
		const workloadId = e.target.value;
		setSelectedWorkload(workloadId);
		if (workloadId) {
			loadEnvironments(true, workloadId); // Call with clone type
		} else {
			setEnvironments([]); // Clear environments if no workload is selected
		}
	};


	// PS_29
		const fetchWorkloadDetails = async (workload , type) => {
			console.log("Fetching workload details...");

			const payload = {
				userId : userId,
				workloadId:workload.workloadId ,
				organizationId:orgId,

			};
			console.log(payload , "Payload for Fetch Workload")
			try {
				const response = await getWorkloadDetailsById(payload);
				if (response.body.success) {
					console.log("yess");
					const data = response.body.data;
					console.log(data , "Dataaaaaaaaa")
					setProviderId2(data.cloudProviderId)
					console.log("Workload details fetched successfully:");

					// Set formData with values from the API response
				// Assuming this is part of a function where data is available
setFormData(prevFormData => {
	const updatedScmCreds = {}; // Initialize updatedScmCreds

	// Update scmCreds based on the selected scmToolName
	switch (data.scmToolName) {
		case 'github':
		case 'GitLab':
			updatedScmCreds.accessToken = data.scmCreds.access_token || '';
			break;
		case 'azure devops':
			updatedScmCreds.organization = data.scmCreds.organization || '';
			updatedScmCreds.pat_token = data.scmCreds.pat_token || '';
			updatedScmCreds.project = data.scmCreds.project || '';
			break;
		case 'bitbucket':
			updatedScmCreds.workspace = data.scmCreds.workspace || '';
			updatedScmCreds.username = data.scmCreds.username || '';
			updatedScmCreds.app_password = data.scmCreds.app_password || '';
			break;
		default:
			// Reset or handle default case
			break;
	}

	// Set formData with the updated scmCreds
	return {
		...prevFormData, // Preserve existing form data
		workloadId: data.workloadId,
		userId: userId, // Set userId if needed
		orgId: data.orgId,
		workloadName: data.workloadName,
		cloudProviderId: data.cloudProviderId,
		cloudProvider: data.cloudProvider,
		language: data.language, // Set language from API response
		business: data.business,
		architectureType: data.architectureType,
		architectureId: data.architectureId,
		rulebook: data.rulebook.map(rule => ({
			categoryId: rule.categoryId,
			categoryName: rule.categoryName,
			ruleName: rule.ruleName.map(ruleName => ({
				rulebook_id: ruleName.rulebook_id,

			}))
		})),
		createArchitectureWithAI: data.createArchitectureWithAI,
		describeArchitecture: data.describeArchitecture,
		services: data.services ? (data.services.map(service => ({
			categoryId: service.categoryId,
			categoryName: service.categoryName,
			service: service.service.map(svc => ({
				serviceId: svc.serviceId,
				serviceName: svc.serviceName
			}))
		}))) : [],
		environments: data.environments.map(env => ({
			environmentId: env.environmentId,
			environment: env.environment,
			canDelete: env.canDelete,
			uniqueTagIdentifier: env.uniqueTagIdentifier
		})),
		scmToolName: data.scmToolName,
		repoDetails: data.repoDetails.map(repo => ({
			repositoryId: repo.repositoryId,
			name: repo.name, // Handle null case
			techStack: repo.techStack, // Keep as an array
			branches: repo.branches
		})),
		scmCreds: updatedScmCreds, // Use the updated scmCreds object based on scmToolName
		member: data.member.map(mem => ({
			memberUserId: mem.memberUserId,
			memberUserName: mem.memberUserName,
			memberEmail: mem.memberEmail,
			roleId: mem.roleId,
			memberRole: mem.memberRole
		}))
	};
});
	console.log("Workload details fetched successfully:");
	if(type == "Workbench"){
		return true
	}else{
	navigate('/workloadsDetails')
	}
	console.log("Workload details fetched successfully:", response.body.data);
	} else {
		console.error("Failed to fetch workload details:", response.body.message);
	}
	} catch (err) {
		console.error("Error fetching workload details:", err);
	}
	};

	const handleCloneClick = (clone) => {
		console.log("clone",clone);
		loadEnvironments(false,clone)
		setShowCloneModal(true); // Show the clone confirmation modal
	};


// PS_32
	const handleDeleteClick = (workload) => {
		setItemToDelete(workload); // Set the workload to be deleted
		setItemType('workload'); // Set item type to workload
		setShowDeleteModal(true); // Show the delete confirmation modal
		};
// PS_33
	const confirmDelete = async () => {

		if (itemToDelete) {
			const payload = {
				userId : userId,
			orgId :orgId,
				workloadId: itemToDelete.workloadId 
			};

			try {
				const response = await deleteWorkloadApi(payload); // Call the API function
				if (response.success) {
					console.log('Workload deleted successfully:', response.data);
					fetchWorkloads(selectedProviderId,1)
					// setSuccessMessage('Workload deleted successfully');
					// setErrorMessage(''); // Clear any previous error messages
				} else {
					console.error('Error deleting workload');
					// setErrorMessage('Error deleting workload');
					// setSuccessMessage(''); // Clear any previous success messages
				}
			} catch (error) {
				console.error('Failed to delete workload:', error);
				// setErrorMessage('Failed to delete workload');
				// setSuccessMessage(''); // Clear any previous success messages
			}
			// Logic to delete the workload from the state (or backend if applicable)
			// setWorkloads(prevWorkloads => prevWorkloads.filter(w => w.workloadName !== itemToDelete.workloadName));
			setShowDeleteModal(false); // Close the modal after deletion
			setItemToDelete(null); // Reset item to delete
			setItemType(''); // Reset item type
		}
	};
	// PS_34
	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
	};
	// Define today's date
const today = new Date();
const minDate = new Date(today);
minDate.setMonth(today.getMonth() - 6); // Set minimum date to 6 months ago
const maxDate = new Date();
maxDate.setFullYear(maxDate.getFullYear() + 1); // Set maximum date to one year from today

// Format dates to YYYY-MM-DD for input value
const formatDate = (date) => {
    return date.toISOString().split('T')[0];
};



// PS_35
	// Handle change for filter criteria
const handleFilterChange = (e) => {
    const { name, value } = e.target;
    const enteredDate = new Date(value);

    // Check if the entered date exceeds the maximum allowed date for 'toDate'
    if (name === 'toDate' && enteredDate > maxDate) {
        console.error("Entered 'To' date exceeds maximum allowed date.");
        return; // Do not update state if the date is invalid
    }

    // Check if the entered 'From' date is before 'To' date
    if (name === 'fromDate') {
        const toDateValue = new Date(filterCriteria.toDate);
        

		if (enteredDate > maxDate) {
			console.error("Entered date exceeds maximum allowed date.");
			return; // Do not update state if the date is invalid
		}
    }

    setFilterCriteria({
        ...filterCriteria,
        [name]: value
    });
};

// PS_36
	const handlePageChange = (newPage) => {
		// Ensure newPage is at least 1
		if (newPage < 1) {
			console.warn("Attempted to navigate to a page less than 1. Defaulting to page 1.");
			newPage = 1;
		}

		// Ensure newPage does not exceed totalPages
		if (newPage > totalPages) {
			console.warn("Attempted to navigate beyond total pages. Defaulting to last page.");
			newPage = totalPages;
		}

		setCurrentPage(newPage); // Update current page state
		fetchWorkloads(selectedProviderId, newPage); // Fetch workloads for the new page
	};
	// PS_37
	const handleClone = async() => {
		const payload = {
			userId : userId,
			orgId : orgId,
			fromEnviId: selectedEnvironment, // Source environment ID
			toEnviId:selectedCloneEnvironment2 // Target environment ID
		};

		try {
			const response = await cloneArchitectureApi(payload); // Call the API function
			if (response.success) {
				console.log('Architecture cloned successfully:', response.data);

			} else {
				console.error('Error cloning architecture');

			}
		} catch (error) {
			console.error('Failed to clone architecture:', error);

		}
		// Close the clone modal after confirming
		setShowCloneModal(false);
		setSelectedOrganization('');
		setSelectedWorkload('');
		setSelectedRepository('');
		setSelectedEnvironment('');


	};
	const [isReadyToNavigate, setIsReadyToNavigate] = useState(false);
	const handleWorkloadSelection = async (workloadData, env) => {
		setworkloadRole(workloadData.role)

		console.log("Selecting workload and environment" , workloadData);
		console.log(cloudProvider , "ProviderNamae in workload" )
		console.log("ProviderID" , selectedProviderId)
		let res = await fetchWorkloadDetails(workloadData.workloads ,"Workbench")
		console.log(res , "res")
		if(res){
			// Set the workload ID and save it to localStorage
			setworkloadId(workloadData.workloads.workloadId);
			localStorage.setItem('workloadId', workloadData.workloads.workloadId);
			setProviderName(cloudProvider)
			localStorage.setItem("providerName",cloudProvider)
			// Set the environment ID and save it to localStorage
			setEnvId(env.envId);
			setEnvName(env.envName)
			localStorage.setItem('envId', env.envId);
			console.log('envId', env.envName);

			setPulseIdentification(false)
			setproviderId(selectedProviderId)
			localStorage.setItem("providerId",selectedProviderId)
			setSelectedLangforDownload("")
			console.log(formData , "FormData Here")
			
			// Set UTI
			setUTI({ Key: "UTI", Value: env.uniqueTag });

			// Always set isReadyToNavigate to true
			setIsReadyToNavigate(true);
		}
		else{
			console.log("Error in FormData Retreiva")
		}
	};


	useEffect(() => {
		if (isReadyToNavigate == true) {
			console.log("Navigating with Workload ID:", workloadId);
			console.log("Navigating with Environment ID:", envId);
			console.log(formData , "Formadata WB ")
			localStorage.setItem('formdata', JSON.stringify(formData));
			navigate('/Workbench');

			// Reset states after navigating
			setIsReadyToNavigate(false);
			// Optionally reset workloadId and envId if needed
			// setworkloadId(null);
			// setEnvId(null);
		}
}, [isReadyToNavigate]); // Only depend on isReadyToNavigate

	const startIndex = (currentPage - 1) * recordsPerPage;
	const endIndex = startIndex + recordsPerPage;
	const currentWorkloads = workloads.slice(startIndex, endIndex); 
	const displayedCount = currentWorkloads.length;
	console.log("dddgg",displayedCount);
	useEffect(()=>{
		console.log(workloads , "currentWorkloads")
	},[workloads])

	const applyFilters = () => {
		fetchWorkloads(selectedProviderId)
		setTimeout(() => {
			const closeButton = document.getElementById('dropdownMenuClickableInside');
			if (closeButton) {
				closeButton.click();
			}
			}, 100);

    };
	const handleClearFilters = () => {
		// Clear filter criteria
		setFilterCriteria({ fromDate: '', toDate: '', status: '' });
		
		// Fetch workloads with the selected provider ID
		fetchWorkloads(selectedProviderId);
		setTimeout(() => {
			const closeButton = document.getElementById('dropdownMenuClickableInside');
			if (closeButton) {
				closeButton.click();
			}
			}, 100);
	};
	const [sortColumn, setSortColumn] = useState(''); // Column to sort by
const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'
	const handleSort = (column) => {
		const newSortOrder = (sortColumn === column && sortOrder === 'asc') ? 'desc' : 'asc';
		setSortColumn(column);
		setSortOrder(newSortOrder);
	
		// Sort workloads based on selected column and order
		const sortedWorkloads = [...workloads].sort((a, b) => {
			const aValue = a.workloads[column]; // Adjust based on your data structure
			const bValue = b.workloads[column];
	
			if (aValue < bValue) return newSortOrder === 'asc' ? -1 : 1;
			if (aValue > bValue) return newSortOrder === 'asc' ? 1 : -1;
			return 0;
		});
	
		setWorkloads(sortedWorkloads); // Update state with sorted workloads
		setCurrentPage(1); // Reset to first page after sorting
	};
	const [isCloneButtonEnabled, setIsCloneButtonEnabled] = useState(false);
	useEffect(() => {
        validateCloneButton(); // Validate on state change
    }, [selectedEnvironment, selectedOrganization, selectedWorkload, selectedCloneEnvironment2]);

    const validateCloneButton = () => {
        const isValid = selectedEnvironment && selectedOrganization && selectedWorkload && selectedCloneEnvironment2;
        setIsCloneButtonEnabled(isValid);
    };
	const isAnyFilterSelected = filterCriteria.fromDate || filterCriteria.toDate || filterCriteria.status;
	return (
		<div className="container-fluid ps-0">

		<div className="row">
			<SideBar/>
		<div className="col-md-9 mt-4 right-container-scroll">
			<div className="tab-content" id="pills-tabContent">

		<div className="tab-pane fade show active" id="pills-Workloads" role="tabpanel" aria-labelledby="pills-Workloads-tab">

			<div className="px-4 mt-4">

				{/* Table Container Heading Start */}
				<div className="d-xl-flex justify-content-between align-items-center">
					<h2 className="font-32 font-bold black-v2 mb-2 mt-xl-0 mb-0">Workloads</h2>
					<div className="d-flex justify-content-center flex-nowrap gap-3">
						{/* Cloud Provider Dropdown */}
						<div className="dropdown">
	<button 
		type="button" 
		className="btn custom-dropdown d-flex justify-content-between align-items-center gap-5 font-12 font-regular color-black shadow-none"
		id="dropdownMenuButton1" 
		data-bs-toggle="dropdown" 
		aria-expanded="false"
	>
		<span className="d-flex">
			<img src={`images/${cloudProvider.toLowerCase()}-logo.svg`} alt={`${cloudProvider}-img`} className="me-1 icon-width" /> 
			{cloudProvider}
		</span>
		<img src="images/drop-down-arrow.svg" alt="dropdown-arrow" />
	</button>
	<ul className="dropdown-menu body-bg access-dropdown w-100" aria-labelledby="dropdownMenuButton1">
	{providers.map((provider) => (
	<li 
		key={provider.providerId} // Use providerId as the key
		className="font-regular color-black font-12 p-2 custom-list"
		onClick={(event) => {
			event.preventDefault(); // Prevent default action
			setCloudProvider(provider.providerName); // Set cloudProvider to providerName
			setselectedProviderId(provider.providerId); // Set selected provider ID
			// setCurrentPage(1); // Reset to the first page when a new provider is selected
			fetchWorkloads(provider.providerId, 1); // Call fetchWorkloads with the new providerId and page 1
			setproviderId(provider.providerId)
			setProviderName(provider.providerName)
			localStorage.setItem('providerId', provider.providerId); // Store orgId in localStorage with a key
			localStorage.setItem('providerName', provider.providerName)

		}} 
	>
		<span className="d-flex align-items-center">
			<img 
				src={`images/${provider.providerName.toLowerCase()}-logo.svg`} 
				alt={`${provider.providerName}-img`} 
				className="me-1 icon-width" 
			/> 
			{provider.providerName}
		</span>
	</li>
))}
	</ul>
</div>

	{/* Search Bar */}
	<div className="input-group input-search custom-border-box custom-search-box">
		<input type="search"
			className="px-3 form-control common-bg border-0 custom-search gray-v1"
			placeholder="Search"
			value={searchQuery}
			onChange={handleSearchChange} />
		<button className="btn border-start-0 custom-search " type="button"><img
			src="images/search-icon.svg" alt="search" /></button>
	</div>

	{/* Filter Button */}
	<button type="button" className="filter-button px-2 position-relative"
		id="dropdownMenuClickableInside" data-bs-toggle="dropdown"
		data-bs-auto-close="outside"><img src="images/filter-blue.svg"
			alt="filter-icon" /></button>

	{/* Filter Dropdown */}
	<div className="dropdown-menu dropdown-menu-end border-0 custom-shadow background-white dd-position p-4 custom-filter-width"
		aria-labelledby="dropdownMenuClickableInside" >
		<h5 className="font-medium font-16 mb-0 color-black mb-4">Filter</h5>

		<div className="row">
			<div className="col-md-12 mb-2">
				<label className="font-14 font-medium black-v2">Date Range</label>
			</div>

		<div className="col-md-6 mb-4">
    <label className="form-label font-14 font-medium label-color black-v2 mb-1" htmlFor="from">From</label>
    <input 
        type='date' 
        name='fromDate' 
        value={filterCriteria.fromDate} 
        onChange={handleFilterChange} 
        min={formatDate(minDate)} // Set minimum date for From input
        max={formatDate(maxDate)} // Set maximum date for From input
        className='form-control custom-dropdown shadow-none py-1 font-14 font-regular black-v2' 
    />
</div>
<div className='col-md-6 mb-4'>
    <label htmlFor='to' className='form-label font-14 font-medium label-color black-v2 mb-1'>To</label>
    <input 
        type='date' 
        name='toDate' 
        value={filterCriteria.toDate} 
        onChange={handleFilterChange} 
        min={formatDate(minDate)} // Set minimum date for To input
        max={formatDate(maxDate)} // Set maximum date for To input
        className='form-control custom-dropdown shadow-none py-1 font-14 font-regular black-v2' 
    />
</div>
			<div className='col-md-6 mb-4'>
				<label htmlFor='status' 
					className='form-label font-14 font-medium black-v2 mb-1'>Status</label>
				<select name='status' value={filterCriteria.status} onChange={handleFilterChange}
					className='form-select custom-dropdown shadow-none form-field-border py-1 font-14 font-regular black-v2'>
					<option selected>Select</option>
					{/* Add more options as needed */}
					<option value='true'>Active</option>
					<option value='false'>Inactive</option>
				</select>
			</div>

			{/* Apply and Clear Buttons */}
			<div className='col-md-12'>
				<div
					className='d-flex justify-content-end align-items-center gap-3'>
					<button
    className='btn button outline-button px-4'
    onClick={handleClearFilters}
>
    Clear All
</button>
					<button  
              disabled={!isAnyFilterSelected}
						className='btn button primary-button px-3'  style={{ cursor: 'pointer' }} data-bs-dismiss="modal" onClick={()=>applyFilters()}>Apply</button>
				</div>
			</div>
		</div>
	</div>

	{/* Add Workload Button */}
	{isAdmin&& <button type='button' 
		className='button primary-button text-nowrap' onClick={()=>{navigate('/workloadsDetails')}}>
		<img src='images/add-icon.svg' alt='add-icon' 
			className='me-2' /> Add Workload
		</button>} 
		</div>
	</div>
	{/* Table Container Heading End */}

	{/* Table Start */}
	{currentWorkloads.length > 0 ? (
		<>
			<div className='table-responsive mt-4 table-border'>
			{loading ? (
<Loader/> ) : (
	<table className='table custom-table'>
		<thead>
			<tr>
			<th onClick={() => handleSort('workloadName')}>
                Workloads
                <span className="cursor-pointer margin-left-2">
                    <img 
                        src="./images/table-sort.svg" 
                        alt="sort" 
                        style={{
                            transform: 
                                sortColumn === "workloadName" && sortOrder === "asc" 
                                    ? "scaleY(-1)" // Flip icon for ascending order
                                    : "scaleY(1)",  // Normal position for descending order
                            transition: "transform 0.2s ease-in-out", // Smooth transition
                            cursor: 'pointer' // Pointer cursor for better UX
                        }} 
                    />
                </span>
            </th>				<th>Environments</th>
				<th>Last Modified</th>
				<th>Status</th>
				<th>Actions</th>
			</tr>
		</thead>
		<tbody>
			{currentWorkloads.map((workloadData, index) => {
				const workload = workloadData.workloads;
				const environments = workloadData.env;
				console.log(environments , "Environmentssss")
				console.log(workload , "Workload!!!!")
				return (
					<tr key={index}>
						{/* Workload Name with Link */}
						<td>
							<a className='primary-color text-decoration-none font-medium' onClick={() => {
								setworkloadId(workloadData.workloads.workloadId);
								localStorage.setItem('workloadId', workloadData.workloads.workloadId);
								setworkloadRole(workloadData.role)
								// When users Tries to Navigate to Repo workbench the First Environment is Set by default
								if(environments.length >=1){
									setEnvId(environments[0].envId)
									setEnvName(environments[0].envName)
									localStorage.setItem('envId', environments[0].envId);
									setproviderId(selectedProviderId)
									localStorage.setItem("providerId",selectedProviderId)
									setProviderName(cloudProvider)
									localStorage.setItem("providerName",cloudProvider)
								}
								// Provider ID and Provider Name 
								

								
								navigate('/repositoryGrid', { state: { workloadName: workload.workloadName } });
							}} 
							style={{"cursor":"pointer"}}>
								{workload.workloadName}
							</a>
						</td>

						{/* Environments in Single Column with Logic for Count Display */}
						<td>
							{environments.length > 3 ? (
								<>
									{/* First Two Environments */}
									{environments.slice(0, 2).map((env, idx) => (
										<button 
											onClick={() => { handleWorkloadSelection(workloadData, env) }}
											type='button'
											data-bs-toggle={env.envName === 'env-1' ? 'modal' : undefined}
											data-bs-target={env.envName === 'env-1' ? '#staticBackdrop' : undefined}
											key={idx}
											className={`table-env-btn ${env.envName === 'env-1' ? 'active' : ''}`}>
											{env.envName}
										</button>
									))}
									{/* Display count of extra environments as a button */}
									{environments.length > 2 && (
										<> 
											&nbsp;<button 
												type='button' className='table-env-btn'
												>
												+{environments.length - 2}
											</button> 
										</>
									)}
								</>
							) : (
								environments.map((env, idx) => (
									<button 
										onClick={() => { handleWorkloadSelection(workloadData, env) }}
										type='button'
										data-bs-toggle={env.envName === 'env-1' ? 'modal' : undefined}
										data-bs-target={env.envName === 'env-1' ? '#staticBackdrop' : undefined}
										key={idx}
										className={`table-env-btn ${env.envName === 'env-1' ? 'active' : ''}`}>
										{env.envName}
									</button>
								))
							)}
						</td>

						{/* Last Modified Date */}
						<td>{moment(workloadData.lastModified).format('MM/DD/YYYY HH:mm')}</td>
						{/* Status Indicator */}
						<td>
							<span className={`status ${workloadData.status ? 'active' : 'inactive'} me-2`}></span>
							{workloadData.status ? 'Active' : 'Inactive'}
						</td>

							{/* Actions Column */}
							<td>
				{/* Edit Icon */}
				{(workloadData.role === "admin" || workloadData.role === "project admin") ? (
					<a onClick={() => fetchWorkloadDetails(workload)}>
						<img src='images/edit-icon.svg' alt='edit-icon' style={{"cursor":"pointer"}} />
					</a>
				) : (
					<span style={{ visibility: 'hidden' }}>
						<img src='images/edit-icon.svg' alt='edit-icon' />
					</span>
				)}

				&nbsp; 

				{/* Copy Icon */}
				{(workloadData.role === "admin" || workloadData.role === "project admin") ? (
					<a href="#" onClick={() => handleCloneClick(workloadData.workloads.workloadId)}>
						<img src='images/copy-icon.svg' alt='copy-icon'/>
					</a>
				) : (
					<span style={{ visibility: 'hidden' }}>
						<img src='images/copy-icon.svg' alt='copy-icon'/>
					</span>
				)}

				&nbsp; 

				{/* Delete Icon */}
				{(workloadData.role === "admin" || workloadData.role === "project admin") && workloadData.canDelete ? (
					<a href="#" onClick={() => handleDeleteClick(workload)}>
						<img src='images/delete-icon.svg' alt='delete-icon'/>
					</a>
				) : (
					<span style={{ visibility: 'hidden' }}>
						<img src='images/delete-icon.svg' alt='delete-icon'/>
					</span>
				)}
</td>
	</tr> 
	);
	})}
	</tbody>
	</table>
	)}


		</div> <Pagination 
			currentPage={currentPage} 
			setCurrentPage={setCurrentPage} 
			totalItems={totalcount} 
			itemsPerPage={recordsPerPage} 
	displayedCount={displayedCount}

		/> 

		</>
		) : (
			// No Records Found Message
			<>
	<div className="d-flex flex-column justify-content-center align-items-center custom-height">
		<img src="images/nodata-found.svg" alt="No Data Found" />
		<h6 className="color-black font-bold font-16 mt-5 mb-0">No Workload Found!</h6>
		<p className="font-medium font-12 mt-4 mb-0 secondary-text-color-v5">
			Add new Workloads to see the data here.
		</p>
		</div> </>
		)}

			<div className='d-flex justify-content-between align-items-center my-4'>
		{/* Record Count */}

		{/* Pagination Controls */}

</div>
	{showDeleteModal && (
		<DeleteConfirmationModal 
			item={itemToDelete} 
			itemType={itemType} 
			onDeleteConfirm={confirmDelete} 
			onClose={() => setShowDeleteModal(false)} 
		/>
	)}
		{/* Clone Confirmation Modal */}
		{showCloneModal && (
	<div className={`modal fade ${showCloneModal ? 'show d-block' : ''}`} 
		style={{ display: showCloneModal ? 'block' : 'none', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
		<div className="modal-dialog modal-dialog-centered justify-content-center">
			<div className="modal-content border-0">
				<div className="modal-header border-0 p-4">
					<h1 className="modal-title font-20 font-bold black-v2" id="staticBackdropLabel">Clone Environment</h1>
					<button type="button" className="btn shadow-none" onClick={() => setShowCloneModal(false)} aria-label="Close">
						<img src="images/close-icon.svg" alt="close-icon" className="icon-hover shadow-none modal-close-filter" />
					</button>
				</div>
				<div className="modal-body px-4 pt-0">
					<h6 className="font-16 font-medium black-v2 mb-3">From</h6>
					<div className="my-3">
						<label className="form-label font-14 font-medium black-v2" htmlFor="environmentSelect">Environment <span className="danger-red">*</span></label>
						<select
	className="form-select custom-dropdown shadow-none custom-border-box font-14 font-regular black-v2 custom-select"
	aria-label="Default select example"
	id="environmentSelect"
	value={selectedEnvironment}
	onChange={(e) => setSelectedEnvironment(e.target.value)}
	>
		<option value="">Select</option>
		{environments.map(env => (
			<option key={env.envId} value={env.envId}>
				{env.envName} {/* Display envName while binding envId */}
			</option>
		))}
	</select>
		</div>

		<h6 className="font-16 font-medium black-v2 mt-4 mb-0">To</h6>
		<div className="my-3">
			<label className="form-label font-14 font-medium black-v2" htmlFor="organizationSelect">Organization <span className="danger-red">*</span></label>
			<select
		className="form-select custom-dropdown shadow-none custom-border-box font-14 font-regular black-v2 custom-select"
		aria-label="Default select example"
		id="organizationSelect"
		value={selectedOrganization}
		onChange={(e) => setSelectedOrganization(e.target.value)}
	>
		<option value="">Select</option>
		{organizations?.map(org => (
			<option key={org.orgId} value={org.orgId}>
				{org.orgName} {/* Display orgName while binding orgId */}
			</option>
		))}
	</select>
		</div>

		<div className="my-3">
			<label className="form-label font-14 font-medium black-v2" htmlFor="workloadSelect">Workload <span className="danger-red">*</span></label>
			<select
		className="form-select custom-dropdown shadow-none custom-border-box font-14 font-regular black-v2 custom-select"
		id="workloadSelect"
		value={selectedWorkload}
		onChange={handleWorkloadChange}
	>
		<option value="">Select</option>
		{workloads2.workload.map(workload => (
			<option key={workload.workloadId} value={workload.workloadId}>
				{workload.workloadName} {/* Display workloadName while binding workloadId */}
			</option>
		))}
	</select>
		</div>
		<div className="my-3">
			<label className="form-label font-14 font-medium black-v2" htmlFor="cloneEnvironmentSelect">Clone Environment<span className="danger-red">*</span></label>
			<select
		className="form-select custom-dropdown shadow-none custom-border-box font-14 font-regular black-v2 custom-select"
		aria-label="Default select example"
		id="cloneEnvironmentSelect"
		value={selectedCloneEnvironment2}
		onChange={(e) => setSelectedCloneEnvironment2(e.target.value)}
	>
		<option value="">Select</option>
		{environments2.map(env => (
			<option key={env.envId} value={env.envId}>
				{env.envName} {/* Display envName while binding envId */}
			</option>
		))}
	</select>
		</div>

		<div className='d-flex justify-content-end align-items-center mt-4 mb-2 gap-3'>
			{/* Cancel Button */}
			<button type='button' 
				className='button outline-button text-nowrap py-12'
				onClick={() => setShowCloneModal(false)}>Cancel</button>

			{/* Clone Button */}
			<button type='button' 
                                className={`button primary-button text-nowrap px-4 py-12 ${!isCloneButtonEnabled ? 'disabled' : ''}`}
                                disabled={!isCloneButtonEnabled}
                                onClick={handleClone}>Clone</button>
		</div>

		</div>

		</div>
		</div>
		</div>
	)}

	</div> 
	</div>

	</div>

</div>
</div>
</div>

	);
};


export { FormProvider, FormContext };

export default Workloads;
