import React, { useContext, useEffect, useState } from 'react';
import { getAllEnvByWorkloadIdApi } from '../../Service/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { OrgContext } from '../../ContextProvider/OrgContext';

const InsightsView = () => {

  //PS_43 
  const location = useLocation();
  const navigate = useNavigate()
  const insightViewData = location.state?.insightViewData ?? [];
  console.log(insightViewData, "insightViewData")
  const { setworkloadId, setProviderName, setEnvId, setEnvName, setproviderId, orgId, setOrgId } = useContext(OrgContext)


  // PS_44
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [expandedOrgId, setExpandedOrgId] = useState(null);

  // Function to handle organization click
  // const handleOrgClick = (orgId) => {
  //   setSelectedOrgId(orgId);
  // };

  // Check if insightViewData exists and handle accordingly
  if (!insightViewData || !Array.isArray(insightViewData) || insightViewData.length === 0) {
    return (
      <div className="no-record-found">
        <img src="images/no-records-found.png" className="no-record-icon" />
      </div>
    );
  }


  //PS_33- PS_34
  const handleViewInsightsClick = async (WorkLoadID, organizationId) => {
    // debugger
    try {
      console.log(WorkLoadID, "WorkLoadID")

      const userId = localStorage.getItem('userId')
      console.log(userId, WorkLoadID, "++++++++++++++++++++++")
      const response = await getAllEnvByWorkloadIdApi(
        {
          userId: userId,
          workloadId: WorkLoadID,
          isInsights: "Yes",
          type: "budget",
        });
      if (response.success == true) {
        const data = await response.data[0]['envId'];
        console.log(data, "Workbench data")
        console.log(organizationId, "orgId inside view")

        setworkloadId(WorkLoadID);
        localStorage.setItem('workloadId', WorkLoadID);

        // setProviderName(template.providerName)
        // localStorage.setItem("providerName",template.providerName)

        setEnvId(data);
        // setEnvName(template.envName)
        localStorage.setItem('envId', data);

        // setproviderId( template.providerId)
        // localStorage.setItem("providerId", template.providerId)

        setOrgId(organizationId)
        localStorage.setItem("orgId", organizationId)

        navigate('/Workbench');
      }
    } catch (error) {
      console.log(error);
    }
  };

  // There's only one organization in the array, so we'll work with that
  // const organization = insightViewData[0];


  //PS_49 -PS_52
  const bindWorkloads = (workloads, organizationId) => {
    console.log(organizationId, "orgIDDD")
    if (!Array.isArray(workloads) || workloads.length === 0) {
      return (
        <div className="no-record-found">
          <img src="images/no-records-found.png" className="no-record-icon" />
        </div>
      )
    }

    return workloads.map((workload) => (
      <li className="py-4" key={workload.WorkLoadID}>
        <div className="d-flex align-items-center justify-content-between title-container">
          <div>
            <a href="#" className="mb-0 d-flex align-items-center font-14 font-medium black-v2 text-decoration-none">
              {workload.WorkLoadName}{" "}
              <button className="domain-btn font-14 font-medium bg-primary-dark-v1 ms-2">
                <img src={workload.WorkLoadHighInsigths.includes("Speedup") ? "images/insights-speedup-icon.svg" : "images/insights-faster-analysis-icon.svg"} alt={workload.WorkLoadHighInsigths} />{" "}
                <span title={workload.WorkLoadHighInsigths}>
                  {workload.WorkLoadHighInsigths.length > 50 ? `${workload.WorkLoadHighInsigths.slice(0, 50)}...` : workload.WorkLoadHighInsigths}
                </span>
              </button>
            </a>
            <p className="mb-0 font-14 font-regular mt-2 pt-1 secondary-text-color-v4 text-truncate">
              {workload.WorkLoadDescription}
            </p>
          </div>
          {/* <a href="#" className="primary-color font-16 font-medium text-decoration-none"></a> */}

          <a
            //href="#"
            className="primary-color font-16 font-medium text-decoration-none"
            onClick={(e) => {
              handleViewInsightsClick(workload.WorkLoadID, organizationId);
            }}
          >View Insights </a>

        </div>
      </li>
    ));
  };


  //PS_45- PS_48

  // Render all organization cards
  const renderOrganizationCards = () => {
    return insightViewData.map((organization) => (
      <div className="accordion-item body-background insights-accordion border-0 mt-4 pt-1 mb-2" key={organization.organizationId}>
        <div className="insights-container mb-1">
          <h2 className="accordion-header d-flex align-items-center" id={`heading-org-${organization.organizationId}`}>
            <button
              className={`custom-acc-btn d-flex align-items-center gap-2 font-14 btn border-0 ${expandedOrgId === organization.organizationId ? '' : 'collapsed'}`}
              type="button"
              onClick={() => setExpandedOrgId(expandedOrgId === organization.organizationId ? null : organization.organizationId)}
            >
              <span className="cust-acc-arrow d-flex align-items-center justify-content-center">
                <img src="images/violet-arrow-right.svg" alt="arrow-right" />
              </span>
            </button>
            <span className="partner-img ms-3">
              <img
                src={organization.organizationImageUrl}
                alt={`${organization.organizationName} logo`}
                className="partner-logo"
              />
            </span>
            <span className="black-v2 font-16 font-medium">{organization.organizationName}</span>
          </h2>
          <div
            id={`collapse-org-${organization.organizationId}`}
            className={`accordion-collapse collapse ${expandedOrgId === organization.organizationId ? 'show' : ''}`}
            aria-labelledby={`heading-org-${organization.organizationId}`}
          >
            <div className="accordion-body">
              <ul className="list-unstyled px-4">
                {bindWorkloads(organization.WorkLoad, organization.organizationId)}
              </ul>
            </div>
          </div>
        </div>
      </div>
    ));
  };





  // PS_53 - PS_54
  return (
    <>
      <>
        {/* header starts */}

        {/* header ends */}
        <div className="container-fluid custom-container right-container-scroll">
          {/* Slide title start here*/}
          <div className="d-flex justify-content-between align-items-center pt-4 mt-2 notifications-title-bar">
            <div className="d-flex align-items-center mb-3 mb-md-0 ">
              <a href="#">
                <img
                  src="images/back-arrow.svg"
                  alt="back-arrow"
                  title="Back"
                  className="icon-filter"
                  onClick={() => { navigate('/Insights') }}
                />
              </a>
              <h2 className="font-20 font-bold black-v2 mb-0 ms-3 ">
                View Insights
              </h2>
            </div>
          </div>
          {/* Slide title end here*/}
          {/* Page Content Start here */}
          <div className="col-md-12">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item body-background insights-accordion border-0 mt-4 pt-1 mb-2">

                {renderOrganizationCards()}


              </div>
            </div>
          </div>
          {/* Page Content End here */}
        </div>
      </>

    </>
  )
}
export default InsightsView;