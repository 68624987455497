import React, { useState, useEffect, useContext } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { getCostByWorkloads } from '../../Service/api'; // Adjust the import path as needed
import { OrgContext } from '../../ContextProvider/OrgContext';
import moment from 'moment';
import { DashboardContext } from './Context/DashboardContext';

const CostByWorkloads = ({ period, fromDate, toDate, selectedProviderId }) => {
  const [workloadData, setWorkloadData] = useState([]);
  const [error, setError] = useState(null);
  const { orgId } = useContext(OrgContext);
  const [showOnlyActualCost, setShowOnlyActualCost] = useState(false);
  const {workloads, setWorkloads} = useContext(DashboardContext);

  const noDataStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    margin: 0,
    fontSize: '14px',
    color: '#333',
  };

  useEffect(() => {
    if (!selectedProviderId || !orgId) {
      return;
    }

    const fetchWorkloadData = async () => {
      setError(null);
      try {
        const userId = localStorage.getItem('userId');
        const formattedFromDate = fromDate ? moment(fromDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : '';
        const formattedToDate = toDate ? moment(toDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : '';
    
        const payload = {
          userId,
          orgId: orgId,
          providerId: selectedProviderId,
          from: formattedFromDate,
          to: formattedToDate
        };
    
        setShowOnlyActualCost(!!formattedFromDate && !!formattedToDate);

        const response = await getCostByWorkloads(payload);
    
        if (response.data && response.data.success) {
          const transformedData = response.data.data.map(item => ({
            workloadId: item.workloadId,
            workloadName: item.workloadName,
            budget: item.budget ? parseFloat(item.budget) : null,
            cost: item.cost ? parseFloat(item.cost) : null
          }));

          let exportedData = transformedData?.map((value,index) => {
            return {
                Workloads: value?.workloadName ?value?.workloadName : "" ,
                Budget: value?.budget ? value?.budget  :  0 ,
                Actual_Cost: value?.cost ? value?.cost : 0
            }
          })

          setWorkloads(exportedData)
          setWorkloadData(transformedData);
        } else {
          setError(response.data?.message || 'Failed to fetch workload data');
          console.log('API request failed:', response.data?.message);
        }
      } catch (error) {
        console.log('Error fetching workload data:', error);
        setError('An error occurred while fetching workload data');
      }
    };

    fetchWorkloadData();
  }, [orgId, selectedProviderId, period, fromDate, toDate]);

  const getChartData = () => {
    return workloadData.map(item => ({
      name: item.workloadName,
      cost: item.cost !== null ? parseFloat(item.cost) : 0,
      budget: item.budget !== null ? parseFloat(item.budget) : 0
    }));
  };

  if (error) return <div style={noDataStyle}><p className='color-black'>No data found</p></div>;

  if (workloadData.length === 0) {
    return <div style={noDataStyle}><p className='color-black'>No data found</p></div>;
  }

  return (
    <div style={{ width: '100%', height: 300, minWidth: '300px' }}>
      <ResponsiveContainer width="100%" height="100%" >
        <LineChart data={getChartData().slice(0, 8)} margin={{ top: 30, right: 30, left: 30, bottom: 50 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="name" 
            angle={-40}
            textAnchor="end"
            height={80}
            interval={0}
            tick={{fontSize: 12}}
            tickFormatter={(name) => name.length > 9 ? `${name.slice(0, 6)}...` : name}
          />
          <YAxis tick={{fontSize: 10}} />
          <Tooltip />
          
          <Line type="linear" dataKey="cost" stroke="#8884d8" name="Actual Cost"  />
          {!showOnlyActualCost && (
            <Line type="linear" dataKey="budget" stroke="#82ca9d" name="Budget" />
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CostByWorkloads;