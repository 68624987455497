// PS_01 
import React, { useState, useEffect, useContext } from 'react';
// PS_02 
// import { getCloudProvidersApi, getAllLanguagesApi, getArchitectureTypesApi, getRulesApi, getAllServicesApi } from './WorkloadApi';
import { FormContext } from './Workload_Grid';
import ChooseServicesModal from './ChooseServicesModel'
import ChooseRulebooksModal from './ChooseRulebooks';
import { useNavigate } from 'react-router-dom';
import { getAllMethodsApi, getAllProvidersApi, getArchTypeByAiApi } from '../../Service/api';
import SideBar from '../sidebar/SideBar';
import { OrgContext } from '../../ContextProvider/OrgContext';
const WorkloadDetailsPage = () => {

  // PS_04 state variables
  const [providers, setProviders] = useState([]);

  const [languages, setLanguages] = useState([]);
  const [architecture, setarchitecture] = useState([]);
  // const [rules, setRules] = useState([]);
  // const [services, setServices] = useState([]);
  const [isNextEnabled, setIsNextEnabled] = useState(false);
  const { formData, setFormData } = useContext(FormContext);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const {
    userRoles,
    orgId,
    providerId,
    architectures,
    canCreateWorkload,
    canDeleteWorkload,
    canViewWorkload,
    setProviderName,
    setuserRoles, providerId2, setProviderId2,

    // Add organization and provider fields here
    providerName,
    orgData,
    setOrgData,
    setArchitectures,

    envId,             // Environment ID
    workloadId,        // Workload ID
    aiArchitecture,
    viewMode,
    envName,
    language,
    archType,
    business,
    rulebook,
    archDesc,
    services,

    lastBuildID,
    lastBuildDate,
    lastBuildMessage,

    repoId,
    repoName,

    PulseOrganization_id,
    PulseVertical_id,
    PulseVertical_name,
    PulseOrganization_name,
    PulseIdentification,

    setEnvId           // Function to update environment ID
  } = useContext(OrgContext);


  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isRulesVisible, setIsRulesVisible] = useState(false);
  const [userId, setuserId] = useState(localStorage.getItem('userId'));

  const navigate = useNavigate()
  // PS_03  Define useEffect hook to fetch workload details on component mount   

  useEffect(() => {
    fetchWorkloadDetails();
    handleGenerateWithAI()
    fetchWorkloadDetails2()

  }, []);

  useEffect(() => {
    if (formData.workloadId) {
      // Check if architectureId exists in formData
      if (formData.architectureId) {
        const selectedArchitecture = architecture.find(type => type.architectureTypeId === formData.architectureId);
        if (selectedArchitecture) {
          setFormData(prevFormData => ({
            ...prevFormData,
            architectureType: selectedArchitecture.architectureTypeName // Set name based on existing ID
          }));
        }
      }
    }
  }, [formData.workloadId, formData.architectureId, architecture])



  const fetchWorkloadDetails2 = async (providerId) => {
    const payload = {
      userId: userId,
      orgId: orgId,
      providerId: providerId ? providerId : formData.cloudProviderId,
    };

    try {
      const response = await getAllMethodsApi(payload); // Call the API function
      if (response.success) {
        console.log('Methods fetched successfully:', response.data);
        setLanguages(response.data); // Update state with fetched methods
      } else {
        console.error('Error fetching methods');
      }
    } catch (error) {
      console.error('An error occurred while fetching methods:', error);
    }

  };

  // PS_04
  const fetchWorkloadDetails = async () => {
    const payload2 = {
      userId: userId,
      orgId: orgId,
      type: "general"
    };

    try {
      const response = await getAllProvidersApi(payload2); // Call the API function
      console.log("checked providers 2");

      if (response.success) {
        console.log('Providers fetched successfully:', response.data);
        setProviders(response.data); // Update state with fetched providers
      } else {
        console.error('Error fetching providers');
      }
    } catch (error) {
      console.error('An error occurred while fetching providers:', error);
    }
  };
  // PS_19 
  const handleCloudProviderChange = (providerId) => {
    fetchWorkloadDetails2(providerId)
    setProviderId2(providerId)
    // Find the selected provider based on the providerId
    const selectedProvider = providers.find(provider => provider.providerId === providerId);

    // Update formData with both cloudProviderId and cloudProviderName
    setFormData({
      ...formData,
      cloudProviderId: providerId,
      cloudProvider: selectedProvider ? selectedProvider.providerName : '' // Set name or empty if not found
    });
  };

  const handleArchitectureChange = (architectureId) => {
    // Find the selected architecture type based on the architectureId
    const selectedArchitecture = architecture.find(type => type.architectureTypeId === architectureId);

    // Update formData with both architectureId and architectureType
    setFormData(prevFormData => ({
      ...prevFormData,
      architectureId: architectureId,
      architectureType: selectedArchitecture ? selectedArchitecture.architectureTypeName : '' // Set name or empty if not found
    }));
    // setArchitectures(selectedArchitecture.architectureTypeName)
  };
  // PS_19 
  const handleLanguageChange = (language) => {
    setFormData({ ...formData, language: language });
  };
  // PS_19 
  const handleBusinessChange = (business) => {
    setFormData({ ...formData, business: business });
  };
  // PS_19 
  const handleGenerateWithAI = async () => {
    const payload = {
      userId: userId,
      orgId: orgId,
      providerId: providerId2,
      business: formData.business, // Business description from state
      archi: formData.describeArchitecture // Architecture description from state
    };

    try {
      const response = await getArchTypeByAiApi(payload); // Call the API function
      if (response.success) {
        console.log('Architecture types fetched successfully:', response.data);
        setarchitecture(response.data); // Update state with fetched architecture types
        if (response.data?.length > 0) {
          const firstArchitecture = response.data[0];
          setFormData(prevFormData => ({
            ...prevFormData, // Preserve previous values
            architectureId: firstArchitecture.architectureTypeId,
            architectureType: firstArchitecture.architectureTypeName,
            // Include other necessary fields if needed
          }));
        }
      } else {
        console.error('Error fetching architecture types');
      }
    } catch (error) {
      console.error('An error occurred while fetching architecture types:', error);
    }
  };


  const handleNextClick = () => {
    if (isNextEnabled) {

    }
  };
  const [errorMessage, setErrorMessage] = useState('');

  const handleWorkloadNameChange = (e) => {
      const value = e.target.value;

      // Regular expression to allow only letters (a-z, A-Z)
      const regex = /^[A-Za-z]*$/;

      if (regex.test(value) || value === '') {
          setFormData({ ...formData, workloadName: value });
          setErrorMessage(''); // Clear error message if valid
      } else {
          setErrorMessage('Please enter only letters (no numbers, spaces, or special characters).');
      }
  };


  const isRulebooksFilled = () => {

    // Ensure that rulebook exists and has at least one category
    if (!formData?.rulebook || formData?.rulebook?.length === 0) {
      return false; // No rulebooks available
    }

    // Check if there is at least one valid rulebook with non-empty rule names
    const hasValidRules = formData?.rulebook?.some(category =>
      category.ruleName && category.ruleName.length > 0 // Check if there are any rules in this category
    );

    return hasValidRules; // Return true only if there's at least one valid rule
  };
  // PS_70 
  const isAllFieldsFilled = () => {
    console.log("Service length:", formData.services);

    // Check if "Create Architecture with AI" is checked
    const createArchitectureWithAIChecked = formData.createArchitectureWithAI;

    // Check if there is at least one service with a valid serviceId and serviceName only if the checkbox is checked
    const hasValidServices = createArchitectureWithAIChecked
      ? formData?.services?.some(category =>
        category?.service?.some(service =>
          service.serviceId && service.serviceName // Check if both serviceId and serviceName are filled
        )
      )
      : true; // If not checked, consider it valid (or you can set it to false based on your requirements)

    // Check if all required fields in formData are filled
    const allRequiredFieldsFilled = [
      formData.workloadName,
      formData.cloudProviderId,
      formData.language,
      formData.business,
      formData.architectureId,
      formData.architectureType
    ].every(field => field); // Ensure all fields are truthy

    return allRequiredFieldsFilled && hasValidServices; // Return true only if all conditions are met
  };

  useEffect(() => {
    setIsNextEnabled(isAllFieldsFilled()&&isRulebooksFilled()); // Ensure both services and rules are filled
  }, [formData]);
  // PS_42


  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  // PS_42
  const handleOpenRules = () => {
    setIsRulesVisible(true);
  };

  const handleCloseRules = () => {
    setIsRulesVisible(false);
  };
  const handleSaveServices = (newServices) => {
    // Update form data with new services
    setFormData(prevFormData => ({
      ...prevFormData,
      services: newServices.filter(category => category.service?.length > 0) // Only include categories with selected services
    }));

    console.log('Updated Form Data:', { ...formData, services: newServices });

    // Close modal after saving
    handleCloseModal();
  };
  return (
    <div className="container-fluid">
      <div className="">

        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <SideBar />
              <div className="col-md-9 px-4">


                <div className="tab-content" id="pills-tabContent">

                  <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

                    <div className="row px-3">

                      <div className="col-md-12">

                        <p className="font-32 font-bold black-v2 mb-4 pb-3">{formData.workloadId?"Edit Workload":"Create Workload"}</p>
                        {/* stepper starts here */}
                        <div className="row justify-content-center">
                          <div className="col-md-12 col-lg-8 mb-4">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="d-flex align-items-center flex-column gap-3">
                                <div className="step-num active d-flex justify-content-center align-items-center position-relative">
                                  <p className="font-medium font-16 m-0">1</p>
                                </div>
                                <span className="font-14 font-medium step-text active">
                                  Workload Details
                                </span>
                              </div>
                              <div className="d-flex align-items-center flex-column gap-3">
                                <div className="step-num d-flex justify-content-center align-items-center position-relative step-line-1">
                                  <p className="font-medium font-16 m-0">2</p>
                                </div>
                                <span className="font-14 font-medium step-text">
                                  Environments
                                </span>
                              </div>
                              <div className="d-flex align-items-center flex-column gap-3">
                                <div className="step-num d-flex justify-content-center align-items-center position-relative step-line-2">
                                  <p className="font-medium font-16 m-0">3</p>
                                </div>
                                <span className="font-14 font-medium step-text">
                                  Repository & CI/CD
                                </span>
                              </div><div className="d-flex align-items-center flex-column gap-3">
                                <div className="step-num d-flex justify-content-center align-items-center position-relative step-line-3">
                                  <p className="font-medium font-16 m-0">4</p>
                                </div>
                                <span className="font-14 font-medium step-text">
                                  Members
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* stepper ends here */}
                      </div>
                      <div className="col-md-12">
                        <p className="font-20 font-bold black-v2 mb-3">Workload Details</p>
                      </div>
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-6 col-lg-4 mb-4 px-3 pb-3">
                            <label className="form-label font-14 font-medium black-v2" htmlFor="WorkloadName">
                              Workload Name
                              <span className="required">*</span>
                            </label>
                            <input
                type="text"
                id="WorkloadName"
                className="form-control padding-size custom-form font-14 font-medium"
                placeholder="Enter Workload Name"
                value={formData.workloadName}
                onChange={handleWorkloadNameChange} // Use the new handler
                // readOnly={!!formData.workloadId}
            />
 {errorMessage && <div className="invalid-feedback">{errorMessage}</div>}
                          </div>
                          <div className="col-md-6 col-lg-4 mb-4 px-3 pb-3">
                            <label className="form-label font-14 font-medium black-v2" htmlFor="Cloud-Provider">
                              Cloud Provider
                              <span className="required">*</span>
                            </label>
                            <select
                              id="Cloud-Provider"
                              className="form-select form-control padding-size custom-form font-14 font-medium"
                              value={formData.cloudProviderId} // Make sure this matches your state
                              onChange={(e) => handleCloudProviderChange(e.target.value)}
                              disabled={!!formData.workloadId}

                            >
                              <option value="">Select</option>
                              {providers.map((provider) => (
                                <option key={provider.providerId} value={provider.providerId}>
                                  {provider.providerName}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-md-6 col-lg-4 mb-4 px-3 pb-3">
                            <label className="form-label font-14 font-medium black-v2" htmlFor="Language">
                              Language
                              <span className="required">*</span>
                            </label>
                            <select
                              id="Language"
                              className="form-select form-control padding-size custom-form font-14 font-medium"
                              value={formData.language}
                              onChange={(e) => handleLanguageChange(e.target.value)}
                              // readOnly={!!formData.workloadId}

                            >
                              
                              <option value="" >Select </option>
                              {languages.map((language) => (
                                <option key={language.languageId} value={language.languageName}>
                                  {language.languageName}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-md-12 mb-5">
                            <label className="form-label font-14 font-medium black-v2" htmlFor="Businesss">
                              Business
                              <span className="required">*</span>
                            </label>
                            <textarea
                              className="form-control padding-size custom-form custom-textarea font-14 font-medium"
                              placeholder="Enter Business"
                              id="Businesss"
                              value={formData.business}
                              onChange={(e) => handleBusinessChange(e.target.value)}
                              // readOnly={!!formData.workloadId}

                            />
                          </div>
                          <div className="col-md-12 mb-4 pb-3">
                            <label className="form-label font-14 font-medium black-v2" htmlFor="Describe-Architecture">
                              Describe Architecture
                              <span className="required">*</span>

                            </label>
                            <textarea
                              className="form-control padding-size custom-form custom-textarea font-14 font-medium"
                              placeholder="Describe the Architecture"
                              id="Describe-Architecture"
                              value={formData.describeArchitecture}
                              onChange={(e) => setFormData({ ...formData, describeArchitecture: e.target.value })}
                              // readOnly={!!formData.workloadId}

                            />
                          </div>
                          <div className="col-md-12 text-end mb-5">
                            <button type="button" className="btn button primary-button"
                              //    disabled={isButtonDisabled}
                              onClick={handleGenerateWithAI}
                              // disabled={!!formData.workloadId ||!(formData.business &&formData.describeArchitecture)}
>

                              Generate with AI
                            </button>
                          </div>
                          <div className="col-md-6 col-lg-4 mb-4 px-3 pb-3">
                            <label className="form-label font-14 font-medium black-v2" htmlFor="Architecture-Type">
                              Architecture Type
                            </label>
                            <select
                              id="Architecture-Type"
                              className="form-select form-control padding-size custom-form font-14 font-medium"
                              value={formData.architectureId} // Use formData.architectureId directly
                              onChange={(e) => handleArchitectureChange(e.target.value)}
                              // readOnly={!!formData.workloadId}

                            >
                              <option value="">Choose Architecture Type</option>
                              {architecture.map((type) => (
                                <option key={type.architectureTypeId} value={type.architectureTypeId}>
                                  {type.architectureTypeName}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-md-6 col-lg-4 mb-4 px-3 pb-3">
                            <label className="form-label font-14 font-medium black-v2" htmlFor="Rulebook">
                              Rulebook
                            </label>
                            <button
                              type="button"
                              className="button secondary-outline-button py-4 d-block lh-0"
                              onClick={handleOpenRules}
                            >
                              Specify Rulebook <span></span>
                            </button>
                            <div>
                              {isRulesVisible && (
                                <ChooseRulebooksModal onClose={handleCloseRules} />
                              )}
                            </div>
                          </div>

                          <div className="col-md-6 col-lg-4 mb-4 px-3 pb-3">
                            <label className="form-label font-14 font-medium black-v2 cursor-pointer" htmlFor="Create-Architecture-With-AI">
                              Create Architecture with AI
                            </label>
                            <div className="form-check form-switch pt-1">
                              <input
                                className="form-check-input custom-switcher cursor-pointer"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckChecked"
                                checked={formData.createArchitectureWithAI}
                                onChange={(e) => setFormData({ ...formData, createArchitectureWithAI: e.target.checked })}
                              />
                              <label className="form-check-label font-14 font-regular  black-v2" htmlFor="flexSwitchCheckChecked">
                                Yes
                              </label>
                            </div>
                          </div>

                          <div className="col-md-6 col-lg-4 mb-4 px-3 pb-3">
                            <label className="form-label font-14 font-medium black-v2" htmlFor="Services">
                              Services
                            </label>
                            <button
                              type="button"
                              className="button secondary-outline-button py-4 d-block lh-0"
                              onClick={handleOpenModal}
                            >
                              Specify Services <span></span>
                            </button>
                            <div>
                              {isModalVisible && (
                                <ChooseServicesModal
                                  onClose={handleCloseModal}
                                  onSave={handleSaveServices} // Pass save handler to modal
                                />
                              )}
                            </div>
                          </div>

                        </div>
                        <div className="col-md-12 mb-4">
                          <div className="d-flex justify-content-between">
                            <button type="button" className="button cancel-button px-4 py-3 lh-1" onClick={() => { navigate('/workloadsGrid') }}>
                              Cancel
                            </button>
                            <button type="button" className="btn button primary-button px-32 py-3 lh-1" onClick={() => { handleNextClick(); navigate('/environmentDetails') }} disabled={!isNextEnabled}>
                              Next
                            </button>
                          </div>
                        </div>



                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default WorkloadDetailsPage;
