import React, { useState } from 'react';

const DeleteConfirmationModal = ({ item, itemType, onDeleteConfirm, onClose }) => {
    return (
        <div className="modal fade show d-block" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div className="modal-dialog modal-dialog-centered justify-content-center">
                <div className="modal-content border-0">
                    <div className="modal-header d-flex justify-content-end border-0 pb-0 ">
                        <button type="button" className="btn shadow-none" onClick={onClose} aria-label="Close">
                            <img src="images/close-icon.svg" alt="close-icon" className="icon-hover shadow-none modal-close-filter" />
                        </button>
                    </div>
                    <div className="modal-body text-center pt-0">
                        <img src="images/popup-delete.svg" alt="delete-icon" />
                        <h6 className="font-20 font-bold black-v2 mt-32 mb-4">{`You’re about to delete the ${itemType}`}</h6>
                        <p className="font-16 font-regular secondary-text-color-v4">{`“${item.workloadName || item.repoName || item.rule ||item.connectorName||item.envName}”`}</p>
                        <p className="font-16 font-regular secondary-text-color-v4">Do you want to proceed?</p>
                        <div className="d-flex justify-content-center align-items-center gap-3 mt-32 mb-3">
                            <button type="button" className="button outline-button px-4 py-12" onClick={onClose}>Cancel</button>
                            <button type="button" className="button danger-button popup-btn px-4 py-12"
                                onClick={onDeleteConfirm}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};





export default DeleteConfirmationModal;