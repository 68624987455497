import React, { useContext, useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { fetchServiceReviewData } from "../../Service/api";
import { OrgContext } from "../../ContextProvider/OrgContext";
import { WorkbenchNav } from "../Organization/WorkBenchNav";
import DeploySideBar from "./DeploySideBar";
import Loader from "../loader/loader";
import DeployLoader from "./DeployLoader";


function ServiceReviewAccordian() {


  const [activeServiceFamily, setActiveServiceFamily] = useState('');
  const [serviceConfiguration, setServiceConfiguration] = useState([]);
  const [openAccordions, setOpenAccordions] = useState({});
  // const [activeServiceFamily, setActiveServiceFamily] = useState(serviceConfiguration[0].serviceFamily.toLowerCase());
  const navigate = useNavigate();
  const [approvalId, setApprovalId] = useState("")
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [accountsData, setAccountsData] = useState([]);
  //const[selectedAccount,setSelectedAccount]=useState('')
  const[isRegenerate,setIsRegenerate]=useState(false)
  const [activeItem, setActiveItem] = useState("ServiceReview");
  const [showLoader, setShowLoader] = useState(true)
  const [isAdmin, setIsAdmin] = useState('')

  const location = useLocation()
  console.log(location, "location")

  const requestedApprovalId = location.state?.approvalId ?? [];
  const approvalgridLanguage = location.state?.language ?? []

  const envID = location.state?.environmentId?? []

  console.log(envID,"envid")
  console.log(requestedApprovalId,"approvalId")
  console.log(approvalgridLanguage,"approvalgridLanguage")


  const userId = localStorage.getItem('userId');
  console.log("userid",userId)

  const{orgId,envId,selectedAccount,setSelectedAccount}=useContext(OrgContext)
  console.log(orgId,"organization")

  useEffect(() => {
    const isAdminValue = localStorage.getItem('isAdmin');
    console.log(isAdminValue, "adminValue")
    setIsAdmin(false);
  }, []);

  console.log("isAdmin",isAdmin)

  //PS_04- Initial render
  useEffect(() => {
    fetchServiceConfiguration();
  }, [approvalId,selectedAccount]);

  //PS_05 - PS_15
  const fetchServiceConfiguration = async () => {
    if (approvalId == '') {
      setLoading(true);
      setError(null);
      const payload =
      {
        "organizationId":orgId,
        "envId": envId || envID,
        "userId":userId,
        "accountId":selectedAccount
    }           
      try {
        setShowLoader(true)
         const response = await fetchServiceReviewData(payload);
         setShowLoader(false)
        console.log(response, "resp")

          setAccountsData( response.data.body.accounts)
          setSelectedAccount(response.data.body.accountId)
      
          setServiceConfiguration(response.data.body.data, "services");

        if (response.data.body.data.length > 0) {
          setActiveServiceFamily(response.data.body.data[0].serviceFamily.toLowerCase());
        }
      } catch (fetchError) {
        setShowLoader(false)
        // Set the error message if the API call fails
        setError('An error occurred while fetching service configuration.');
      } finally {
        // Set loading to false indicating that the loading has completed
        setLoading(false);
      }
    }
  };


 

  // PS_23
  // Function to toggle accordion state
  const toggleAccordion = (accordionId) => {
    setOpenAccordions(prevState => ({
      ...prevState,
      [accordionId]: !prevState[accordionId]
    }));
  };

  //PS_33
  //On click of each serviceFamily toggle
  const handleServiceFamilyClick = (serviceFamily) => {
    setActiveServiceFamily(serviceFamily.toLowerCase());
  };

  //PS_34 - PS_39
  //Binding for on click of services
  
  
  const renderServices = () => {
    const activeServices = serviceConfiguration.find(sf => sf.serviceFamily.toLowerCase() === activeServiceFamily)?.services || [];
    
    return activeServices.map((service, serviceIndex) => (
      <div className="accordion-item border-0 body-background mb-2" key={serviceIndex}>
        <div className="template-container">
          <h2 className="accordion-header d-flex align-items-center" id={`heading${serviceIndex}`}>
            <button
              className={`cust-acc-btn ${openAccordions[`service-${serviceIndex}`] ? '' : 'collapsed'} d-flex align-items-center gap-2 font-14 btn border-0`}
              type="button"
              onClick={() => toggleAccordion(`service-${serviceIndex}`)}
            >
            </button>
            <div>
              <p className="mb-0 font-16 font-medium black-v2">
                <img src={service.image} alt={service.name} style={{ width: '20px', height: '20px', objectFit: 'contain' }} className="me-2" />
                {service.name}
              </p>
            </div>
          </h2>
          <div
            className={`accordion-collapse collapse ${openAccordions[`service-${serviceIndex}`] ? 'show' : ''}`}
          >
            <div className="accordion-body pe-0">
              {service.instances.map((instance, instanceIndex) => (
                <div className="accordion-item border-0 body-background" key={instanceIndex}>
                  <div className="template-container mt-2">
                    <h2 className="accordion-header d-flex align-items-center" id={`sub-heading${serviceIndex}-${instanceIndex}`}>
                      <button
                        className={`cust-acc-btn ${openAccordions[`instance-${serviceIndex}-${instanceIndex}`] ? '' : 'collapsed'} d-flex align-items-center gap-2 font-14 btn border-0`}
                        type="button"
                        onClick={() => toggleAccordion(`instance-${serviceIndex}-${instanceIndex}`)}
                      >
                      </button>
                      <div>
                        <p className="mb-0 font-14 font-medium black-v2">{instance.Name}</p>
                      </div>
                    </h2>
                    <div
                      className={`accordion-collapse collapse ${openAccordions[`instance-${serviceIndex}-${instanceIndex}`] ? 'show' : ''}`}
                    >
                      <div className="accordion-body mt-4">
                        <div className="row">
                          <div className="col-xl-6 pe-2 pe-xl-5 right-border">
                            {Object.entries(instance).slice(0, Math.ceil(Object.entries(instance).length / 2)).map(([key, value], i) => (
                              value ? (
                                <div className="d-flex justify-content-between align-items-center mb-4" key={i}>
                                  <h6 className="mb-0 secondary-color font-14 font-medium">{key}:</h6>
                                  {renderValue(key, value)}
                                </div>
                              ) : null
                            ))}
                          </div>
                          <div className="col-xl-6 ps-2 ps-xl-5">
                            {Object.entries(instance).slice(Math.ceil(Object.entries(instance).length / 2)).map(([key, value], i) => (
                              value ? (
                                <div className="d-flex justify-content-between align-items-center mb-4" key={i}>
                                  <h6 className="mb-0 secondary-color font-14 font-medium">{key}:</h6>
                                  {renderValue(key, value)}
                                </div>
                              ) : null
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    ));
  };
  // Handled for Tags , File Sceanrio and Boolean 
   
const renderValue = (key, value) => {
  if (key === 'Tags' && Array.isArray(value)) {
    return (
      <div className="wrap-container">
        {value.map((tag, tagIndex) => (
          <p key={tagIndex} className="font-14 font-regular secondary-color mb-0 wrap-text" style={{paddingLeft:"140px"}}>
            {tag.key}: {tag.value}
          </p>
        ))}
      </div>
    );
  } else if (key === 'Upload File' && typeof value === 'object') {
    return (
      <div className="wrap-container">
        <p className="font-14 font-regular secondary-color mb-0 wrap-text" style={{paddingLeft:"140px"}}>
          File Name: {value.fileName || '-'}
        </p>
        <p className="font-14 font-regular secondary-color mb-0 wrap-text" style={{paddingLeft:"140px"}}>
          File Value: {value.fileValue ? 'File uploaded' : '-'}
        </p>
      </div>
    );
  } else if (typeof value === 'boolean') {
    return <p className="font-14 font-regular secondary-color mb-0 wrap-text" style={{paddingLeft:"40px"}}>{value.toString()}</p>;
  } else if (value === '') {
    return <p className="font-14 font-regular secondary-color mb-0 wrap-text" style={{paddingLeft:"40px"}}>-</p>;
  } else {
    return <p className="font-14 font-regular secondary-color mb-0 wrap-text" style={{paddingLeft:"140px"}}>{value}</p>;
  }
};

  useEffect(()=>{
      console.log(serviceConfiguration, "serviceConfiguration")
  },[serviceConfiguration])
  

  //PS_27- PS_31
  //Bind on click of services family toggle
  const renderServiceFamilyMenu = () => (
    <ul className="nav nav-pills mb-3 flex-column" id="pills-tab" role="tablist">
      {serviceConfiguration.map((serviceFamily, index) => (
        <li className="nav-item" role="presentation" key={index}>
          <button
            className={`service-menu ${activeServiceFamily === serviceFamily.serviceFamily.toLowerCase() ? 'active' : ''} nav-link`}
            onClick={() => handleServiceFamilyClick(serviceFamily.serviceFamily)}
            type="button"
            role="tab"
            style={{
              
              minWidth:'350px',
              marginTop:'10px'
               
            }}
          >
            <p className="mb-0">
              <img
                src={serviceFamily.serviceFamilyImage}
                alt={serviceFamily.serviceFamily}
                className="me-3 side-icon"
                style={{ width: '24px', height: '24px', objectFit: 'contain' }}
              />
              {serviceFamily.serviceFamily}
            </p>
          </button>
        </li>
      ))}
    </ul>
  );



  return (
    <>
      {showLoader ?
        <DeployLoader/> :

      <div className="container-fluid">
        {/* header starts */}
        <WorkbenchNav/>
        {/* header ends */}
        <div className="row mt-4">
          {/* sidebar-menu start */}
          <DeploySideBar 
            activeItemName = {activeItem}
          />
          {/*sidebar-menu end  */}
          {/* service review start */}
          <div className="col-md-9 mt-3 px-32">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h5 className="font-18 font-medium black-v2 mb-0">Service Review</h5>
              <div className="d-flex align-items-center">
                <label
                  className="form-label font-14 font-medium black-v2 secondary-text-color mb-0 me-4"
                  htmlFor="Account"
                >
                  Account
                </label>
                <select
                  className="form-select custom-dropdown shadow-none custom-border-box py-2 
                  font-14 font-medium black-v2 custom-select"
                  aria-label="Default select example"
                  id="Account"
                  value={selectedAccount}
                  onChange={(e)=>setSelectedAccount(e.target.value)}
                >
                   <option selected="">Select</option>
                  {accountsData.map((account) => (
                    <option key={account.accountId} value={account.accountId}>
                      {account.accountName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row">
              {/* service review left menu start */}
              <div className="col-md-4 primary-border-right">
                {renderServiceFamilyMenu()}
              </div>
              {/* service review left menu end */}
              {/* service review right content start */}
              <div className="col-md-8 acc-scrl">
                <div className="tab-content" id="pills-tabContent">
                  {renderServices()}
                </div>
              </div>
            </div>
            {/* service review right content end */}
            <div className="d-flex justify-content-end align-items-center my-4">
            {requestedApprovalId.length !== 0 || isAdmin == "false" ? (
                // Render Proceed button if requestedApprovalId is not empty
                <button type="button" className="button primary-button text-nowrap" 
                    onClick={() => {
                      setActiveItem("CodeGeneration")
                        // Handle proceed action here
                        navigate('/DeployModuleCodeEditor', {
                          state: { 
                            selectedAccount: selectedAccount,
                             isRegenerate: true,
                             requestedApprovalId:requestedApprovalId ,
                             activeItem: "CodeGeneration",
                             language: approvalgridLanguage  // Add this line
                            }
                      });
                    }}>
                    Proceed
                </button>
            ) : (
                // Render Generate Code button if requestedApprovalId is empty
                <button type="button" className="button primary-button text-nowrap" 
                    onClick={() => {
                        navigate('/DeployModuleCodeEditor', {
                            state: { 
                              selectedAccount: selectedAccount, 
                              isRegenerate: true,
                              activeItem: "CodeGeneration" ,
                              //language: approvalgridLanguage
                             }
                        });
                    }}>
                    Generate Code
                </button>
            )}
        </div>
          </div>
        </div>
        {/* service review end */}
      </div>
}
    </>
  )
}
export default ServiceReviewAccordian;
